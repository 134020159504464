import * as ROUTES from 'Utils/constants/routes';
import CustomTabs from 'components/CustomTabs';
import CustomTypography from 'components/Typography';
import { ReactComponent as AppIcon } from 'images/app_icon.svg';
import { ReactComponent as BackIcon } from 'images/icons/backVec.svg';
import { ReactComponent as DietIcon } from 'images/icons/dietVec.svg';
import { ReactComponent as IntegrationIcon } from 'images/icons/integrationVec.svg';
import { ReactComponent as AccountSettingsIcon } from 'images/sidebar/account-settings-icon.svg';
import { ReactComponent as GoldiText } from 'images/sidebar/goldi-logo-text.svg';
import { ReactComponent as LogoutIcon } from 'images/sidebar/logout-icon.svg';
import { ReactComponent as OrdersIcon } from 'images/sidebar/orders-icon.svg';
import { ReactComponent as ReportingIcon } from 'images/sidebar/reporting-icon.svg';
import { ReactComponent as RestaurantsIcon } from 'images/sidebar/restaurants-icon.svg';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AuthService from 'services/auth.service';
import { Divider, GoldiLogo, Header, NavigationSidebar } from './index.styles';

interface propType {
  sidebarType: 'Main' | 'Client';
}

const Sidebar = ({ sidebarType }: propType) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  // TODO [integration]: make sure this is valid
  const { merchantName } = useParams();
  const navigate = useNavigate();
  // const { useAppSelector } = useCustomHook();
  // const roleType = useAppSelector((state) => state.user.role);
  const authUser = AuthService.getCurrentUser();
  const [roleType , setRoleType] = useState();
  
  useEffect(() => {
    console.log(roleType)
    AuthService.getCurrentUserRole().then((data) => {
      setRoleType(data.roleType);
    });
  }, [roleType]);

  const MerchantTabs = [
    {
      label: 'Restaurants',
      icon: <RestaurantsIcon fill={selectedTab === 0 ? 'black' : '#949494'} />,
      onClick: () => navigate(ROUTES.RESTAURANTS),
    },
    // {
    //   label: 'Reporting',
    //   icon: <ReportingIcon fill={selectedTab === 1 ? 'black' : '#949494'} />,
    //   onClick: () => navigate(ROUTES.REPORTINGS),
    // },
    {
      label: 'Orders',
      icon: <OrdersIcon fill={selectedTab === 2 ? 'black' : '#949494'} />,
      onClick: () => navigate(ROUTES.ORDERS),
    },
    {
      label: 'Account Settings',
      icon: (
        <AccountSettingsIcon fill={selectedTab === 3 ? 'black' : '#949494'} />
      ),
      onClick: () => navigate(ROUTES.ACCOUNT_SETTINGS),
    },
    // {
    //   label: 'Support',
    //   icon: <SupportIcon fill={selectedTab === 4 ? 'black' : '#949494'} />,
    //   onClick: () => navigate(ROUTES.SUPPORT),
    // },
    {
      label: 'Logout',
      icon: <LogoutIcon fill={selectedTab === 5 ? 'black' : '#949494'} />,
      onClick: AuthService.logout,
    },
  ];

  const AdminClientTabs = [
    {
      label: 'Dashboard',
      icon: <RestaurantsIcon fill={selectedTab === 0 ? 'black' : '#949494'} />,
      onClick: () => navigate(ROUTES.DASHBOARD),
    },
    {
      label: 'Reporting',
      icon: <ReportingIcon fill={selectedTab === 1 ? 'black' : '#949494'} />,
      onClick: () => navigate(ROUTES.MERCHANTS_REPORTING),
    },
    {
      label: 'Orders',
      icon: <OrdersIcon fill={selectedTab === 2 ? 'black' : '#949494'} />,
      onClick: () => navigate(ROUTES.MERCHANTS_ORDERS),
    },
    {
      label: 'Integrations',
      icon: <IntegrationIcon fill={selectedTab === 3 ? 'black' : '#949494'} />,
      onClick: () => navigate(ROUTES.MERCHANTS_INTEGRATIONS),
    },
    {
      label: 'Account Settings',
      icon: (
        <AccountSettingsIcon fill={selectedTab === 3 ? 'black' : '#949494'} />
      ),
      onClick: () => navigate(ROUTES.MERCHANTS_ACCOUNT_SETTINGS),
    },
  ];

  const AdminMainTabs = [
    {
      label: 'Merchants',
      icon: <RestaurantsIcon fill={selectedTab === 0 ? 'black' : '#949494'} />,
      onClick: () => navigate(ROUTES.MERCHANTS),
    },
    {
      label: 'Ingredients',
      icon: <DietIcon fill={selectedTab === 1 ? 'black' : '#949494'} />,
      onClick: () => navigate(ROUTES.INGREDIENTS),
    },
    {
      label: 'Orders',
      icon: <OrdersIcon fill={selectedTab === 2 ? 'black' : '#949494'} />,
      onClick: () => navigate(ROUTES.ADMIN_ORDERS),
    },
    {
      label: 'Restaurants',
      icon: <RestaurantsIcon fill={selectedTab === 3 ? 'black' : '#949494'} />,
      onClick: () => navigate(ROUTES.ADMIN_RESTAURANTS),
    },
    {
      label: 'Diets',
      icon: <DietIcon fill={selectedTab === 4 ? 'black' : '#949494'} />,
      onClick: () => navigate(ROUTES.DIETS),
    },
    {
      label: 'Allergies',
      icon: <DietIcon fill={selectedTab === 5 ? 'black' : '#949494'} />,
      onClick: () => navigate(ROUTES.ALLERGIES),
    },
    // {
    //   label: 'Account Settings',
    //   icon: (
    //     <AccountSettingsIcon fill={selectedTab ===6  ? 'black' : '#949494'} />
    //   ),
    //   onClick: () => navigate(ROUTES.ACCOUNT_SETTINGS),
    // },
    {
      label: 'Global Settings',
      icon: (
        <AccountSettingsIcon fill={selectedTab ===6  ? 'black' : '#949494'} />
      ),
      onClick: () => navigate(ROUTES.ADMIN_GLOBAL_SETTINGS),
    },
    {
      label: 'Logout',
      icon: <LogoutIcon fill={selectedTab === 7 ? 'black' : '#949494'} />,
      onClick: AuthService.logout,
    },
  ];

  const AdminOrderTabs = [
   
    {
      label: 'Orders',
      icon: <OrdersIcon fill={selectedTab === 0 ? 'black' : '#949494'} />,
      onClick: () => navigate(ROUTES.ADMIN_ORDERS),
    },
    
   
    {
      label: 'Logout',
      icon: <LogoutIcon fill={selectedTab === 1 ? 'black' : '#949494'} />,
      onClick: AuthService.logout,
    },
  ];
  const handleTabClick = (tabIndex: number) => {
    // alert(roleType); alert(sidebarType); alert(tabIndex);
    roleType === 'SuperAdmin' && sidebarType === 'Main'
      ? AdminMainTabs[tabIndex].onClick()
      : roleType === 'SuperAdmin' && sidebarType === 'Client'
      ? AdminClientTabs[tabIndex].onClick()
      : roleType === 'OrderAdmin'?
      AdminOrderTabs[tabIndex].onClick():
      
      MerchantTabs[tabIndex].onClick();
  };

  return (
    <NavigationSidebar>
      <Header>
        {roleType === 'SuperAdmin' && sidebarType === 'Client' ? (
          <div className='drawer-header'>
            <BackIcon
              width='20px'
              height='20px'
              className='back-btn app-icon'
              onClick={() => navigate(ROUTES.MERCHANTS)}
            />
            <h2 style={{ paddingLeft: '3%' }}>Merchants</h2>
          </div>
        ) : (
          <GoldiLogo>
            <AppIcon width='20px' height='20px' />
            <GoldiText />
          </GoldiLogo>
        )}
        <CustomTypography fontSize='12px' lineHeight='13px' color='#868686'>
          {roleType === "SuperAdmin" ? "ADMIN" : roleType === "OrderAdmin" ? "Order Admin" :"MERCHANT"}
        </CustomTypography>

        <CustomTypography
          fontSize='22px'
          lineHeight='28px'
          color='#0C847E'
          marginTop='10px'
        >
          {authUser?.displayName || (roleType === "SuperAdmin" ? "Goldi Admin" : roleType === 'OrderAdmin' ? "Order Admin" :"Merchant")}
        </CustomTypography>
      </Header>

      <Divider />

      <CustomTabs
        orientation='vertical'
        tabs={
          roleType === 'SuperAdmin' && sidebarType === 'Main'
            ? AdminMainTabs
            : roleType === 'SuperAdmin' && sidebarType === 'Client'
            ? AdminClientTabs
            : roleType === 'OrderAdmin' ?
            AdminOrderTabs:
            MerchantTabs
        }
        selectedTab={selectedTab}
        onChange={(
          _event: React.SyntheticEvent<Element, Event>,
          newValue: number
        ) => setSelectedTab(newValue)}
        onClick={(index: number) => handleTabClick(index)}
      />
    </NavigationSidebar>
  );
};

export default Sidebar;
