export const generateMerchantsQuery = () => 'merchants';

export const generateMerchantIdQuery = (merchantId?: string) => `merchants_${merchantId}`;

export const generateMerchantRestaurantsQuery = (merchantId?: string) => `merchants_${merchantId}_restaurants`;

export const generateRestaurantIdQuery = (restaurantId?: string) => `restaurants_${restaurantId}`;

export const generateRestaurantMenusQuery = (restaurantId?: string) => `restaurants_${restaurantId}_menus`;

export const genreateMenuIdQuery = (menuId?: string) => `menus_${menuId}`;

export const generateMenuItemsQuery = (menuId?: string) => `menus_${menuId}_menuItems`;

export const generateMenuItemIdQuery = (itemId?: string) => `menuItems_${itemId}`;

export const generateRestaurantCustomIngredientsQuery = (restaurantId?: string) => `restaurants_${restaurantId}_customIngredients`;

export const generateCustomIngredientIdQuery = (customIngredientId?: string) => `customIngredients_${customIngredientId}`;

export const generateIngredientsQuery = () => 'ingredients';

export const generateIngredientIdQuery = (ingredientId?: string) => `ingredients_${ingredientId}`;

export const generateAllergiesQuery = () => 'allergies';

export const generateAllergiesIdQuery = (allergyId?: string) => `allergies${allergyId}`;

export const generateDietsQuery = () => 'diets';

export const generateDietsIdQuery = (dietId?: string) => `ingredients_${dietId}`;

export const generatePublicRestaurantDataQuery = (restaurantId?: string) => `public_restaurants_${restaurantId}`;

export const generateOrdersQuery = () => 'orders';

export const generateOrdersIdDetailsQuery = (orderId ?: string) => `orders_${orderId}_getdetails`;

export const generateGlobalSettingsQuery = () => 'globalsettings';

export const generateChatgptIngredientNameQuery = (menuItemName: string) => `chatgpt_ingredients_${menuItemName}`;