import { Link, styled } from '@mui/material';

export const StyledLink = styled(Link)({
  fontFamily: 'Graphik',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '15px',
  letterSpacing: '-0.015em',
  color: '#0C847E',
  cursor: 'pointer',
});
