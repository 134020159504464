import { Box, Typography, Stack, Chip } from "@mui/material";
import { FastField } from "formik";
import { useDietaryPreferences } from 'providers/DietaryPreferenceProvider';
import { removeDuplicates } from '../Utils/helpers/removeDuplicates';

type PropsType = {
    diets: string[];
};

const DietsDisplay = ({ diets }: PropsType) => {
    const { dietKeyToName: Diets } = useDietaryPreferences();
    diets = removeDuplicates(diets);
    return <Box width={'100%'}>
        <Typography>Diets</Typography>
        <FastField
            name='diets'
            type='text'
        >
            {() => (
                <Stack direction="row" sx={{flexWrap:'wrap', gap:1}}>
                    {diets.map((diet) => (
                        <Chip
                            key={diet}
                            label={Diets[diet]}
                        />
                    ))}
                </Stack>
            )}
        </FastField>
    </Box> 
};

export default DietsDisplay;