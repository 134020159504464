import { InputAdornment, SxProps, Theme } from '@mui/material';
import { StyledFormHelperText } from 'components/CustomFormError/index.styles';
import { MouseEventHandler } from 'react';
import {
  DeepMap,
  FieldError,
  get,
  Path,
  RegisterOptions,
  UseFormRegister,
} from 'react-hook-form';
import {
  StyledEndAdornmentButton,
  StyledInputLabel,
  StyledTextField,
} from './index.styles';

interface ICustomTextField<TFormValues> {
  id: string;
  label?: string;
  register: UseFormRegister<TFormValues>;
  fieldName: Path<TFormValues>;
  maxLength?: number;
  validations?: RegisterOptions;
  errors?: Partial<DeepMap<TFormValues, FieldError>>;
  type?: 'text' | 'password';
  endAdornmentText?: 'SHOW' | 'HIDE';
  handleClickEndAdornment?: MouseEventHandler<HTMLButtonElement>;
  sx?: SxProps<Theme>; // Optional sx prop for custom styles
}

const CustomTextField = <TFormValues extends Record<string, unknown>>({
  id,
  label,
  register,
  fieldName,
  maxLength,
  validations,
  errors,
  type,
  endAdornmentText,
  handleClickEndAdornment,
  sx, // Add sx here
}: ICustomTextField<TFormValues>) => {
  const errorMessage = get(errors, fieldName);
  const hasError = !!(errors && errorMessage);

  return (
    <>
      <StyledInputLabel htmlFor={id}>{label}</StyledInputLabel>

      <StyledTextField
        id={id}
        type={type}
        {...(register && register(fieldName, validations))}
        error={hasError}
        inputProps={{
          maxLength,
        }}
        InputProps={
          endAdornmentText && {
            endAdornment: (
              <InputAdornment position='end'>
                <StyledEndAdornmentButton onClick={handleClickEndAdornment}>
                  {endAdornmentText}
                </StyledEndAdornmentButton>
              </InputAdornment>
            ),
          }
        }
        sx={sx ? sx : null} // Apply sx here
      />

      <StyledFormHelperText>
        {hasError && errorMessage.message}
      </StyledFormHelperText>
    </>
  );
};
export default CustomTextField;
