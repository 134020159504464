import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDietaryPreferences } from 'providers/DietaryPreferenceProvider';
import { useState } from 'react';
import intro from '../../../../images/onboarding-intro.svg';
import { PrimaryActionButton } from '../components/PrimaryActionButton';

interface Props {
  changeStep(val: number, index: boolean): any;
  stepValue: number;
}

export default function Intro({ stepValue, changeStep }: Props) {
  const theme = useTheme();
  const {
    allergies,
    diets,
    username,
    setUsernameHandler,
  } = useDietaryPreferences();

  const [name, setName] = useState(username);

  const saveName = () => {
    setUsernameHandler(name);
    changeStep(1, false);
  };

  const styles = {
    introContainer: {
      width: '100%',
      position: 'relative',
      backgroundImage: `url(${intro})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundColor: '#FFF',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh', // Ensure the content fits the viewport
     // overflow: 'hidden', // Prevents scrolling
      paddingBottom: theme.spacing(0), // Minimal padding at the bottom
    },
    imageProps: {
      height: '80px',
      width: '80px',
      display: 'block',
      marginBottom: theme.spacing(1),
    },
    selectedTab: {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 50,
      color: theme.palette.primary.lighter,
      textAlign: 'center',
      width: '100px',
      padding: 0.5,
      mt: 1,
    },
    gridContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      mt: theme.spacing(4), // Moves the images down
    },
    introContent: {
      display: 'flex',
      alignItems: 'center', // Centers content vertically
      justifyContent: 'flex-end', // Aligns content to the end of the container
      mb: theme.spacing(14),
      paddingTop: theme.spacing(8), // Adds space above the Customized text
    },
    buttonContainer: {
      mt: theme.spacing(8),
      display: 'flex',
      justifyContent: 'center',
    },
  };

  return (
    <Box sx={styles.introContainer} maxWidth="sm">
      <Grid container sx={styles.introContent}>
        <Grid item xs={6} sm={8} />
        <Grid item xs={6} sm={4} sx={{ textAlign: 'right', pr: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography variant="h3">Customized</Typography>
          <Typography variant="subtitle1" mt={3}>
            Goldi recommends restaurants and menu items to fit your needs
          </Typography>
        </Grid>
      </Grid>

      <Box sx={{ backgroundColor: theme.palette.grey[0], width: '100%' }} mb={2}>
        <Grid container>
          <Grid item xs={6} p={2} sx={{ display: 'inline-grid', alignContent: 'center' }}>
            <Typography variant="h3" mt={3}>Personalized</Typography>
            <Typography variant="subtitle1" mt={3}>Goldi makes it easy for everyone to order what they want</Typography>
          </Grid>
          <Grid item xs={6} textAlign="center">
            {allergies && (
              <Box sx={styles.gridContainer}>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/goldi-fe3e4.appspot.com/o/images%2Fgoals%2Fwhole_30_filled.png?alt=media"
                  alt="Whole 30"
                  style={styles.imageProps}
                />
                <Typography color={theme.palette.secondary.light} sx={styles.selectedTab} variant="subtitle2">
                  Whole 30
                </Typography>
              </Box>
            )}
            {diets && (
              <Box sx={styles.gridContainer}>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/goldi-fe3e4.appspot.com/o/images%2Fallergies%2Fonion_filled.png?alt=media"
                  alt="Onions"
                  style={styles.imageProps}
                />
                <Typography color={theme.palette.secondary.light} sx={styles.selectedTab} variant="subtitle2">
                  Onions
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box sx={styles.buttonContainer}>
        <PrimaryActionButton title="Select Preferences" onClick={saveName} />
      </Box>
    </Box>
  );
}
