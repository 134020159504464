import Tabs from '@mui/material/Tabs';
import CustomTab from 'components/CustomTabs/CustomTab';

interface ICustomTabs {
  tabs: Record<string, unknown>[];
  selectedTab: number;
  orientation: 'vertical' | 'horizontal' | undefined;
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number
  ) => void;
  onClick?: (index: number) => void;
}

const CustomTabs = ({
  tabs,
  selectedTab,
  orientation,
  onChange,
  onClick,
}: ICustomTabs) => {
  return (
    <Tabs
      orientation={orientation}
      value={selectedTab}
      onChange={onChange}
      TabIndicatorProps={{
        style:
          orientation === 'vertical'
            ? {
                background: '#FFBF30',
                left: '0px',
                width: '5px',
              }
            : {
                background: '#FFBF30',
                height: '4px',
              },
      }}
    >
      {tabs.map((tab: any, index: number) => (
        <CustomTab
          label={tab.label}
          key={index}
          onClick={onClick ? () => onClick(index) : undefined}
          icon={tab.icon}
          iconPosition='start'
          tabStyles={
            orientation === 'vertical'
              ? {
                  fontSize: '16px',
                  lineHeight: '18px',
                  padding: '14px auto 14px 39px',
                  width: '100%',
                  justifyContent: 'flex-start',
                  '&:hover': {
                    background: '#e5e5e5',
                  },
                  '& .MuiTab-iconWrapper': {
                    marginRight: '16px',
                  },
                }
              : {
                  fontSize: '15px',
                  lineHeight: '17px',
                  padding: '0',
                  marginRight: '34px',
                  width: 'fit-content',
                }
          }
          selectedFontWeight={orientation === 'vertical' ? 'normal' : '600'}
        />
      ))}
    </Tabs>
  );
};

export default CustomTabs;
