import { Field, FieldArray, Form, Formik, FormikProps } from 'formik';
import {Grid, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { styled } from "@mui/material/styles";

import {
  RestaurantOperationHours, 
} from 'interfaces/ResturantInterface';
import GoldiButton from '../../Button';
import { GoldiTextField } from '../../InputField';
import { RestaurantFormStepPropsType } from '..';
import { RestaurantOperatingHoursValidation } from 'Utils/Schemas/NewRestaurant.schema';
import { WEEKDAYS } from 'Utils/constants/schedule';

const StyledTableCell = styled(TableCell)({
  borderBottom: 'none',
});

const initialValues: RestaurantOperationHours = {
  operatingHours: {
    [WEEKDAYS.MONDAY]: { hours: [{startAt: '08:00', endAt: '23:30'}], isOpen: true },
    [WEEKDAYS.TUESDAY]: { hours: [{startAt: '08:00', endAt: '23:30'}], isOpen: true },
    [WEEKDAYS.WEDNESDAY]: { hours: [{startAt: '08:00', endAt: '23:30'}], isOpen: true },
    [WEEKDAYS.THURSDAY]: { hours: [{startAt: '08:00', endAt: '23:30'}], isOpen: true },
    [WEEKDAYS.FRIDAY]: { hours: [{startAt: '08:00', endAt: '23:30'}], isOpen: true },
    [WEEKDAYS.SATURDAY]: { hours: [{startAt: '08:00', endAt: '23:30'}], isOpen: true },
    [WEEKDAYS.SUNDAY ]: { hours: [{startAt: '08:00', endAt: '23:30'}], isOpen: true },
  }
}

const saveContinueRestaurantFormBtn = {
  position: 'sticky',
  bottom: 0,
  background: 'white',
  justifyContent: 'space-between',
  paddingBottom: 4,
  paddingTop: 2,
};


const OperatingHoursForm = ({ setCurrentStep, setFormValues, currentValues, currentStep }: RestaurantFormStepPropsType) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography fontWeight={'bold'} fontSize={'1.4rem'}>
          Add Operating Hours
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Formik
          initialValues={{ ...initialValues, ...currentValues }}
          enableReinitialize={true}
          onSubmit={(values: RestaurantOperationHours) => {
            setFormValues({ ...currentValues, ...values });
            setCurrentStep(++currentStep);
          }}
          validationSchema={RestaurantOperatingHoursValidation}
        >
          {({
            values,
            handleBlur,
            handleChange,
            isValid,
            handleSubmit,
          }: FormikProps<RestaurantOperationHours>) => (
            <Form>
              <FieldArray name='operatingHours'>
                {() => (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Days Available</TableCell>
                          <TableCell>Open</TableCell>
                          <TableCell>Close</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.values(WEEKDAYS).map((key) => {
                          const day = (values.operatingHours ||
                            initialValues.operatingHours)[key as WEEKDAYS];
                          return (
                            <TableRow key={key} sx={{ borderBottom: 'none' }}>
                              <StyledTableCell>
                                <Grid container spacing={1} mt={'2px'}>
                                  <Grid item xs={3}>
                                    <Field
                                      type='checkbox'
                                      name={`operatingHours.${key}.isOpen`}
                                    />
                                  </Grid>
                                  <Grid item xs={9}>
                                    <Typography textTransform={'capitalize'}>
                                      {key}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Field
                                  name={`operatingHours.${key}.hours.${0}.startAt`}
                                  value={day.hours[0].startAt}
                                >
                                  {({
                                    field, // { name, value, onChange, onBlur }
                                  }: // form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                  // meta,
                                  any) => (
                                    <GoldiTextField
                                      {...field}
                                      id={`operatingHours.${key}.hours.${0}.startAt`}
                                      type='time'
                                      value={day.hours[0].startAt}
                                      onChange={handleChange}
                                      // This is how you would save changes as you update the field
                                      // onChange={(e: React.ChangeEvent<any>) => {
                                      //   // setValues((values) => {
                                      //   //   values.operatingHours[key as WEEKDAYS].hours[0].startAt = e.target.value
                                      //   //   setFormValues({ ...currentValues, ...values });
                                      //   //   return values
                                      //   // })
                                      //   // setFieldValue(`operatingHours.${key}.hours.${0}.startAt`, e.target.value)
                                      // }}
                                      onBlur={handleBlur}
                                      inputProps={{
                                        step: 300, // 5 min
                                      }}
                                      disabled={!day.isOpen}
                                    />
                                  )}
                                </Field>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Field
                                  name={`operatingHours.${key}.hours.${0}.endAt`}
                                  value={day.hours[0].endAt}
                                >
                                  {({
                                    field, // { name, value, onChange, onBlur }
                                  }: any) => (
                                    <GoldiTextField
                                      {...field}
                                      id={`operatingHours.${key}.hours.${0}.endAt`}
                                      type='time'
                                      value={day.hours[0].endAt}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      disabled={!day.isOpen}
                                      inputProps={{
                                        step: 300, // 5 min
                                      }}
                                    />
                                  )}
                                </Field>
                              </StyledTableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </FieldArray>

              <Grid
                container
                mt={5}
                pb={1}
                sx={saveContinueRestaurantFormBtn}
               
              >
                <Grid item>
                  <GoldiButton
                    text='Back'
                    outlined={true}
                    onClick={() => setCurrentStep(currentStep - 1)}
                  />
                </Grid>

                <Grid item justifyContent='flex-end'>
                  <GoldiButton
                    text='Save And Continue'
                    disable={!isValid}
                    onClick={handleSubmit}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default OperatingHoursForm;
