import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from 'services/auth.service';
import * as ROUTES from 'Utils/constants/routes';

const SignInWithLink = () => {
    const navigate = useNavigate();
    // const [loggedIn] = useState(AuthService.getCurrentUser() ? true : false);
    const [errorSigningIn, setErrorSigningIn] = useState(false);

    const handleLoginOnLoad = async () => { 
        try {
            await AuthService.signInWithLink(window.location.href);
            navigate(ROUTES.SET_NEW_PASSWORD);
        } catch (error) {
            setErrorSigningIn(true);
        }
    }

    useEffect(() => {
        if (!AuthService.getCurrentUser() && AuthService.isValidSignInLink(window.location.href)) {
            handleLoginOnLoad();
        } else {
            navigate('/');
        }
    }, []);

    return ( errorSigningIn ? <>ERROR SIGNING IN WITH LINK</> : <></>);
};

export default SignInWithLink;
