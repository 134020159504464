import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

import { generateMerchantsQuery } from "Utils/helpers/queryGenerators";
import { Restaurant } from "interfaces/ResturantInterface";
import { useApi } from 'providers/ApiProvider';

import GoldiDropDownMenu from 'components/DropDown';
import LoadingPage from 'components/LoadingPage';
import { Merchant } from "interfaces/MerchantInterface";

type ImportRestaurantDialogPropsType = {
  isOpen: boolean;
  merchantId: string;
  handleClose: () => void;
  handleImport: (restaurant: Restaurant, merchantId:string) => void;
}

const ImportRestauranDialog = ({ isOpen, merchantId, handleClose, handleImport }: ImportRestaurantDialogPropsType) => {

  const [restaurants, setRestaurants] = useState<Restaurant[]>([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState<Restaurant>();
  const [selectedMerchant, setSelectedMerchant] = useState<Merchant>();

  const api = useApi();
  const { status: _status, error: _error, data: merchants, isLoading: _isLoading } = useQuery(generateMerchantsQuery(), async () => {
    return api.getMerchants();
  });

  const isLoading = !merchants

  const handleSelectMerchant = async (merchant: Merchant) => {
    const restaurants = await api.getMerchantRestaurants(merchant.id);
    setRestaurants(restaurants);

  };

  const handleSelectRestaurant = async (restaurant: Restaurant) => {
    setSelectedRestaurant(restaurant)
  };

  useEffect(() => {
    if (selectedMerchant) {
      handleSelectMerchant(selectedMerchant)
    } 
  }, [selectedMerchant]);

  useEffect(() => {
    if (selectedRestaurant) {
      handleSelectRestaurant(selectedRestaurant)
    }
  }, [selectedRestaurant]);

  return (isLoading ? <LoadingPage /> :
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="import-menu-dialog-title"
      aria-describedby="import-menu-dialog-description"
      maxWidth='sm'
      fullWidth={true}
    >
      <DialogTitle>
        Import Restaurant
      </DialogTitle>
      <DialogContent>
        <Typography>Select Merchant</Typography>
        <GoldiDropDownMenu
          id={'import-menu-dialog-merchants-dropdown'}
          // value={formik.values.status}
          onChange={(e: any) => {
            setSelectedMerchant(merchants.find((merchant: Merchant) => merchant.id === e.target.value))
          }}
          options={merchants.map((merchant: Merchant) => ({
            key: merchant.id,
            value: merchant.id,
            label: merchant.businessName,
          }))}
        />
        <Typography>Select Restaurant</Typography>
        <GoldiDropDownMenu
          id={'import-menu-dialog-restaurants-dropdown'}
          // value={formik.values.status}
          onChange={(e: any) => {
            setSelectedRestaurant(restaurants.find((restaurant: Restaurant) => restaurant.id === e.target.value))
          }}
          options={restaurants.map((restaurant: Restaurant) => ({
            key: restaurant.id!,
            value: restaurant.id!,
            label: restaurant.name,
          }))}
        />

      </DialogContent>
      <DialogActions>
        <Button sx={{ mr: 2 }} onClick={handleClose}>Cancel</Button>
        <Button
          variant='contained'
          disableElevation
          disabled={!selectedRestaurant}
          onClick={() => {
            handleImport(selectedRestaurant!,merchantId!)
          }}
        >
          Import
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default ImportRestauranDialog