import { Box, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';

interface TabsProps {
  children?: React.ReactNode;
  value: number;
  setValue: (newValue: number) => void;
}

function CustomTabs(props: TabsProps) {
  const theme = useTheme();
  const { children, value, setValue, ...other } = props;

  const useStyles = makeStyles({
    tabs: {
      '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.primary.darker,
        height: 3,
        overflowY: 'hidden',
      },
      '& .MuiTab-root': {
        color: theme.palette.common.black,
        fontSize: 18,
        fontWeight: 400,
      },
      '& .MuiTabs-scroller': {
          overflowX: 'scroll!important',
          overflowY: 'hidden',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none'
          }
      },
      '& .MuiTab-root.Mui-selected': {
        color: theme.palette.primary.darker,
        fontSize: 18,
        fontWeight: 400
      },
    },
  });

  const classes = useStyles();
  return (
    <Box
          component={Tabs}
          value={value}
          mt={2}
          onChange={(a: React.SyntheticEvent<Element, Event>, newValue: number) =>
            setValue(newValue)
          }
          className={classes.tabs}
          aria-label='basic tabs example'
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            mb: 2,
            overflowY: 'scroll !important',
          }}
        >
        {children}
    </Box>
  );
}

export default CustomTabs;
