import { toast } from 'react-toastify';

const showToaster = (status: string, msg: string) => {
  switch (status) {
    case 'success':
      {
        toast.success(msg, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
      break;
    case 'error':
      {
        toast.error(msg, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }

      break;
    default:
      break;
  }
};

export default showToaster;
