import { MenuItemCustomization, MenuItemCustomizationOption } from "interfaces/MenuInterface";
import { Item } from "../model/menu";
import { Preference } from "../model/preference";

interface Image {
  imageUrl?: string;
  imageUrls?: string[];
}
export const getImageUrl = ({ imageUrl, imageUrls }: Image) => {
  if (imageUrl) {
    return imageUrl;
  }

  if (imageUrls && imageUrls.length > 0) {
    return imageUrls[0];
  }

  return 'https://picsum.photos/1125/552';
};

const substituteOmmitedOptions:any = {};
const omittedOptions:any = {};
const substituteAddedOptions:any = {};
const addOptions:any = {};

export function formatCentsToDollars(value: number) {
  const a = (value + '').replace(/[^\d.-]/g, '');
  const b = parseFloat(a);
  return `$${(b ? b / 100 : 0).toFixed(2)}`;
}

export function checkValidEmail(email: string) {
  const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return expression.test(email); // true
}
export function checkValidPhone(phoneNumber:string) {
  // Remove any non-numeric characters from the phone number
  const cleanNumber = phoneNumber.replace(/\D/g, '');

  // Define a regular expression pattern to check for a valid phone number
  // You may need to customize this pattern based on your country's format
  const pattern = /^\+?[1-9]\d{1,14}$/;

  // Check if the phone number matches the pattern
  return pattern.test(cleanNumber);
}


// /**
//  * Filter the appropriate menu items for the user base on the user's diets and allergies
//  * @param user
//  * @param items
//  */
// export const getMatchingCustomizations = <T extends Item>(
//   items: T[], 
//   preferences?: Preference
// ):   T[]  => {
  
//   let filteredItems = items ? [...items] : [];
//   const omit: T[] = [];
  
//   if (preferences) {
//     const userAllergies = preferences.enabledAllergies;
//     const userDiets = preferences.enabledDiets;

//     // If user has diets, filter the subset
//     if (userDiets && userDiets.length > 0) {
//       filteredItems = filteredItems.filter(({ diets = [], additionalDiets = [], components = [], menuItemCustomizations = [], title }) => {
//         let keepItem = true; // assume we will keep the item

//         if (menuItemCustomizations && menuItemCustomizations.length > 0) {
//           for (let j = 0; j < menuItemCustomizations.length; j++) {
//             const customEle = menuItemCustomizations[j];
//             if (customEle && customEle.minPermitted > 0 && customEle.menuItemCustomizationOptions.length > 0) {
//               let boolFound = false;
//               let optionsCount = 0;
//               for (let l = 0; l < customEle.menuItemCustomizationOptions.length; l++) {
//                 const cusOptionEle = customEle.menuItemCustomizationOptions[l];
//                 if (
//                   userDiets.every((key) => cusOptionEle.diets.includes(key)) &&
//                   !(userAllergies.length > 0 && userAllergies.some((key) => cusOptionEle.allergies.includes(key)))
//                 ) {
//                   optionsCount++;
//                   boolFound = true;
//                 }
//               }
//               if (!boolFound || optionsCount < customEle.minPermitted) {
//                 keepItem = false;
//                 break;
//               }
//             }
//           }
//         }

//         if (components && components.length > 0) {
//           for (let i = 0; i < components.length; i++) {
//             const component = components[i];
//             if (
//               component.required &&
//               component.customizations.length === 0 &&
//               component.diets &&
//               (component.diets.length === 0 || !userDiets.every((key) => component.diets.includes(key)))
//             ) {
//               keepItem = false;
//               break;
//             }
//           }
//         }

//         if (!diets.length) return true; // supports all diets
//         const itemDiets = [...diets];
//         const res = userDiets.every((key) => itemDiets.includes(key));
        
//         if (!res) {
//           keepItem = false;
//         }

//         if (!keepItem) {
//           omit.push({ diets, additionalDiets, components, menuItemCustomizations, title } as T);
//         }

//         return keepItem;
//       });
//     }

//     // If user has allergies, filter the subset
//     if (userAllergies && userAllergies.length > 0) {
//       filteredItems = filteredItems.filter(({ allergies = [], additionalAllergies = [], menuItemCustomizations = [] }) => {
//         let keepItem = true;

//         if (menuItemCustomizations && menuItemCustomizations.length > 0) {
//           for (let j = 0; j < menuItemCustomizations.length; j++) {
//             const customEle = menuItemCustomizations[j];
//             if (customEle && customEle.minPermitted > 0) {
//               let boolFound = false;
//               for (let l = 0; l < customEle.menuItemCustomizationOptions.length; l++) {
//                 const cusOptionEle = customEle.menuItemCustomizationOptions[l];
//                 if (!userAllergies.some((key) => cusOptionEle.allergies.includes(key))) {
//                   boolFound = true;
//                 }
//               }
//               if (!boolFound) {
//                 keepItem = false;
//                 break;
//               }
//             }
//           }
//         }

//         const itemAllergies = [...allergies];
//         const res = userAllergies.find((key) => itemAllergies.includes(key)) === undefined;

//         if (!res) {
//           keepItem = false;
//         }

//         if (!keepItem) {
//           omit.push({ allergies, additionalAllergies, menuItemCustomizations } as T);
//         }

//         return keepItem;
//       });
//     }
//   }

//   //return { filteredItems, omit };
//   return filteredItems;
// };
/**
 * Filter the appropriate menu items for the user based on the user's diets and allergies.
 * @param items - List of menu items to filter.
 * @param preferences - User's dietary preferences and allergies.
 * @returns An object with filteredItems (matching items) and omit (items that don't match).
 */
export const getMatchingCustomizations = <T extends Item>(
  items: T[], 
  preferences?: Preference
): { filteredItems: T[], omit: T[] } => {
  
  const filteredItems = items ? [...items] : [];
  const omit: T[] = [];

  if (!preferences) return { filteredItems, omit };

  const { enabledAllergies: userAllergies = [], enabledDiets: userDiets = [] } = preferences;

  // Helper function to check if diets match
  const matchesDiets = (itemDiets: string[]) =>
    userDiets.length === 0 || userDiets.every(diet => itemDiets.includes(diet));

  // Helper function to check if allergies match
  const matchesAllergies = (itemAllergies: string[]) =>
    userAllergies.length === 0 || !userAllergies.some(allergy => itemAllergies.includes(allergy));

  const result = filteredItems.filter(item => {
    const { diets = [], allergies = [], components = [], menuItemCustomizations = [] } = item;
    let keepItem = true;

    // Check diets
    if (userDiets.length > 0 && diets.length > 0 && !matchesDiets(diets)) {
      keepItem = false;
    }

    // Check customizations for diets
    if (keepItem && menuItemCustomizations.length > 0) {
      for (const customization of menuItemCustomizations) {
        if (customization.minPermitted > 0) {
          const validOptions = customization.menuItemCustomizationOptions.filter((option: { diets: string[]; allergies: string[]; }) =>
            matchesDiets(option.diets) && matchesAllergies(option.allergies)
          );

          if (validOptions.length < customization.minPermitted) {
            keepItem = false;
            break;
          }
        }
      }
    }

    // Check required components for diets
    if (keepItem && components.length > 0) {
      for (const component of components) {
        if (
          component.required &&
          (!component.diets || !matchesDiets(component.diets))
        ) {
          keepItem = false;
          break;
        }
      }
    }

    // Check allergies
    if (keepItem && allergies.length > 0 && !matchesAllergies(allergies)) {
      keepItem = false;
    }

    if (!keepItem) {
      omit.push(item);
    }

    return keepItem;
  });

  return { filteredItems: result, omit };
};



export const getCustomizationOmitsCombined = (
  customizations: MenuItemCustomization[],
  enabledAllergies: string[],
  enabledDiets: string[],
  isHtml = false // New parameter to switch between plain text and HTML output
): { dietMismatchOptions: string[]; allergyMismatchOptions: string[]; omitMessages: string[] } => {
  const dietMismatchOptions: Set<string> = new Set();
  const allergyMismatchOptions: Set<string> = new Set();

  // Iterate through each customization and check its options
  customizations.forEach((customization) => {
    customization.menuItemCustomizationOptions.forEach((option) => {
      // Check if the option matches the user's diets
      if (enabledDiets.length > 0 && option.diets?.length) {
        const missingDiets = enabledDiets.filter((diet) => !option.diets!.includes(diet));
        if (missingDiets.length > 0) {
          if(option.defaultModifier){
          dietMismatchOptions.add(option.title);
          }
        }
      }

      // Check if the option matches the user's allergies
      if (enabledAllergies.length > 0 && option.allergies?.length) {
        const conflictingAllergies = enabledAllergies.filter((allergy) => option.allergies!.includes(allergy));
        if (conflictingAllergies.length > 0) {
          if(option.defaultModifier){
          allergyMismatchOptions.add(option.title);
          }
        }
      }
    });
  });

  // Build a combined message for all customizations
  const omitMessages: string[] = [];

  // Add diet mismatch message, if any
  if (dietMismatchOptions.size > 0) {
    const dietMessage = isHtml
      ? `Omit (diets): ${Array.from(dietMismatchOptions).join(', ')}.<br/><br/>`
      : `Omit (diets): ${Array.from(dietMismatchOptions).join(', ')}.\n\n`;
    omitMessages.push(dietMessage);
  }

  // Add allergy mismatch message, if any
  if (allergyMismatchOptions.size > 0) {
    const allergyMessage = isHtml
      ? `Omit (allergens): ${Array.from(allergyMismatchOptions).join(', ')}.<br/><br/>`
      : `Omit (allergens): ${Array.from(allergyMismatchOptions).join(', ')}.\n\n`;
    omitMessages.push(allergyMessage);
  }

  // Return the options and messages
  return {
    dietMismatchOptions: Array.from(dietMismatchOptions),
    allergyMismatchOptions: Array.from(allergyMismatchOptions),
    omitMessages,
  };
};




/**
 * Filter the appropriate menu items for the user based on the user's diets and allergies.
 * Mark items and customizations as disabled instead of removing them.
 * @param items - The list of items to filter.
 * @param enabledAllergies - The user's enabled allergies.
 * @param enabledDiets - The user's enabled diets.
 * @returns The updated items with disabled property added where necessary.
 */
export const getEnabledMatchingCustomizations = <T extends Item>(
  items: T[],
  { enabledAllergies, enabledDiets }: Preference
): T[] => {
  const updatedItems = [...items];

  // Iterate over each item and mark its components and customizations as disabled
  updatedItems.forEach(item => {
    const { diets = [], additionalDiets = [], components = [], menuItemCustomizations = [] } = item;

    // Handle menu item customizations
    if (menuItemCustomizations && menuItemCustomizations.length > 0) {
      menuItemCustomizations.forEach(customization => {
        customization.disabled = false; // Reset disabled state initially

        // Check if the customization options match the user's diets and allergies
        customization.menuItemCustomizationOptions.forEach((option: MenuItemCustomizationOption) => {
          const matchesDiets = enabledDiets.length === 0 || (option.diets && enabledDiets.every(diet => option.diets!.includes(diet)));
          const matchesAllergies = enabledAllergies.length === 0 || !(option.allergies && enabledAllergies.some(allergy => option.allergies!.includes(allergy)));

          // Mark the option as disabled if it doesn't match the diets or allergies
          option.disabled = !(matchesDiets && matchesAllergies);
        });

        // Mark the entire customization as disabled if none of its options are valid
        const validOptions = customization.menuItemCustomizationOptions.filter((option: MenuItemCustomizationOption) => !option.disabled); // Add the type annotation here
        customization.disabled = validOptions.length === 0;
      });
    }

    // Handle item components based on diets
    if (components && components.length > 0) {
      components.forEach(component => {
        component.disabled = false; // Reset disabled state initially

        // If the component has required diets, check if it matches the enabled diets
        if (component.required && component.diets && component.diets.length > 0) {
          const matchesDiets = enabledDiets.every(diet => component.diets!.includes(diet));
          component.disabled = !matchesDiets;
        }
      });
    }

    // Mark the entire item as disabled if it doesn't match the user's diets or allergies
    if (diets.length > 0) {
      const itemDiets = [...diets, ...additionalDiets];
      item.disabled = !enabledDiets.every(diet => itemDiets.includes(diet));
    } else {
      item.disabled = false; // Reset disabled state if no diets are set
    }
  });

  return updatedItems;
};
