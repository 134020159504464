import { Chip, Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { clsx } from 'clsx';
import React from 'react';
import CreateIcon from '@mui/icons-material/Create';

export const DietList: React.FC<{
  title: string;
  diets: string[];
  enabledDiets: string[];
  onToggle: (key: string) => void;
  dietToName: { [key: string]: string };
  onEdit: () => void;
}> = ({ title, diets, enabledDiets, onToggle, dietToName, onEdit }) => {
  const theme = useTheme();
  const useStyles = makeStyles(() => ({
    chipEnabled: {
      color: theme.palette.primary.dark,
      height: 48,
      borderRadius: 30,
      padding: 10,
      fontSize: 15,
      backgroundColor: theme.palette.primary.main.concat('26'),
      border: '1px solid '.concat(theme.palette.primary.main, '26')
    },

    chipDisabled: {
      border: '1px solid '.concat(theme.palette.info.light),
      color: theme.palette.info.light,
      height: 48,
      borderRadius: 30,
      padding: 10,
      fontSize: 15,
      background: theme.palette.grey[0],
      fontWeight: 'normal',
    },
  }));

  const classes = useStyles();

  return (
    <Stack direction='row' alignItems='center'>
      
      <Stack
        direction='row'
        flex={1}
        sx={{
          overflowY: 'scroll',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
        textAlign='start'
      >
        {diets.map((key) => (
          <Chip
            key={key}
            className={clsx({
              [classes.chipEnabled]: enabledDiets.includes(key),
              [classes.chipDisabled]: !enabledDiets.includes(key),
            })}
            sx={{
              mr: 1,
              mb: 1,
            }}
            label={dietToName[key]}
            onClick={(_) => onToggle(key)}
          />
        ))}
      </Stack>
      <IconButton sx={{
        maxWidth: '40px',
        maxHeight: '40px',
        backgroundColor: '#fe8154;',
        borderRadius: '50%',
      }} onClick={onEdit}>
        <CreateIcon
            style={{color: 'white', maxWidth: '40px'}}
          />
      </IconButton>
    </Stack>
  );
};
