import { SortableContainer } from 'react-sortable-hoc';
import { StyledContainer } from '../index.styles';
import SortableItem from './SortableItem';

interface ISortableList {
  items: any;
  children: React.ReactNode[];
  display: 'grid' | 'vertical';
}

const SortableList = SortableContainer(
  ({ items, children, display }: ISortableList) => {
    return (
      <StyledContainer sx={display === 'grid' ? { margin: '0 -8px' } : {}}>
        {items.map((_item: unknown, index: number) => {
          return (
            <SortableItem
              key={`item-${index}`}
              index={index}
              display={display}
            >
              {children[index]}
            </SortableItem>
          );
        })}
      </StyledContainer>
    );
  }
);
export default SortableList;
