import './index.css';

const AccountSettings = () => {
  
  return (
    <div className='main-account-settings-root'>
      <div className='main-account-settings-outlet'>
        <h1>Account Settings</h1>
      </div>
    </div>
  );
};

export default AccountSettings;
