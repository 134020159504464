import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Card, Container, Link } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import CustomButton from 'components/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox';
import CustomFormError from 'components/CustomFormError';
import CustomTextField from 'components/CustomTextField';
import { Widget } from 'interfaces/WidgetInterface';
import AuthService from 'services/auth.service';
import { loginSchema } from 'Utils/Schemas/Login.schema';

type LoginFormFields = {
  email: string;
  password: string;
};

type Props = {
  isLoggedIn: boolean;
  setLoggedIn: (value: boolean) => void;
  setLoadingIndicator: (value: boolean) => void;
  setWidget: (value: Widget) => void;
};

const LoginWidget = ({
  isLoggedIn,
  setLoggedIn,
  setLoadingIndicator,
  setWidget,
}: Props) => {
  const [rememberMe, setRememberMe] = useState<boolean>(true);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [unsuccessfulLogin, setUnsuccessfulLogin] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<LoginFormFields>({
    mode: 'onChange',
    resolver: yupResolver(loginSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const formFields = useWatch({
    control,
    name: ['email', 'password'],
  });

  useEffect(() => {
    setUnsuccessfulLogin(false);
  }, [formFields]);

  const onLogin = async (data: LoginFormFields) => {
    try {
      setLoadingIndicator(true);
      await AuthService.login(data.email, data.password, rememberMe);
      setLoadingIndicator(false);
    } catch (error) {
      console.error(error);
      setUnsuccessfulLogin(true);
      setLoadingIndicator(false);
    }
  };

  return (
    <Container>
      <Card
        sx={{
          boxShadow: 3,
          maxWidth: '95%',
          margin: '40px auto',
          padding: '20px',
        }}
      >
        <h4 style={{ textAlign: 'center' }}>Log in to Goldi</h4>

        <CustomTextField
          id='login-email'
          label='Email Address'
          register={register}
          fieldName='email'
          errors={errors}
        />

        <CustomTextField
          id='login-password'
          label='Password'
          register={register}
          fieldName='password'
          errors={errors}
          type={showPassword ? 'text' : 'password'}
          endAdornmentText={showPassword ? 'HIDE' : 'SHOW'}
          handleClickEndAdornment={() => setShowPassword(!showPassword)}
        />

        <Box
          sx={{ margin: '20px auto' }}
          display='flex'
          alignContent='center'
          justifyContent='space-between'
        >
          <CustomCheckbox
            checked={rememberMe}
            label='Remember Me'
            onChange={() => setRememberMe(!rememberMe)}
          />

          <Link
            variant='body2'
            color='#fe8154;'
            sx={{ opacity: 0.8, cursor: 'pointer' }}
            onClick={() => {
              setWidget(Widget.ForgotPassword);
            }}
          >
            Forgot Password?
          </Link>
        </Box>

        {unsuccessfulLogin && (
          <CustomFormError errorText='Incorrect email or password' />
        )}

        <CustomButton
          backgroundColor='#FE8154'
          margin='auto auto 10px'
          fontColor='white'
          onClick={handleSubmit(onLogin)}
          disabled={!isValid}
          width='100%'
        >
          Login
        </CustomButton>
        <Link
          component='button'
          variant='body2'
          color='#fe8154'
          sx={{ opacity: 0.8, cursor: 'pointer'}}
          onClick={() => {
            console.log('widget');
            setWidget(Widget.SignUp);
          }}
        >
          New User? Register
        </Link>
      </Card>
    </Container>
  );
};

export default LoginWidget;
