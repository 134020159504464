import { generateAllergiesQuery } from 'Utils/helpers/queryGenerators';
import { useApi } from 'providers/ApiProvider';
import { useQuery } from 'react-query';
import './index.css';

import { Box, Chip, Typography } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import TableActions from 'components/TableActions';
import CustomTypography from "components/Typography";

import DeleteResourceDialog from "components/DeleteResourceDialog";
import LoadingPage from 'components/LoadingPage';
import { useMutationQuery } from 'providers/MutationQueryProvider';
import { useState } from 'react';
import AllergiesDialog from './AllergiesDialog';

import { Allergy } from 'interfaces/AllergyInterface';

const Allergies = () => {
  const api = useApi();

  const { status: _status, error: _error, data: allergies, isLoading: _isLoading, refetch } = useQuery(generateAllergiesQuery(), async () => {
    return api.getAllergies();
  });

  const newAllergy = {
    id: '1',
    name: 'Example Allergy',
    description: 'Example allergy description',
    filledIconUrl: 'https://example.com/icon.png',
    active: true,
    title: 'Example Title',
    allergies: [],
    diets: [],
    Allergy: 'Example Allergy'
  };

  const [allergyDialogIsOpen, setAllergyDialogIsOpen] = useState(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const [allergy, setAllergy] = useState(newAllergy);

  const { addAllergyMutation, deleteAllergyMutation, editAllergyMutation, } = useMutationQuery();

  const [isEdit, setIsEdit] = useState<boolean>(false);



  if (_isLoading) <LoadingPage />;

  return (
    <>
      <div className='allergies-outlet'>
        <Box sx={{ px: 2, py: 4 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <CustomTypography
              fontWeight='bold'
              fontSize='24px'
              lineHeight='26px'
              marginBottom='37px'
            >
              Allergies
            </CustomTypography>
            {/* <ButtonAddNew
            label='Add New'
            styles={{ marginTop: '-8px', marginRight: '3px' }}
            onClick={() => {
              setIngredient(newIngredient);
              setIsEdit(false);
              setAllergyDialogIsOpen(true);
            }}
          /> */}
          </Box>
          <Box sx={{ backgroundColor: 'white', height: 850 }}>

            {!_isLoading && <Box sx={{ backgroundColor: 'white', height: 850 }}>
              <DataGrid
                // editMode='row'
                showCellRightBorder={true}
                rows={allergies}
                columns={[
                  {
                    field: 'id', headerName: 'Key', minWidth: 120, flex: 1,
                    renderCell: (params) => {
                      return params.row.key
                    },
                  },
                  {
                    field: 'icon', minWidth: 150, headerName: 'Image',
                    renderCell: (params) => {
                      return (
                        <Box sx={{
                          width: 100,
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}>
                          <img src={params.row.unfilledIconUrl} width={30} height={30} style={{ marginRight: 10 }} />
                          <img src={params.row.filledIconUrl} width={30} height={30} />
                        </Box>
                      )
                    },
                  },
                  {
                    field: 'title', headerName: 'Name', minWidth: 100, flex: 1,
                    renderCell: (params) => {
                      return params.row.name
                    },
                  },
                  {
                    field: 'description',
                    headerName: 'Description',
                    minWidth: 200,
                    flex: 1,
                    renderCell: (params) => {
                      return (
                        <Typography variant='body2' overflow={'scroll'}>
                          {params.row.description}
                        </Typography>
                      )
                    }
                  },
                  {
                    field: 'active',
                    headerName: 'Active',
                    minWidth: 80,
                    flex: 1,
                    renderCell: (params) => {
                      return (
                        <Chip
                          label={params.row.active ? 'Yes' : 'No'}
                          color={params.row.active ? 'success' : 'error'}
                        />
                      );
                    },
                  },
                  {
                    field: 'actions',
                    headerName: 'Actions',
                    minWidth: 150,
                    flex: 1,
                    renderCell: (params) => {
                      return (
                        <>
                          {
                            params.row.active ? <TableActions
                              handleEdit={() => {
                                // alert('Working on it');
                                setAllergy(params.row);
                                setIsEdit(true);
                                setAllergyDialogIsOpen(true);
                              }}
                            />
                              :
                              <TableActions
                                handleEdit={() => {
                                  // alert('Working on it');
                                  setAllergy(params.row);
                                  setIsEdit(true);
                                  setAllergyDialogIsOpen(true);
                                }}
                                handleDelete={() => {
                                  alert('Working on it');
                                  // setIngredient(params.row);
                                  // setDeleteDialogIsOpen(true);
                                }}
                              />
                          }
                        </>
                      );
                    },
                  },
                ]}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
            </Box>}
          </Box>
        </Box>

        <AllergiesDialog
          isOpen={allergyDialogIsOpen}
          saveDisabled={
            addAllergyMutation.isLoading || editAllergyMutation.isLoading
          }
          allergy={allergy}
          isEdit={isEdit}
          handleCloseDialog={() => {
            setAllergyDialogIsOpen(false);
          }}
          handleSave={async (allergy: Allergy) => {
            const result = isEdit
              ? await editAllergyMutation.mutateAsync(allergy)
              : await addAllergyMutation.mutateAsync(allergy);

            if (!isEdit) {
              setAllergy(result);
              setIsEdit(true);
            }
          }}
        />

        <DeleteResourceDialog
          isOpen={deleteDialogIsOpen}
          handleClose={() => setDeleteDialogIsOpen(false)}
          title={`Delete ${allergy?.name || 'Allergy'}?`}
          body={
            'This is an irreversible action. Are you sure you wish to delete this ingredient?'
          }
          handleDelete={async () => {
            alert('working on it');
            // await deleteAllergyMutation.mutateAsync(ingredient);
            // setIngredient(newIngredient);
            // setDeleteDialogIsOpen(false);
          }}
        // disabled={deleteIngredientMutation.isLoading}
        />
      </div>
    </>
  );
};

export default Allergies;
