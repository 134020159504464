import { Box, styled } from '@mui/material';

export const TabContainer = styled(Box)({
  padding: '32px 94px',
  '@media (max-width: 768px)': {
    padding: '20px',
  },
});

export const OverviewContainer = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: '28px',
  width: '100%',
  height: 'fit-content',
  background: '#FFFFFF',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.04)',
  borderRadius: '8px',
  padding: '24px 18px 28px 24px',
});

export const ImageryContainer = styled(Box)({
  width: '100%',
});
