import { Box, styled } from '@mui/material';

export const Content = styled(Box)({
  width: '402px',
  background: '#FFFFFF',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.04)',
  borderRadius: '4px',
  margin: 'auto',
  padding: '34px 24px',
  paddingBottom: '38px',
  display: 'flex',
  flexDirection: 'column',
});
