import { getStorage, ref, uploadBytes } from 'firebase/storage';
import makeFirestoreFileUrl from './makeStorageFileUrl';

export const uploadFileToStorage = async (file: File, storagePath: string): Promise<string> => {
    const storageRef = ref(getStorage(), storagePath);
    const snapshot = await uploadBytes(storageRef, file);

    return makeFirestoreFileUrl(snapshot.metadata.fullPath);    
}

export default uploadFileToStorage