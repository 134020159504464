import './index.css';
import { BiArrowBack } from 'react-icons/bi';

interface propType {
  text: string;
  onClick: () => void;
}

const SimpleNav = ({ text, onClick }: propType) => {
  return (
    <div className='simple-nav-root'>
      <div className='simple-nav-left'>
        <BiArrowBack className='cross-icon' onClick={() => onClick()} />
        <span className='nav-text'>{text}</span>
      </div>
     
    </div>
  );
};

export default SimpleNav;
