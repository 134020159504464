import { styled } from '@mui/system';

export const RestaurantDetailsHeader = styled('div')({
  // height: '144px',
  backgroundColor: 'white',
  padding: '32px 94px 0 94px',
  '@media (max-width: 768px)': {
    paddingLeft: '20px',
  },
});
