import { Box, Chip, Stack, Typography } from "@mui/material";
import { FastField } from "formik";
import { useDietaryPreferences } from 'providers/DietaryPreferenceProvider';
import { removeDuplicates } from '../Utils/helpers/removeDuplicates';

type PropsType = {
    allergies: string[];
};

const AllergiesDisplay = ({ allergies }: PropsType) => {
    const { allergyKeyToName: Allergies } = useDietaryPreferences();
    allergies = removeDuplicates(allergies);
    return <Box width={'100%'}>
        <Typography>Dislikes</Typography>
        <FastField
            name='allergies'
            type='text'
        >
            {() => (
                <Stack direction="row" spacing={1}>
                    {allergies.map((allergy) => (
                        <Chip
                            key={allergy}
                            label={Allergies[allergy]}
                        />
                    ))}
                </Stack>
            )}
        </FastField>
    </Box>
};

export default AllergiesDisplay;