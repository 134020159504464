import React, { ReactElement } from 'react';

import ApiService, { IApi } from '../services/api.service';

const ApiContext = React.createContext<IApi | null>(null);

interface IProps {
  children: ReactElement;
}

export function ApiProvider({ children }: IProps): ReactElement {
    /** Initialize API **/
  const api: IApi = new ApiService();
  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
}

export function useApi(): IApi {
  const api = React.useContext(ApiContext);

  //Required by typescript so don't need to check for null all the time
  if (!api) {
    throw new Error('useApi must be used by the children of the ApiProvider.');
  }

  return api;
}
