import { Grid, Typography, Box, Divider, ImageListItem } from '@mui/material';
import CustomEdit from 'components/CustomEditBox';
import { Merchant } from 'interfaces/MerchantInterface';
// import { WEEKDAYS } from 'Utils/constants/schedule';

type MerchantSummaryPropsType = {
    merchant: Merchant;
    handleClickEdit?: () => void;
    canEdit?: boolean;
}

const MerchantSummary = ({ merchant, handleClickEdit, canEdit = false }: MerchantSummaryPropsType) => {
    return (<>
        <Grid item container>
          <Grid item xs={5}>
            <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
              <Typography fontWeight={'bold'}> {merchant.businessName} </Typography>
              {canEdit && <CustomEdit handleClick={handleClickEdit || (() => ({}))} sx={{ ml: 2}} />}
            </Box>
            <Typography> {merchant.businessAddress} </Typography>
            <Typography> {merchant.phoneNumber} </Typography>
            <br />
            <Typography fontWeight={'bold'}> {merchant.contactName} </Typography>
            <Typography> {merchant.email} </Typography>
            <Typography> {merchant.contactPhoneNumber} </Typography>
          </Grid>
        </Grid>
        <Divider />

        {(merchant.image || merchant.imageUrl) && 
          <Grid item container mt={2}>
            <Grid item xs={7}>
              <ImageListItem>
                <img
                  alt={merchant.businessName}
                  src={(merchant.image && URL.createObjectURL(merchant.image)) || merchant.imageUrl || ''}
                />
              </ImageListItem>
              
            </Grid>
          </Grid>}
    </>)
}

export default MerchantSummary