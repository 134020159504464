import { Grid, Typography } from '@mui/material';
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';

import { MerchantInformationValidation } from 'Utils/Schemas/Merchant.schema';
import GoldiDropDownMenu from 'components/DropDown';
import { MerchantInformation, MerchantStatus } from 'interfaces/MerchantInterface';
import { MerchantFormStepPropsType } from '..';
import GoldiAddressInputField from '../../AddressInputField';
import GoldiButton from '../../Button';
import { GoldiPhoneNumberField, GoldiTextField } from '../../InputField';

const initialValues: MerchantInformation = {
  businessName: '',
  businessAddress: '',
  phoneNumber: '',
  contactName: '',
  contactPhoneNumber: '',
  email: '',
  status: MerchantStatus.PENDING,
  homePage: '',
  restaurantCount: 0,
};

const saveContinueButton = {
  position: 'sticky',
  bottom: 0,
  background: 'white',
  justifyContent: 'flex-end',
  paddingBottom: 2,
  paddingTop: 2,
};


  
export const MerchantInformationForm = ({ setCurrentStep, setFormValues, currentValues, currentStep, isAdmin = false }: MerchantFormStepPropsType) => {

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography fontWeight={'bold'} fontSize={'1.4rem'}>
          Merchant Information
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant='subtitle1' fontWeight={'bold'}>
          Input Merchant information below
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Formik
          initialValues={{ ...initialValues, ...currentValues }}
          enableReinitialize={true}
          onSubmit={(values: MerchantInformation) => {
            setFormValues({ ...currentValues, ...values });
            setCurrentStep(++currentStep);
          }}
          validationSchema={MerchantInformationValidation}
        >
          {(formik: FormikProps<MerchantInformation>) => (
            <Form>
              <Grid container spacing={2}>
                {/* Merchant Name */}
                <Grid item xs={12}>
                  <Typography>Merchant Name*</Typography>
                  <Field name='businessName' type='text'>
                    {({ field, form: { errors } }: FieldProps) => (
                      <GoldiTextField
                        {...field}
                        id={field.name}
                        value={formik.values.businessName || ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={!!errors[field.name]}
                      />
                    )}
                  </Field>
                </Grid>
                {/* Merchant Address */}
                <Grid item xs={12}>
                  <Typography>Merchant Address*</Typography>
                  <Field name='businessAddress' type='text'>
                    {({ field, form: { errors } }: FieldProps) => (
                      <GoldiAddressInputField
                        value={formik.values.businessAddress || ''}
                        error={!!errors[field.name]}
                        handleSelect={(address) => {
                          formik.setFieldValue(
                            'businessAddress',
                            address,
                            true
                          );
                        }}
                      />
                    )}
                  </Field>
                </Grid>

                {/* Contact Info */}
                <Grid item xs={6}>
                  {/* Merchant Phone Number */}
                  <Typography>Merchant Phone Number*</Typography>
                  <Field name='phoneNumber' type='text'>
                    {({ field, form: { errors } }: FieldProps) => (
                      <GoldiPhoneNumberField
                        {...field}
                        id={field.name}
                        value={formik.values.phoneNumber || ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={!!errors[field.name]}
                      />
                    )}
                  </Field>
                </Grid>

                {isAdmin && (
                  <Grid item xs={6}>
                    {/* Merchant Phone Number */}
                    <Typography>Merchant Status*</Typography>
                    <Field name='status' type='text'>
                      {({ field, form: { errors } }: FieldProps) => (
                        <GoldiDropDownMenu
                          {...field}
                          id={field.name}
                          value={formik.values.status || MerchantStatus.PENDING}
                          onChange={formik.handleChange}
                          options={Object.values(MerchantStatus).map(
                            (value) => ({
                              key: value,
                              value: value,
                              label: value.toUpperCase(),
                            })
                          )}
                          onBlur={formik.handleBlur}
                          error={!!errors[field.name]}
                        />
                      )}
                    </Field>
                  </Grid>
                )}

                {/* Merchant Contact Full Name */}
                <Grid item xs={12}>
                  <Typography>Merchant Contact Full Name*</Typography>
                  <Field name='contactName' type='text'>
                    {({ field, form: { errors } }: FieldProps) => (
                      <GoldiTextField
                        {...field}
                        id={field.name}
                        value={formik.values.contactName || ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={!!errors[field.name]}
                      />
                    )}
                  </Field>
                </Grid>

                {/* Email */}
                <Grid item xs={6}>
                  <Typography>Email Address*</Typography>
                  <Field name='email' type='text'>
                    {({ field, form: { errors } }: FieldProps) => (
                      <GoldiTextField
                        {...field}
                        id={field.name}
                        value={formik.values.email || ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={!!errors[field.name]}
                        disabled={currentValues.id ? true : false}
                      />
                    )}
                  </Field>
                </Grid>

                {/* Phone */}
                <Grid item xs={6}>
                  <Typography>Contact Phone Number*</Typography>
                  <Field name='contactPhoneNumber' type='text'>
                    {({ field, form: { errors } }: FieldProps) => (
                      <GoldiPhoneNumberField
                        {...field}
                        id={field.name}
                        value={formik.values.contactPhoneNumber || ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={!!errors[field.name]}
                      />
                    )}
                  </Field>
                </Grid>

                {/* Merchant Home Page */}
                <Grid item xs={12}>
                  <Typography>Merchant Home Page</Typography>
                  <Field name='homePage' type='text'>
                    {({ field, form: { errors } }: FieldProps) => (
                      <GoldiTextField
                        {...field}
                        id={field.name}
                        value={formik.values.homePage || ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={!!errors[field.name]}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid
                  item
                  container
                  mt={5}
                  mb={1}
                  sx={saveContinueButton}
                >
                  <GoldiButton
                    text='Save and Continue'
                    disable={!formik.isValid}
                    onClick={formik.handleSubmit}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default MerchantInformationForm;