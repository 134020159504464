import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as MoreOptions } from 'images/restaurants/details/menu-tab/menu-details/more-options.svg';
import { ReactElement, useState } from 'react';
import { Box } from '@mui/material';
import { StyledMenu, StyledMenuItem } from './index.styles';

interface MenuOption {
  label: string;
  onClick: () => void;
}

interface IOptionsMenu {
  id: string;
  options: MenuOption[];
  buttonIcon?: ReactElement;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export default function OptionsMenu({
  id,
  options,
  buttonIcon,
  onClick,
}: IOptionsMenu) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (index: number) => {
    handleClose();
    options[index].onClick();
  };

  return (
    <Box onClick={onClick}>
      <IconButton id={id} onClick={handleClick}>
        {buttonIcon ? buttonIcon : <MoreOptions />}
      </IconButton>
      <StyledMenu id={id} anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options.map((option, index) => (
          <StyledMenuItem
            key={option.label}
            onClick={() => handleOptionClick(index)}
          >
            {option.label}
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </Box>
  );
}
