import React, { useState, useEffect } from 'react';
import { Box, Card, Container, Link } from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import CustomTextField from 'components/CustomTextField';
import CustomButton from 'components/CustomButton';
import CustomFormError from 'components/CustomFormError';
import { forgotPasswordSchema } from 'Utils/Schemas/ForgotPassword.schema';
import AuthService from 'services/auth.service';
import { Widget } from 'interfaces/WidgetInterface';

type ForgotPasswordFormFields = {
  email: string;
};

type Props = {
  setLoadingIndicator: (value: boolean) => void;
  setWidget: (value: Widget) => void;
};

const ForgotPasswordWidget = ({
  setLoadingIndicator,
  setWidget,
}: Props) => {
  const [unsuccessfulReset, setUnsuccessfulReset] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<ForgotPasswordFormFields>({
    mode: 'onChange',
    resolver: yupResolver(forgotPasswordSchema),
    defaultValues: {
      email: '',
    },
  });

  const formFields = useWatch({
    control,
    name: ['email'],
  });

  useEffect(() => {
    setUnsuccessfulReset(false);
  }, [formFields]);

  const onResetPassword = async (data: ForgotPasswordFormFields) => {
    try {
       setLoadingIndicator(true);
      // TODO: to be integrated in later versions
       await AuthService.sendResetPasswordEmail(data.email);
      setWidget(Widget.Login);
    } catch (error) {
      console.error(error);
      setUnsuccessfulReset(true);
      setLoadingIndicator(false);
    }
  };

  return (
    <Container>
      <Card
        sx={{
          boxShadow: 3,
          maxWidth: '95%',
          margin: '40px auto',
          padding: '20px',
        }}
      >
        <h4 style={{ textAlign: 'center' }}>Forgot Password</h4>

        <CustomTextField
          id='forgot-email'
          label='Email Address'
          register={register}
          fieldName='email'
          errors={errors}
        />

        {unsuccessfulReset && (
          <CustomFormError errorText='Reset failed. Please check your email.' />
        )}

        <CustomButton
          backgroundColor='#fe8154;'
          margin='auto auto 10px'
          fontColor='white'
          onClick={handleSubmit(onResetPassword)}
          disabled={false}
          width='100%'
        >
          Reset Password
        </CustomButton>

        <Link
          variant='body2'
          color='#fe8154;'
          sx={{ opacity: 0.8, cursor: 'pointer' }}
          onClick={() => {
            setWidget(Widget.Login);
          }}
        >
          Back to Login
        </Link>
      </Card>
    </Container>
  );
};

export default ForgotPasswordWidget;
