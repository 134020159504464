import './index.css';

const Reportings = () => {
  return (
    <div className='reportings-root'>
      <h1>Reportings</h1>
    </div>
  );
};

export default Reportings;
