import Stack from '@mui/material/Stack';
import { Button, Divider, TextField, Typography, Grid, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDietaryPreferences } from 'providers/DietaryPreferenceProvider';
import logo from '../../../images/fall_back-menu.svg';
import notForMe from '../../../images/not-for-me-line.png';
import { useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';

export enum PreferenceType {
  allergy,
  diet,
}

type Props = {
  preferenceType: PreferenceType;
};


const SelectPreferences = ({ preferenceType }: Props) => {
  const {
    allergies,
    diets,
    selectedAllergies,
    selectedDiets,
    toggleSelectedAllergy,
    toggleSelectedDiet,
  } = useDietaryPreferences();
  const theme = useTheme();
  const hash = window.location.hash;
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const imageProp = {
    height: '47.5px',
    width: '47.5px',
    display: 'inline-block'
  }

  const notForMeProp = {
    top: 12,
    left: '50%',
    marginLeft: '-24px',
    position: 'absolute',
  }

  const selectedTabProp = {
    backgroundColor: theme.palette.secondary.main, 
    borderRadius: 50, 
    color: theme.palette.primary.lighter,
    padding: .5
  }

  const defaultTabProp = {
    backgroundColor: theme.palette.grey[0],
    padding: .5
  }


  const title = preferenceType === PreferenceType.allergy
          ? 'Dislikes'
          : 'Preferences';


  const preferences =
    (preferenceType === PreferenceType.allergy ? allergies : diets) ?? [];
  const selectedPreferences =
    preferenceType === PreferenceType.allergy
      ? selectedAllergies
      : selectedDiets;

  useEffect(() => {
    if(selectedPreferences.length > 3 && preferenceType != PreferenceType.allergy){
      const lastPreference:string = selectedPreferences[selectedPreferences.length - 1];
      onToggle(lastPreference);
      setError("You can select upto 3 ".concat(title));
    }
  }, [selectedPreferences]);

  const onToggle =
    preferenceType === PreferenceType.allergy
      ? toggleSelectedAllergy
      : toggleSelectedDiet;

  const [filteredPreferences, setFilteredPreferences] = useState(
    () => preferences
  );

  const addDefaultSrc = (ev:any) => {
    //ev.target.src = 'some default image url'
    ev.target.src = logo
  }

  useEffect(() => {
    if (searchTerm.trim().length === 0) {
      setFilteredPreferences(preferences);
    } else {
      setFilteredPreferences(
        preferences.filter(({ name }) =>
          name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [searchTerm, preferences]);

  useEffect(() => {
    const filteredPref = [...filteredPreferences];
    filteredPref.forEach(({filledIconUrl}) => {
      const newImage = new Image();
      newImage.src = filledIconUrl;
    });
  }, [filteredPreferences]);

  const fontColor = theme.palette.secondary.light;
  return (
    <Stack sx={{ pb: 6 }}>
      <Typography variant='h4' color={theme.palette.secondary.main}>
        {title}
      </Typography>
      <TextField
        placeholder='Filter'
        size='small'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{mt: 2}}
        inputProps={{
           style: {
             paddingLeft: 25
           }
        }}
      />
      {error && <Typography variant='subtitle2' sx={{color: theme.palette.chart.red}}>{error}</Typography>}
      <Grid mt={4} p={0} container alignItems='start' mb={12}>
        {filteredPreferences.map(({ id, name, key, filledIconUrl, unfilledIconUrl }) => (
          <Grid onClick={() => onToggle(key)}  key={id} item xs={4} sm={4} md={4}>
            <Box sx={{position:'relative'}} textAlign='center' p={1} mt={1}>
              <img onError={addDefaultSrc} src={selectedPreferences.indexOf(key) > -1 ? filledIconUrl : unfilledIconUrl} style={imageProp}  />
              {/**selectedPreferences.indexOf(key) > -1 && preferenceType === PreferenceType.allergy && <Box sx={notForMeProp}><img src={notForMe} /></Box>**/}
              <Typography mt={1} color={fontColor} sx={selectedPreferences.indexOf(key) > -1 ? selectedTabProp : defaultTabProp} variant='subtitle2'>{name}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default SelectPreferences;
