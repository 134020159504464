import { generatePublicRestaurantDataQuery } from 'Utils/helpers/queryGenerators';
import { MenuItem } from 'interfaces/MenuInterface';
import { FullRestaurant } from 'interfaces/ResturantInterface';
import { useApi } from 'providers/ApiProvider';
import { useDietaryPreferences } from 'providers/DietaryPreferenceProvider';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Cart, CartItem, Order, OrderType } from '../model/cart';
import { getMatchingCustomizations } from '../util/util';

const CartContext = createContext<{
  cart: Cart;
  orders: Order;
  addToCart: (action: UpdateCartAction) => void;
  publicRestaurant: FullRestaurant;
  removeFromCart: (action: UpdateCartAction) => void;
  updateQuantity: (action: UpdateCartAction) => void;
  setCartTotal: (newCart: Cart, userId: string) => void;
  clearCart: () => void;
  saveOrder: (cartObj: any) => void;
  isInCart: (item: MenuItem) => boolean;
}>({} as any);

const initialState: Cart = {
  orders: [],
  subtotal: 0,
};

const initialStateOrders: Order = {
  cart: initialState,
  restaurant: {},
  notes: '',
  OrderType: OrderType.InRestaurant
}

interface UpdateCartAction {
  userId: string;
  item: CartItem;
  index?: number;
}

interface Props {
  children: ReactNode;
}

export const CartContextProvider = ({ children }: Props) => {
  const [cart, setCart] = useState(initialState);
  const [orders, setOrders] = useState<Order>(initialStateOrders);
  const { restaurantId } = useParams();
  const api = useApi();
  const { enabledAllergies, enabledDiets } = useDietaryPreferences();
 const {
   status: _publicRestaurantStatus,
   error: _publicRestaurantError,
   data: publicRestaurant,
   isLoading: _isLoadingDPublicRestaurant,
 } = useQuery(generatePublicRestaurantDataQuery(), async () => {
   return api.getPublicRestaurantData(restaurantId);
 });

 const getCartHandler = () => {
  if(publicRestaurant && publicRestaurant.id) {
      const localCart = sessionStorage.getItem('cart');
      if(localCart) {
        const localCartJSON = JSON.parse(localCart);
        return localCartJSON[publicRestaurant.id];
      }
    }
    return cart;
 }

 const getLastOrderHandler = () => {
    const localOrders = localStorage.getItem('orders');
    if(localOrders) {
      const localOrdersJSON = JSON.parse(localOrders);
      return localOrdersJSON[localOrdersJSON.length - 1];
    }
    return orders;
 }

 useEffect(() => {
    const cartTemp = getCartHandler();
    if(cartTemp) {
      setCart(cartTemp);
    }
    const orderTemp = getLastOrderHandler();
    if(orderTemp) {
      setOrders(orderTemp);
    }
  }, [publicRestaurant])

  const setCartHandler = async (obj: Cart) => {
    const restoCart:any = {};
    restoCart[publicRestaurant.id] = obj;
    sessionStorage.setItem('cart', JSON.stringify(restoCart));
    setCart(obj);
  }

  const isInCart = (item: MenuItem) => {
    return !!cart.orders
      .flatMap(({ items }) => items)
      .find(({ menuItem }) => menuItem.id === item.id);
  };

  const addToCart = ({ userId, item }: UpdateCartAction) => {
    const newCart = { ...cart };
    //   const { id } = user
    const userIndex = cart.orders.findIndex(
      ({ userId: uid }) => uid === userId
    );
    item.preference = { enabledAllergies, enabledDiets };
    // Upserts user's order
    if (userIndex > -1) {
      newCart.orders[userIndex].items.push(item);
    } else {
      newCart.orders.push({
        userId,
        items: [item],
        quantity: item.quantity,
        size:item.quantity,
        total: item.price * item.quantity,
      });
    }

    newCart.subtotal = newCart.orders.reduce(
      (sum, order) => sum + order.total,
      0
    );
    setCartTotal(newCart, userId);
  };

  const removeFromCart = ({ userId, item, index }: UpdateCartAction) => {
    const newCart = { ...cart };
    const userIndex = newCart.orders.findIndex(
      ({ userId: uid }) => uid === userId
    );
    if (userIndex == -1) {
      return;
    }
    if(index != null) newCart.orders[userIndex].items.splice(index, 1);
    setCartTotal(newCart, userId);
    //setCartHandler(newCart);
  };

  const setCartTotal = (newCart: Cart, userId: string) => {
    const userIndex = newCart.orders.findIndex(({ userId: uid }) => uid === userId);
    
    if (userIndex === -1) return;
  
    const items = newCart.orders[userIndex].items;
    let tempTotal = 0;
  
    items.forEach((item) => {
      const selectedCustomizationOptionIds = item.customizations
        .flatMap((customization) => customization.menuItemCustomizationOptions)
        .map((option) => option.id);
  
      // Get only the filtered customizations
      const { filteredItems } = getMatchingCustomizations(item.menuItem.menuItemCustomizations, {
        enabledAllergies,
        enabledDiets,
      });
  
      const customizationPrice = filteredItems
        .flatMap(({ menuItemCustomizationOptions }) => menuItemCustomizationOptions)
        .filter(({ id }) => selectedCustomizationOptionIds.includes(id ?? ''))
        .reduce((sum, { price }) => sum + price, 0);
  
      // Update the customized price for the item and accumulate the total
      item.customisedPrice = item.price + customizationPrice;
      tempTotal += item.customisedPrice * item.quantity;
    });
  
    // Update the total and subtotal in the cart
    newCart.orders[userIndex].total = tempTotal;
    newCart.subtotal = tempTotal;
    setCartHandler(newCart);
  };
  

  const updateQuantity = ({ userId, item, index }: UpdateCartAction) => {
    const newCart = { ...cart };
    const userIndex = cart.orders.findIndex(
      ({ userId: uid }) => uid === userId
    );
    if (userIndex > -1) {
      newCart.orders[userIndex].items.map((val, i) => {
        if(val.menuItem.id === item.menuItem.id && i === index) {
          val.quantity = item.quantity;
        }
      });
    }
    setCartTotal(newCart, userId);
  };

  const saveOrder = async (cartObj:any) => {
    const orders = localStorage.getItem('orders');
    let ordersJSON = null;
    if(orders) {
      ordersJSON = JSON.parse(orders);
      ordersJSON.push(cartObj);
    } else {
      ordersJSON = [cartObj];
    }
    setOrders(ordersJSON[ordersJSON.length - 1]);
    localStorage.setItem('orders', JSON.stringify(ordersJSON));
  }

  const clearCart = async () => {
    setCart({
      orders: [],
      subtotal: 0,
    });
    sessionStorage.setItem('cart', '');
  };

  return (
    <CartContext.Provider value={{ cart, publicRestaurant, isInCart, setCartTotal, addToCart, removeFromCart, updateQuantity, clearCart, saveOrder, orders }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
