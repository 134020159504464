import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import toPriceDisplay from 'Utils/helpers/toPriceDisplay';
import { MenuItem } from 'interfaces/MenuInterface';
import { BaseSyntheticEvent, useEffect, useState } from 'react';

type EditMenuItemsDialogPropsType = {
    isOpen: boolean;
    categoryItems: string[];
    menuItems: MenuItem[];
    handleCloseModal: () => void;
    handleSave: (menuItemIds: string[]) => void;
}

const EditMenuItemsDialog = ({
    isOpen,
    categoryItems,
    menuItems,
    handleCloseModal,
    handleSave
}: EditMenuItemsDialogPropsType) => {
    const [selectedCategoryItems, setSelectedCategoryItems] = useState<string[]>(categoryItems);

    useEffect(() => {
        setSelectedCategoryItems(categoryItems);
    }, [categoryItems])

    const handleCancel = () => {
        setSelectedCategoryItems(categoryItems);
        handleCloseModal();
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleCloseModal}
            aria-labelledby="edit-menu-item-modal-title"
            aria-describedby="edit-menu-item-modal-description"
            maxWidth='sm'
            fullWidth={true}
        >
            <DialogTitle>
                Edit Menu Subsection Items
            </DialogTitle>
            <DialogContent>
                <Box
                    height={650}
                    width='100%'
                >
                    <DataGrid
                        showCellRightBorder={true}
                        rows={menuItems.map(m => ({ id: m.id, name: m.title, price: toPriceDisplay(m.price) }))}
                        columns={[
                            { field: 'name', headerName: 'Name', width: 250 },
                            { field: 'price', headerName: 'Price', width: 100 },
                        ]}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        checkboxSelection
                        onSelectionModelChange={(selectionModel) => {
                            console.log({ selectionModel })
                            setSelectedCategoryItems(selectionModel as string[]);
                        }}
                        selectionModel={selectedCategoryItems}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button sx={{ mr: 2 }} onClick={handleCancel}>Cancel</Button>
                <Button variant='contained' disableElevation onClick={(e: BaseSyntheticEvent) => handleSave(selectedCategoryItems)}>Save</Button>
            </DialogActions>
        </Dialog>)
}

export default EditMenuItemsDialog;