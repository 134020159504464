import { Box, styled } from '@mui/material';

export const EmptyStateContainer = styled(Box)({
  width: '100%',
  height: '214px',
  backgroundColor: '#ffffff',
  marginTop: '45px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});
