import { MenuItem, MenuItemCustomization } from 'interfaces/MenuInterface';
import { Restaurant } from 'interfaces/RestaurantDetails';

export interface CartItem {
  menuItem: MenuItem;

  customizations: MenuItemCustomization[];

  quantity: number;

  size: number;

  price: number;
  
  customisedPrice?: number;

  category?:Category;

  preference?: Preference;
}

export interface UserOrder {
  //   user: User;
  userId: string;

  items: CartItem[];

  quantity: number;

  size: number;

  total: number;
}

export interface Cart {
  orders: UserOrder[];

  restaurant?: Restaurant;

  subtotal: number;

  deliveryFee?: number

  tipFee?: number

  taxFee?: number

  grandTotal?: number

  serviceFee?: number

  coupon?: string

  discount? : number
}

export interface Order {
  cart: Cart,

  restaurant?: any,

  notes: string,

  email?: string,

  OrderType: string

  restaurantName?: string

  orderType?: string

  addressString?: string
}

export interface Category {
  id?: string;
  title: string;

}
export interface Preference {
  enabledDiets: string[];

  enabledAllergies: string[];
}

export enum OrderType {
  InRestaurant = "In Restaurant",
}

