import { Box, Container, Divider, Tab, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ErrorAlert from 'components/ErrorAlert';
import { ReactComponent as Expand } from 'images/icons/expand.svg';
import { FullRestaurant } from 'interfaces/ResturantInterface';
import { useApi } from 'providers/ApiProvider';
import { useDietaryPreferences } from 'providers/DietaryPreferenceProvider';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FeastPage } from '../FeastPage';
import { a11yProps } from '../components/TabPanel';
import Tabs from '../components/Tabs';
import { CartContextProvider, useCart } from '../context/CartContext';
import { getMatchingCustomizations } from '../util/util';
import { RestaurantMenuList } from './RestaurantMenuList';
import { DietList } from './TagList';

function RestaurantDetail() {
  const theme = useTheme();
  const { restaurantId } = useParams();
  const [restaurant, setRestaurant] = useState<any>(null);
  const [loader, setLoader] = useState(false);
  const [menuSubsections, setMenuSubsections] = useState<any>(null);
  const api = useApi();
  const navigate = useNavigate();
  const sectionRefs = useRef<Array<HTMLDivElement | null>>([]);
  const observerRef = useRef<IntersectionObserver | null>(null);

  const { cart } = useCart();

  const handleEvent = (val: number) => {
    if (restaurantId) {
      navigate('/explore/'.concat(restaurantId, `/onboarding#${val}`));
    }
  };

  const navigateHome = () => {
    if (restaurantId) {
      navigate('/explore/'.concat(restaurantId));
    }
  };

  function truncateString(str: string, num: number) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  }

  type TimePeriod = {
    start_time: string;
    end_time: string;
};

type ServiceAvailability = {
    enabled: boolean;
    day_of_week: string;
    time_periods: TimePeriod[];
};

type Menu = {
    active: boolean;
    serviceAvailability: ServiceAvailability[];
};

const WEEKDAYS = {
    SUNDAY: "sunday",
    MONDAY: "monday",
    TUESDAY: "tuesday",
    WEDNESDAY: "wednesday",
    THURSDAY: "thursday",
    FRIDAY: "friday",
    SATURDAY: "saturday",
} as const;

const menuIsAvailable = (menu: Menu): boolean => {
  const date = new Date();
  const currentTime = `${date.getHours().toString().padStart(2, '0')}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  const weekday = [
      WEEKDAYS.SUNDAY,
      WEEKDAYS.MONDAY,
      WEEKDAYS.TUESDAY,
      WEEKDAYS.WEDNESDAY,
      WEEKDAYS.THURSDAY,
      WEEKDAYS.FRIDAY,
      WEEKDAYS.SATURDAY,
  ][date.getDay()];

  const serviceAvailability = menu.serviceAvailability.find(
      (s) => s.day_of_week === weekday
  );

  return (
      menu.active &&
      serviceAvailability !== undefined &&
      serviceAvailability.enabled &&
      serviceAvailability.time_periods.some(
          (period) =>
              period.start_time <= currentTime && period.end_time >= currentTime
      )
  );
};


  const getMenu = (restaurant: FullRestaurant) => {
    console.log('getMenu',restaurant)
    const availableMenus = restaurant.menus.filter(menuIsAvailable);
    const activeMenus = restaurant.menus.filter((menu) => menu.active);
    return availableMenus.length > 0 ? availableMenus[0] : activeMenus[0];
  };

  const {
    selectedAllergies,
    selectedDiets,
    enabledAllergies,
    enabledDiets,
    allergyKeyToName,
    dietKeyToName,
    toggleEnabledAllergy,
    toggleEnabledDiet,
  } = useDietaryPreferences();
  const [value, setValue] = useState(0);

  useEffect(() => {
    setLoader(true);
  }, []);

  useEffect(() => {
    api.getPublicRestaurantData(restaurantId).then((res: any) => {
      setRestaurant(res);
      setTimeout(() => {
        setLoader(false);
      }, 3000);

      const activeMenu = getMenu(res);
      setMenuSubsections(
        activeMenu.subsections.map((subsection) => {
          const _menuItems = activeMenu.menuItems.filter((menuItem) =>
            subsection.items.includes(menuItem.id!)
          );

          const { filteredItems } = getMatchingCustomizations(_menuItems, {
            enabledAllergies,
            enabledDiets,
          });

          if (filteredItems.length === 0) setValue(0);

          return {
            ...subsection,
            menuItems: filteredItems,
          };
        })
      );
    });
  }, [enabledAllergies, enabledDiets]);

  const restoNameProps = {
    py: 0.5,
    pl: 1,
    alignItems: 'center',
    backgroundColor: theme.palette.primary.dark,
    display: 'inline-flex',
  };

  const Header = (data: any) => {
    return (
      <Box sx={{ ...restoNameProps, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography
          variant='h3'
          color={theme.palette.grey[0]}
          sx={{ textAlign: 'center' }}
        >
          {truncateString(data.resto.name, 25)}
        </Typography>
      </Box>
    );
  };

  const handleTabChange = useCallback((val: number) => {
    if (value !== val) {
      setValue(val);
      sectionRefs.current[val]?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [value]);

  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = sectionRefs.current.indexOf(entry.target as HTMLDivElement);
          if (index !== -1) {
            setValue(index);
          }
        }
      });
    },
    []
  );

  useEffect(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    observerRef.current = new IntersectionObserver(handleIntersection, {
      threshold: 0.5,
    });

    sectionRefs.current.forEach((section) => {
      if (section) observerRef.current?.observe(section);
    });

    return () => observerRef.current?.disconnect();
  }, [menuSubsections, handleIntersection]);

  return (
    <Box
      display='flex'
      flexDirection='column'
      sx={{ backgroundColor: theme.palette.grey[0], height: '100vh' }}
    >
      <CartContextProvider>
        {loader && <FeastPage />}
        {restaurant && (
          <>
            <Header resto={restaurant} />
            <Container
              sx={{
                mt: 4,
                backgroundColor: theme.palette.grey[0],
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                overflow: 'hidden',
              }}
            >
              {selectedDiets.length > 0 && (
                <DietList
                  title='Preferences'
                  diets={selectedDiets}
                  enabledDiets={enabledDiets}
                  dietToName={dietKeyToName}
                  onToggle={toggleEnabledDiet}
                  onEdit={() => handleEvent(1)}
                />
              )}
              {selectedAllergies.length > 0 && (
                <DietList
                  title='Avoided'
                  diets={selectedAllergies}
                  enabledDiets={enabledAllergies}
                  dietToName={allergyKeyToName}
                  onToggle={toggleEnabledAllergy}
                  onEdit={() => handleEvent(2)}
                />
              )}
              {selectedAllergies.length === 0 && selectedDiets.length === 0 && (
                <Box textAlign='right'>
                  <Expand onClick={() => handleEvent(1)} />
                </Box>
              )}
              {menuSubsections && menuSubsections.length > 0 ? (
                <Tabs setValue={handleTabChange} value={value}>
                  {menuSubsections.map(
                    (subsection: any, index: number) =>
                      subsection.menuItems.length > 0 && (
                        <Tab
                          key={index}
                          label={subsection.name}
                          {...a11yProps(index)}
                        />
                      )
                  )}
                </Tabs>
              ) : (
                <>
                  <Divider />
                  <ErrorAlert error={'No Item found!'} />
                </>
              )}
              {menuSubsections && menuSubsections.length > 0 && (
                <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                  {menuSubsections.map(
                    (subsection: any, index: number) =>
                      subsection.menuItems.length > 0 && (
                        <div key={index}>
                          <h3
                            ref={(el) => (sectionRefs.current[index] = el)}
                            style={{ marginBottom: 15, color: "#fe8154" }}
                          >
                            {subsection.name}
                          </h3>
                          <RestaurantMenuList menuItems={subsection.menuItems} />
                          {index < menuSubsections.length - 1 && (
                            <hr style={{ marginBottom: 15 }} />
                          )}
                        </div>
                      )
                  )}
                </Box>
              )}
            </Container>
          </>
        )}
      </CartContextProvider>
    </Box>
  );
}

export default RestaurantDetail;
