import { Form, Formik, FormikProps } from 'formik';
import { Grid, Typography } from '@mui/material';

import { RestaurantImage } from '../../../interfaces/ResturantInterface';
import GoldiButton from '../../Button';
import { RestaurantFormStepPropsType } from '..';
import GoldiInputImage from '../../InputImage';
import trimText from 'Utils/helpers/trimString';

const initialValues: RestaurantImage  = {
  image: undefined,
}

const saveContinueRestaurantFormBtn = {
  position: 'sticky',
  bottom: 0,
  background: 'white',
  justifyContent: 'space-between',
  paddingBottom: 0,
  paddingTop: 2,
};

const RestaurantImages = ({ setCurrentStep, setFormValues, currentValues, currentStep }: RestaurantFormStepPropsType) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography fontWeight={'bold'} fontSize={'1.4rem'}>
          Add Image
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant='subtitle1' fontWeight={'bold'}>
          This is what users will see when looking up your restaurant.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography>{trimText(currentValues.name, 30) || 'New Restaurant'}</Typography>
      </Grid>

      <Grid item xs={12}>
        <Formik
          initialValues={{ ...initialValues, ...currentValues }}
          enableReinitialize={true}
          onSubmit={(values: RestaurantImage) => {
            setFormValues({ ...currentValues, ...values });
            setCurrentStep(currentStep + 1);
          }}
        >
          {(formik: FormikProps<RestaurantImage>) => (
            <Form>
              <Grid item xs={7}>
                <GoldiInputImage
                  id='image'
                  imageUrl={
                    currentValues.imageUrl ||
                    (currentValues.imageUrls && currentValues.imageUrls[0]) ||
                    undefined
                  }
                  formik={formik}
                />
              </Grid>

              <Grid
                container
                mt={5}
                pb={1}
                sx={saveContinueRestaurantFormBtn}>
                <Grid item xs={4}>
                  <GoldiButton
                    text='Back'
                    outlined={true}
                    onClick={() => setCurrentStep(currentStep - 1)}
                  />
                </Grid>

                <Grid item justifyContent='flex-end'>
                  <GoldiButton
                    text='Save And Continue'
                    disable={!formik.isValid}
                    onClick={formik.handleSubmit}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default RestaurantImages;
