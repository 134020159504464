import { ReactComponent as PencilSvg } from '../../../../images/icons/pencilVec.svg';
import { useNavigate } from 'react-router-dom';
import './index.css';
import GoldiInput from '../../../../components/InputField';
import { useRef, useState } from 'react';
import useOutSideClick from '../../../../Utils/hooks/useOutSideClick';
import GoldiButton from '../../../../components/Button';

import { AccountDetails } from '../../../../Utils/Schemas/AccountSttings.schema';
import { AccountDetails as IAccountDetails } from '../../../../interfaces/AccountSettingsInterface';
import { FormikProps, useFormik } from 'formik';

import { useApi } from 'providers/ApiProvider';
import { useQuery } from "react-query";
import AuthService from 'services/auth.service';
import {Container } from '@mui/material';
import { styled } from "@mui/material/styles";
import LoadingPage from 'components/LoadingPage';
import SimpleNav from 'components/SimpleNav';
import MerchantForm from 'components/MerchantForm';
import { Merchant } from 'interfaces/MerchantInterface';
import { useMutationQuery } from 'providers/MutationQueryProvider';
import { generateMerchantIdQuery } from 'Utils/helpers/queryGenerators';

const accountDetailsInitialValues: IAccountDetails = {
  BusinessName: 'ABCD Restaurant',
  Address: '44 Westwood Blvd., Culver City, CA 90323',
  address_PhoneNumber: '5555555557',
  Contact_Name: 'Dana Smith',
  contact_Email: 'dana@natenals.com',
  contact_Phone_Number: '5555555557',
  Licience: 'TBD',
};

const StyledContainer = styled(Container)({
  width: '100%', 
  backgroundColor: '#E5E5E5'
});

const AccountSettings = () => {
  // states
  const [editAccountDetails, setEditAccountDetails] = useState<boolean>(false);
  const [selectedField, setSelectedField] = useState<number>(0);
  const api = useApi();
  const authUser = AuthService.getCurrentUser();
  const { editMerchantMutation } = useMutationQuery();

  const { status: _status, error: _error, data: merchant, isLoading: isLoading } = useQuery(generateMerchantIdQuery(authUser?.uid), async () => {
    return api.getMerchantById(authUser?.uid);
  });

  //  hooks
  const navigate = useNavigate();
  const accountDetailsRef = useRef<HTMLDivElement>(null);
  const formik: FormikProps<IAccountDetails> = useFormik<IAccountDetails>({
    initialValues: accountDetailsInitialValues,
    validationSchema: AccountDetails,
    onSubmit: () => {
      setEditAccountDetails(false);
      alert('Save successfully');
    },
  });

  const handleClickOutside = () => {
    setEditAccountDetails(false);
  };

  const handleSubmit = (params: Merchant): Promise<any> => {
    return editMerchantMutation.mutateAsync(params)
  }

  useOutSideClick(accountDetailsRef, handleClickOutside);

  return ( isLoading ? <LoadingPage/> :
  <StyledContainer className='edit-client-container'>
    <MerchantForm handleSubmit={handleSubmit} merchant={merchant} />
  </StyledContainer>
  );

// return (
  //   <div className='account-settings-root'>
  //     <h1>Account Settings</h1>
  //     <div className='account-paper'>
  //       {/* Account Details */}
  //       <div className='left-col' ref={accountDetailsRef}>
  //         <div className='header'>
  //           <div>
  //             <h4>Accounts Details</h4>
  //           </div>
  //           <div
  //             onClick={() => setEditAccountDetails(true)}
  //             className='logo-sec'
  //           >
  //             <div>
  //               <PencilSvg className='logo' />{' '}
  //             </div>
  //             <div className='edit-text'>Edit</div>
  //           </div>
  //         </div>
  //         <div className='hr-line'></div>
  //         <form onSubmit={formik.handleSubmit}>
  //           <div className='contact'>
  //             <span className='heading'>Business Name</span>
  //             {/*  if edit then show value in input field else in span tag */}
  //             {editAccountDetails ? (
  //               <div>
  //                 <GoldiInput
  //                   id='BusinessName'
  //                   onClick={() => setSelectedField(1)}
  //                   onChange={formik.handleChange}
  //                   onBlur={formik.handleBlur}
  //                   value={formik.values.BusinessName}
  //                   isSelect={selectedField === 1}
  //                 />
  //                 <div className='error-field'>
  //                   {formik.touched.BusinessName &&
  //                   formik.errors.BusinessName ? (
  //                     <span> {formik.errors.BusinessName} </span>
  //                   ) : (
  //                     <span>&nbsp;</span>
  //                   )}
  //                 </div>
  //               </div>
  //             ) : (
  //               <span className='desc'>{formik.values.BusinessName}</span>
  //             )}
  //           </div>

  //           <div className='contact'>
  //             <span className='heading'>Address</span>

  //             {editAccountDetails ? (
  //               <div style={{ display: 'flex', flexDirection: 'column' }}>
  //                 <div>
  //                   <GoldiInput
  //                     id='Address'
  //                     onClick={() => setSelectedField(2)}
  //                     onChange={formik.handleChange}
  //                     onBlur={formik.handleBlur}
  //                     value={formik.values.Address}
  //                     isSelect={selectedField === 2}
  //                   />
  //                 </div>
  //                 <div className='error-field'>
  //                   {formik.touched.Address && formik.errors.Address ? (
  //                     <span> {formik.errors.Address} </span>
  //                   ) : (
  //                     <span>&nbsp;</span>
  //                   )}
  //                 </div>
  //                 <div>
  //                   <GoldiInput
  //                     id='address_PhoneNumber'
  //                     onClick={() => setSelectedField(3)}
  //                     onChange={formik.handleChange}
  //                     onBlur={formik.handleBlur}
  //                     value={formik.values.address_PhoneNumber}
  //                     isSelect={selectedField === 3}
  //                   />
  //                 </div>
  //                 <div className='error-field'>
  //                   {formik.touched.address_PhoneNumber &&
  //                   formik.errors.address_PhoneNumber ? (
  //                     <span> {formik.errors.address_PhoneNumber} </span>
  //                   ) : (
  //                     <span>&nbsp;</span>
  //                   )}
  //                 </div>
  //               </div>
  //             ) : (
  //               <div style={{ display: 'flex', flexDirection: 'column' }}>
  //                 <span className='desc'>{formik.values.Address}</span>
  //                 <span className='desc'>
  //                   {formik.values.address_PhoneNumber}
  //                 </span>
  //               </div>
  //             )}
  //           </div>

  //           <div className='contact'>
  //             <span className='heading'>Contact</span>

  //             {editAccountDetails ? (
  //               <div style={{ display: 'flex', flexDirection: 'column' }}>
  //                 <div>
  //                   <GoldiInput
  //                     id='Contact_Name'
  //                     onClick={() => setSelectedField(4)}
  //                     onChange={formik.handleChange}
  //                     onBlur={formik.handleBlur}
  //                     value={formik.values.Contact_Name}
  //                     isSelect={selectedField === 4}
  //                   />
  //                 </div>
  //                 <div className='error-field'>
  //                   {formik.touched.Contact_Name &&
  //                   formik.errors.Contact_Name ? (
  //                     <span> {formik.errors.Contact_Name} </span>
  //                   ) : (
  //                     <span>&nbsp;</span>
  //                   )}
  //                 </div>

  //                 <div>
  //                   <GoldiInput
  //                     id='contact_Email'
  //                     onClick={() => setSelectedField(5)}
  //                     onChange={formik.handleChange}
  //                     onBlur={formik.handleBlur}
  //                     value={formik.values.contact_Email}
  //                     isSelect={selectedField === 5}
  //                   />
  //                 </div>
  //                 <div className='error-field'>
  //                   {formik.touched.contact_Email &&
  //                   formik.errors.contact_Email ? (
  //                     <span> {formik.errors.contact_Email} </span>
  //                   ) : (
  //                     <span>&nbsp;</span>
  //                   )}
  //                 </div>
  //                 <div>
  //                   <GoldiInput
  //                     id='contact_Phone_Number'
  //                     onClick={() => setSelectedField(6)}
  //                     onChange={formik.handleChange}
  //                     onBlur={formik.handleBlur}
  //                     value={formik.values.contact_Phone_Number}
  //                     isSelect={selectedField === 6}
  //                   />
  //                 </div>
  //                 <div className='error-field'>
  //                   {formik.touched.contact_Phone_Number &&
  //                   formik.errors.contact_Phone_Number ? (
  //                     <span> {formik.errors.contact_Phone_Number} </span>
  //                   ) : (
  //                     <span>&nbsp;</span>
  //                   )}
  //                 </div>
  //               </div>
  //             ) : (
  //               <div style={{ display: 'flex', flexDirection: 'column' }}>
  //                 <span className='desc'> {formik.values.Contact_Name} </span>
  //                 <span className='desc'> {formik.values.contact_Email} </span>
  //                 <span className='desc'>
  //                   {' '}
  //                   {formik.values.contact_Phone_Number}{' '}
  //                 </span>
  //               </div>
  //             )}
  //           </div>

  //           <div className='contact'>
  //             <span className='heading'>Licience #</span>

  //             {editAccountDetails ? (
  //               <div>
  //                 <GoldiInput
  //                   id='Licience'
  //                   onClick={() => setSelectedField(7)}
  //                   onChange={formik.handleChange}
  //                   onBlur={formik.handleBlur}
  //                   value={formik.values.Licience}
  //                   isSelect={selectedField === 7}
  //                 />
  //                 <div className='error-field'>
  //                   {formik.touched.Licience && formik.errors.Licience ? (
  //                     <span> {formik.errors.Licience} </span>
  //                   ) : (
  //                     <span>&nbsp;</span>
  //                   )}
  //                 </div>
  //               </div>
  //             ) : (
  //               <span className='desc'> {formik.values.Licience} </span>
  //             )}
  //           </div>

  //           {editAccountDetails && (
  //             <div
  //               style={{
  //                 display: 'flex',
  //                 justifyContent: 'space-between',
  //                 marginTop: '2%',
  //                 width: '90%',
  //               }}
  //             >
  //               <div style={{ width: '30%' }}>
  //                 <GoldiButton
  //                   text='Cancel'
  //                   onClick={() => setEditAccountDetails(false)}
  //                   outlined={true}
  //                 />
  //               </div>
  //               <div style={{ width: '30%' }}>
  //                 <GoldiButton text='Save' onClick={formik.handleSubmit} />
  //               </div>
  //             </div>
  //           )}
  //         </form>
  //       </div>

  //       {/* Account Credentials */}
  //       <div className='right-col'>
  //         <div className='header'>
  //           <h4>Accounts Credentials</h4>
  //         </div>
  //         <div className='hr-line'></div>

  //         <div className='creds-sec'>
  //           <span className='head'>Email</span>
  //           <div className='head-row'>
  //             <span>dana@email.com</span>
  //             <div className='edit-icon-sec'>
  //               <div className='edit-icon'>
  //                 {' '}
  //                 <PencilSvg className='icon' />{' '}
  //               </div>
  //               <div className='edit-text'>Edit</div>
  //             </div>
  //           </div>
  //         </div>

  //         <div className='creds-sec'>
  //           <span className='head'>Pasword</span>
  //           <div className='head-row'>
  //             <span>**********</span>
  //             <div
  //               onClick={() => navigate(`/change_password/dfsdfsd35435`)}
  //               className='edit-icon-sec'
  //             >
  //               <div className='edit-icon'>
  //                 {' '}
  //                 <PencilSvg className='icon' />{' '}
  //               </div>
  //               <div className='edit-text'>Edit</div>
  //             </div>
  //           </div>
  //         </div>

  //         <div className='creds-sec'>
  //           <span className='head'>Contact</span>
  //           <span className='desc'>User name</span>
  //           <span className='desc'>Dana smith</span>
  //         </div>

  //         <div className='creds-sec'>
  //           <span className='head'>Contact</span>
  //           <span className='desc'>User Type</span>
  //           <span className='desc'>Admin</span>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default AccountSettings;
