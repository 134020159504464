import { useEffect, useState } from "react";
import { Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { useQuery } from "react-query";

import { useApi } from 'providers/ApiProvider';
import { Menu } from "interfaces/MenuInterface";
import { Restaurant } from "interfaces/ResturantInterface";
import { generateMerchantsQuery } from "Utils/helpers/queryGenerators";

import LoadingPage from 'components/LoadingPage';
import GoldiDropDownMenu from 'components/DropDown';
import { Merchant } from "interfaces/MerchantInterface";

type ImportMenuDialogPropsType = {
  isOpen: boolean;
  merchantId: string;
  restaurant: Restaurant;
  selectedMenu: Menu;
  handleClose: () => void;
  handleImport: (menu: Menu) => void;
}

const CopyMenuDialog = ({ isOpen, merchantId, selectedMenu, restaurant, handleClose, handleImport }: ImportMenuDialogPropsType) => {

  return ( 
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="import-menu-dialog-title"
        aria-describedby="import-menu-dialog-description"
        maxWidth='sm'
        fullWidth={true}
      >
        <DialogTitle>
          Copy - {selectedMenu.title}
        </DialogTitle>
        <DialogContent>
            Are you sure you wish to duplicate this menu?
        </DialogContent>
        <DialogActions>
          <Button sx={{mr: 2}} onClick={handleClose}>Cancel</Button>
          <Button 
            variant='contained' 
            disableElevation
            disabled={!selectedMenu}
            onClick={() => {
                handleImport(selectedMenu)
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
  )
};

export default CopyMenuDialog