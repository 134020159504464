import { Button } from '@mui/material';
import CustomTypography from 'components/Typography';

interface ICustomButton {
  children: string;
  disabled?: boolean;
  iconPath?: string;
  width?: string;
  height?: string;
  backgroundColor?: string;
  border?: string;
  margin?: string;
  fontColor?: string; // New optional prop for font color
  onClick: () => void;
}

const CustomButton = ({
  children,
  disabled,
  iconPath,
  width,
  height,
  backgroundColor,
  border,
  margin,
  fontColor, // Destructure the new prop
  onClick,
}: ICustomButton) => {
  return (
    <Button
      sx={{
        margin,
        padding: '16px',
        cursor:'hand',
        height: height || 'fit-content',
        width: width || 'fit-content',
        backgroundColor: backgroundColor || '#FE8154',
        borderRadius: '96px',
        border: border || 'none',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: backgroundColor || '#FE8154',
        },
        '&:disabled': {
          opacity: '0.5',
        },
      }}
      startIcon={iconPath && <img src={iconPath} alt="plus icon" />}
      onClick={onClick}
      disabled={disabled}
    >
      <CustomTypography
        fontSize="16px"
        lineHeight="18px"
        color={fontColor || '#ffffff'} // Use fontColor or default to black
        fontWeight="600"
      >
        {children}
      </CustomTypography>
    </Button>
  );
};

export default CustomButton;
