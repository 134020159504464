import * as yup from 'yup';

export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required.')
    .min(8, 'Password must be at least 8 characters long.'),
  confirmPassword: yup
    .string()
    .required('Password confirmation is required.')
    .min(8, 'Password confirmation must be at least 8 characters long.')
    .oneOf([yup.ref('password'), null], 'Passwords must match.'),
});
