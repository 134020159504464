import CloseIcon from '@mui/icons-material/Close';
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import { FastField, Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import * as yup from "yup";


import { processIngredients } from 'Utils/helpers/processMenuItem';
import FormikAutoSave from 'components/FormikAutoSave';
import IngredientsSearchField from 'components/IngredientsSearchField';
import { GoldiTextField } from 'components/InputField';
import { CustomIngredient } from 'interfaces/CustomIngredientInterface';
import { Ingredient } from 'interfaces/IngredientInterface';
import { useDietaryPreferences } from 'providers/DietaryPreferenceProvider';

type CustomIngredientDialogPropsType = {
    isOpen: boolean;
    handleCloseDialog: () => void;
    handleSave: (customIngredient: CustomIngredient) => void;
    customIngredient: CustomIngredient;
    saveDisabled?: boolean;
    isEdit?: boolean;
}

const CustomIngredientValidationSchema = yup.object({
    title: yup
        .string()
        .required(),
});

const CustomIngredientDialog = ({
    isOpen,
    saveDisabled = false,
    customIngredient,
    handleCloseDialog,
    handleSave,
    isEdit = false,
}: CustomIngredientDialogPropsType) => {
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(true);
    const { dietKeyToName: Diets, allergyKeyToName: Allergies } = useDietaryPreferences();
    
    useEffect(() => {
        setModalIsOpen(isOpen);
    }, [isOpen]);

    // Slightly hacky, we need to use this state to stop the auto-save from firing when we close the dialog
    useEffect(() => {
        if (!modalIsOpen) {
            handleCloseDialog();
        }
    }, [modalIsOpen]); //Do not add handleCloseDialog to the dependency array, it will stop modal from re-opening
    //console.log(customIngredient)
    return (
        <Dialog
            // fullScreen
            open={isOpen}
            onClose={(_e, reason) => {
                // disable closing dialog when clicking outside of dialog
                if (reason && reason == "backdropClick") return;
                setModalIsOpen(false);
            }}
            aria-labelledby="new-menu-item-dialog-title"
            aria-describedby="new-menu-item-dialog-description"
            maxWidth='sm'
            fullWidth={true}
            disableEscapeKeyDown
        >
            <Formik
                enableReinitialize={true}
                initialValues={customIngredient}
                onSubmit={(customIngredient: CustomIngredient) => { 
                   // alert(customIngredient.restaurantId);
                    handleSave(customIngredient);
                }}
                validationSchema={CustomIngredientValidationSchema}
                validateOnMount={true}
                initialErrors={{ title: 'Required' }}
            >
                {(formik: FormikProps<CustomIngredient>) => {
                    const { values, handleBlur, handleChange, isValid, handleSubmit, setFieldValue } = formik;

                    return (
                    <Form>
                        <DialogTitle>
                            <Box display={'flex'} alignItems={'center'} width={'100%'}>
                                <Typography width={'100%'} fontSize={'1.25rem'}>
                                { isEdit ? `Edit ${customIngredient.title.length ? customIngredient.title : 'Custom Ingredient'}` : 'New Custom Ingredient' }
                                </Typography>
                                <Box display={'flex'} justifyContent={'flex-end'} >
                                    <IconButton
                                        onClick={handleCloseDialog}
                                        disableRipple
                                        sx={{cursor:'pointer', float:'right', width: '20px'}}
                                    >
                                        <CloseIcon />
                                    </IconButton>    
                                </Box>       
                            </Box>
                        </DialogTitle>
                        <Divider />
                        <DialogContent>
                            <Box>
                                <Grid container spacing={1}>
                                    {/* Item Name */}
                                    <Grid item xs={6}>
                                        <Typography>Name</Typography>
                                        <Field
                                            name='title'
                                            type='text'
                                            render={({ field, form: { errors } }: FieldProps) => (
                                                <GoldiTextField 
                                                    {...field}
                                                    id={field.name}
                                                    value={values.title}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={!!errors[field.name]}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControlLabel
                                            value="top"
                                            control={
                                                <Switch
                                                    checked={values.active} 
                                                    onChange={handleChange} 
                                                    name={'active'}
                                                />
                                            }
                                            label="Active"
                                            labelPlacement="top"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControlLabel
                                            value="top"
                                            control={
                                                <Switch
                                                    checked={values.isRestaurantOnly} 
                                                    onChange={handleChange} 
                                                    name={'isRestaurantOnly'}
                                                />
                                            }
                                            label="Restaurant Only"
                                            labelPlacement="top"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* Item Description */}
                                        <Box width={'100%'}>
                                            <Typography>Description</Typography>
                                            <Field
                                                name='description'
                                                type='text'
                                                render={({ field, form: { errors } }: FieldProps) => (
                                                    <GoldiTextField 
                                                        {...field}
                                                        id={field.name}
                                                        value={values.description}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={!!errors[field.name]}
                                                    />
                                                )}
                                            />
                                        </Box>  
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>Ingredients</Typography>
                                        <Field
                                            name={'ingredients'}
                                            key={'ingredients'}
                                            type='text'
                                            render={({ field, form: { errors } }: FieldProps) => (
                                                <IngredientsSearchField
                                                    ingredients={values.ingredients || []}
                                                    restaurantId={customIngredient.restaurantId}
                                                    merchantId={customIngredient.merchantId}
                                                    handleSetIngredients={(ingredients: Ingredient[]) => {
                                                        const { allergies, diets } = processIngredients(ingredients);
                                                        setFieldValue('ingredients', ingredients);
                                                        setFieldValue('allergies', allergies);
                                                        setFieldValue('diets', diets);
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                        <Grid item xs={12}>
                                            {/* <AllergiesDisplay allergies={values.allergies} />   */}
                                            <Typography>Dislikes</Typography>
                                            <FastField
                                                name='allergies'
                                                type='text'
                                            >
                                                {() => (
                                                    <Autocomplete
                                                        multiple
                                                        limitTags={3}
                                                        onChange={(e, value) => {
                                                            setFieldValue(`allergies`, value);
                                                        }}
                                                        options={Object.keys(Allergies)}
                                                        getOptionLabel={(option: string) => Allergies[option]}
                                                        value={values.allergies || []}
                                                        renderInput={(params) => (
                                                            <TextField {...params} placeholder="Dislikes" />
                                                        )}
                                                    />
                                                )}
                                            </FastField>
                                        </Grid>
                                    <Grid item xs={12}>
                                            {/* <DietsDisplay diets={values.diets} /> */}
                                            <Typography>Preferences</Typography>
                                            <FastField
                                                name='diets'
                                                type='text'
                                            >
                                                {() => (
                                                    <Autocomplete
                                                        multiple
                                                        limitTags={2}
                                                        onChange={(e, value) => {
                                                            setFieldValue(`diets`, value);
                                                        }}
                                                        options={Object.keys(Diets)}
                                                        getOptionLabel={(option: string) => Diets[option]}
                                                        value={values.diets || []}
                                                        renderInput={(params) => (
                                                            <TextField {...params} placeholder="Supported Diets" />
                                                        )}
                                                    />
                                                )}
                                            </FastField>
                                        </Grid>
                                </Grid>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <FormikAutoSave 
                                canAutoSave={isValid && modalIsOpen}
                                sx={{ 
                                    mr: 2,
                                    fontSize: '0.75rem',
                                    color: 'text.secondary',
                                }} 
                            />
                            <Button sx={{mr: 2}} onClick={() => {
                                setModalIsOpen(false);
                            }}>Cancel</Button>
                            <Button 
                                variant='contained' 
                                disableElevation 
                                onClick={() => {
                                    handleSubmit();
                                    setModalIsOpen(false);
                                }}
                                disabled={!isValid || saveDisabled}
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </Form>
                )}} 
            </Formik>
        </Dialog>
    )
}

export default CustomIngredientDialog;