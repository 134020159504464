import SortableGrid from 'components/SortableGrid';
import CustomTypography from 'components/Typography';
import { Container, Header } from './index.styles';
import { arrayMoveImmutable } from 'array-move';
import { IMenuCategory } from 'interfaces/MenuDetails';
import SubsectionTab from 'components/SubsectionTab';
import CustomIconButton from 'components/CustomIconButton';
import addIcon from 'images/icons/add-icon.svg';
import { MenuSubsection } from 'interfaces/MenuInterface';
import { Button } from '@mui/material';

interface IMenuCategoriesSidebar {
  subsections: MenuSubsection[];
  selectedSubsection: MenuSubsection;
  // setSelectedCategory: React.Dispatch<React.SetStateAction<IMenuCategory>>;
  setSelectedSubsectionIdx: React.Dispatch<React.SetStateAction<number>>;
  setSubsections: (subsections: MenuSubsection[]) => void; //React.Dispatch<React.SetStateAction<MenuCategory[]>>;
  handleAddCategory: () => void;
  handleEditCategory: (category: MenuSubsection) => void;
  handleDeleteCategory: (category: MenuSubsection) => void;
}

const MenuCategoriesSidebar = ({
  subsections,
  selectedSubsection,
  // setSelectedCategory,
  setSelectedSubsectionIdx,
  setSubsections,
  handleAddCategory,
  handleEditCategory,
  handleDeleteCategory,
}: IMenuCategoriesSidebar) => {  
  const onSortEnd = ({oldIndex,newIndex,}: {
    oldIndex: number;
    newIndex: number;
  }) => {
    const newCategoriesOrder = arrayMoveImmutable(
      subsections,
      oldIndex,
      newIndex
    );
    setSubsections(newCategoriesOrder);
  };

  return (
    <Container>
      <Header>
        <CustomTypography fontSize='22px' lineHeight='28px' fontWeight='600'>
          Menu Subsections
        </CustomTypography>
        <Button 
          startIcon={<img src={addIcon} alt='Add New' />}
          onClick={handleAddCategory}  
          sx={{
            color: '#108680',
            fontWeight: 'bold',
          }}
        > 
          Add New
        </Button>
      </Header>

      <SortableGrid
        gridItems={subsections || []}
        display='vertical'
        onSortEnd={onSortEnd}
      >
        {subsections.map((subsection: MenuSubsection, idx: number) => (
          <SubsectionTab
            key={subsection.id || subsection.name}
            subsection={subsection}
            selected={selectedSubsection === subsection}
            onClick={() => setSelectedSubsectionIdx(idx)}
            handleEditCategory={handleEditCategory}
            handleDeleteCategory={handleDeleteCategory}
          />
        ))}
      </SortableGrid>
    </Container>
  );
};

export default MenuCategoriesSidebar;
