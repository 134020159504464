import './index.css';

const Integrations = () => {
  return (
    <div className='integrations-root'>
      <h1>Integrations</h1>
    </div>
  );
};

export default Integrations;
