import { ButtonBase, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface Props {
  title: string;

  subtitle?: string;

  onClick: () => void;

  disabled?: boolean;
}

export const ErrorActionButton = ({
  title,
  subtitle,
  onClick,
  disabled = false,
}: Props) => {
  const theme = useTheme();
  return (
    <ButtonBase
      sx={{
        color: theme.palette.grey[0],
        background: disabled ? theme.palette.secondary.darker : theme.palette.primary.dark,
        '&:hover': {
          background: disabled ? theme.palette.secondary.darker : theme.palette.primary.dark,
        },
        maxWidth: 360,
        borderRadius: 12,
      }}
      onClick={(_) => onClick()}
      disabled={disabled}
    >
      <Stack
        direction='row'
        justifyContent={subtitle ? 'space-between' : 'center'}
        sx={{ width: '100%', px: 2, py: .5 }}
      >
        <Typography variant='subtitle2' sx={{ fontWeight: 'bold', fontSize: 13 }}>
          {title}
        </Typography>
        {subtitle && (
          <Typography variant='subtitle1' sx={{ fontWeight: 'bold', fontSize: 10 }}>
            {subtitle}
          </Typography>
        )}
      </Stack>
    </ButtonBase>
  );
};
