import { useState, useEffect } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
// import { useQuery } from "react-query";
// import { styled } from "@mui/material/styles";
import { Grid, Typography } from '@mui/material';

// import SimpleNav from '../SimpleNav';
// import { useApi } from '../../providers/ApiProvider';
import RestaurantInformaton from './RestaurantInformation';
import OperatingHours from './OperatingHours';
import RestaurantImages from './RestaurantImages';
import RestaurantSummary from './RestaurantSummary';
// import LoadingPage from 'components/LoadingPage';
// import { Restaurant } from 'interfaces/ResturantInterface';


export type RestaurantFormStepPropsType = {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setFormValues: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  currentValues: Record<string, any>;
  currentStep: number;
  isAdmin?: boolean;
}

type RestaurantFormPropsType = {
  handleSubmit: (params: any) => Promise<any>
  restaurant?: Record<string, any> //TODO: fix this type to actually be restaurant
  isAdmin?: boolean;
}

const RestaurantForm = ({ restaurant = {}, handleSubmit, isAdmin = false }: RestaurantFormPropsType) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [formValues, setFormValues] = useState(restaurant);
  
  useEffect(() => {
    if(restaurant.id) {
      setFormValues(restaurant);
    }
  }, [restaurant])
  
  const getStepComponent = () => {
    switch (currentStep) {
      case 1:
        return <RestaurantInformaton
          setCurrentStep={setCurrentStep}
          setFormValues={setFormValues}
          currentValues={formValues}
          currentStep={currentStep}
          isAdmin={isAdmin}
        />
      case 2:
        return <OperatingHours 
          setCurrentStep={setCurrentStep}
          setFormValues={setFormValues}
          currentValues={formValues}
          currentStep={currentStep}
        />
      case 3:
        return <RestaurantImages 
          setCurrentStep={setCurrentStep}
          setFormValues={setFormValues}
          currentValues={formValues}
          currentStep={currentStep}
        />
      case 4:
        return <RestaurantSummary
          setCurrentStep={setCurrentStep}
          setFormValues={setFormValues}
          currentValues={formValues}
          currentStep={currentStep}
          handleSubmit={handleSubmit}
        />
      default:
        break;
    }
  }

  return (
    <Grid container mt={5} className='new-restaurant-form'>
      <Grid item xs />
      <Grid
        item
        xs={7}
        p={3}
        style={{ backgroundColor: 'white', minWidth: '100%', position:'relative' }}
        className='_create-content-step-1'
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>Step {currentStep} of 4</Typography>
          </Grid>
          <Grid item xs={12}>
            {getStepComponent()}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs />
    </Grid>
  );
};

export default RestaurantForm;
