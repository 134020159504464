import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useApi } from 'providers/ApiProvider';

const OauthAuthorize = () => {
    const api = useApi();
    const navigate = useNavigate();
    const urlParams = useParams();
    const [searchParams] = useSearchParams();
    const [error, setError] = useState<any>();

    const handleOauthLogin = async (provider: string) => {
        try {
            const queryParams = Object.fromEntries(searchParams.entries());
            if (Object.keys(queryParams).length === 0) {
                navigate('/');    
            } else {
                const result = await api.oauthLogin(provider, queryParams);
                console.log({result})
                navigate('/');
            }
        } catch (error) {
            console.error(error);
            setError(error);
        }
    }

    useEffect(() => {
        if (urlParams.provider) {
            handleOauthLogin(urlParams.provider);
        }
    }, []);

    return (<>
        <p>Pleae wait while we set up your location...</p>
        {error && <p>{error.message}</p>}
    </>);
};

export default OauthAuthorize;
