import { IconButton, InputLabel, styled, TextField } from '@mui/material';

export const StyledInputLabel = styled(InputLabel)({
  fontFamily: 'Graphik',
  fontSize: '14px',
  lineHeight: '20px',
  color: '#131313',
  marginBottom: '4px',
});

export const StyledTextField = styled(TextField)({
  height: '44px',
  width: '100%',
  marginBottom: '16px',
  '& .MuiInputBase-input': {
    padding: '10px 8px',
  },
  '& .MuiOutlinedInput-root': {
    fontFamily: 'Graphik',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#131313',
    '& fieldset': {
      border: '1px solid rgba(97, 117, 134, 0.5)',
    },
    '&:hover fieldset': {
      border: '1px solid rgba(97, 117, 134, 0.2)',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid rgba(97, 117, 134, 0.2)',
    },
  },
});

export const StyledEndAdornmentButton = styled(IconButton)({
  fontFamily: 'Graphik',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '12px',
  lineHeight: '13px',
  color: '#000000',
});
