import { ChangeEvent } from 'react';
import { StyledCheckbox, StyledFormControlLabel } from './index.styles';

interface ICustomCheckbox {
  checked: boolean;
  label: string;
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  styles?: Record<string, unknown>;
}

const CustomCheckbox = ({
  checked,
  onChange,
  label,
  styles,
}: ICustomCheckbox) => {
  return (
    <StyledFormControlLabel
      control={<StyledCheckbox checked={checked} onChange={onChange} />}
      label={label}
      sx={{
        ...styles,
      }}
    />
  );
};

export default CustomCheckbox;
