import { Box, styled } from '@mui/material';

export const Container = styled(Box)({
  minWidth: '378px',
  background: '#ffffff',
  boxShadow: '1px 0px 0px #E9E9E9',
  padding: '0 24px',
  marginRight: '1px',
});

export const Header = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: '34px',
  marginBottom: '24px',
});
