import { Menu, MenuItem } from 'interfaces/MenuInterface';
import { useApi } from 'providers/ApiProvider';
import React, { ReactElement } from 'react';
import { useMutation, useQueryClient } from "react-query";
import { v4 as uuidv4 } from 'uuid';

import {
  generateAllergiesIdQuery,
  generateAllergiesQuery,
  generateCustomIngredientIdQuery,
  generateDietsIdQuery,
  generateDietsQuery,
  generateGlobalSettingsQuery,
  generateIngredientIdQuery,
  generateIngredientsQuery,
  generateMenuItemIdQuery,
  generateMenuItemsQuery,
  generateMerchantIdQuery,
  generateMerchantRestaurantsQuery,
  generateMerchantsQuery,
  generateOrdersQuery,
  generateRestaurantCustomIngredientsQuery,
  generateRestaurantIdQuery,
  generateRestaurantMenusQuery,
  genreateMenuIdQuery
} from 'Utils/helpers/queryGenerators';
import { Allergy } from 'interfaces/AllergyInterface';
import { CustomIngredient } from 'interfaces/CustomIngredientInterface';
import { GlobalSettings } from 'interfaces/GlobalSettingsInterface';
import { Ingredient } from 'interfaces/IngredientInterface';
import { Merchant } from 'interfaces/MerchantInterface';
import { Order } from 'interfaces/OrderInterface';
import { Restaurant } from 'interfaces/ResturantInterface';

const MuatationQueryContext = React.createContext<any | null>(null);

interface IProps {
  children: ReactElement;
}

export function MutationQueryProvider({ children }: IProps): ReactElement {
    const api = useApi();
    const queryClient = useQueryClient();

    const generateAddMutations = <T,>(params: {queryGenerator: ((key?: string) => string), queryKey?: string}) => {
      const { queryGenerator, queryKey } = params;
      return {
        // Optimistically update the cache value on mutate, but store the old value and return it so that it's accessible in case of an error
        onMutate: async (newData: T) => {
          const query = queryGenerator(queryKey && (newData as Record<string, any>)[queryKey]);
          await queryClient.cancelQueries(query);

          const context: T[] | undefined = queryClient.getQueryData(query);

          queryClient.setQueryData(query, (oldDatum: T[] = []) => {
            return [...oldDatum, { ...newData, id:  uuidv4() }];
          });

          // const context = { previousDatum };
          return context;
        },
        // On failure, roll back to the previous value
        onError: (_error: unknown, variables: T, context: T[] | undefined) => {
          const query = queryGenerator(queryKey && (variables as Record<string, any>)[queryKey]);
          queryClient.setQueryData(query, context);
        },
        // After success or failure, refetch the query
        onSettled: (_data: unknown, _error: unknown, variables: T, _context: T[] | undefined) => {
          const query = queryGenerator(queryKey && (variables as Record<string, any>)[queryKey]);
          queryClient.invalidateQueries(query);
        },
      }
    }

    const generateDuplicateMutations = <T,>(params: {queryGenerator: ((key?: string) => string), queryKey?: string, dataKey: string}) => {
      const { queryGenerator, queryKey, dataKey } = params;
      return {
        // Optimistically update the cache value on mutate, but store the old value and return it so that it's accessible in case of an error
        onMutate: async (newData: T) => {
          const query = queryGenerator(queryKey && (newData as Record<string, any>)[dataKey][queryKey]);
          await queryClient.cancelQueries(query);

          const context: T[] | undefined = queryClient.getQueryData(query);

          queryClient.setQueryData(query, (oldDatum: T[] = []) => {
            return [...oldDatum, { ...(newData as Record<string, any>)[dataKey], id:  uuidv4() }];
          });

          // const context = { previousDatum };
          return context;
        },
        // On failure, roll back to the previous value
        onError: (_error: unknown, variables: T, context: T[] | undefined) => {
          const query = queryGenerator(queryKey && (variables as Record<string, any>)[queryKey]);
          queryClient.setQueryData(query, context);
        },
        // After success or failure, refetch the query
        onSettled: (_data: unknown, _error: unknown, variables: T, _context: T[] | undefined) => {
          const query = queryGenerator(queryKey && (variables as Record<string, any>)[queryKey]);
          queryClient.invalidateQueries(query);
        },
      }
    }

    const generateDeleteMutations = <T,>(params: {queryGenerator: (key?: string) => string, queryKey?: string}) => {
      const { queryGenerator, queryKey } = params;
      return {
        // Optimistically update the cache value on mutate, but store the old value and return it so that it's accessible in case of an error
        onMutate: async (deleteData: T) => {
          const query = queryGenerator(queryKey && (deleteData as Record<string, any>)[queryKey])
          await queryClient.cancelQueries(query);

          const context: T[] | undefined = queryClient.getQueryData(query);

          queryClient.setQueryData(query, (oldDatum: T[] = []) => {
            return oldDatum.filter((d: Record<string, any>) => d.id !== (deleteData as Record<string, any>).id);
          });

          return context;
        },
        // On failure, roll back to the previous value
        onError: (_error: unknown, variables: T, context: T[] | undefined) => {
          const query = queryGenerator(queryKey && (variables as Record<string, any>)[queryKey]);
          queryClient.setQueryData(query, context);
        },
        // After success or failure, refetch the todos query
        onSettled: (_data: unknown, _error: unknown, variables: T, _context: T[] | undefined) => {
          const query = queryGenerator(queryKey && (variables as Record<string, any>)[queryKey]);
          queryClient.invalidateQueries(query);
        },
      }
    }

    const generateEditMutations = <T,>(params: {
      datumQueryGenerator: (key?: string) => string, 
      datumQueryKey?: string, 
      dataQueryGenerator: (key?: string) => string,
      dataQueryKey?: string,
    }) => {
      const { datumQueryGenerator, datumQueryKey, dataQueryGenerator, dataQueryKey = 'id' } = params;
      return {
        // Optimistically update the cache value on mutate, but store the old value and return it so that it's accessible in case of an error
        onMutate: async (editData: T) => {
          const datumQuery = datumQueryGenerator(datumQueryKey && (editData as Record<string, any>)[datumQueryKey])
          const dataQuery = dataQueryGenerator((editData as Record<string, any>)[dataQueryKey])
          await queryClient.cancelQueries(datumQuery);

          const previousDatum: T[] | undefined = queryClient.getQueryData(datumQuery);
          const previousData: T | undefined = queryClient.getQueryData(dataQuery);

          queryClient.setQueryData(datumQuery, (oldDatum: T[] = []) => {
            const index = oldDatum.findIndex((d: Record<string, any>) => d.id === (editData as Record<string, any>).id)
            return [...oldDatum.slice(0, index), editData, ...oldDatum.slice(index + 1)]
          });

          const context = { previousDatum, previousData };

          return context;
        },
        // On failure, roll back to the previous value
        onError: (_error: unknown, variables: T, context: {previousDatum: T[] | undefined; previousData: T | undefined} | undefined) => {
          const datumQuery = datumQueryGenerator(datumQueryKey && (variables as Record<string, any>)[datumQueryKey]);
          const dataQuery = dataQueryGenerator((variables as Record<string, any>)[dataQueryKey]);
          queryClient.setQueryData(datumQuery, context?.previousDatum || []);
          queryClient.setQueryData(dataQuery, context?.previousData);
        },
        // After success or failure, refetch the todos query
        onSettled: (_data: unknown, _error: unknown, variables: T, _context: {previousDatum: T[] | undefined; previousData: T | undefined} | undefined) => {
          const query = datumQueryGenerator(datumQueryKey && (variables as Record<string, any>)[datumQueryKey]);
          queryClient.invalidateQueries(query);
        },
      }
    }

    // Client CRUD mutations
    const addMerchantMutation = useMutation(api.createMerchant,
      generateAddMutations<Merchant>({
        queryGenerator: generateMerchantsQuery,
      })
    )

    const editMerchantMutation = useMutation(api.editMerchant,
      generateEditMutations<Merchant>({
        datumQueryGenerator: generateMerchantsQuery,
        dataQueryGenerator: generateMerchantIdQuery,
      })
    )

    // Restaurant CRUD mutations
    const addRestaurantMutation = useMutation(api.createRestaurant,
      generateAddMutations<Restaurant>({
        queryGenerator: generateRestaurantIdQuery, 
        queryKey: 'merchantId',
      })
    )

    const editRestaurantMutation = useMutation(api.editRestaurant,
      generateEditMutations<Restaurant>({
        datumQueryGenerator: generateMerchantRestaurantsQuery,
        datumQueryKey: 'merchantId',
        dataQueryGenerator: generateRestaurantIdQuery,
      })
    )

    const deleteRestaurantMutation = useMutation(api.deleteRestaurant,
      generateDeleteMutations<Restaurant>({
        queryGenerator: generateMerchantRestaurantsQuery, 
        queryKey: 'merchantId',
      })
    )

    const copyRestaurantMutation = useMutation(api.copyRestaurant,
      generateDeleteMutations<Restaurant>({
        queryGenerator: generateMerchantRestaurantsQuery, 
        queryKey: 'merchantId',
      })
    )

    const importRestaurantMutation = useMutation(api.importRestaurant,
      generateDeleteMutations<Restaurant>({
        queryGenerator: generateMerchantRestaurantsQuery, 
        queryKey: 'merchantId',
      })
    )
    // Menu CRUD mutations
    const addMenuMutation = useMutation(api.createMenu,
      generateAddMutations<Menu>({
        queryGenerator: generateRestaurantMenusQuery, 
        queryKey: 'restaurantId',
      })
    )
  
    const deleteMenuMutation = useMutation(api.deleteMenu,
      generateDeleteMutations<Menu>({
        queryGenerator: generateRestaurantMenusQuery, 
        queryKey: 'restaurantId',
      })
    )
  
    const editMenuMutation = useMutation(api.editMenu,
      generateEditMutations<Menu>({
        datumQueryGenerator: generateRestaurantMenusQuery,
        datumQueryKey: 'restaurantId',
        dataQueryGenerator: genreateMenuIdQuery,
      })
    )

    const duplicateMenuMutation = useMutation(api.duplicateMenu,
      generateDuplicateMutations<Menu>({
        queryGenerator: generateRestaurantMenusQuery, 
        queryKey: 'restaurantId',
        dataKey: 'menu',
      })
    )

    // MenuItem CRUD mutations
    const addMenuItemMutation = useMutation(api.createMenuItem,
      generateAddMutations<MenuItem>({
        queryGenerator: generateMenuItemsQuery, 
        queryKey: 'menuId',
      })
    )

    const duplicateMenuItemMutation = useMutation(api.duplicateMenuItem,
      generateDuplicateMutations<MenuItem>({
        queryGenerator: generateMenuItemsQuery, 
        queryKey: 'menuId',
        dataKey: 'menuItem',
      })
    )

    const editMenuItemMutation = useMutation(api.editMenuItem,
      generateEditMutations<MenuItem>({
        datumQueryGenerator: generateMenuItemsQuery,
        datumQueryKey: 'menuId',
        dataQueryGenerator: generateMenuItemIdQuery,
      })
    )

    const deleteMenuItemMutation = useMutation(api.deleteMenuItem,
      generateDeleteMutations<MenuItem>({
        queryGenerator: generateMenuItemsQuery, 
        queryKey: 'menuId',
      })
    )

    const generateMenuItemIngredientsMutation = useMutation(api.generateMenuItemIngredients,
      generateEditMutations<MenuItem>({
        datumQueryGenerator: generateMenuItemsQuery, 
        datumQueryKey: 'menuId',
        dataQueryGenerator: generateMenuItemIdQuery,
      })  
    )

    // CustomIngredient CRUD mutations
    const addCustomIngredientMutation = useMutation(api.createCustomIngredient,
      generateAddMutations<CustomIngredient>({
        queryGenerator: generateRestaurantCustomIngredientsQuery, 
        queryKey: 'restaurantId',
      })
    )

    const deleteCustomIngredientMutation = useMutation(api.deleteCustomIngredient,
      generateDeleteMutations<CustomIngredient>({
        queryGenerator: generateRestaurantCustomIngredientsQuery, 
        queryKey: 'restaurantId',
      })
    )
  
    const editCustomIngredientMutation = useMutation(api.editCustomIngredient,
      generateEditMutations<CustomIngredient>({
        datumQueryGenerator: generateRestaurantCustomIngredientsQuery,
        datumQueryKey: 'restaurantId',
        dataQueryGenerator: generateCustomIngredientIdQuery,
      })
    )

    // CustomIngredient CRUD mutations
    const duplicateCustomIngredientMutation = useMutation(api.duplicateCustomIngredient,
      generateAddMutations<CustomIngredient>({
        queryGenerator: generateRestaurantCustomIngredientsQuery, 
        queryKey: 'restaurantId',
      })
    )
    // Client CRUD mutations
    const addIngredientMutation = useMutation(api.createIngredient,
      generateAddMutations<Ingredient>({
        queryGenerator: generateIngredientsQuery,
      })
    )

    const editIngredientMutation = useMutation(api.editIngredient,
      generateEditMutations<Ingredient>({
        datumQueryGenerator: generateIngredientsQuery,
        dataQueryGenerator: generateIngredientIdQuery,
      })
    )

    const deleteIngredientMutation = useMutation(api.deleteIngredient,
      generateDeleteMutations<Ingredient>({
        queryGenerator: generateIngredientsQuery,
      })
    )

     // CustomIngredient CRUD mutations
     const duplicateIngredientMutation = useMutation(api.duplicateIngredient,
      generateDuplicateMutations<Ingredient>({
        queryGenerator: generateIngredientIdQuery,
        dataKey: 'internalId'
      })
    )

  // get instead of add new Allergy

    const addAllergyMutation = useMutation(api.getAllergies,
      generateAddMutations<Allergy>({
        queryGenerator: generateAllergiesQuery,
      })
    )

    const editAllergyMutation = useMutation(api.editAllergies,
      generateEditMutations<Allergy>({
        datumQueryGenerator: generateAllergiesQuery,
        dataQueryGenerator: generateAllergiesIdQuery,
      })
    )

    const deleteAllergytMutation = useMutation(api.deleteAllergies,
      generateDeleteMutations<Allergy>({
        queryGenerator: generateAllergiesQuery,
      })
    )


    // get instead of add new diet
    const addDietMutation = useMutation(api.getDiets,
      generateAddMutations<Ingredient>({
        queryGenerator: generateDietsQuery,
      })
    )

    const editDietMutation = useMutation(api.editDiets,
      generateEditMutations<Ingredient>({
        datumQueryGenerator: generateDietsQuery,
        dataQueryGenerator: generateDietsIdQuery,
      })
    )

    const deleteDiettMutation = useMutation(api.deleteDiets,
      generateDeleteMutations<Ingredient>({
        queryGenerator: generateDietsQuery,
      })
    )

    const editAdminOrderMutation = useMutation(api.updateOrderDetails,
      generateEditMutations<Order>({
        datumQueryGenerator: generateOrdersQuery,
        dataQueryGenerator: generateOrdersQuery,
      })
    )

    const markAdminOrderCompleteMutation = useMutation(api.markOrderAsComplete,
      generateEditMutations<Order>({
        datumQueryGenerator: generateOrdersQuery,
        dataQueryGenerator: generateOrdersQuery,
      })
    )

    const markAdminOrderClaimedMutation = useMutation(api.markOrderAsClaimed,
      generateEditMutations<Order>({
        datumQueryGenerator: generateOrdersQuery,
        dataQueryGenerator: generateOrdersQuery,
      })
    )

    const markAdminOrderCancelledMutation = useMutation(api.markOrderAsCancelled,
      generateEditMutations<Order>({
        datumQueryGenerator: generateOrdersQuery,
        dataQueryGenerator: generateOrdersQuery,
      })
    )
    const editGlobalSettingsMutation = useMutation(api.editGlobalSettings,
      generateAddMutations<GlobalSettings>({
        queryGenerator: generateGlobalSettingsQuery,
      })
    )
    
    return <MuatationQueryContext.Provider value={{
      addMerchantMutation,
      editMerchantMutation,

      addRestaurantMutation,
      editRestaurantMutation,
      deleteRestaurantMutation,
      copyRestaurantMutation,
      importRestaurantMutation,
      addMenuMutation, 
      deleteMenuMutation, 
      editMenuMutation,
      duplicateMenuMutation,
      
      addMenuItemMutation,
      editMenuItemMutation,
      deleteMenuItemMutation,
      duplicateMenuItemMutation,
      generateMenuItemIngredientsMutation,
      
      addCustomIngredientMutation,
      deleteCustomIngredientMutation,
      editCustomIngredientMutation,
      duplicateCustomIngredientMutation,

      addIngredientMutation,
      editIngredientMutation,
      deleteIngredientMutation,
      duplicateIngredientMutation,

      addAllergyMutation,
      editAllergyMutation,
      deleteAllergytMutation,

      addDietMutation,
      editDietMutation,
      deleteDiettMutation,

      editAdminOrderMutation,
      markAdminOrderCompleteMutation,
      markAdminOrderClaimedMutation,
      markAdminOrderCancelledMutation,

      editGlobalSettingsMutation

    }}>{children}</MuatationQueryContext.Provider>;
}

export function useMutationQuery(): any {
  const mutationQuery = React.useContext(MuatationQueryContext);

  //Required by typescript so don't need to check for null all the time
  if (!mutationQuery) {
    throw new Error('useApi must be used by the children of the MutationProvider.');
  }

  return mutationQuery;
}
