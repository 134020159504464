import { Box, Chip, Stack, Typography } from "@mui/material";
import { FastField } from "formik";

import { Ingredient } from 'interfaces/IngredientInterface';
import { useCallback, useEffect, useState } from "react";

import { useApi } from 'providers/ApiProvider';

type PropsType = {
    ingredients: Ingredient[];
};

const RawIngredientsDisplay = ({ ingredients }: PropsType) => {
    const [rawIngredients, setRawIngredients] = useState<Ingredient[]>([]);
    const [loading, setLoading] = useState(false);
    const api = useApi();

    const getRawIngredients = useCallback(async (ingredients: Ingredient[], callback) => {
        setRawIngredients([]);
        setLoading(true);
        const rawIngredients = await api.getRawIngredients(ingredients.map(ingredient => ingredient.id));
        callback(rawIngredients);
    }, [api]);

    useEffect(() => {
        getRawIngredients(ingredients, (rawIngredients: Ingredient[]) => {
            setRawIngredients(rawIngredients);
            setLoading(false);
        });
    }, [ingredients, getRawIngredients]);

    return <Box width={'100%'}>
        <Typography>Raw Ingredients</Typography>
        {
            loading ?
            <Typography>Loading...</Typography> :
            <FastField
                name='raw-ingredients'
                type='text'
            >
                {() => (
                    <Stack direction="row" spacing={1} flexWrap={"wrap"}>
                        {rawIngredients.map((rawIngredient) => (
                            <Chip
                                key={rawIngredient.id}
                                label={rawIngredient.title}
                            />
                        ))}
                    </Stack>
                )}
            </FastField>
        }
    </Box>
};

export default RawIngredientsDisplay;