import React from 'react';
import { Helmet } from 'react-helmet';
import './App.css';
import Routers from './modules/router';
import ReactGA from 'react-ga';

const { REACT_APP_GOOGLE_MAPS_API_KEY, GTAG } = process.env;

function App(): React.ReactElement {
  if(GTAG) {
    ReactGA.initialize(GTAG);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  return (
    <div className='App'>
      <Helmet>
        <script
          src={`https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`}
        ></script>
      </Helmet>
      <Routers />
    </div>
  );
}

export default React.memo(App);
