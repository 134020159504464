import Loader from 'components/loader';
import { Container } from './index.styles';

const LoadingPage = () => {
  return (
    <Container>
      <Loader width={40} height={40} />
    </Container>
  );
};

export default LoadingPage;
