import { useNavigate } from 'react-router';

import CustomTypography from 'components/Typography';
import { Restaurant } from 'interfaces/ResturantInterface';
import {
  OverviewContainer,
  TabContainer,
} from './index.styles';
import RestaurantSummary from 'components/RestaurantSummary';
import { RESTAURANTS } from 'Utils/constants/routes';
interface IRestaurantOverview {
  restaurant: Restaurant;
}

const RestaurantOverview = ({ restaurant }: IRestaurantOverview) => {
  const navigate = useNavigate();

  return (
    <TabContainer>
      <CustomTypography
        fontWeight='700'
        fontSize='24px'
        lineHeight='26px'
        letterSpacing='-0.015em'
        color='#000000'
        marginBottom='24px'
      >
        Overview
      </CustomTypography>

      <OverviewContainer>
        <RestaurantSummary 
          restaurant={restaurant} 
          handleClickEdit={() => navigate(`${RESTAURANTS}/${restaurant.id}/edit`)} 
          canEdit={true}
        />
      </OverviewContainer>
    </TabContainer>
  );
};

export default RestaurantOverview;
