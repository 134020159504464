import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, ImageListItem, styled, Tooltip, Typography } from '@mui/material';

import OptionsMenu from 'components/OptionsMenu';
import CustomTypography from 'components/Typography';
import { MenuItem } from 'interfaces/MenuInterface';
import toPriceDisplay from 'Utils/helpers/toPriceDisplay';
// import { ReactComponent as CameraVec } from '../../images/icons/cameraVec.svg';
import DefaultImage from 'components/DefaultImage';

export const MenuItemCardContainer = styled(Box)({
  width: '385px',
  height: '100px',
  background: '#FFFFFF',
  borderRadius: '8px',
  display: 'flex',
  padding: '16px 16px 16px 13px',
  cursor: 'move',
  '@media (max-width: 767px)': {
    width: '300px',
  },
});

export const MenuItemDetails = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'space-between',
  marginLeft: 5,
});

export const MenuItemDetailsFooter = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

interface IMenuItemCard {
  item: MenuItem;
  handleRemoveMenuItem: () => void;
  handleEditMenuItem: () => void;
  handleDuplicateMenuItem: () => void;
  handleDeleteMenuItem: () => void;
  handleGenerateRawIngredients: () => void;
}

const DISPLAY_TEXT_LENGTH = 15;

const MenuItemCard = ({ 
    item, 
    handleRemoveMenuItem, 
    handleEditMenuItem, 
    handleDeleteMenuItem,
    handleDuplicateMenuItem, 
    handleGenerateRawIngredients,
  }: IMenuItemCard) => {
  const menuItemOptions = [
    {
      label: 'Edit Menu Item',
      onClick: handleEditMenuItem,
    },
    {
      label: 'Delete Menu Item',
      onClick: handleDeleteMenuItem,
    },
    {
      label: 'Duplicate Menu Item',
      onClick: handleDuplicateMenuItem,
    },
    {
      label: 'Remove Menu Item',
      onClick: handleRemoveMenuItem,
    },
    {
      label: 'Generate Raw Ingredients',
      onClick: handleGenerateRawIngredients,
    },
  ];

  return (
    <MenuItemCardContainer>
      { (item.imageUrl?.length || item.imageUrls?.length || item.image)
        ? <ImageListItem sx={{ width: 100, mr: 1 }}>
          <img
            alt={item.title}
            src={item.imageUrl 
              || (item.imageUrls && item.imageUrls[0])
              || (item.image && (typeof item.image === 'string' ? item.image : URL.createObjectURL(item.image as File)))  
              || ''}
          />
        </ImageListItem>
      : <DefaultImage 
        sx={{
          width: 100,
          mr: 1,
        }}
      />}
      
      <MenuItemDetails>
        {/* Show first 25 characters, if longer show rest in Tooltip */}
        <Tooltip title={item.title.length > DISPLAY_TEXT_LENGTH ? item.title : ''} placement='top'>
          <CustomTypography fontWeight='600' fontSize='16px' lineHeight='22px'>
            
            {`${item.title.slice(0, DISPLAY_TEXT_LENGTH)}${item.title.length > DISPLAY_TEXT_LENGTH ? '...' : ''}`}
          </CustomTypography>
        </Tooltip>
        {/* <Tooltip title={(item.description && item.description.length > DISPLAY_TEXT_LENGTH) ? item.description : ''} placement='top'>
          <Typography variant='body2' color='text.secondary'>
            {
              item.description
              ? `${item.description.slice(0, DISPLAY_TEXT_LENGTH)}${item.description.length > DISPLAY_TEXT_LENGTH ? '...' : ''}`
              : null
            }
          </Typography>
          
        </Tooltip> */}
        <Tooltip title={(item.description && item.description.length > DISPLAY_TEXT_LENGTH) ? item.description : ''} placement='top'>
          <Typography variant='body2' color='text.secondary'>
            {
              item.description
              ? `${item.description.slice(0, DISPLAY_TEXT_LENGTH)}${item.description.length > DISPLAY_TEXT_LENGTH ? '...' : ''}`
              : null
            }
          </Typography>
          
        </Tooltip>
        <MenuItemDetailsFooter>
          <CustomTypography fontSize='14px' lineHeight='15px'>
            {toPriceDisplay(item.price)}
          </CustomTypography>

          <OptionsMenu
            id={`menuItem-${item.id}-options`}
            buttonIcon={<MoreVertIcon/>}
            options={menuItemOptions}
          />
        </MenuItemDetailsFooter>
      </MenuItemDetails>
    </MenuItemCardContainer>
  );
};

MenuItemCard.displayName = 'MenuItemCard';

export default MenuItemCard;
