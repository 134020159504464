import * as yup from 'yup';

export const AccountDetails = yup.object({
  BusinessName: yup.string().required(),
  Address: yup.string().required(),
  address_PhoneNumber: yup.string().required(),
  Contact_Name: yup.string().required(),
  contact_Email: yup.string().required(),
  contact_Phone_Number: yup.string().required(),
  Licience: yup.string().required(),
});
