import CustomTypography from 'components/Typography';
import { EmptyStateContainer } from './index.styles';
import emptyState from 'images/restaurants/details/menu-tab/empty-state.svg';

interface IEmptyState {
  entity: string;
  sx?: Record<string, unknown>;
}

const EmptyState = ({ entity, sx }: IEmptyState) => {
  return (
    <EmptyStateContainer style={sx}>
      <img src={emptyState} alt='no menus' />
      <CustomTypography
        fontSize='16px'
        fontWeight='600'
        lineHeight='20px'
        color='#000000'
        marginTop='14px'
      >
        {`You don't have any ${entity} yet.`}
      </CustomTypography>
    </EmptyStateContainer>
  );
};

export default EmptyState;
