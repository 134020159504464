import { Box, styled } from '@mui/material';

export const Content = styled(Box)({
  width: '354px',
  height: '263px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: '#FFFFFF',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.04)',
  borderRadius: '4px',
  margin: 'auto',
  padding: '34px 47px',
  paddingBottom: '60px',
});
