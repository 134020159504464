import React from "react";
import { useEffect, useState } from 'react';
import { ButtonGroup, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const GroupedButtons = (Props:any) => {
  const theme = useTheme();
  const [counter, setCounter] = useState(parseInt(Props.quantity));
  const handleIncrement = () => {
    const newQuantity = counter + 1;
    setCounter(newQuantity);
    Props.setSelectedQuantity(newQuantity);
  };

  const incrementButtonProp = {
    color: theme.palette.common.black,
    border: 'none',
    minWidth: '10px'
  }

  const decrementButtonProp = { 
    color: theme.palette.common.black,
    border: 'none',
    minWidth: '10px'
  }

  const groupButtonProps = {
    border: '1px solid '.concat(theme.palette.common.black),
    borderRadius: '5px',
  }

  const counterProperties = {
    color: theme.palette.common.black,
    border: 'none'
  }

  const handleDecrement = () => {
    if(counter > 1) {
      const newQuantity = counter - 1;
      setCounter(newQuantity);
      Props.setSelectedQuantity(newQuantity);
    }
  };
  
  const displayCounter = counter > 1;

  return (
    <ButtonGroup size="small" sx={groupButtonProps}>
      <Button sx={decrementButtonProp} onClick={handleDecrement}>-</Button>
      <Button sx={counterProperties}>{counter}</Button>
      <Button sx={incrementButtonProp} onClick={handleIncrement}>+</Button>
    </ButtonGroup>
  );
}

export default GroupedButtons;