import { useCallback, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Typography, SxProps } from '@mui/material';
import debounce from "lodash/debounce";

//default to auto save every 5 sec
const DEBOUNCE_SAVE_DELAY_MS = 5000; 

const FormikAutoSave = ({ sx, delayInMs, canAutoSave }: {sx?: SxProps, delayInMs?: number, canAutoSave?: boolean}) => {
    const formik = useFormikContext();
    const [displayText, setDisplayText] = useState<string>();
    const _delayInMs = delayInMs || DEBOUNCE_SAVE_DELAY_MS;

    const debouncedSubmit = useCallback(
      debounce(async () => {
        setDisplayText('Saving...');
        await formik.submitForm();
        setDisplayText(`Last Saved at ${new Date().toLocaleTimeString()}`);
      }, _delayInMs),
      [formik.submitForm, _delayInMs],
    );
  
    useEffect(() => {
        if (canAutoSave) {
            debouncedSubmit();
        } else {
            debouncedSubmit.cancel();
        }
    }, [debouncedSubmit, formik.values, canAutoSave]);
  
    return (
      <Typography sx={sx}>
        {displayText}
      </Typography>
    );
};

export default FormikAutoSave;