import { Ingredient } from 'interfaces/IngredientInterface';
import { MenuItem, MenuItemComponent, MenuItemCustomization, MenuItemCustomizationOption } from 'interfaces/MenuInterface';
import { intersection, union } from 'lodash';

export const processIngredients = (ingredients: Ingredient[]) => {
	// allergies: union of allergies of all required ingredients
	const allergies = union(...ingredients.map(i => i.allergies));
	
	// diets: intersection of diets of all required ingredients
	const diets = intersection(...ingredients.map(i => i.diets));
	// console.log(customization.name, {allergies: customization.allergies, diets: customization.diets})
	return {
        allergies,
        diets,
    }
}

export const processCustomzationOptions = (customizationOption: MenuItemCustomizationOption) => {
	const { allergies, diets } = processIngredients(customizationOption.ingredients || []);
	return {
		...customizationOption,
		allergies,
		diets,
	}
}

export const processCustomization = (customization: MenuItemCustomization) => {
	// const { menuItemCustomizationOptions } = customization;
	
	// ingredients is the list of all ingredients from all options
	customization.menuItemCustomizationOptions = customization.menuItemCustomizationOptions.map(processCustomzationOptions);

	// a customization has allergy if all options contains the allergy
	customization.allergies = intersection(...customization.menuItemCustomizationOptions.map(i => i.allergies));
	
	// a customization supports a diet if any one options supports the diet
	customization.diets = union(...customization.menuItemCustomizationOptions.map(i => i.diets));

	return customization;
}

export const processComponent = (menuItemComponent: MenuItemComponent, menuItemCustomizations: MenuItemCustomization[]) => {
	const { ingredients, } = menuItemComponent;
	const { allergies, diets } = processIngredients(ingredients || []);
	const customizations = menuItemComponent.customizations.map(customization => menuItemCustomizations.find(menuItemCustomization => menuItemCustomization.id === customization.customizationId));

	//allergies: union all the ingredients of this component + intersection of allergies of each customization
	menuItemComponent.allergies = [
		...allergies, 
		...intersection(...customizations.map(c => c?.allergies || [])),
	]
	
	//diets: intersection of all ingredietns of this component + union of allergies of each customization
	menuItemComponent.diets = [
		...diets, 
		...union(...customizations.map(c => c?.diets || [])),
	]

	// console.log(component.name, {allergies: component.allergies, diets: component.diets});
	return menuItemComponent
}

export const processMenuItem = (menuItem: MenuItem) => {
	// const { allergies, diets } = menuItem;

	const processedCustomizations = (menuItem.menuItemCustomizations || []).map(processCustomization);

	menuItem.components = (menuItem.components || []).map(component => processComponent(component, processedCustomizations));

	const requiredComponents = menuItem.components.filter(c => c.required);

	// allergies: union of allergies from all required components
	menuItem.allergies = union(...requiredComponents.map(c => c.allergies));
	
	// diets: intersection of diets of all required ingredients
	menuItem.diets = intersection(...requiredComponents.map(c => c.diets));

	return menuItem;
}