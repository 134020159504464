import { makeStyles } from '@mui/styles';
import { Button, ButtonProps, Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
  filled: {
    backgroundColor: '#ffbf30',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#ffbf30',
    },
    color: '#1b4238'
  },
  outlined: {
    border: '2px solid #1b4238',
    '&:hover': {
      border: '2px solid #1b4238',
    },
    color: '#1b4238'
  }
}));

interface propType {
  text: string;
  onClick: () => void;
  disable?: boolean | undefined;
  outlined?: boolean;
  startIcon?: ButtonProps['startIcon'];
  endIcon?: ButtonProps['endIcon'];
}

const GoldiButton = ({ text, onClick, disable, outlined, startIcon, endIcon }: propType) => {
  const classes = useStyles();

  return (
    <Button
      className={outlined ? classes.outlined : classes.filled}
      onClick={onClick}
      disabled={disable}
      variant={outlined ? 'outlined' : 'contained'}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      <Typography>{text}</Typography>
    </Button>
  );
};

export default GoldiButton;
