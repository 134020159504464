import { generateDietsQuery } from 'Utils/helpers/queryGenerators';
import { useApi } from 'providers/ApiProvider';
import { useQuery } from 'react-query';
import './index.css';

import { Box, Chip, Typography } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import TableActions from 'components/TableActions';
import CustomTypography from "components/Typography";

import DeleteResourceDialog from "components/DeleteResourceDialog";
import LoadingPage from 'components/LoadingPage';
import { Goal } from 'interfaces/GoalInterface';
import { useMutationQuery } from 'providers/MutationQueryProvider';
import { useState } from 'react';
import GoalsDialog from './DietsDialog';


const Diets = () => {
  const api = useApi();

  const { status: _status, error: _error, data: diets, isLoading: _isLoading, refetch } = useQuery(generateDietsQuery(), async () => {
    return api.getDiets();
  });

  const newDiet = {
    id: 'your_id',
    name: 'your_name',
    title: 'your_title',
    description: 'your_description',
    filledIconUrl: ['your_icon_url'],
    active: true,
    diets: [],
    goals: []
  };

  const [dietDialogIsOpen, setDietDialogIsOpen] = useState(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const [diet, setDiet] = useState(newDiet);


  const { addDietMutation, deleteDietMutation, editDietMutation, } = useMutationQuery();

  const [isEdit, setIsEdit] = useState<boolean>(false);




  if (_isLoading) <LoadingPage />;

  return (
    <>
      <div className='diets-outlet'>
        <Box sx={{ px: 2, py: 4 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <CustomTypography
              fontWeight='bold'
              fontSize='24px'
              lineHeight='26px'
              marginBottom='37px'
            >
              Diets
            </CustomTypography>
            {/* <ButtonAddNew
            label='Add New'
            styles={{ marginTop: '-8px', marginRight: '3px' }}
            onClick={() => {
              setIngredient(newIngredient);
              setIsEdit(false);
              setDietDialogIsOpen(true);
            }}
          /> */}
          </Box>
          <Box sx={{ backgroundColor: 'white', height: 850 }}>

            {!_isLoading && <Box sx={{ backgroundColor: 'white', height: 850 }}>
              <DataGrid
                // editMode='row'
                showCellRightBorder={true}
                rows={diets}
                columns={[
                  {
                    field: 'id', headerName: 'key', minWidth: 150, flex: 1,
                    renderCell: (params) => {
                      return params.row.key
                    },
                  },
                  {
                    field: 'icon', minWidth: 40, headerName: 'Image',
                    renderCell: (params) => {
                      return (
                        <Box sx={{
                          width: 100,
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}>
                          <img src={params.row.unfilledIconUrl} width={30} height={30} style={{ marginRight: 10 }} />
                          <img src={params.row.filledIconUrl} width={30} height={30} />
                        </Box>
                      )
                    },
                  },
                  {
                    field: 'name', headerName: 'Name', minWidth: 100, flex: 1,
                    renderCell: (params) => {
                      return params.row.name
                    },
                  },
                  {
                    field: 'title', headerName: 'Title', minWidth: 200, flex: 1,
                    renderCell: (params) => {
                      return (
                        <Typography variant='body2' overflow={'scroll'}>
                          {params.row.title}
                        </Typography>
                      )
                    },
                  },
                  {
                    field: 'description',
                    headerName: 'Description',
                    minWidth: 500,
                    flex: 1,
                    renderCell: (params) => {
                      return (
                        <Typography variant='body2' overflow={'scroll'}>
                          {params.row.description}
                        </Typography>
                      )
                    }
                  },
                  {
                    field: 'active',
                    headerName: 'Active',
                    minWidth: 20,
                    flex: 1,
                    renderCell: (params) => {
                      return (
                        <Chip
                          label={params.row.active ? 'Yes' : 'No'}
                          color={params.row.active ? 'success' : 'error'}
                        />
                      );
                    },
                  },
                  {
                    field: 'actions',
                    headerName: 'Actions',
                    minWidth: 10,
                    flex: 1,
                    renderCell: (params) => {
                      return (
                        <>
                          {
                            params.row.active ? <TableActions
                              handleEdit={() => {
                                setDiet(params.row);
                                setIsEdit(true);
                                setDietDialogIsOpen(true);
                              }}
                            />
                              :
                              <TableActions
                                handleEdit={() => {
                                  setDiet(params.row);
                                  setIsEdit(true);
                                  setDietDialogIsOpen(true);
                                }}
                                handleDelete={() => {
                                  setDiet(params.row);
                                  setDeleteDialogIsOpen(true);
                                }}
                              />
                          }
                        </>
                      );
                    },
                  },
                ]}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
            </Box>}
          </Box>
        </Box>

        <GoalsDialog
          isOpen={dietDialogIsOpen}
          saveDisabled={
            addDietMutation.isLoading || editDietMutation.isLoading
          }
          goal={diet}
          isEdit={isEdit}
          handleCloseDialog={() => {
            setDietDialogIsOpen(false);
          }}
          handleSave={async (diet: Goal) => {
            const result = isEdit
              ? await editDietMutation.mutateAsync(diet)
              : await addDietMutation.mutateAsync(diet);

            if (!isEdit) {
              setDiet(result);
              setIsEdit(true);
            }
          }}
        />


        <DeleteResourceDialog
          isOpen={deleteDialogIsOpen}
          handleClose={() => setDeleteDialogIsOpen(false)}
          title={`Delete ${diet?.name || 'diet'}?`}
          body={
            'This is an irreversible action. Are you sure you wish to delete this diet?'
          }
          handleDelete={async () => {
            await deleteDietMutation.mutateAsync(diet);
            setDiet(newDiet);
            setDeleteDialogIsOpen(false);
          }}
        // disabled={deleteAllergyMutation.isLoading}
        />
      </div>
    </>
  );
};

export default Diets;
