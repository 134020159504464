import { Box, Container, Divider, Typography } from '@mui/material';

// import AddSpecialInstructions from './AddSpecialInstructions';
import { useTheme } from '@mui/material/styles';
import { Item } from 'modules/explorer/ExploreRestaurant/model/menu';
import { Preference } from 'modules/explorer/ExploreRestaurant/model/preference';
import { formatCentsToDollars, getMatchingCustomizations } from 'modules/explorer/ExploreRestaurant/util/util';
import { useApi } from 'providers/ApiProvider';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { OrderMenuItem } from './OrderMenuItem';
interface Props {
  order: any;
  isEditable: boolean,
  setInstruction: (str: string) => void,
  instruction: string,
  orderType: string
}

export const OrderItem = ({ order, isEditable, instruction, setInstruction, orderType }: Props) => {
  const { items, total, user } = order;
  const theme = useTheme();
  // const { publicRestaurant, cart, updateQuantity, setCartTotal, removeFromCart, clearCart, saveOrder } = useCart();
  const { restaurantId } = useParams();
  const api = useApi();
  const navigate = useNavigate();
  const [orderCustomizations, setOrderCustomizations] = useState<any>({});
  const [userEmail, setUserEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [customItems, setCustomItems] = useState<Item[]>(items);
  const userId = "1";
  const orderFooterProps = {
    textAlign: 'right'
  }
  const appStoreFormProps = {
    mb: 20
  }

  useEffect(() => {
    getOrderCustomizationsHandler();
  }, [order]);

  const saveInstruction = (val: string) => {
    setInstruction(val);
  }

  const getOrderCustomizationsHandler = async () => {
    const res = await api.getOrderCustomizations(order);
    setOrderCustomizations(res);
  }

  useEffect(() =>{
    let tempTotal = 0;
    items.map((item: { customizations: any[]; preference: Preference; menuItem: {
      price: any; menuItemCustomizations:  Item[]; menuItemCustomizationOptions: Item[]; 
}; customisedPrice: number; price: any; quantity: number; }) => {
      const enabledAllergies = item.preference.enabledAllergies;
      const enabledDiets = item.preference.enabledDiets;
      const selectedCustomizationOptionIds = item.customizations
        .map((customization) => customization.menuItemCustomizationOptions)
        .flat()
        .map((option) => option.id);

       const {filteredItems:filteredCustomizations} = getMatchingCustomizations(
        item.menuItem.menuItemCustomizations,
        {
          enabledAllergies,
          enabledDiets,
        }
      );
      const customizationPrice = filteredCustomizations
          .flatMap(
            ({ menuItemCustomizationOptions }) => menuItemCustomizationOptions
          )
          .filter(({ id }) => selectedCustomizationOptionIds.includes(id ?? ''))
          .reduce((sum, { price }) => {
            return sum + (price ? price : 0);
          }, 0);
          item.customisedPrice = parseInt(item.menuItem.price) + parseInt(customizationPrice);
          tempTotal += (item.customisedPrice * item.quantity);
    });
  }, [])

   const boxProperties = {
    p:2, 
    backgroundColor: theme.palette.grey[0], 
    boxShadow: 3, 
    mb: 1
  }

  return (
    <Box mt={5}>
      <Container sx={boxProperties}>
        <Typography variant='body1'>
        Place Your Order:
        </Typography>
         <Box>
            <Typography variant='subtitle1' fontSize={12}>
            Your order is powered by Goldi’s algorithm, with your preferences in mind. Order the below with your server & your meal will be Just Right.
            </Typography>
          </Box>
          <Typography variant='body1'  mb={2}>
          Customer Name - {user.name}
        </Typography>
          <Divider />
        {items.map((item: { menuItem: { id: any; }; }, index: number) => (
          <OrderMenuItem orderCustomizations={orderCustomizations[index]} userId={userId} handleOnMenuItemDelete={(obj: any) => null } updateQuantity={(obj: any) => null} key={`row-${item.menuItem.id}-${index}`} item={item} isEditable={isEditable} index={index} />
        ))}
      {/* <AddSpecialInstructions instruction={instruction} saveInstruction={(str:string) => saveInstruction(str)} isEditable={isEditable} /> */}
      <Box sx={orderFooterProps}>
        <Typography variant='subtitle2' sx={{fontSize: 16, mt:2}}>
          Subtotal: {formatCentsToDollars(total)}
        </Typography>
      </Box>
      </Container>
    </Box>
  );
};
