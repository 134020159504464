import { WEEKDAYS } from "Utils/constants/schedule";
import { FullMenu } from "./MenuInterface";

export enum RestaurantStatus {
  ACTIVE = 'active',
  PENDING = 'pending',
  DISABLED = 'disabled',
  PAUSED = 'paused',
  INCOMPLETE = 'incomplete',
}

export const RestaurantDeliveryStatus = {
  ENABLED: true,
  DISABLED: false
}
export const RestaurantChowly = {
  ENABLED: true,
  DISABLED: false
}

export type RestaurantInformation = {
  tags?: string[];
  name: string;
  address: string;
  latitude?: number;
  longitude?: number;
  googlePlaceId?: string;
  phoneNumber: string;
  contactName: string;
  contactPhoneNumber?: string;
  isDeliverySupported: boolean;
  email: string;
  status: RestaurantStatus;
  homePage?: string;
  orderPage?: string;
  externalId?: string;
  externalSource?: string;
  urlSlug?: string;
  isChowly?: boolean;
  isPickUpSupported?: boolean;
  isDineInSupported?: boolean;
  priceMarkUpPercentage?: number;
}

export type RestaurantDayHours = {
  openAt?: string;
  closeAt?: string;
  isOpen: boolean;
  hours: {
    startAt: string;
    endAt: string;
  }[]
}

export type RestaurantOperationHours = {
  operatingHours: {
    [key in WEEKDAYS]: RestaurantDayHours
  }
}

export type RestaurantImage = {
  image?: File;
  imageUrl?: string;
  imageUrls?: string[];
}

export type Restaurant = {
  id?: string;
  merchantId: string;
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
  updatedBy?: string;
  deletedAt?: string;
  deletedBy?: string;
} & RestaurantInformation & RestaurantOperationHours & RestaurantImage

export type FullRestaurant = Restaurant & {
  menus: FullMenu[];
}