import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useApi } from 'providers/ApiProvider';
import { useState } from 'react';
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import { Box } from '@mui/system';
import CustomIngredientsTab from 'modules/merchant_portal/Restaurants/RestaurantDetails/IngredientsTab';
import MenuTab from 'modules/merchant_portal/Restaurants/RestaurantDetails/MenuTab';
// import OverviewTab from 'modules/merchant_portal/Restaurants/RestaurantDetails/OverviewTab';
import CustomTabs from 'components/CustomTabs';
import TabPanel from 'components/CustomTabs/TabPanel';
import CustomTypography from 'components/Typography';
import { RestaurantDetailsHeader } from './index.styles';
// import { RestaurantLocationDetails } from 'interfaces/RestaurantDetails';
// import { WEEKDAYS } from 'Utils/constants/schedule';
import { useTheme } from '@mui/material';
import { generateRestaurantCustomIngredientsQuery, generateRestaurantIdQuery, generateRestaurantMenusQuery } from 'Utils/helpers/queryGenerators';
import trimText from 'Utils/helpers/trimString';
import LoadingPage from 'components/LoadingPage';
import RestaurantOverview from 'components/RestaurantOverview';



const RestaurantDetails = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const navigate = useNavigate();
  const theme = useTheme();
  const api = useApi();
  const { restaurantId } = useParams();
  const { status: _restaurantStatus, error: _restaurantError, data: restaurant, isLoading: _isLoadingRestaurant } = useQuery(generateRestaurantIdQuery(restaurantId), async () => {
    return api.getRestaurantById(restaurantId);
  });
  const { status: _menusStatus, error: _menusError, data: menus, isLoading: _isLoadingMenus } = useQuery(generateRestaurantMenusQuery(restaurantId), async () => {
    return api.getMenusByRestaurantId(restaurantId);
  });
  const { status: _customIngredientsStatus, error: _customIngredientsError, data: customIngredients, isLoading: _isLoadingCustomIngredients } = useQuery(generateRestaurantCustomIngredientsQuery(restaurantId), async () => {
    return api.getCustomIngredientsByRestaurantId(restaurantId);
  });
  const orderHeader = {
    position: 'fixed',
    pl: 2,
    pt: 1.5,
    top: 0,
    width: "100%",
    color: theme.palette.grey[500],
    
    zIndex: 99999,
  }
  const isLoading = !(restaurant && menus && customIngredients);

  return ( isLoading ? <LoadingPage /> :
    <Box>
       <Box sx={orderHeader} maxWidth='sm'>
        <ArrowBackIosNewIcon onClick={() => { navigate(-1); }} />
      </Box>
      <RestaurantDetailsHeader>
        <CustomTypography fontSize='14px' lineHeight='20px'>
          Restaurants / {trimText(restaurant.name, 30)}
        </CustomTypography>
        <CustomTypography
          fontWeight='bold'
          fontSize='35px'
          lineHeight='39px'
          letterSpacing='-0.015em'
          margin='10px 0px 28px'
        >
          {trimText(restaurant.name, 30)}
        </CustomTypography>


        <CustomTabs
          orientation='horizontal'
          selectedTab={selectedTab}
          onChange={(
            _event: React.SyntheticEvent<Element, Event>,
            newValue: number
          ) => setSelectedTab(newValue)}
          tabs={[
            { label: 'Menu' },
            { label: 'Ingredients' },
            { label: 'Overview' },
          ]}
        />
      </RestaurantDetailsHeader>

      <TabPanel value={selectedTab} index={0} id='menu-tab'>
        <MenuTab restaurant={restaurant} menus={menus} />
      </TabPanel>
      <TabPanel value={selectedTab} index={1} id='ingredients-tab'>
        <CustomIngredientsTab restaurantId={restaurant.id} merchantId={restaurant.merchantId} customIngredients={customIngredients} />
      </TabPanel>
      <TabPanel value={selectedTab} index={2} id='overview-tab'>
        {/* <OverviewTab restaurantLocations={restaurantLocations} restaurant={restaurant} /> */}
        <RestaurantOverview restaurant={restaurant} />
      </TabPanel>
    </Box>
  );
};

export default RestaurantDetails;
