import React from 'react';
import resetLinkSentImg from 'images/icons/reset-link-sent.svg';
import GoldiHeader from 'components/GoldiHeader';
import { Content } from './index.styles';
import { Container } from '../index.styles';
import CustomLink from 'components/CustomLink';
import CustomTypography from 'components/Typography';
import { useLocation } from 'react-router-dom';
import AuthService from 'services/auth.service';

type LocationProps = {
  state: {
    email: Location;
  };
};

export default function ResetLinkSent() {
  const location = useLocation() as unknown as LocationProps;
  const email = location.state?.email;

  const resendLink = () => {
    AuthService.sendResetPasswordEmail(email as unknown as string);
  };

  return (
    <Container>
      <GoldiHeader />

      <Content>
        <img
          src={resetLinkSentImg}
          alt='reset-link-sent'
          width={83}
          height={84}
        />
        <CustomTypography
          fontWeight='bold'
          fontSize='24px'
          lineHeight='26px'
          letterSpacing='-0.015em'
          color='#000000'
          marginBottom={'24px'}
          marginTop='15px'
        >
          Reset Link Sent
        </CustomTypography>

        <CustomTypography
          fontSize='15px'
          lineHeight='20px'
          letterSpacing='-0.015em'
          color='#000000'
          textAlign='center'
          marginBottom={'39px'}
        >
          If the email address you&apos;ve entered is associated with a Goldi
          account, an email with instructions to reset your password will be
          sent.
        </CustomTypography>

        <CustomLink onClick={resendLink}>Resend Link</CustomLink>
      </Content>
    </Container>
  );
}
