import React from 'react';
import { Content } from './index.styles';
import { Container } from '../index.styles';
import CustomButton from 'components/CustomButton';
import CustomTextField from 'components/CustomTextField';
import CustomTypography from 'components/Typography';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import GoldiHeader from 'components/GoldiHeader';
import CustomLink from 'components/CustomLink';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthService from 'services/auth.service';
import * as ROUTES from 'Utils/constants/routes';

export const forgotPasswordSchema = yup.object().shape({
  email: yup.string().email('Invalid Email').required('Email is required'),
});

type LocationProps = {
  state: {
    email: Location;
  };
};

function ResetPassword(): React.ReactElement {
  const navigate = useNavigate();
  const location = useLocation() as unknown as LocationProps;
  const email = location.state?.email?.pathname || '';

  const {
    register,
    handleSubmit,
    getValues,
    formState: { isValid, errors },
  } = useForm<{
    email: string;
  }>({
    mode: 'onChange',
    resolver: yupResolver(forgotPasswordSchema),
    defaultValues: {
      email: email,
    },
  });

  const onSendResetLink = async (data: { email: string }) => {
    AuthService.sendResetPasswordEmail(data.email);
    navigate(ROUTES.RESET_LINK_SENT, {
      state: {
        email: getValues('email'),
      },
    });
  };

  return (
    <Container>
      <GoldiHeader />

      <Content>
        <CustomTypography
          fontWeight='bold'
          fontSize='24px'
          lineHeight='26px'
          letterSpacing='-0.015em'
          color='#000000'
          marginBottom={'23px'}
        >
          Reset Password
        </CustomTypography>

        <CustomTypography
          fontSize='15px'
          lineHeight='20px'
          letterSpacing='-0.015em'
          color='#000000'
          marginBottom={'34px'}
        >
          Enter the email that is associated with your account and we’ll send
          you a link to reset your password.
        </CustomTypography>

        <CustomTextField
          id='reset-password-email'
          label='Email Address'
          register={register}
          fieldName='email'
          errors={errors}
        />

        <CustomButton
          onClick={handleSubmit(onSendResetLink)}
          disabled={!isValid}
          width='100%'
          margin='20px 0px'
        >
          Send Reset Link
        </CustomButton>

        <CustomLink
          onClick={() => navigate('/')}
          sx={{ marginTop: '4px', alignSelf: 'center' }}
        >
          Go Back to Log in
        </CustomLink>
      </Content>
    </Container>
  );
}

export default React.memo(ResetPassword);
