import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Splash from './components/Splash';

const ExploreRestaurant = () => {
  const { restaurantId } = useParams();

  const [videoPlayed, setVideoPlayed] = useState(false);
   const navigate: any = useNavigate();

   useEffect(() => {
    if (videoPlayed && restaurantId) {
      navigate('/explore/' . concat(restaurantId, '/onboarding'));
    }
  }, [videoPlayed]);

  return (
    <Splash setVideoPlayed={(val) => setVideoPlayed(true)} />
  );
};

export default ExploreRestaurant;
