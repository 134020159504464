import { Currency } from "firebase/analytics";

export enum OrderStatus {
    COMPLETE = 'complete',
    PENDING = 'pending',
    CANCELLED = 'cancelled',
    DISABLED = 'disabled',
    PAUSED = 'paused',
    INPROCESS = 'inprocess',
    CLAIMED = 'claimed',
    ESCALATED = 'escalated',
}
export enum PaymentStatus {
    PROCESSING = 'processing',
    SUCCEEDED = 'succeeded',
    AMOUNT_CAPTURABLE_UPDATED = 'amount_capturable_updated',
    PAYMENT_FAILED = 'payment_failed',
    PAYMENT_NA = 'N/A',
    PENDING = 'pending'
}
export enum OrderType {
    Delivery = "Delivery",
    Pickup = "Pickup",
    InRestaurant = "In Restaurant",
}
export type OrderInformation = {
    restaurantName?: string,
    title?: string,
    status?:string;
    paymentStatus?: PaymentStatus
    orderStatus?: OrderStatus,
    orderType?:OrderType,
    isGuestOrder?:boolean,
    userName?: string,
    total?: Currency,
    restaurntId?:string,
    merchantId?:string,
    createdAt?:Date
    claimedBy?:string,
    externalOrderId?:string,
    externalOrderStatus?:string,
    externalOrderSystem?:string,
    commments?:string,
    restaurantHomePage?: string,
    restaurantOrderPage?: string,
    isChowly?: boolean,
};

export type Order = {
    id?: string;
    createdAt?: string;
    updatedAt?: string;
    createdBy?: string;
    updatedBy?: string;
    deletedAt?: string;
    deletedBy?: string;
  } & OrderInformation ;