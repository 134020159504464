import { FC } from 'react';
import classes from './index.module.css';

interface Props {
  width?: number;
  height?: number;
}

const Loader: FC<Props> = ({ width, height }) => {
  return (
    <div className={classes.center}>
      <div
        className={classes.loader}
        style={{ width: width && width, height: height && height }}
      ></div>
    </div>
  );
};

export default Loader;
