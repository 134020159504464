import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useDietaryPreferences } from 'providers/DietaryPreferenceProvider';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SelectPreferences, { PreferenceType } from '../SelectPreferences';
import ConfirmDialog from '../components/ConfirmDialog';
import ErrorAlert from '../components/ErrorAlert';
import Footer from './footer';
import Header from './header';
import Intro from './intro';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const pages = [0, 1, 2];

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function Onboarding() {
  const theme = useTheme();
  const hash = window.location.hash ? parseInt(window.location.hash.replace("#", "")) : 0;
  const [value, setValue] = React.useState(pages.indexOf(hash) == -1 ? 0 : hash);
  const [error, setError] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const navigate: any = useNavigate();
  const { restaurantId } = useParams();
  
  const {
    selectedAllergies,
    selectedDiets,
    toggleSelectedAllergy,
    toggleSelectedDiet,
    clearAllergies,
    clearDiets,
    username
  } = useDietaryPreferences();

  useEffect(() => {
    if((!username || username.length == 0) && value != 0) {
      //setValue(0);
      //setError("Please enter your name");
    }
    if(typeof username == 'string' && username) {
      setError("")
    }
    if(value == 1) {
      clearDiets();
    } else if(value == 2) {
      clearAllergies();
    }
  }, [value])

  const clearSelections = async (index: number) => {
    const selections = index == 2 ? selectedDiets : selectedAllergies;
    if(index == 2) {
      clearDiets()
    } else {
      clearAllergies()
    }
  }

  const handleChangeIndex = async (index: number, skip: boolean) => {
    if(skip) {
      await clearSelections(index);
    }
    
    if(index > 2 && restaurantId) {
      setOpenDialog(true);
    } else {
      setValue(index);
    }
  };

  const checkConfirmation = (val: boolean, answer: boolean) => {
    if(answer && restaurantId) {
      navigate('/explore/' . concat(restaurantId, '/menu'));
    }
    setOpenDialog(val);
  }

  const getSelectedPreferencesCount = (val: number) => {
    if (val == 1) {
      return selectedDiets.length;
    } else if (val == 2) {
      return selectedAllergies.length;
    } else {
      return 0;
    }
  }

  return (
    <Box height="100vh" display="flex" flexDirection="column" sx={{ backgroundColor: theme.palette.grey[0] }}>
      <Header changeStep={(val, skip) => handleChangeIndex(val, skip)} stepValue={value} selectedPreferencesCount={getSelectedPreferencesCount(value)} />
        <Box sx={{ backgroundColor: theme.palette.grey[0], mt: 6 }}>
          {error && <ErrorAlert error={error}/>}
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Intro changeStep={(val, skip) => handleChangeIndex(val, skip)} stepValue={value} />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <SelectPreferences preferenceType={PreferenceType.diet} />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <SelectPreferences preferenceType={PreferenceType.allergy} />
          </TabPanel>
          {openDialog && <ConfirmDialog setOpenDialog={(val:boolean, answer: boolean) => checkConfirmation(val, answer)} openDialog={openDialog} />}
          <Footer changeStep={(val) => handleChangeIndex(val, false)} tab={value} selectedPreferencesCount={getSelectedPreferencesCount(value)} />
        </Box>
    </Box>
  );
}
