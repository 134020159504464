import React from "react";
import { useEffect, useState } from 'react';
import { ButtonGroup, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const GroupedButtons = (Props:any) => {
  const theme = useTheme();
  const [counter, setCounter] = useState(1);
  const handleIncrement = () => {
    const newQuantity = counter + 1;
    setCounter(newQuantity);
    Props.setSelectedQuantity(newQuantity);
  };

  const incrementButtonProp = {
    borderBottomRightRadius: '150px',
    borderTopRightRadius: '150px',
    border: '1px solid '.concat(theme.palette.info.lighter)
  }

  const decrementButtonProp = {
    borderBottomLeftRadius: '150px',
    borderTopLeftRadius: '150px', 
    border: '1px solid '.concat(theme.palette.info.lighter)
  }

  const counterProperties = {
    border: '1px solid '.concat(theme.palette.info.lighter, '!important')
  }

  const handleDecrement = () => {
    if(counter > 1) {
      const newQuantity = counter - 1;
      setCounter(newQuantity);
      Props.setSelectedQuantity(newQuantity);
    }
  };
  
  const displayCounter = counter > 1;

  return (
    <ButtonGroup size="medium" aria-label="small outlined button group">
      <Button sx={decrementButtonProp} onClick={handleDecrement}>-</Button>
      <Button sx={counterProperties} disabled>{counter}</Button>
      <Button sx={incrementButtonProp} onClick={handleIncrement}>+</Button>
    </ButtonGroup>
  );
}

export default GroupedButtons;