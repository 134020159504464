import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { MouseEventHandler, ReactElement } from 'react';
import { StyledIconButton } from './index.styles';

interface ICustomIconButton {
  icon?: string;
  muiIcon?: OverridableComponent<SvgIconTypeMap> & { muiName: string };
  label: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  component?: string;
  altText: string;
  children?: ReactElement;
  styles?: Record<string, unknown>;
}
const CustomIconButton = ({
  icon,
  muiIcon,
  label,
  onClick,
  component,
  altText,
  children,
  styles,
}: ICustomIconButton) => {
  return (
    <StyledIconButton component={component} onClick={onClick} sx={styles}>
      <>
        { icon && <img src={icon} alt={altText} style={{marginRight: 5}} />}
        { muiIcon && muiIcon }
        {label}
        {children}
      </>
    </StyledIconButton>
  );
};

export default CustomIconButton;
