import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

import { generateMerchantsQuery } from "Utils/helpers/queryGenerators";
import { Menu, MenuItem } from "interfaces/MenuInterface";
import { Restaurant } from "interfaces/ResturantInterface";
import { useApi } from 'providers/ApiProvider';

import GoldiDropDownMenu from 'components/DropDown';
import LoadingPage from 'components/LoadingPage';
import { Merchant } from "interfaces/MerchantInterface";

type ImportMenuItemsDialogPropsType = {
  isOpen: boolean;
  handleClose: () => void;
  handleImport: (menuItems: MenuItem[]) => void;
}

const ImportMenuItemsDialog = ({ isOpen, handleClose, handleImport }: ImportMenuItemsDialogPropsType) => {
 
    const [restaurants, setRestaurants] = useState<Restaurant[]>([]);
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
    const [menus, setMenus] = useState<Menu[]>([]);
    const [selectedMenuItemIds, setSelectedMenuItemIds] = useState<string[]>([]);
    const [selectedMenu, setSelectedMenu] = useState<Menu>();
    const [selectedRestaurant, setSelectedRestaurant] = useState<Restaurant>();
    const [selectedMerchant, setSelectedMerchant] = useState<Merchant>();

    const api = useApi();
    const { status: _status, error: _error, data: merchants, isLoading: _isLoading } = useQuery(generateMerchantsQuery(), async () => {
        return api.getMerchants();
    });

    const isLoading = !merchants;

    const handleSelectMerchant = async (merchant: Merchant) => {
        const restaurants = await api.getMerchantRestaurants(merchant.id);
        setRestaurants(restaurants);
    };

    const handleSelectRestaurant = async (restaurant: Restaurant) => {
        const menus = await api.getMenusByRestaurantId(restaurant.id);
        setMenus(menus);
    };

    const handleSelectMenu = async (menu: Menu) => {
        const menuItems = await api.getMenuItemsByMenuId(menu.id);
        setMenuItems(menuItems);
    };
    
   // const filteredMerchants = merchants.filter((merchant:Merchant) => merchant.restaurantCount > 0);
   // const filteredRestaurants = restaurants.filter((restaurant:Restaurant) => restaurant.restaurantCount > 0);

    useEffect(() => {
        if (selectedMenu) {
            handleSelectMenu(selectedMenu)
        } else {
            setMenuItems([]);
            setSelectedMenuItemIds([]);
        }
    }, [selectedMenu]);

    useEffect(() => {
        if (selectedRestaurant) {
            handleSelectRestaurant(selectedRestaurant)
        } else {
            setMenus([]);
            setSelectedMenu(undefined);
            setMenuItems([]);
            setSelectedMenuItemIds([]);
        }
    }, [selectedRestaurant]);

    useEffect(() => {
        if (selectedMerchant) {
            handleSelectMerchant(selectedMerchant)
        } else {
            setMenus([]);
            setSelectedMenu(undefined);
        }
    }, [selectedMerchant]);

  return ( isLoading ? <LoadingPage /> : 
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="import-menu-item-dialog-title"
        aria-describedby="import-menu-item-dialog-description"
        maxWidth='sm'
        fullWidth={true}
      >
        <DialogTitle>
          Import Menu Items
        </DialogTitle>
        <DialogContent>
            <Typography>Select Merchant</Typography>
            <GoldiDropDownMenu 
                id={'import-menu-item-dialog-merchants-dropdown'}
                // value={formik.values.status}
                onChange={(e: any) => {
                    setSelectedMerchant(merchants.find((merchant: Merchant) => merchant.id === e.target.value))
                }}
                options={ merchants.filter((merchant:Merchant) => merchant.restaurantCount > 0).map((merchant: Merchant) => ({
                    key: merchant.id,
                    value: merchant.id,
                    label: merchant.businessName,
                }))}
            />
            <Typography>Select Restaurant</Typography>
            <GoldiDropDownMenu 
                id={'import-menu-item-dialog-restaurants-dropdown'}
                // value={formik.values.status}
                onChange={(e: any) => {
                    setSelectedRestaurant(restaurants.find((restaurant: Restaurant) => restaurant.id === e.target.value))
                }}
                options={restaurants.map((restaurant: Restaurant) => ({
                    key: restaurant.id!,
                    value: restaurant.id!,
                    label: restaurant.name,
                }))}
            />
            <Typography>Select Menu</Typography>
            <GoldiDropDownMenu 
                id={'import-menu-item-dialog-menus-dropdown'}
                // value={formik.values.status}
                onChange={(e: any) => {
                    setSelectedMenu(menus.find((menu: Menu) => menu.id === e.target.value))
                }}
                options={menus.map((menu: Menu) => ({
                    key: menu.id!,
                    value: menu.id!,
                    label: menu.title,
                }))}
            />
            <Typography>Select Menu Items</Typography>
            <GoldiDropDownMenu 
                id={'import-menu-item-dialog-menu-items-dropdown'}
                value={selectedMenuItemIds}
                onChange={(e: any) => {
                    setSelectedMenuItemIds(e.target.value)
                }}
                options={menuItems.map((menuItem: MenuItem) => ({
                    key: menuItem.id!,
                    value: menuItem.id!,
                    label: menuItem.title,
                }))}
                multiple
            />
        </DialogContent>
        <DialogActions>
          <Button sx={{mr: 2}} onClick={handleClose}>Cancel</Button>
          <Button 
            variant='contained' 
            disableElevation
            disabled={!selectedMenuItemIds.length}
            onClick={() => {
                handleImport(
                    menuItems.filter((menuItem: MenuItem) => selectedMenuItemIds.includes(menuItem.id!))
                )
            }}
          >
            Import
          </Button>
        </DialogActions>
      </Dialog>
  )
};

export default ImportMenuItemsDialog