import { DaysAndHours } from 'interfaces/RestaurantDetails';

// export const WEEKDAYS = [
//   'Monday',
//   'Tuesday',
//   'Wednesday',
//   'Thursday',
//   'Friday',
//   'Saturday',
//   'Sunday',
// ];

export enum WEEKDAYS {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}

export const getInitialSchedule = () => {
  return Object.values(WEEKDAYS).map((day) => ({ day, from: 0, to: 0 }));
};

export const getHoursOfTheDay = () => {
  const hours = [];
  for (let i = 0; i < 24; i++) {
    const period = i < 12 ? 'AM' : 'PM';
    const hour = i <= 12 ? (i === 0 ? 12 : i) : i - 12;

    hours.push(`${hour}:00 ${period}`);
  }
  return hours;
};

export const formatDaysAndHours = (daysAndHours: DaysAndHours[]) => {
  return Object.entries(daysAndHours).map(
    ([, val]) => `${val.day}, ${val.from} - ${val.to}`
  );
};
