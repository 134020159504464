import { Divider, Grid, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router';

// import { RESTAURANTS } from 'Utils/constants/routes';
import GoldiButton from '../../Button';
// import CustomEdit from 'components/CustomEditBox';
import { RestaurantFormStepPropsType } from '..';
// import AuthService, { ROLES } from 'services/auth.service';
import { useTheme } from '@mui/material/styles';
import ErrorAlert from 'components/ErrorAlert';
import RestaruantSummaryDisplay from 'components/RestaurantSummary';
import { Restaurant } from 'interfaces/ResturantInterface';
import { useState } from 'react';

type RestaurantSummaryPropsType = {
  handleSubmit: (params: any) => Promise<any>
} & RestaurantFormStepPropsType

const RestaurantSummary = ({setCurrentStep, currentValues, handleSubmit}: RestaurantSummaryPropsType) => {
  console.log('------currentValues------');
  console.log(currentValues);
  const navigate = useNavigate();
  const theme = useTheme();
  const { merchantId } = useParams();
  const [error, setError] = useState('');
  const submitForm = async () => {
    await handleSubmit({...currentValues, merchantId}).then((res) => {
      if(res.success == false) {
        setError(res.error);
      } else {
        navigate(`/merchants/${merchantId || currentValues.merchantId}/restaurants`)
      }
    }).catch((e) => {
      setError(e.error);
    });
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography fontWeight={'bold'} fontSize={'1.4rem'}>
          Review
        </Typography>
        {error && <ErrorAlert error={error} />}
      </Grid>

      <Grid item xs={12}>
        <Typography variant='subtitle1' fontWeight={'bold'}>
          Look over the info below to make sure everything looks right
        </Typography>
      </Grid>

      <Divider variant='middle' />

      <RestaruantSummaryDisplay
        restaurant={currentValues as Restaurant}
        canEdit={false}
      />

      <Grid
        container
        mt={5}
        sx={{
          position: 'sticky',
          bottom: 0,
          background: 'white',
          justifyContent: 'space-between',
          padding: 1,
        }}
      >
        <Grid item xs={4}>
          <GoldiButton
            text='Edit'
            outlined={true}
            onClick={() => setCurrentStep(1)}
          />
        </Grid>
       
        <Grid item justifyContent='flex-end'>
          <GoldiButton onClick={submitForm} text='Save and Finish' />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RestaurantSummary;
