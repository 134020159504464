import { TextField } from "@mui/material";
import Autocomplete  from "@mui/material/Autocomplete";
import { useState, useEffect } from "react";

type PropsType = {
    initialTags: any,
    handleGetTags: (tags: string[]) => void;
}

const TagsInputField =({
    initialTags,
    handleGetTags
}: PropsType) => {
    const [values, setValue] = useState<any>(initialTags);
    const [inputValue, setInputValue] = useState<any>("");

    useEffect(() => {
        setValue(initialTags);
    }, [initialTags])
    
    return(
        <Autocomplete
        multiple
        freeSolo
        id="tags"
        options={[]}
        value={values}
        inputValue={inputValue}
        onChange={(event, newValue) => {
            setValue(newValue);
            handleGetTags(newValue);
        }}
        onInputChange={(event, newInputValue) => {
            const options = newInputValue.split(",");
            if (options.length > 1) {
                const updateTags = values.concat(options).map((x: string) => x.trim()).filter((x: any) => x)
                handleGetTags(updateTags);
                setValue(updateTags);            
                setInputValue('')
            } else {
                setInputValue(newInputValue);
            }
        }}
        renderInput={params => (
            <TextField
                {...params}
                placeholder="Add Tags : Pizza, Pasta,"
            />
        )}
    />
    );
}

export default TagsInputField;