import StyledContainer from 'components/StyledContainer';
import { useMutationQuery } from 'providers/MutationQueryProvider';
import { useNavigate } from 'react-router-dom';
import MerchantForm from '../../../components/MerchantForm';
import SimpleNav from '../../../components/SimpleNav';

const NewMerchant = () => {
  // API
  const navigate = useNavigate();
  const { addMerchantMutation } = useMutationQuery();
  
  const handleSubmit = (params: any): Promise<any> => {
    return addMerchantMutation.mutateAsync(params)
  }

  return ( 
    <StyledContainer>
      <SimpleNav text='Create New Merchant' onClick={() => navigate(-1)} />
      
      <MerchantForm handleSubmit={handleSubmit}/>
    </StyledContainer>
  );
};

export default NewMerchant;
