import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import MenuCard from 'components/MenuCard';
import { MenuTabWrapper, TabHeader } from './index.styles';
import CustomTypography from 'components/Typography';
import CustomGridList from 'components/GridList';
import ButtonAddNew from 'components/ButtonAddNew';
import DeleteResourceDialog from 'components/DeleteResourceDialog';
import ActionDialog from 'components/ActionDialog';
import MenuFormSidebar from './MenuFormSidebar';
import { WEEKDAYS } from "Utils/constants/schedule";
import EmptyState from 'components/EmptyState';
import { Restaurant, RestaurantDayHours } from 'interfaces/ResturantInterface';
import { useMutationQuery } from 'providers/MutationQueryProvider';
import { Menu } from 'interfaces/MenuInterface';
import CustomButton from 'components/CustomButton';
import ImportMenuDialog from 'components/ImportMenuDialog';
import AuthService from 'services/auth.service';
import { Box } from '@mui/system';
import CopyMenuDialog from 'components/CopyMenuDialog';

type MenuTabPropsType = {
  restaurant: Restaurant;
  menus: Menu[];
}

const MenuTab = ({ restaurant, menus = [] }: MenuTabPropsType) => {
  const { operatingHours } = restaurant;
  const newMenu: Menu = {
    restaurantId: restaurant.id!, 
    title: '',
    subtitle: '',
    description: '',
    merchantId: restaurant.merchantId,
    active: false,
    serviceAvailability: operatingHours ? 
      Object.keys(operatingHours).map((day: any) => ({
        enabled: (operatingHours[day as WEEKDAYS] as RestaurantDayHours).isOpen,
        day_of_week: day,
        time_periods: operatingHours[day as WEEKDAYS].hours.map((hour) => ({
          start_time: hour.startAt,
          end_time: hour.endAt,
        })),
      })) : [
        { enabled: true, day_of_week: WEEKDAYS.MONDAY, time_periods: [{start_time: '08:00', end_time: '23:30'}] },
        { enabled: true, day_of_week: WEEKDAYS.TUESDAY, time_periods: [{start_time: '08:00', end_time: '23:30'}] },
        { enabled: true, day_of_week: WEEKDAYS.WEDNESDAY, time_periods: [{start_time: '08:00', end_time: '23:30'}] },
        { enabled: true, day_of_week: WEEKDAYS.THURSDAY, time_periods: [{start_time: '08:00', end_time: '23:30'}] },
        { enabled: true, day_of_week: WEEKDAYS.FRIDAY, time_periods: [{start_time: '08:00', end_time: '23:30'}] },
        { enabled: true, day_of_week: WEEKDAYS.SATURDAY, time_periods: [{start_time: '08:00', end_time: '23:30'}] },
        { enabled: true, day_of_week: WEEKDAYS.SUNDAY , time_periods: [{start_time: '08:00', end_time: '23:30'}] },
      ],
    subsections: [],
    externalId: '',
    externalSource: '',
  }
  const navigate = useNavigate();
  const [createMenuOpened, setCreateMenuOpened] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState<Menu>(newMenu);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [deleteMenuDialogIsOpen, setDeleteMenuDialogIsOpen] = useState<boolean>(false);
  const [toggleMenuDialogIsOpen, setToggleMenuDialogIsOpen] = useState<boolean>(false);
  const [importMenuDialogIsOpen, setImportMenuDialogIsOpen] = useState<boolean>(false);
  const [copyMenuDialogIsOpen, setCopyMenuDialogIsOpen] = useState<boolean>(false);
  const { addMenuMutation, editMenuMutation, deleteMenuMutation, duplicateMenuMutation } = useMutationQuery();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const _isAdmin = await AuthService.isAdmin();
      setIsAdmin(_isAdmin);
    })()
  }, [isAdmin]);

  return (
    <MenuTabWrapper>
      <TabHeader>
        <CustomTypography
          fontWeight='600'
          fontSize='24px'
          lineHeight='26px'
          flexGrow={1}
        >
          Menus
        </CustomTypography>

        <Box sx={{display:'flex', gap:1}}>
          <Box>
            <ButtonAddNew
              label='Add Menu'
              onClick={() => {
                setIsEdit(false);
                setSelectedMenu(newMenu);
                setCreateMenuOpened(true);
              }}
            />
          </Box>

          {isAdmin && (
            <CustomButton
              onClick={() => {
                setImportMenuDialogIsOpen(true);
              }}
            >
              Import Menu
            </CustomButton>
          )}
        </Box>
      </TabHeader>

      {menus && menus.length ? (
        <CustomGridList sx={{ marginTop: '40px', gap: '24px' }}>
          {menus.map((menu: Menu, index: number) => (
            <MenuCard
              key={menu.id || index}
              menu={menu}
              deleteMenu={(menu: Menu) => {
                setDeleteMenuDialogIsOpen(true);
                setSelectedMenu(menu);
              }}
              copyMenu={(menu: Menu) => {
                setCopyMenuDialogIsOpen(true);
                setSelectedMenu(menu);
              }}
              editMenuCard={(menu: Menu) => {
                setSelectedMenu(menu);
                setIsEdit(true);
                setCreateMenuOpened(true);
              }}
              autoAssignMenuIngredients={(menu: Menu) => {
                navigate(`/menus/${menu.id}/auto-assign-ingredients`);
              }}
              onClick={() => navigate(`/menus/${menu.id}`)}
              handleToggleActive={(menu: Menu) => {
                setSelectedMenu(menu);
                setToggleMenuDialogIsOpen(true);
              }}
            />
          ))}
        </CustomGridList>
      ) : (
        <EmptyState entity='menus' />
      )}
      {createMenuOpened && (
        <MenuFormSidebar
          closeMenu={() => setCreateMenuOpened(false)}
          handleSave={async (menu: Menu) => {
            setCreateMenuOpened(false);
            setIsEdit(false);
            const result = await (isEdit
              ? editMenuMutation.mutateAsync(menu)
              : addMenuMutation.mutateAsync(menu));
          }}
          menu={selectedMenu}
          isEdit={isEdit}
        />
      )}
      <DeleteResourceDialog
        isOpen={deleteMenuDialogIsOpen}
        handleClose={() => setDeleteMenuDialogIsOpen(false)}
        title={`Delete ${selectedMenu?.title || 'Menu'}`}
        body={
          'This is an irreversible action. Are you sure you wish to delete this menu?'
        }
        handleDelete={async () => {
          await deleteMenuMutation.mutateAsync(selectedMenu);
          setSelectedMenu(newMenu);
          setDeleteMenuDialogIsOpen(false);
        }}
        disabled={deleteMenuMutation.isLoading}
      />
      <ActionDialog
        isOpen={toggleMenuDialogIsOpen}
        handleClose={() => setToggleMenuDialogIsOpen(false)}
        title={`${selectedMenu!.active ? 'Deactivate' : 'Activate'} ${
          selectedMenu?.title || 'Menu'
        }`}
        body={`Are you sure you wish to ${
          selectedMenu!.active ? 'deactivate' : 'activate'
        } ${selectedMenu?.title || 'this menu'}?`}
        handleSubmit={async () => {
          await editMenuMutation.mutateAsync({
            ...selectedMenu,
            active: !selectedMenu!.active,
          });
          setToggleMenuDialogIsOpen(false);
        }}
        disabled={editMenuMutation.isLoading}
      />
      {isAdmin && (
        <ImportMenuDialog
          isOpen={importMenuDialogIsOpen}
          handleClose={() => setImportMenuDialogIsOpen(false)}
          merchantId={restaurant.merchantId}
          handleImport={async (menu: Menu) => {
            await duplicateMenuMutation.mutateAsync({
              menu,
              restaurantId: restaurant.id!,
            });
            setImportMenuDialogIsOpen(false);
          }}
        />
      )}
      {isAdmin && (
        <CopyMenuDialog
          isOpen={copyMenuDialogIsOpen}
          handleClose={() => setCopyMenuDialogIsOpen(false)}
          merchantId={restaurant.merchantId}
          restaurant={restaurant}
          selectedMenu={selectedMenu}
          handleImport={async (menu: Menu) => {
            setCopyMenuDialogIsOpen(false);
            await duplicateMenuMutation.mutateAsync({
              menu,
              restaurantId: restaurant.id!,
            });
          }}
        />
      )}
    </MenuTabWrapper>
  );
};

export default MenuTab;
