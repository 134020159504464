import { BaseSyntheticEvent, useEffect, useState } from "react";
import { Typography, Button, TextField, Modal, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { Box } from "@mui/system";
import { v4 as uuidv4 } from 'uuid';

import { MenuSubsection } from "interfaces/MenuInterface";

type MenuSubsectionDialogPropsType = {
  isOpen: boolean;
  subsection?: MenuSubsection;
  handleClose: () => void;
  handleSaveMenuSubsection: (menuSubsection: MenuSubsection) => void;
}

const createNewMenuSubsection = (): MenuSubsection => ({
  id: uuidv4(),
  name: 'New Subsection',
  description: '',
  items: [],
});

const MenuSubsectionDialog = ({ isOpen, subsection, handleClose, handleSaveMenuSubsection }: MenuSubsectionDialogPropsType) => {
  const [menuSubsection, setMenuSubsection] = useState<MenuSubsection>(createNewMenuSubsection());

  useEffect(() => {
    if (subsection) {
      setMenuSubsection(subsection)
    } else {
      setMenuSubsection(createNewMenuSubsection());
    }
  }, [subsection]);

  return (
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="add-menu-subsection-modal-title"
        aria-describedby="add-menu-subsection-modal-description"
        maxWidth='sm'
        fullWidth={true}
      >
        <DialogTitle>
          { subsection ? 'Edit Subsection' : 'Add New Subsection'}
        </DialogTitle>
        <DialogContent>
          <TextField
            id="add-menu-subsection-modal-input"
            variant="outlined"
            placeholder='Subsection Name'
            value={menuSubsection?.name}
            fullWidth
            onChange={(e: BaseSyntheticEvent) => { 
              const subsectionName = e.target.value;
              setMenuSubsection({...menuSubsection, name: subsectionName })
            }}
            sx={{
              mt: 2
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button sx={{mr: 2}} onClick={handleClose}>Cancel</Button>
          <Button 
            variant='contained' 
            disableElevation
            disabled={!(menuSubsection && menuSubsection.name)}
            onClick={() => {
              handleSaveMenuSubsection(menuSubsection!);
              setMenuSubsection(createNewMenuSubsection());
            }}
          >
            {subsection ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
  )
};

export default MenuSubsectionDialog