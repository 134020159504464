import CloseIcon from '@mui/icons-material/Close';
import ListIcon from '@mui/icons-material/List';
import { Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';

import { arrayMoveImmutable } from 'array-move';
import ActionDialog from 'components/ActionDialog';
import ButtonAddNew from 'components/ButtonAddNew';
import CustomButton from 'components/CustomButton';
import DeleteResourceDialog from 'components/DeleteResourceDialog';
import EmptyState from 'components/EmptyState';
import ImportMenuItemsDialog from 'components/ImportMenuItemsDialog';
import MenuItemCard from 'components/MenuItemCard';
import SortableGrid from 'components/SortableGrid';
import CustomTypography from 'components/Typography';
import { Menu, MenuItem, MenuSubsection } from 'interfaces/MenuInterface';
import { useMutationQuery } from 'providers/MutationQueryProvider';
import { useNavigate } from 'react-router-dom';
import AuthService from 'services/auth.service';
import EditMenuSubsectionItemsDialog from './EditMenuSubsectionItemsDialog';
import MenuItemDialog from './MenuItemDialog';
import {
  CategoryHeader,
  CategoryItemsContainer,
  CategoryOptions
} from './index.styles';


interface MenuSubsectionItems {
  selectedSubsection: MenuSubsection;
  handleSetSubsectionMenuItems: (orderedMenuItemIds: string[]) => void; //React.Dispatch<React.SetStateAction<MenuCategory[]>>;
  menu: Menu;
  menuItems: MenuItem[];
  saveMenu: () => void;
  mobileMenuHandler?: () => void;
  isMobileMenuOpen?: boolean;
}

const MenuSubsectionItems = ({
  selectedSubsection,
  handleSetSubsectionMenuItems,
  menuItems,
  menu,
  saveMenu,
  mobileMenuHandler,
  isMobileMenuOpen,
}: MenuSubsectionItems) => {
  const newMenuItem: MenuItem = {
    tags: [],
    menuId: menu.id!,
    restaurantId: menu.restaurantId,
    merchantId: menu.merchantId,
    title: '',
    subtext: '',
    description: '',
    imageUrl: '',
    imageUrls: [],
    price: 0,
    components: [
      // {
      //   name: '',
      //   ingredients: [],
      //   allergies: [],
      //   diets: [],
      //   required: false,
      //   customizations: [],
      // }
    ],
    menuItemCustomizations: [],
    active: true,
    allergies: [],
    additionalAllergies: [],
    diets: [],
    additionalDiets: [],
    externalId: '', //Hack to avoid error when creating new menu item
    externalSource: '', //Hack to avoid error when creating new menu item
  };
  const {
    addMenuItemMutation,
    editMenuItemMutation,
    deleteMenuItemMutation,
    duplicateMenuItemMutation,
    generateMenuItemIngredientsMutation,
  } = useMutationQuery();
  const [editMenuItemsDialogIsOpen, setEditMenuItemsDialogIsOpen] =
    useState<boolean>(false);
  const [menuItemDialogIsOpen, setMenuItemDialogIsOpen] =
    useState<boolean>(false);
  const [deleteMenuItemDialogIsOpen, setDeleteMenuItemDialogIsOpen] =
    useState<boolean>(false);
  const [duplicateMenuItemDialogIsOpen, setDuplicateMenuItemDialogIsOpen] =
    useState<boolean>(false);
  const [generateMenuItemIngredientsDialogIsOpen, setGenerateMenuItemIngredientsDialogIsOpen] =
    useState<boolean>(false);
  const [importMenuItemDialogIsOpen, setImportMenuItemDialogIsOpen] =
    useState<boolean>(false);
  const [subsectionItems, setSubsectionItems] = useState<string[]>(
    selectedSubsection?.items || []
  );
  const [subsectionMenuItems, setSubsectionMenuItems] = useState<MenuItem[]>(
    []
  );
  const [menuItem, setMenuItem] = useState<MenuItem>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [numberOfCopies, setNumberOfCopies] = useState(1);
  const navigate  = useNavigate();

  useEffect(() => {
    (async () => {
      const _isAdmin = await AuthService.isAdmin();
      setIsAdmin(_isAdmin);
    })();
  }, [isAdmin]);

  useEffect(() => {
    if (selectedSubsection) {
      // console.log({selectedCategory: selectedSubsection})
      setSubsectionItems(selectedSubsection.items);
    }
  }, [selectedSubsection]);

  useEffect(() => {
    if (subsectionItems) {
      const categoryMenuItems = subsectionItems.map((menuItemId) =>
        menuItems.find((menuItem) => menuItem.id === menuItemId)
      );
      setSubsectionMenuItems(categoryMenuItems as MenuItem[]);
    }
  }, [subsectionItems, menuItems]);

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    // Sort the selected category's menu items
    const newSubsectionMenuItems = arrayMoveImmutable(
      subsectionMenuItems,
      oldIndex,
      newIndex
    );
    handleSetSubsectionMenuItems(
      newSubsectionMenuItems.map((menuItem) => menuItem.id!)
    );
  };

  const mainContainer = {
    width: '100%',
    background: '#e5e5e5',
  };

  return (
    <Box sx={mainContainer}>
      <CategoryHeader>
        <Box
          sx={{
            display: { lg: 'none', xs: 'flex' },
            paddingRight: 3,
            cursor: 'pointer',
            alignItems: 'center',
            marginBottom: 4,
          }}
          onClick={mobileMenuHandler}
        >
          {isMobileMenuOpen === true ? (
            <CloseIcon fontSize='large' sx={{ marginRight: 1 }} />
          ) : (
            <ListIcon fontSize='large' sx={{ marginRight: 1 }} />
          )}
          Menu
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CustomTypography
            fontWeight='600'
            fontSize='22px'
            lineHeight='24px'
            color='#020202'
          >
            {selectedSubsection?.name || 'Menu Category'}
          </CustomTypography>
          <Button sx={{ ml: 1 }}>
            <ListIcon
              fontSize='large'
              onClick={() => setEditMenuItemsDialogIsOpen(true)}
            />
          </Button>
        </Box>
        <CategoryOptions sx={{ display: 'flex', gap: 1 }}>
          <ButtonAddNew
            label='New Item'
            onClick={() => {
              setIsEdit(false);
              setMenuItem(newMenuItem);
              setMenuItemDialogIsOpen(true);
            }}
          />
          {isAdmin && (
            <CustomButton
              onClick={() => {
                setImportMenuItemDialogIsOpen(true);
              }}
            >
              Import Menu Items
            </CustomButton>
          )}
        </CategoryOptions>
      </CategoryHeader>

      {subsectionMenuItems.length ? (
        <CategoryItemsContainer>
          <SortableGrid
            gridItems={subsectionMenuItems}
            display='grid'
            onSortEnd={onSortEnd}
          >
            {subsectionMenuItems.map(
              (menuItem) =>
                menuItem && (
                  <MenuItemCard
                    key={menuItem.id}
                    item={menuItem}
                   
                    handleRemoveMenuItem={() => {
                      // console.log('Delete menu item with ID: ', menuItem.id);
                      handleSetSubsectionMenuItems(
                        subsectionItems.filter(
                          (itemId) => itemId !== menuItem.id
                        )
                      );
                    }}
                    handleEditMenuItem={() => {
                      // console.log('Edit menu item with ID: ', menuItem.id);
                      setIsEdit(true);
                      setMenuItem(menuItem);
                      setMenuItemDialogIsOpen(true);
                    }}
                    handleDuplicateMenuItem={() => {
                      // console.log('Edit menu item with ID: ', menuItem.id);
                      setIsEdit(true);
                      setMenuItem(menuItem);
                      setDuplicateMenuItemDialogIsOpen(true);
                    }}
                    handleDeleteMenuItem={() => {
                      // console.log('delete menu item with id', menuItem.id);
                      setDeleteMenuItemDialogIsOpen(true);
                      setMenuItem(menuItem);
                    }}
                    handleGenerateRawIngredients={async() => {
                      // console.log('Edit menu item with ID: ', menuItem.id);
                      setIsEdit(true);
                      setMenuItem(menuItem);
                      setGenerateMenuItemIngredientsDialogIsOpen(true);
                    }}
                  />
                )
            )}
          </SortableGrid>
        </CategoryItemsContainer>
      ) : (
        <EmptyState
          entity='items'
          sx={{
            width: '95%',
            marginTop: 30,
            margin: 'auto',
          }}
        />
      )}

      <EditMenuSubsectionItemsDialog
        isOpen={editMenuItemsDialogIsOpen}
        categoryItems={subsectionItems}
        menuItems={menuItems}
        handleCloseModal={() => setEditMenuItemsDialogIsOpen(false)}
        handleSave={(menuItemIds: string[]) => {
          handleSetSubsectionMenuItems(menuItemIds);
          setEditMenuItemsDialogIsOpen(false);
        }}
      />

      {menuItem && (
        <MenuItemDialog
          isOpen={menuItemDialogIsOpen}
          saveDisabled={
            addMenuItemMutation.isLoading || editMenuItemMutation.isLoading
          }
          menuItem={menuItem}
          subsection={selectedSubsection?.name}
          isEdit={isEdit}
          handleCloseDialog={() => {
            setMenuItemDialogIsOpen(false);
          }}
          handleSave={async (menuItem: MenuItem) => {
            const _menuItem = {
              ...menuItem,
              price: parseFloat(menuItem.price.toString()),
            };
            const result = isEdit
              ? await editMenuItemMutation.mutateAsync(_menuItem)
              : await addMenuItemMutation.mutateAsync(_menuItem);
            // console.log({newMenuItem: result})

            if (!isEdit) {
              handleSetSubsectionMenuItems([...subsectionItems, result.id]);
              setMenuItem(result);
              setIsEdit(true);
            }

            await saveMenu();
          }}
          // handleAddNewMenuItemComponent={(newMenuItemComponent: MenuItemComponent) => {
          //   console.log({newMenuItemComponent})
          //   setMenuItem({
          //     ...menuItem,
          //     components: [...menuItem.components, newMenuItemComponent],
          //   });
          //   console.log({menuItem})
          // }}
        />
      )}

      <DeleteResourceDialog
        isOpen={deleteMenuItemDialogIsOpen}
        handleClose={() => setDeleteMenuItemDialogIsOpen(false)}
        title={`Delete ${menuItem?.title || 'Menu Item'}`}
        body={
          'This is an irreversible action. Are you sure you wish to delete this menu item?'
        }
        handleDelete={async () => {
          await deleteMenuItemMutation.mutateAsync(menuItem);
          setMenuItem(newMenuItem);
          handleSetSubsectionMenuItems(
            subsectionItems.filter((ci) => ci !== menuItem?.id)
          );
          setDeleteMenuItemDialogIsOpen(false);
        }}
        disabled={deleteMenuItemMutation.isLoading}
      />

      <ActionDialog
        isOpen={duplicateMenuItemDialogIsOpen}
        handleClose={() => setDuplicateMenuItemDialogIsOpen(false)}
        title={`Copy ${menuItem?.title || 'Menu Item'}`}
        fieldValue={numberOfCopies}
        setFieldValue={setNumberOfCopies}
        fieldText={"Number of Copies:"}
        fieldOptions={[1]}
        body={`Are you sure you wish to duplicate ${
          menuItem?.title
            ? `the menu item ${menuItem?.title}`
            : 'this menu item'
        }?`}
        handleSubmit={async () => {
          const duplicatedMenuItem: MenuItem =
            await duplicateMenuItemMutation.mutateAsync({ menuItem, numberOfCopies });
            //alert(duplicatedMenuItem);
          setMenuItem(duplicatedMenuItem);
          setDuplicateMenuItemDialogIsOpen(false);
          navigate(0);
        }}
        disabled={duplicateMenuItemMutation.isLoading}
      />

      <ActionDialog
        isOpen={generateMenuItemIngredientsDialogIsOpen}
        handleClose={() => setGenerateMenuItemIngredientsDialogIsOpen(false)}
        title={`Generate Ingredients for ${menuItem?.title || 'Menu Item'}`}
        // fieldValue={numberOfCopies}
        // setFieldValue={setNumberOfCopies}
        // fieldText={"Number of Copies:"}
        // fieldOptions={[1]}
        body={`Generate raw ingredients for ${
          menuItem?.title
            ? `the menu item ${menuItem?.title}`
            : 'this menu item'
        }?`}
        handleSubmit={async () => {
          const menuItemWithIngredients: MenuItem = await generateMenuItemIngredientsMutation.mutateAsync(menuItem);
          // console.log({menuItemWithIngredients});
          setMenuItem(menuItemWithIngredients);
          setGenerateMenuItemIngredientsDialogIsOpen(false);
          setMenuItemDialogIsOpen(true);
          // navigate(0);
        }}
        disabled={duplicateMenuItemMutation.isLoading}
      />

      {isAdmin && (
        <ImportMenuItemsDialog
          isOpen={importMenuItemDialogIsOpen}
          handleClose={() => setImportMenuItemDialogIsOpen(false)}
          handleImport={async (menuItems: MenuItem[]) => {
            const importedMenuItems: MenuItem[] = await Promise.all(
              menuItems.map((menuItem) => {
                return duplicateMenuItemMutation.mutateAsync({
                  menuItem,
                  menuId: menu.id,
                });
              })
            );
            setImportMenuItemDialogIsOpen(false);
          }}
        />
      )}
    </Box>
  );
};

export default MenuSubsectionItems;
