import { Stack, Typography } from '@mui/material';
import NoMealsIcon from '@mui/icons-material/NoMeals';
import { useCart, CartContextProvider } from '../context/CartContext';
import { UserOrderItem } from './UserOrderItem';
import { useEffect, useState } from 'react';
import { UserOrderDetails } from './UserOrderDetails';
import { auth } from 'firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

const OrderDetail = (Props:any) => {

  const [showLogin, setShowLogin] = useState(false)
  const [user, loading] = useAuthState(auth);
  const [isLoggedIn, setLoggedIn] = useState(false)


  useEffect(()=>{
    console.log(loading)
  })


  useEffect(()=>{
    if(!loading)
      setLoggedIn(!!user)
  }, [loading])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Stack sx={{ pt: 4 }}>
      <CartContextProvider>
        <UserOrderDetails isEditable={Props.isEditable} />
      </CartContextProvider>    
    </Stack>
  );
};

export default OrderDetail;
