import { useEffect, useState } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
// import { useQuery } from "react-query";
// import { styled } from "@mui/material/styles";
import {
  // Container, 
  Grid, 
  Typography 
} from '@mui/material';

// import SimpleNav from '../SimpleNav';
// import { useApi } from '../../providers/ApiProvider';
import MerchantInformaton from './MerchantInformation';
// import MerchantImages from './MerchantImages';
import MerchantSummary from './MerchantSummary';
import LoadingPage from 'components/LoadingPage';
// import { Restaurant } from 'interfaces/ResturantInterface';

import AuthService from 'services/auth.service';

export type MerchantFormStepPropsType = {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setFormValues: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  currentValues: Record<string, any>;
  currentStep: number;
  isAdmin?: boolean;
}

type MerchantFormPropsType = {
  handleSubmit: (params: any) => Promise<any>
  merchant?: Record<string, any> //TODO: fix this type to actually be restaurant
}

const MerchantForm = ({ merchant = {}, handleSubmit }: MerchantFormPropsType) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [formValues, setFormValues] = useState(merchant);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    (async () => {
      const _isAdmin = await AuthService.isAdmin();
      setIsAdmin(_isAdmin);
      setIsLoading(false);
    })()
  }, [isAdmin]);

  useEffect(() => {
    if(merchant.id) {
      setFormValues(merchant);
    }
  }, [merchant])

  const getStepComponent = () => {
    switch (currentStep) {
      case 1:
        return <MerchantInformaton
          setCurrentStep={setCurrentStep}
          setFormValues={setFormValues}
          currentValues={formValues}
          currentStep={currentStep}
          isAdmin={isAdmin}
        />
      // case 2:
      //   return <MerchantImages 
      //     setCurrentStep={setCurrentStep}
      //     setFormValues={setFormValues}
      //     currentValues={formValues}
      //     currentStep={currentStep}
      //     isAdmin={isAdmin}
      //   />
      case 2:
        return <MerchantSummary
          setCurrentStep={setCurrentStep}
          setFormValues={setFormValues}
          currentValues={formValues}
          currentStep={currentStep}
          handleSubmit={handleSubmit}
          isAdmin={isAdmin}
        />
      default:
        break;
    }
  }

  return (isLoading ? <LoadingPage /> : 
    <Grid container mt={5} className="new-merchant-form">
      <Grid item xs />
      <Grid item xs={7} p={3} style={{backgroundColor: 'white', minWidth:'100%', position:'relative'}} className='_create-content-step-1'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>Step {currentStep} of 2</Typography>
            </Grid>
            <Grid item xs={12}>
              {getStepComponent()}
            </Grid>
          </Grid>
      </Grid>
      <Grid item xs />
    </Grid>
  );
};

export default MerchantForm;
