import { Box, Chip, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DataGrid, GridEventListener, GridToolbar } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useQuery } from "react-query";
import { useNavigate } from 'react-router-dom';

import * as ROUTES from 'Utils/constants/routes';
import ButtonAddNew from 'components/ButtonAddNew';
import LoadingPage from 'components/LoadingPage';
import StyledContainer from 'components/StyledContainer';
import TableActions from 'components/TableActions';
import CustomTypography from 'components/Typography';
import { MerchantStatus } from 'interfaces/MerchantInterface';
import { useApi } from 'providers/ApiProvider';
import AuthService from 'services/auth.service';

import BorderColorIcon from '@mui/icons-material/BorderColor';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import LockResetIcon from '@mui/icons-material/LockReset';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { generateMerchantsQuery } from 'Utils/helpers/queryGenerators';
import ActionDialog from 'components/ActionDialog';

const ClientContent = () => {
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [dialogSettings, setDialogSettings] = useState<Record<string, any>>({});
  const [actionDisabled, setActionDisabled] = useState(false);
  const navigate = useNavigate();
  const api = useApi();

  const { status: _status, error: _error, data: merchants, isLoading: _isLoading, refetch } = useQuery(generateMerchantsQuery(), async () => {
    const isAdmin = await AuthService.isAdmin();

    if (!isAdmin) {
      navigate('/');
    }

    return api.getMerchants();
  });

  useEffect(() => {
    refetch();
  }, [])

  const TabHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
  });

  const isLoading = !(merchants);

  const getChipColor = (status: MerchantStatus) => {
    switch (status) {
      case MerchantStatus.ACTIVE:
        return 'success';
      case MerchantStatus.DISABLED:
        return 'error';
      case MerchantStatus.PAUSED:
        return 'warning';
      case MerchantStatus.PENDING:
      default:
        return 'default';
    }
  }

  const getChipText = (status: MerchantStatus) => {
    switch (status) {
      case MerchantStatus.ACTIVE:
        return 'Active';
      case MerchantStatus.DISABLED:
        return 'Disabled';
      case MerchantStatus.PAUSED:
        return 'Paused';
      case MerchantStatus.PENDING:
        return 'Pending';
      default:
        return 'Unknown';
    }
  }
  const handleEvent: GridEventListener<'rowClick'> = (
    params
  ) => {
    navigate(`/merchants/${params.row.id}/restaurants`);
  };
  return isLoading ? (
    <LoadingPage />
  ) : (
    <>
      <StyledContainer>
        <TabHeader>
          <CustomTypography
            fontWeight='bold'
            fontSize='24px'
            lineHeight='26px'
            marginBottom='37px'
          >
            Clients
          </CustomTypography>
          <ButtonAddNew
            label='Add New'
            styles={{ marginTop: '-8px', marginRight: '3px' }}
            onClick={() => {
              // setCustomIngredient(newCustomIngredient);
              // setCustomIngredientDialogIsOpen(true);
              navigate(ROUTES.CREATE_CLIENT);
            }}
          />
        </TabHeader>
        <Box sx={{ backgroundColor: 'white', height: 750 }}>
          <DataGrid
            showCellRightBorder={true}
            rows={merchants || []}
            onRowClick={handleEvent}
            columns={[
              {
                field: 'businessName',
                headerName: 'Name',
                minWidth: 200,
                flex: 1,
                // renderCell: (params) => {
                //   return (
                //     <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                //       <Link
                //         to={`/merchants/${params.row.id}/restaurants`}
                //         className='noWrap'
                //       >
                //         {params.value}
                //       </Link>
                //     </Box>
                //   );
                // },
              },
              {
                field: 'status',
                headerName: 'Status',
                minWidth: 80,
                flex: 1,
                renderCell: (params) => {
                  return (
                    <Chip
                      label={getChipText(params.value as MerchantStatus)}
                      color={getChipColor(params.value)}
                    />
                  );
                },
              },
              {
                field: 'verified',
                headerName: 'Verified',
                minWidth: 60,
                flex: 1,
                renderCell: (params) => {
                  return (
                    <Chip
                      label={
                        params.row.emailVerified ? 'Yes' : 'No'
                      }
                      color={
                        params.row.emailVerified
                          ? 'success'
                          : 'error'
                      }
                    />
                  );
                },
              },
              { field: 'email', headerName: 'Email', minWidth: 250, flex: 1 },
              {
                field: 'phoneNumber',
                headerName: 'Phone',
                minWidth: 140,
                flex: 1,
              },
              {
                field: 'restaurantCount',
                headerName: '# of Restaurants',
                minWidth: 80,
                flex: 1,
              },
              {
                field: 'actions',
                headerName: 'Actions',
                minWidth: 200,
                flex: 1,
                renderCell: (params) => {
                  return (
                    <TableActions>
                      <Tooltip title='Edit'>
                        <IconButton
                          sx={{ color: '#0C847E' }}
                          onClick={() => {
                            navigate(
                              `${ROUTES.MERCHANTS}/${params.row.id}/edit`
                            );
                          }}
                        >
                          {<BorderColorIcon />}
                        </IconButton>
                      </Tooltip>

                      <Tooltip title='Verify Email'>
                        <IconButton
                          sx={{ color: '#0C847E' }}
                          onClick={() => {
                            setDialogSettings(() => ({
                              title: 'Verify Email',
                              body: `A verification email will be sent to ${params.row.merchantRecord.email}`,
                              handleSubmit: async () => {
                                setActionDisabled(true);
                                await api.sendMerchantVerificationEmail(
                                  params.row.id
                                );
                                setActionDisabled(false);
                                setDialogIsOpen(false);
                              },
                            }));
                            setDialogIsOpen(true);
                          }}
                        >
                          {<HowToRegIcon />}
                        </IconButton>
                      </Tooltip>

                      {/* <Tooltip title="Sign In">
                    <IconButton sx={ {color: '#0C847E'} } onClick={() => {
                      setDialogSettings({
                        title: 'Sign In',
                        body: `An email with a sign-in link will be send to ${params.row.merchantRecord.email}`,
                        handleSubmit: async () => {
                          setActionDisabled(true);
                          await api.sendMerchantSignInEmail(params.row.id);
                          setActionDisabled(false);
                          setDialogIsOpen(false);
                        },
                      });
                      setDialogIsOpen(true);
                    }}>
                      {<LockOpenIcon />}
                    </IconButton>
                  </Tooltip> */}

                      <Tooltip title='Sign Up'>
                        <IconButton
                          sx={{ color: '#0C847E' }}
                          onClick={() => {
                            setDialogSettings(() => ({
                              title: 'Sign In',
                              body: `An email with a sign-up link will be send to ${params.row.merchantRecord.email}`,
                              handleSubmit: async () => {
                                setActionDisabled(true);
                                await api.sendMerchantSignUpEmail(
                                  params.row.id
                                );
                                setActionDisabled(false);
                                setDialogIsOpen(false);
                              },
                            }));
                            setDialogIsOpen(true);
                          }}
                        >
                          {<PersonAddIcon />}
                        </IconButton>
                      </Tooltip>

                      <Tooltip title='Reset Password'>
                        <IconButton
                          sx={{ color: '#0C847E' }}
                          onClick={() => {
                            setDialogSettings(() => ({
                              title: 'Reset Password',
                              body: `An email with a password reset link will be send to ${params.row.merchantRecord.email}`,
                              handleSubmit: async () => {
                                setActionDisabled(true);
                                await api.sendMerchantResetPasswordEmail(
                                  params.row.id
                                );
                                setActionDisabled(false);
                                setDialogIsOpen(false);
                              },
                            }));
                            setDialogIsOpen(true);
                          }}
                        >
                          {<LockResetIcon />}
                        </IconButton>
                      </Tooltip>
                    </TableActions>
                  );
                },
              },
            ]}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
        </Box>
      </StyledContainer>
      <ActionDialog
        isOpen={dialogIsOpen}
        handleClose={() => {
          setDialogIsOpen(false);
          setActionDisabled(false);
        }}
        title={dialogSettings.title}
        body={dialogSettings.body}
        handleSubmit={dialogSettings.handleSubmit}
        disabled={actionDisabled}
      />
    </>
  );
};

export default ClientContent;
