import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import StyledContainer from 'components/StyledContainer';

import SimpleNav from '../../../components/SimpleNav';
import RestaurantForm from '../../../components/RestaurantForm';
import { useMutationQuery } from 'providers/MutationQueryProvider';

import AuthService from 'services/auth.service';

const NewRestaurant = () => {
  // API
  const navigate = useNavigate();
  const { addRestaurantMutation } = useMutationQuery();
  const [isAdmin, setIsAdmin] = useState(false);
  const { merchantId } = useParams();

  useEffect(() => {
    (async () => {
      const _isAdmin = await AuthService.isAdmin();
      setIsAdmin(_isAdmin);
    })()
  }, [isAdmin]);
  
  const handleSubmit = (params: any): Promise<any> => {
    return addRestaurantMutation.mutateAsync(params)
  }

  return ( 
    <StyledContainer>
      <SimpleNav text='Create New Restaurant' onClick={() => navigate(merchantId ? '/merchants/'.concat(merchantId, '/restaurants') : '/')} />
      
      <RestaurantForm 
        handleSubmit={handleSubmit} 
        isAdmin={isAdmin} 
      />
    </StyledContainer>
  );
};

export default NewRestaurant;
