import { IconButton, styled } from '@mui/material';
import { MouseEventHandler, ReactElement } from 'react';

interface IStyledIconButton {
  component?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children: ReactElement;
}

export const StyledIconButton = styled((props: IStyledIconButton) => (
  <IconButton {...props}>{props.children}</IconButton>
))({
  fontFamily: 'Graphik',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '15px',
  lineHeight: '20px',
  letterSpacing: '-0.015em',
  color: '#0C847E',
  padding: 0,
  justifyContent: 'space-between',
});
