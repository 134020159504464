import { FormControl, Select, MenuItem, SelectProps } from '@mui/material';
import './index.css';

type propType = {
  height?: number | string;
  options: {
    key?: string;
    value: string | boolean;
    label: string;
  }[];
} & SelectProps

const GoldiDropDownMenu = (props: propType) => {
  return (
    <FormControl fullWidth>
      <Select
        {...props}
        sx={{
          height: props.height || 44
        }}
      >
        {props.options.map((option, index) => (
          <MenuItem key={option.key || index} value={option.value as any}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default GoldiDropDownMenu;
