import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Typography
} from '@mui/material';
import { FastField, FieldProps, Form, Formik, FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import * as yup from "yup";

import { GoldiTextField } from 'components/InputField';

import { Order } from 'interfaces/OrderInterface';

import FormikAutoSave from 'components/FormikAutoSave';

import debounce from 'lodash/debounce';


type OrderDialogPropsType = {
    isOpen: boolean;
    handleCloseDialog: () => void;
    handleSave: (order: Order) => void;
    order: Order;
    saveDisabled?: boolean;
    isEdit?: boolean;
}

const DEBOUNCE_DELAY_MS = 500;

const OrderDialog = ({
    isOpen,
    saveDisabled = false,
    order,
    handleCloseDialog,
    handleSave,
    isEdit = false,
}: OrderDialogPropsType) => {
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(true);
   // const queryClient = useQueryClient();
    //const existingOrder: Order = queryClient.getQueryData(generateOrderQuery()) || null;
   

    useEffect(() => {
        setModalIsOpen(isOpen);
    }, [isOpen]);

    console.log({ order })
    // Slightly hacky, we need to use this state to stop the auto-save from firing when we close the dialog
    useEffect(() => {
        if (!modalIsOpen) {
            handleCloseDialog();
        }
    }, [modalIsOpen]); //Do not add handleCloseDialog to the dependency array, it will stop modal from re-opening

    // debounce validation code from https://stackoverflow.com/questions/58576825/yup-formik-async-validation-with-debounce=
    const validationFunction = async (value: string|undefined, resolve: any) => {
        // Don't need to perform this validation if we're editing existing ingredient
        if (isEdit) resolve(true);

        try {
            const existingOrder = order;
            if (existingOrder) {
                resolve(false);
            } else {
                resolve(true);
            }
        } catch (error) {
            resolve(false);
        }
    };

    const validationDebounced = debounce(validationFunction, DEBOUNCE_DELAY_MS);

    const orderValidationSchema = yup.object({
        commments: yup
            .string()
            .required(),
          
        title: yup
            .string()
            .required(),
    });

    return (
        <Dialog
            // fullScreen
            open={isOpen}
            onClose={(_e, reason) => {
                // disable closing dialog when clicking outside of dialog
                if (reason && reason == "backdropClick") return;
                setModalIsOpen(false);
            }}
            aria-labelledby="new-menu-item-dialog-title"
            aria-describedby="new-menu-item-dialog-description"
            maxWidth='sm'
            fullWidth={true}
            disableEscapeKeyDown
        >
            <Formik
                enableReinitialize={true}
                initialValues={order}
                onSubmit={(order: Order) => { 
                    handleSave(order);
                }}
                validationSchema={orderValidationSchema}
                validateOnMount={true}
                initialErrors={{ commments: 'Required' }}
            >
                {(formik: FormikProps<Order>) => {
                    const { values, handleBlur, handleChange, isValid, handleSubmit } = formik;
                    return (
                    <Form>
                        <DialogTitle>
                            <Box display={'flex'} alignItems={'center'} width={'100%'}>
                                <Typography width={'100%'} fontSize={'1.25rem'}>
                                {  ' Edit Order' }
                                </Typography>
                                <Box display={'flex'} justifyContent={'flex-end'} >
                                    <IconButton
                                        onClick={handleCloseDialog}
                                        disableRipple
                                        sx={{cursor:'pointer', float:'right', width: '20px'}}
                                    >
                                        <CloseIcon />
                                    </IconButton>    
                                </Box>       
                            </Box>
                        </DialogTitle>
                        <Divider />
                        <DialogContent>
                            <Box>
                                <Grid container spacing={1}>
                                    {/* Item ID */}
                                    
                                    
                                    
                                    <Grid item xs={12}>
                                        {/* Item Description */}
                                        <Box width={'100%'}>
                                            <Typography>Comments</Typography>
                                            <FastField
                                                name='commments'
                                                type='text'
                                                render={({ field, form: { errors } }: FieldProps) => (
                                                    <GoldiTextField 
                                                        {...field}
                                                        id={field.name}
                                                        value=''
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={!!errors[field.name]}
                                                    />
                                                )}
                                            />
                                        </Box>  
                                    </Grid>
                                    
                                </Grid>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <FormikAutoSave 
                                canAutoSave={isValid && modalIsOpen}
                                sx={{ 
                                    mr: 2,
                                    fontSize: '0.75rem',
                                    color: 'text.secondary',
                                }} 
                            />
                            <Button sx={{mr: 2}} onClick={() => {
                                setModalIsOpen(false);
                            }}>Cancel</Button>
                            <Button 
                                variant='contained' 
                                disableElevation 
                                onClick={() => {
                                    handleSubmit();
                                    setModalIsOpen(false);
                                }}
                                disabled={!isValid || saveDisabled}
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </Form>
                )}} 
            </Formik>
        </Dialog>
    )
}

export default OrderDialog;
