import { Menu, MenuItem, styled } from '@mui/material';

export const StyledMenu = styled(Menu)({
  '& .MuiList-root': {
    padding: '6px',
    background: '#ffffff',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
    borderRadius: '8px',
  },
});
export const StyledMenuItem = styled(MenuItem)({
  fontFamily: 'Graphik',
  fontSize: '16px',
  lineHeight: '18px',
  letterSpacing: '-0.015em',
  color: '#000000',
  padding: '10px 6px',
});
