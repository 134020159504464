import { Box, SxProps, Theme } from '@mui/material';
import { ReactComponent as CameraVec } from 'images/icons/cameraVec.svg';

type DefaultImageType = {
    sx?: SxProps<Theme> 
}

const DefaultImage = ({sx}: DefaultImageType) => {
    return (
        <Box 
            sx={{
                backgroundColor: 'lightgray',
                display: "flex",
                alignItems: 'center', 
                justifyContent: 'center',
                ...sx
            }}>
            <CameraVec />
        </Box>
    )
}

export default DefaultImage