import { ChangeEvent } from 'react';
import CustomSwitch from './index.styles';

interface IToggleSwitch {
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  sx: Record<string, unknown>;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const ToggleSwitch = ({ checked, onChange, sx, onClick }: IToggleSwitch) => {
  return (
    <CustomSwitch
      sx={sx}
      checked={checked}
      onChange={onChange}
      onClick={onClick}
    />
  );
};

export default ToggleSwitch;
