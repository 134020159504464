import { Box, Divider, Grid, ImageListItem, Typography } from '@mui/material';
import CustomEdit from 'components/CustomEditBox';
import { Restaurant } from 'interfaces/ResturantInterface';
import { WEEKDAYS } from 'Utils/constants/schedule';
type RestaurantSummaryPropsType = {
    restaurant: Restaurant;
    handleClickEdit?: () => void;
    canEdit?: boolean;
}

const RestaurantSummary = ({ restaurant, handleClickEdit, canEdit = false }: RestaurantSummaryPropsType) => {
    return (<>
        <Grid item container>
          <Grid item xs={5}>
          <Typography> Restaurant </Typography>
            <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
              <Typography fontWeight={'bold'}>Name: {restaurant.name} </Typography>
              {canEdit && <CustomEdit handleClick={handleClickEdit || (() => ({}))} sx={{ ml: 2}} />}
            </Box>
            {restaurant.tags && <Typography>Tags: {restaurant.tags.join(', ')} </Typography>}
            <Typography>Address: {restaurant.address} </Typography>
            <Typography>Phone: {restaurant.phoneNumber} </Typography>
            <Typography>Status: {restaurant.status} </Typography>
            <Typography>Delivery: {restaurant.isDeliverySupported ? "Enabled" : "Disabled"}</Typography>
            <Typography>Dine In: {restaurant.isDineInSupported ? "Enabled" : "Disabled"}</Typography>
            <Typography>Pick Up: {restaurant.isPickUpSupported ? "Enabled" : "Disabled"}</Typography>
            <Typography>Mark Up: {restaurant.priceMarkUpPercentage}</Typography>
            <Typography>Delivery: {restaurant.isDeliverySupported ? "Enabled" : "Disabled"}</Typography>
            <Typography>IsChowly: {restaurant.isChowly ? "Enabled" : "Disabled"}</Typography>
            <Typography>Urlslug: {restaurant.urlSlug} </Typography>
            <Typography>ExternalId: {restaurant.externalId} </Typography>
            <Typography><a href={restaurant.homePage} target='_new'>Home Page</a> </Typography>
            <Typography><a href={restaurant.orderPage} target='_new'>Order Page</a> </Typography>
            <br />
            <Typography> Contact </Typography>
            <Typography fontWeight={'bold'}>Name: {restaurant.contactName} </Typography>
            <Typography>Email: {restaurant.email} </Typography>
            <Typography>Phone: {restaurant.contactPhoneNumber} </Typography>
            <br />
           
           
          </Grid>
          
          <Grid item xs={7}>
            <Typography fontWeight={'bold'}>Hours</Typography>
            {Object.values(WEEKDAYS).map((key) => {
              const day = restaurant.operatingHours[key];
              return (
                <Grid key={key} container direction={'row'} spacing={2}>
                  <Grid item xs={5}>
                    <Typography textTransform={'capitalize'}>{key}:</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    {day.isOpen ? 
                    <>
                      <Typography>{day.hours[0].startAt} - {day.hours[0].endAt}</Typography>
                    </> : <Typography>Closed</Typography> }
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
        <Divider />

        {(restaurant.image || restaurant.imageUrl) && 
          <Grid item container mt={2}>
            <Grid item xs={7}>
              <ImageListItem>
                <img
                  alt={restaurant.name}
                  src={(restaurant.image && URL.createObjectURL(restaurant.image)) || restaurant.imageUrl || ''}
                />
              </ImageListItem>
              
            </Grid>
          </Grid>}
    </>)
}

export default RestaurantSummary