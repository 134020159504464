import { ReactComponent as PencilSvg } from '../../../../images/icons/pencilVec.svg';
import { useNavigate } from 'react-router-dom';
import './index.css';

const AccountSettings = () => {
  const navigate = useNavigate();

  return (
    <div className='account-settings-root'>
      <h1>Account Settings</h1>
      <div className='account-paper'>
        {/* Account Details */}
        <div className='left-col'>
          <div className='header'>
            <div>
              <h4>Accounts Details</h4>
            </div>
            <div
              // onClick={() => navigate()}
              className='logo-sec'
            >
              <div>
                <PencilSvg className='logo' />
              </div>
              <div className='edit-text'>Edit</div>
            </div>
          </div>
          <div className='hr-line'></div>

          <div className='contact'>
            <span className='heading'>Business Name</span>
            <span className='desc'>ABCD Restaurant Group</span>
          </div>

          <div className='contact'>
            <span className='heading'>Address</span>
            <span className='desc'>
              44 Westwood Blvd., Culver City, CA 90323
            </span>
            <span className='desc'>555-555-5557</span>
          </div>

          <div className='contact'>
            <span className='heading'>Contact</span>
            <span className='desc'>Dana Smith</span>
            <span className='desc'>dana@natenals.com</span>
            <span className='desc'>555-555-5557</span>
          </div>

          <div className='contact'>
            <span className='heading'>Licience #</span>
            <span className='desc'>TBD</span>
          </div>
        </div>

        {/* Account Credentials */}
        <div className='right-col'>
          <div className='header'>
            <h4>Accounts Credentials</h4>
          </div>
          <div className='hr-line'></div>

          <div className='creds-sec'>
            <span className='head'>Email</span>
            <div className='head-row'>
              <span>dana@email.com</span>
              <div className='edit-icon-sec'>
                <div className='edit-icon'>
                  {' '}
                  <PencilSvg className='icon' />{' '}
                </div>
                <div className='edit-text'>Edit</div>
              </div>
            </div>
          </div>

          <div className='creds-sec'>
            <span className='head'>Pasword</span>
            <div className='head-row'>
              <span>**********</span>
              <div
                onClick={() => navigate(`/change_pasword/`)}
                className='edit-icon-sec'
              >
                <div className='edit-icon'>
                  {' '}
                  <PencilSvg className='icon' />{' '}
                </div>
                <div className='edit-text'>Edit</div>
              </div>
            </div>
          </div>

          <div className='creds-sec'>
            <span className='head'>Contact</span>
            <span className='desc'>User name</span>
            <span className='desc'>Dana smith</span>
          </div>

          <div className='creds-sec'>
            <span className='head'>Contact</span>
            <span className='desc'>User Type</span>
            <span className='desc'>Admin</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
