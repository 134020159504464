import { Box, SxProps, Typography } from '@mui/material';
import { ReactComponent as PencilSVG } from 'images/icons/pencilVec.svg';

type EditPropsType = {
    handleClick: () => void;
    sx?: SxProps
};

const Edit = ({ handleClick, sx = {} }: EditPropsType) => {
    return (
        <Box ml={1} mt='2px' sx={{cursor: 'pointer', ...sx}} onClick={handleClick}>
            <PencilSVG />
            <Typography component={'span'} ml={'4px'}>Edit</Typography>
        </Box>
    )
}

export default Edit;