import { ReactComponent as CameraVec } from '../../images/icons/cameraVec.svg';
import { FormikProps } from 'formik';
import { Box, ImageListItem, Paper, SxProps, Theme } from '@mui/material';

interface propType {
  id: string;
  formik: FormikProps<any>;
  imageUrl?: string;
  sx?: SxProps<Theme> 
}

//TODO: refactor this to be more generic
const GoldiInputImage = ({id, imageUrl, formik: {setFieldValue, values}, sx = {}}: propType) => {
  const onChange = (e: any) => {
    setFieldValue(id, e?.target?.files[0]);
  };

  return (
    <Paper elevation={0} style={{ backgroundColor: '#E0E0E4' }}>
      <Box sx={{display:'flex', minHeight: 175, ...sx}}>
        <Box m='auto'>
          <input
            type='file'
            accept='image/*'
            name='image'
            id='actual-btn'
            hidden
            onChange={onChange}
          />
          <label className='goldi-input-file-label' htmlFor='actual-btn'>
            {values.image ? (
              <ImageListItem>
                <img
                  className='selected-image'
                  alt=''
                  src={URL.createObjectURL(values.image)}
                />
              </ImageListItem>
            ) :  ( 
              imageUrl ? 
              <ImageListItem>
                <img
                  className='selected-image'
                  alt=''
                  src={imageUrl}
                />
              </ImageListItem> :
              <CameraVec className='camera-icon'/> 
            )}
          </label>  
        </Box>
        
      </Box>
    </Paper>
  );
};

export default GoldiInputImage;
