import { Box } from '@mui/material';
import Alert from '@mui/material/Alert';

const style = {
  bottom: 0,
  zIndex: 99999999,
  textAlign: 'center',
  display: 'block',
  width: '100%',
  margin: '-2px',
  mt: 1
}

interface Props {
  error: string
}

function ErrorAlert({error} : Props) {
  return (
    <Box sx={style}>
      <Alert severity="error">{error}</Alert>
    </Box>
  );
}

export default ErrorAlert;
