import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Grid, Typography } from '@mui/material';

import ToggleSwitch from 'components/ToggleSwitch';
import { ReactComponent as MoreOptions } from 'images/restaurants/details/menu-tab/more-options.svg';
import { useState } from 'react';
import CustomTypography from 'components/Typography';
import { MenuCardContainer, CardHeader } from './index.styles';
import OptionsMenu from 'components/OptionsMenu';
import { WEEKDAYS } from 'Utils/constants/schedule';
import { Menu } from 'interfaces/MenuInterface';

type MenuCardPropTypes = {
  menu: Menu;
  deleteMenu: (menu: Menu) => void;
  copyMenu: (menu: Menu) => void;
  editMenuCard: (menu: Menu) => void;
  autoAssignMenuIngredients: (menu: Menu) => void;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  handleToggleActive: (menu: Menu) => void;
}

const MenuCard = ({
  menu,
  deleteMenu,
  copyMenu,
  editMenuCard,
  autoAssignMenuIngredients,
  onClick,
  handleToggleActive,
}: MenuCardPropTypes) => {
  const menuCardOptions = [
    {
      label: 'Edit Menu card',
      onClick: () => {
        editMenuCard(menu)
      },
    },
    {
      label: 'Auto-assign Ingredients',
      onClick: () => {
        autoAssignMenuIngredients(menu)
      },
    },
    {
      label: 'Copy Menu',
      onClick: () => {
        copyMenu(menu)
      },
    },
    {
      label: 'Delete Menu',
      onClick: () => {
        deleteMenu(menu)
      },
    },
  ];

  return (
    <MenuCardContainer onClick={onClick}>
      <CardHeader>
        <CustomTypography
          fontSize='20px'
          lineHeight='20px'
          fontWeight='600'
          sx={{ overflowWrap: 'anywhere', hyphens: 'auto', marginRight: '8px' }}
        >
          {menu.title}
        </CustomTypography>

        <ToggleSwitch
          sx={{
            marginLeft: 'auto',
            marginRight: '8px',
          }}
          checked={menu.active}
          onChange={(e) => {
            e.stopPropagation();
            handleToggleActive(menu);
          }}
          onClick={(e) => e.stopPropagation()}
        />
        <OptionsMenu
          id={`menuCard-${name}-options`}
          options={menuCardOptions}
          buttonIcon={<MoreVertIcon />}
          onClick={(e) => e.stopPropagation()}
        />
      </CardHeader>

      {menu.serviceAvailability.map((availability) => {
        // const day = menu.schedule[key];
        return (
          <Grid key={availability.day_of_week} display={'flex'} justifyContent={'space-between'} marginTop={1} direction={'row'} spacing={3}>
            <Grid item xs={6}>
              <Typography textTransform={'capitalize'}>{availability.day_of_week}:</Typography>
            </Grid>
            <Grid item xs={6}>
              {availability.enabled ? 
              <>
                <Typography>{availability.time_periods[0].start_time} - {availability.time_periods[0].end_time}</Typography>
              </> : <Typography> Unavailable </Typography> }
            </Grid>
          </Grid>
        )
      })}
    </MenuCardContainer>
  );
};

export default MenuCard;
