import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router';
import { useQuery } from "react-query";
import { Box, Button, Modal, styled, TextField, Typography } from '@mui/material';
import WestIcon from '@mui/icons-material/West';
import ErrorAlert from 'components/ErrorAlert';
import { useApi } from 'providers/ApiProvider';
import { useMutationQuery } from 'providers/MutationQueryProvider';
import MenuCategoriesSidebar from 'components/MenuCategoriesSidebar';
import CustomButton from 'components/CustomButton';
import CustomTypography from 'components/Typography';
import MenuSubsectionItems from 'components/MenuSubsectionItems';

import LoadingPage from 'components/LoadingPage';
import MenuSubsectionDialog from './MenuSubsectionDialog';
import { MenuSubsection, MenuItem } from 'interfaces/MenuInterface';
import DeleteResourceDialog from 'components/DeleteResourceDialog';
import { genreateMenuIdQuery, generateMenuItemsQuery } from 'Utils/helpers/queryGenerators';

const MenuDetailsPage = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const MenuDetailsHeader = styled(Box)({
  height: '50px',
  background: '#FFFFFF',
  boxShadow: 'inset 0px -1px 0px #E9E9E9',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '12px 24px',
});

const MenuDetailsContent = styled(Box)({
  position: 'absolute',
  minHeight: 'calc(100% - 74px);',
  marginTop: '74px',
  width: '100%',
  display: 'flex',
});


const MenuDetails = () => {
  const { editMenuMutation } = useMutationQuery();
  const [selectedSubsectionIdx, setSelectedSubsectionIdx] = useState<any>();
  const [selectedSubsection, setSelectedSubsection] = useState<MenuSubsection>();
  const [subsections, setSubsections] = useState<MenuSubsection[]>([]);
  const [menuSubsectionModalIsOpen, setMenuSubsectionDialogIsOpen] = useState(false);
  const [deleteMenuSubsectionModalIsOpen, setDeleteMenuSubsectionDialogIsOpen] = useState(false);
  const [isMoblieSidebar, setIsMoblieSidebar] = useState(false);
  const navigate = useNavigate();
  const [restaurantId, setRestaurantId] = useState("");
  const [error, setError] = useState('');
  const [disableSave, setDisableSave] = useState(false);
  const api = useApi();
  const urlParams = useParams();
  const { status: _menuStatus, error: _menuError, data: menu, isLoading: _isLoadingMenu, refetch } = useQuery(genreateMenuIdQuery(urlParams.menuId), async () => {
    return api.getMenuById(urlParams.menuId);
  });
  const { status: _menuItemsStatus, error: _menuItemsError, data: menuItems, isLoading: _isLoadingMenuItems } = useQuery(generateMenuItemsQuery(urlParams.menuId), async () => {
    return api.getMenuItemsByMenuId(urlParams.menuId);
  });

  const isLoading = !(menu && menuItems)
// console.log({menuItems});
  useEffect(() => {
    if (menu) {
      // setSelectedCategory(menu.categories[0])
      setRestaurantId(menu.restaurantId);
      if(window.location.hash) {
        setSelectedSubsectionIdx(window.location.hash.replace("#", ""));
      } else {
        setSelectedSubsectionIdx(0);
      }
      //setSelectedSubsectionIdx(0);
      setSubsections(menu.subsections)
    }
  }, [menu]);

  useEffect(() => {
    if(window.location.hash) {
      setSelectedSubsectionIdx(window.location.hash.replace("#", ""));
    }
  }, [window.location.hash])

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setTimeout(()=> {
      setDisableSave(false);
    }, 2000);
  }, [disableSave]);

  const handleSaveMenu = async () => {
    setError('');
    setDisableSave(false);
    try {
      await editMenuMutation.mutateAsync({...menu, subsections});
      setDisableSave(true);
    } catch (e:any) {
      setError(e.error)
    }
  };

  const handleSaveMenuSubsection = async (subsection: MenuSubsection) => {
    const _subsectionIdx = subsections.findIndex((s) => s.id === subsection.id);
    
    if (_subsectionIdx >= 0) {
      // If the subsection already exists, update it
      const _subsections = [...subsections];
      _subsections[_subsectionIdx] = subsection;
      setSubsections(_subsections);
    } else {
      // If the subsection doesn't exist, add it
      setSubsections([...subsections, subsection]);
    }
    
    //TODO: decide if we need to save menu here
    // await handleSaveMenu();
    setSelectedSubsection(undefined);
    setMenuSubsectionDialogIsOpen(false);
  }

  const handleDeleteMenuSubsection = () => {
    const subsectionIdx = subsections.findIndex((s) => s.id === selectedSubsection!.id);
    if (subsectionIdx >= 0) {
      // If the subsection already exists, remove it
      setSubsections([...subsections.slice(0, subsectionIdx), ...subsections.slice(subsectionIdx + 1)]);
    }
  }

 const sidebar = {
   display: {
     lg: 'block',
     xs: 'none',
   },
   };

   const sidebarMobile = {
   display: {
     sm: 'block',
   },
   position:'absolute',
   top:185,
   left:0,
   zIndex:10
  }
 

 const mobileSidebarHandler = () => {
  setIsMoblieSidebar(!isMoblieSidebar);
 }

 const setSelectedSubsectionIdxHandler = (val:any) => {
  window.location.hash = val.toString();
 }

  return isLoading ? (
    <LoadingPage />
  ) : (
    <>
      <MenuDetailsPage>
        <MenuDetailsHeader>
          <Button onClick={() => restaurantId ? navigate("/restaurants/".concat(restaurantId)) : navigate(-1)}>
            <WestIcon />
          </Button>
          <CustomTypography
            fontWeight='600'
            fontSize='22px'
            lineHeight='24px'
            color='#000000'
          >
            {menu.name}
          </CustomTypography>
          {error && <ErrorAlert error={error} />}
          <CustomButton
            onClick={() => navigate(-1)}
            backgroundColor='#ffffff'
            border='1px solid #d8d8d8'
            margin='0 16px 0 auto'
            width='102px'
          >
            Cancel
          </CustomButton>
          <CustomButton
            onClick={handleSaveMenu}
            disabled={editMenuMutation.isLoading || disableSave}
          >
            {disableSave ? "Saved!" : "Save Menu"}
          </CustomButton>
        </MenuDetailsHeader>

        <MenuDetailsContent>
          <Box sx={isMoblieSidebar === false ? sidebar : sidebarMobile}>
            <MenuCategoriesSidebar
              subsections={subsections}
              setSubsections={(orderedCategories) => {
                setSubsections(orderedCategories);
              }}
              selectedSubsection={subsections[selectedSubsectionIdx]}
              setSelectedSubsectionIdx={(val) => setSelectedSubsectionIdxHandler(val)}
              handleAddCategory={() => {
                setSelectedSubsection(undefined);
                setMenuSubsectionDialogIsOpen(true);
              }}
              handleEditCategory={(subsection: MenuSubsection) => {
                // console.log({subsection});
                setSelectedSubsection(subsection);
                setMenuSubsectionDialogIsOpen(true);
              }}
              handleDeleteCategory={(subsection: MenuSubsection) => {
                // console.log({subsection});
                setSelectedSubsection(subsection);
                setDeleteMenuSubsectionDialogIsOpen(true);
              }}
            />
          </Box>

          <MenuSubsectionItems
            mobileMenuHandler={mobileSidebarHandler}
            isMobileMenuOpen={isMoblieSidebar}
            selectedSubsection={subsections[selectedSubsectionIdx]}
            handleSetSubsectionMenuItems={(menuItemIds: string[]) => {
              subsections[selectedSubsectionIdx] = {
                ...subsections[selectedSubsectionIdx],
                items: menuItemIds,
              };
              setSubsections([...subsections]);
            }}
            menuItems={menuItems}
            menu={menu}
            saveMenu={handleSaveMenu}
          />
        </MenuDetailsContent>
      </MenuDetailsPage>
      <MenuSubsectionDialog
        isOpen={menuSubsectionModalIsOpen}
        subsection={selectedSubsection}
        handleSaveMenuSubsection={handleSaveMenuSubsection}
        handleClose={() => setMenuSubsectionDialogIsOpen(false)}
      />
      <DeleteResourceDialog
        isOpen={deleteMenuSubsectionModalIsOpen}
        title={`Remove ${selectedSubsection?.name || 'Menu Section'}?`}
        body={'Are you sure you wish to remove this menu section?'}
        handleClose={() => setDeleteMenuSubsectionDialogIsOpen(false)}
        handleDelete={() => {
          handleDeleteMenuSubsection();
          setDeleteMenuSubsectionDialogIsOpen(false);
        }}
      />
    </>
  );
};

export default MenuDetails;
