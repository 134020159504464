import { FC } from 'react';
import { Field } from 'formik';
import Typography from '@mui/material/Typography';
import classes from './index.module.css';

interface Props {
  label: string;
  error: any;
  touched: any;
  name: string;
  type?: string;
  style?: Record<string, unknown>;
  hideLabel?: boolean;
  showLabel?: boolean;
  value?: any;
}

const Input: FC<Props> = ({
  label,
  error,
  touched,
  name,
  type,
  style,
  hideLabel,
  children,
  showLabel,
  value,
}) => (
  <div style={{ position: 'relative' }}>
    <label>
      {!hideLabel && <p className={classes.label}>{label}</p>}
      {!showLabel ? (
        <>
          <Field
            name={name}
            className={classes.input}
            type={type || 'text'}
            style={style && { ...style }}
          />
          {children}

          {error && touched ? (
            <div className={classes.error}>{error}</div>
          ) : null}
        </>
      ) : (
        <Typography>
          {type === 'password' ? value.replace(/./g, '*') : value}
        </Typography>
      )}
    </label>
  </div>
);

export default Input;
