import { useEffect, useState } from 'react';
import SimpleNav from '../../../components/SimpleNav';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Input from '../../../components/input';
import { BiHide, BiShowAlt } from 'react-icons/bi';
import AuthService from 'services/auth.service';

const useStyles = makeStyles(() => ({
  loginContainer: {
    width: 400,
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 4,
    paddingLeft: 24,
    paddingRight: 24,
  },
  title: {
    marginTop: 36,
    marginBottom: 0,
    textAlign: 'start',
    fontSize: 24,
    fontWeight: 900,
  },
  subTitle: {
    margin: '5px 0px 10px 0px !important',
    fontSize: 15,
    fontWeight: 500,
  },
  highlight: {
    color: '#0c847e',
    fontWeight: 500,
    cursor: 'pointer',
  },
  submitBtn: {
    backgroundColor: '#ffbf30 !important',
    margin: '10px 0px 40px 0px !important',
    padding: '10px 30px !important',
    color: '#000 !important',
    fontWeight: 800,
  },
}));

const changePasswordSchema = Yup.object().shape({
  new_password: Yup
    .string()
    .trim()
    .min(8, 'Must be atleast 8 character long')
    .required('New password required'),
  confirm_password: Yup
    .string()
    .trim()
    .required('Confirm password required'),
});

const initialState = {
  new_password: '',
  confirm_password: '',
};

const ChangePassword = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [new_passwordType, setNewPasswordType] = useState(true);
  const [confirm_passwordType, setConfirmPasswordtype] = useState(true);
  // const [loggedIn, setLoggedIn] = useState(AuthService.getCurrentUser() ? true : false);

  const submitHandler = async (data: any) => {
    if (data.new_password === data.confirm_password) {
      await AuthService.changePassword(data.new_password)
      navigate('/');
    }
  };

  const handleNewPasswordType = () => setNewPasswordType((prev) => !prev);

  const handleConfirmPasswordType = () =>
    setConfirmPasswordtype((prev) => !prev);

  // const handleLoginOnLoad = async () => { 
  //   try {
  //     const userCredential = await AuthService.signInWithLink(window.location.href);
  //     console.log({userCredential});
  //     setLoggedIn(true);
  //   } catch (error) {
  //     console.error(error);
  //     // navigate('/');
  //   }
  // }

  // useEffect(() => {
  //   if (!loggedIn && AuthService.isValidSignInLink(window.location.href)) {
  //     handleLoginOnLoad();
  //   } else {
  //     navigate('/');
  //   }
  // }, [loggedIn]);

  return (
    <div>
      <SimpleNav text='Change Password' onClick={() => navigate(-1)} />
      <div className='Content'>
        <div className={classes.loginContainer}>
          <h1 className={classes.title}>Welcome to Goldi</h1>
          <p className={classes.subTitle}>
            Create a new password to secure your account.
          </p>
          <Formik
            initialValues={initialState}
            validationSchema={changePasswordSchema}
            onSubmit={submitHandler}
          >
            {(data) => (
              <Form>
                <Input
                  name={'new_password'}
                  label={'New Password'}
                  key={1}
                  error={data.errors.new_password}
                  touched={data.touched.new_password}
                  type={new_passwordType ? 'password' : 'text'}
                >
                  {new_passwordType ? (
                    <BiShowAlt
                      className='icon'
                      onClick={handleNewPasswordType}
                      style={{
                        bottom:
                          data.touched.new_password && data.errors.new_password
                            ? 33
                            : 10,
                      }}
                    />
                  ) : (
                    <BiHide
                      className='icon'
                      onClick={handleNewPasswordType}
                      style={{
                        bottom:
                          data.touched.new_password && data.errors.new_password
                            ? 33
                            : 10,
                      }}
                    />
                  )}
                </Input>
                <Input
                  name={'confirm_password'}
                  label={'Confirm Password'}
                  key={2}
                  error={data.errors.confirm_password}
                  touched={data.touched.confirm_password}
                  type={confirm_passwordType ? 'password' : 'text'}
                >
                  {confirm_passwordType ? (
                    <BiShowAlt
                      className='icon'
                      onClick={handleConfirmPasswordType}
                      style={{
                        bottom:
                          data.touched.confirm_password &&
                          data.errors.confirm_password
                            ? 33
                            : 10,
                      }}
                    />
                  ) : (
                    <BiHide
                      className='icon'
                      onClick={handleConfirmPasswordType}
                      style={{
                        bottom:
                          data.touched.confirm_password &&
                          data.errors.confirm_password
                            ? 33
                            : 10,
                      }}
                    />
                  )}
                </Input>
                <div>
                  <p>
                    By clicking Change Password, you agree to our{' '}
                    <span className={classes.highlight}>
                      Terms and Conditions
                    </span>{' '}
                    and{' '}
                    <span className={classes.highlight}>Privacy Statement</span>
                    .
                  </p>
                  <Button
                    variant='contained'
                    className={classes.submitBtn}
                    fullWidth
                    type='submit'
                  >
                    Change Password
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
