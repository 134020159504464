import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { User } from 'firebase/auth';
import { auth } from '../firebase';

import uploadFileToStorage from 'Utils/helpers/uploadFileToStorage';
import { Menu, MenuItem } from 'interfaces/MenuInterface';
import { FullRestaurant, Restaurant } from 'interfaces/ResturantInterface';
import AuthService from 'services/auth.service';

import { ALLERGIES, GOALS } from 'Utils/constants/routes';
import { Allergy } from 'interfaces/AllergyInterface';
import { CustomIngredient } from 'interfaces/CustomIngredientInterface';
import { GlobalSettings } from 'interfaces/GlobalSettingsInterface';
import { Goal } from 'interfaces/GoalInterface';
import { Ingredient } from 'interfaces/IngredientInterface';
import { Merchant } from 'interfaces/MerchantInterface';
import { Order } from 'interfaces/OrderInterface';

const MERCHANTS_ROOT = '/merchants';
const RESTAURANTS_ROOT = '/restaurants';
const PUBLIC_RESTAURANTS_ROOT = '/public/restaurants';
const MENUS_ROOT = '/menus';
const MENU_ITEMS_ROOT = '/menuItems';
const SEARCH_ROOT = '/search';
const CUSTOM_INGREDIENTS_ROOT = '/customIngredients';
const OAUTH_ROOT = '/oauth';
const INGREDIENTS_ROOT = '/ingredients';
const ORDERS_ROOT = '/orders';
const ADMIN_ORDERS_ROOT = '/admin/orders';
const ADMIN_RESTAURANTS_ROOT = '/admin/restaurants';
const ADMIN_GLOBAL_SETTINGS = '/admin/globalsettings';

const parseResult = (result: AxiosResponse) => {
  try {
    return result.data.data;
  } catch (e) {
    return result;
  }
}

export interface IApi {
  [key: string]: any; //TODO: define interface
  // createRestaurant: (restaurantParams: Restaurant) => Promise<Restaurant>
}

export type SuccessResult = {
  success: boolean;
}

export default class ApiService implements IApi {
  private axiosClient: AxiosInstance;
  private currentUser: User | null;


  constructor() {
    this.axiosClient = axios.create({
      baseURL: `${process.env.REACT_APP_API_ENDPOINT}/`,
    });
    this.axiosClient.interceptors.response.use(
      (response) => response,
      (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
    );
    this.currentUser = auth.currentUser;
  }

  getMerchants = async (snapshotId?: string) => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.get(MERCHANTS_ROOT, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        snapshotId
      }
    });

    return parseResult(result);
  }

  getOrders = async (snapshotId?: string) => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.get(`${ORDERS_ROOT}/`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        snapshotId
      }
    });

    return parseResult(result);
  }
  createMerchant = async (merchantParams: Merchant): Promise<Merchant> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.post(MERCHANTS_ROOT, merchantParams, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).catch(e => {
      return e;
    });

    return parseResult(result);
  }

  getMerchantById = async (merchantId: string) => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.get(`${MERCHANTS_ROOT}/${merchantId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return parseResult(result);
  }

  getMerchantProfile = async () => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.get(`${MERCHANTS_ROOT}/profile`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return parseResult(result);
  }

  editMerchant = async (merchantParams: Merchant): Promise<Merchant> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.patch(`${MERCHANTS_ROOT}/${merchantParams.id}`, merchantParams, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return parseResult(result);
  }

  sendMerchantVerificationEmail = async (merchantId: string): Promise<SuccessResult> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.post(`${MERCHANTS_ROOT}/${merchantId}/send-verification-email`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return parseResult(result);
  }

  sendMerchantResetPasswordEmail = async (merchantId: string): Promise<SuccessResult> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.post(`${MERCHANTS_ROOT}/${merchantId}/send-password-reset-email`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return parseResult(result);
  }

  pushGuestOrders = async (obj: any): Promise<SuccessResult> => {
    const result = await this.axiosClient.post(`guestorders`, obj);
    return result.data.data;
  }

  getOrderCustomizations = async (obj: any): Promise<SuccessResult> => {
    const token = ''
    console.log('here getOrderCustomizations')
   const result = await this.axiosClient.post(`/orders/getordercustomizations`, obj,{
       headers: {
    //   'Authorization': `Bearer ${token}`
    }
  });
    return result.data;
   
  }

  sendMerchantSignUpEmail = async (merchantId: string): Promise<SuccessResult> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.post(`${MERCHANTS_ROOT}/${merchantId}/send-signup-email`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return parseResult(result);
  }

  // sendMerchantSignInEmail = async (merchantId: string): Promise<SuccessResult> => {
  //   const token =  await AuthService.getToken();
  //   const result = await this.axiosClient.post(`${MERCHANTS_ROOT}/${merchantId}/send-signin-email`, {}, {
  //       headers: {
  //           Authorization: `Bearer ${token}`
  //       }
  //   });

  //   return parseResult(result);
  // }

  createRestaurant = async (restaurantParams: Restaurant): Promise<Restaurant> => {
    const { merchantId, image } = restaurantParams;

    // First we upload the image
    if (image) {
      const imageUrl = await uploadFileToStorage(image, `images/merchants/${merchantId}/restaurants/${image.name}`)
      restaurantParams.image = undefined; // get rid of the file since it's already been uploaded
      restaurantParams.imageUrl = imageUrl;
    }

    const token = await AuthService.getToken();
    const result = await this.axiosClient.post(RESTAURANTS_ROOT, restaurantParams, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).catch(e => {
      return e;
    });
    return parseResult(result);
  }

  getMerchantRestaurants = async (merchantId?: string) => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.get(RESTAURANTS_ROOT, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        merchantId
      }
    });

    // const { restaurants } = parseResult(result);

    // return restaurants;
    return parseResult(result);
  }

  // getMyRestaurants = async () => {
  //   const token =  await AuthService.getToken();
  //   const user = await AuthService.getCurrentUser();
  //   const result = await this.axiosClient.get(`/merchants/${user?.uid}/restaurants`, {
  //       headers: {
  //           Authorization: `Bearer ${token}`
  //       },
  //   });

  //   const { restaurants } = parseResult(result);

  //   return restaurants.map((r: any) => ({...r, thumb: theapplepan, desc: r.contactName}));
  // }

  //TODO: see if this is needed
  uploadFile = async (merchantId: string, file: any, fileName?: string) => {
    const formData = new FormData();
    formData.append('file', file)
    formData.append('fileName', fileName || file.name)
    const token = await AuthService.getToken();
    const result = await this.axiosClient.post(`/merchants/${merchantId}/upload`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'content-type': 'multipart/form-data',
      },
    });
    return parseResult(result);
  }

  getRestaurantById = async (restaurantId: string) => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.get(`${RESTAURANTS_ROOT}/${restaurantId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });

    const restaurant = parseResult(result);

    return restaurant;
  }

  deleteRestaurant = async (restaurant: Restaurant): Promise<SuccessResult> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.delete(`${RESTAURANTS_ROOT}/${restaurant.id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });

    return parseResult(result);
  }

  importRestaurant = async (restaurant: Restaurant, selectedMerchantId: string): Promise<SuccessResult> => {
    console.log('copyRestaurant', restaurant);
    console.log('merchantId', selectedMerchantId);

    const requestBody = {
     'merchantId': selectedMerchantId,
    };

    console.log('requestBody', requestBody);
    const token = await AuthService.getToken();
    const restaurantId = restaurant.id;
    // formData.append('restaurantId', ''+ restaurantId)
    const result = await this.axiosClient.post(`${RESTAURANTS_ROOT}/duplicaterestuarant/${restaurantId}`, requestBody, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      params: {
        restaurantId,
      }
    });
    return parseResult(result);
  }

  copyRestaurant = async (restaurant: Restaurant): Promise<SuccessResult> => {
    // console.log('copyRestaurant',restaurant);

    const formData = new FormData();


    //alert(formData);
    const token = await AuthService.getToken();
    const restaurantId = restaurant.id;
    // formData.append('restaurantId', ''+ restaurantId)
    const result = await this.axiosClient.post(`${RESTAURANTS_ROOT}/duplicaterestuarant/${restaurantId}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        restaurantId,
      }
    });
    return parseResult(result);
  }

  editRestaurant = async (restaurantParams: Partial<Restaurant>): Promise<Restaurant> => {
    const { merchantId, image } = restaurantParams;

    // First we upload the image
    if (image) {
      const imageUrl = await uploadFileToStorage(image, `images/merchants/${merchantId}/restaurants/${image.name}`)
      restaurantParams.image = undefined; // get rid of the file since it's already been uploaded
      restaurantParams.imageUrl = imageUrl;
    }

    const token = await AuthService.getToken();
    const result = await this.axiosClient.patch(`${RESTAURANTS_ROOT}/${restaurantParams.id}`, restaurantParams, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });

    const restaurant = parseResult(result);

    return restaurant;
  }


  getMenusByRestaurantId = async (restaurantId: string): Promise<Menu[]> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.get(MENUS_ROOT, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        restaurantId,
      }
    });

    // const { menus } = parseResult(result);

    return parseResult(result);
  }

  createMenu = async (menuParams: Menu): Promise<Menu> => {
    const { merchantId, image } = menuParams;

    // First we upload the image
    if (image) {
      const imageUrl = await uploadFileToStorage(image, `images/merchants/${merchantId}/menus/${image.name}`)
      menuParams.image = undefined; // get rid of the file since it's already been uploaded
      menuParams.imageUrl = imageUrl;
    }

    const token = await AuthService.getToken();
    const result = await this.axiosClient.post(MENUS_ROOT, menuParams, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });

    return parseResult(result);
  }

  deleteMenu = async (menu: Menu): Promise<SuccessResult> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.delete(`${MENUS_ROOT}/${menu.id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });

    return parseResult(result);
  }

  editMenu = async (menuParams: Partial<Menu>): Promise<Menu> => {
    const { merchantId, image } = menuParams;

    // First we upload the image
    if (image) {
      const imageUrl = await uploadFileToStorage(image, `images/merchants/${merchantId}/menus/${image.name}`)
      menuParams.image = undefined; // get rid of the file since it's already been uploaded
      menuParams.imageUrl = imageUrl;
    }

    const token = await AuthService.getToken();
    const result = await this.axiosClient.patch(`${MENUS_ROOT}/${menuParams.id}`, menuParams, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });

    return parseResult(result);
  }

  getMenuById = async (menuId: string): Promise<Menu> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.get(`${MENUS_ROOT}/${menuId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });

    return parseResult(result)
  }

  duplicateMenu = async (params: { menu: Menu, restaurantId?: string }): Promise<Menu> => {
    const { menu, restaurantId } = params;
    const { id: menuId } = menu;

    const token = await AuthService.getToken();
    const result = await this.axiosClient.post(`${MENUS_ROOT}/${menuId}/duplicate`, {
      restaurantId,
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });

    const duplicatedMenu = parseResult(result);
    return duplicatedMenu;
  }

  getMenuItemsByMenuId = async (menuId: string): Promise<MenuItem[]> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.get(MENU_ITEMS_ROOT, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        menuId,
      }
    });

    // const { menuItems } = parseResult(result);

    return parseResult(result);
  }

  createMenuItem = async (menuItemParams: MenuItem): Promise<MenuItem> => {
    const { image, ...rest } = menuItemParams;

    // First we create the object
    const token = await AuthService.getToken();
    const result = await this.axiosClient.post(MENU_ITEMS_ROOT, rest, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });

    const newMenuItem = parseResult(result);

    if (image) {
      const imageUrl = await uploadFileToStorage(image, `images/merchants/${newMenuItem.merchantId}/menuItems/${newMenuItem.id}/${image.name}`)
      return this.editMenuItem({ id: newMenuItem.id, imageUrl })
    }

    return newMenuItem;
  }

  duplicateMenuItem = async (params: { menuItem: MenuItem, menuId?: string, numberOfCopies?: string | number }): Promise<MenuItem> => {
    const { menuItem, menuId, numberOfCopies } = params;
    const { id: menuItemId } = menuItem;

    const token = await AuthService.getToken();
    const result = await this.axiosClient.post(`${MENU_ITEMS_ROOT}/${menuItemId}/duplicate`, {
      menuId,
      numberOfCopies
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });

    const duplicatedMenuItem = parseResult(result);
    return duplicatedMenuItem;
  }

  deleteMenuItem = async (menuItem: MenuItem): Promise<SuccessResult> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.delete(`${MENU_ITEMS_ROOT}/${menuItem.id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });

    return parseResult(result);
  }

  editMenuItem = async (menuItemParams: Partial<MenuItem>): Promise<MenuItem> => {
    const { id, image, ...rest } = menuItemParams;

    // First we upload the image
    if (image) {
      const imageUrl = await uploadFileToStorage(image, `images/merchants/${rest.merchantId}/menuItems/${id}/${image.name}`)
      rest.imageUrl = imageUrl;
    }

    const token = await AuthService.getToken();
    const result = await this.axiosClient.patch(`${MENU_ITEMS_ROOT}/${id}`, rest, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });

    return parseResult(result);
  }

  generateMenuItemIngredients = async (menuItem: MenuItem): Promise<MenuItem> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.post(`${MENU_ITEMS_ROOT}/generate-ingredients`, {
      menuItem,
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });

    return parseResult(result);
  }

  searchIngredients = async (ingredientName: string, restaurantId?: string, merchantId?: string): Promise<Ingredient> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.post(`${SEARCH_ROOT}/ingredients`, {
      ingredientName,
      restaurantId,
      merchantId,
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });

    return parseResult(result)
  }

  getIngredients = async (params: { query?: string, limit: number, offset: number, allergies?: string[], diets?: string[] }): Promise<Ingredient[]> => {
    const token = await AuthService.getToken();
    const { query, limit = 10000, offset = 0, allergies, diets } = params || {};

    const result = await this.axiosClient.get(`${INGREDIENTS_ROOT}`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: { query, limit, offset, allergies, diets },
    });

    return parseResult(result);
  }

  getIngredientById = async (ingredientId: number): Promise<Ingredient> => {
    const token = await AuthService.getToken();

    const result = await this.axiosClient.get(`${INGREDIENTS_ROOT}/${ingredientId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });

    return parseResult(result);
  }

  createIngredient = async (ingredientParams: Ingredient): Promise<Ingredient> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.post(INGREDIENTS_ROOT, ingredientParams, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });

    return parseResult(result);
  }

  deleteIngredient = async (ingredient: Ingredient): Promise<SuccessResult> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.delete(`${INGREDIENTS_ROOT}/${ingredient.internalId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });
    return parseResult(result);
  }

  duplicateIngredient = async (ingredient: Ingredient): Promise<Ingredient> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.post(`${INGREDIENTS_ROOT}/${ingredient.internalId}/copy`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });

    return parseResult(result);
  }

  editIngredient = async (ingredientParams: Partial<Ingredient>): Promise<Ingredient> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.patch(`${INGREDIENTS_ROOT}/${ingredientParams.internalId}`, ingredientParams, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });

    return parseResult(result);
  }

  getCustomIngredientsByRestaurantId = async (restaurantId: string): Promise<CustomIngredient[]> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.get(CUSTOM_INGREDIENTS_ROOT, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        restaurantId,
      }
    });

    // const { menus } = parseResult(result);

    return parseResult(result);
  }

  createCustomIngredient = async (customIngredientParams: CustomIngredient): Promise<CustomIngredient> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.post(CUSTOM_INGREDIENTS_ROOT, customIngredientParams, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });

    return parseResult(result);
  }

  deleteCustomIngredient = async (customIngredient: CustomIngredient): Promise<SuccessResult> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.delete(`${CUSTOM_INGREDIENTS_ROOT}/${customIngredient.id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });

    return parseResult(result);
  }

  editCustomIngredient = async (customIngredientParams: Partial<CustomIngredient>): Promise<CustomIngredient> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.patch(`${CUSTOM_INGREDIENTS_ROOT}/${customIngredientParams.id}`, customIngredientParams, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });

    return parseResult(result);
  }

  duplicateCustomIngredient = async (customIngredient: CustomIngredient): Promise<CustomIngredient> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.post(`${CUSTOM_INGREDIENTS_ROOT}/${customIngredient.id}/copy`, customIngredient, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });

    return parseResult(result);
  }

  getCustomIngredientById = async (customIngredientId: string): Promise<CustomIngredient> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.get(`${CUSTOM_INGREDIENTS_ROOT}/${customIngredientId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });

    return parseResult(result)
  }

  getRawIngredients = async (ingredientIds: string[]): Promise<Ingredient[]> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.post(`${INGREDIENTS_ROOT}/getRawIngredients`, {
      ingredientIds,
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });

    return parseResult(result);
  }

  oauthLogin = async (provider: string, params: Record<string, any>): Promise<any> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.post(`${OAUTH_ROOT}/${provider}`, params, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });
    return parseResult(result);
  }

  getAllergies = async (): Promise<any[]> => {
    const result = await this.axiosClient.get(`allergies`);
    return result.data.data;
  }

  editAllergies = async (allergyParams: Partial<Allergy>): Promise<Allergy> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.post(`${ALLERGIES}/${allergyParams.id}/update`, allergyParams, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });

    return parseResult(result);
  }

  deleteAllergies = async (allergy: Allergy): Promise<SuccessResult> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.delete(`${ALLERGIES}/${allergy.id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });

    return parseResult(result);
  }



  getDiets = async (): Promise<any[]> => {
    const result = await this.axiosClient.get(`${GOALS}`);
    return result.data.data;
  }

  editDiets = async (goalsParams: Partial<Goal>): Promise<Goal> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.post(`${GOALS}/${goalsParams.id}/update`, goalsParams, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });

    return parseResult(result);
  }

  deleteDiets = async (goal: Goal): Promise<SuccessResult> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.delete(`${GOALS}/${goal.id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });

    return parseResult(result);
  }


  getPublicRestaurantData = async (restaurantId: string): Promise<FullRestaurant> => {
    const result = await this.axiosClient.get(`${PUBLIC_RESTAURANTS_ROOT}/${restaurantId}`);
    return parseResult(result);
  }

  markOrderAsClaimed = async (orderId: string): Promise<SuccessResult> => {
    // alert(orderId);
    const token = await AuthService.getToken();
    const result = await this.axiosClient.post(`${ADMIN_ORDERS_ROOT}/${orderId}/mark-order-claimed`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return parseResult(result);
  }
  markOrderAsEscalated = async (orderId: string): Promise<SuccessResult> => {
    // alert(orderId);
    const token = await AuthService.getToken();
    const result = await this.axiosClient.post(`${ADMIN_ORDERS_ROOT}/${orderId}/mark-order-escalated`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return parseResult(result);
  }

  markOrderAsComplete = async (orderId: string, externalOrderNumber: string): Promise<SuccessResult> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.post(`${ADMIN_ORDERS_ROOT}/${orderId}/mark-order-complete`, { externalOrderNumber: externalOrderNumber }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return parseResult(result);
  }

  markOrderAsCancelled = async (orderId: string): Promise<SuccessResult> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.post(`${ADMIN_ORDERS_ROOT}/${orderId}/mark-order-cancelled`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return parseResult(result);
  }
  markPaymentAsComplete = async (orderId: string): Promise<SuccessResult> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.post(`${ADMIN_ORDERS_ROOT}/${orderId}/mark-payment-complete`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return parseResult(result);
  }
  markPaymentAsCancelled = async (orderId: string): Promise<SuccessResult> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.post(`${ADMIN_ORDERS_ROOT}/${orderId}/mark-payment-cancelled`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return parseResult(result);
  }

  getAdminOrders = async (snapshotId?: string) => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.get(`${ADMIN_ORDERS_ROOT}/`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        snapshotId
      }
    });

    return parseResult(result);
  }


  getOrderDetails = async (orderId: string): Promise<SuccessResult> => {


    const token = await AuthService.getToken();

    const result = await this.axiosClient.get(`${ADMIN_ORDERS_ROOT}/${orderId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return result.data;
  }
  updateOrderDetails = async (order: Order): Promise<SuccessResult> => {


    const token = await AuthService.getToken();

    const result = await this.axiosClient.post(`${ADMIN_ORDERS_ROOT}/${order.id}`, order, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return result.data;
  }

  getAdminRestaurants = async (snapshotId?: string) => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.get(`${ADMIN_RESTAURANTS_ROOT}/`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        snapshotId
      }
    });

    return parseResult(result);
  }
  getGlobalSettings = async () => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.get(`${ADMIN_GLOBAL_SETTINGS}/`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });
    return parseResult(result);
  }

  editGlobalSettings = async (globalsettingsParams: Partial<GlobalSettings>): Promise<GlobalSettings> => {
    const token = await AuthService.getToken();
    const result = await this.axiosClient.patch(`${ADMIN_GLOBAL_SETTINGS}`, globalsettingsParams, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });
    return parseResult(result);
  }

  getChatgptIngredients = async (menuItemName: string, menuItemDescription: string, subsection: string) => {
    const data = {
      'dishname': menuItemName,
      'description': menuItemDescription,
      'subsection': subsection,
    }
    const token = await AuthService.getToken();
    const result = await this.axiosClient.post(`/chatgpt/getIngredients`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });
    return parseResult(result);
  }

}
