import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import NoMealsIcon from '@mui/icons-material/NoMeals';
import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import { OrderType } from '../model/cart';
import { UserOrderItem } from './UserOrderItem';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from 'firebase';
import LoginWidget from 'modules/explorer/Auth/LoginWidget';
import RegisterWidget from 'modules/explorer/Auth/RegisterWidget';
import { Widget } from 'interfaces/WidgetInterface';
import ForgotPasswordWidget from 'modules/explorer/Auth/ForgotPasswordWidget';

interface Props {
  isEditable: boolean;
}

export const UserOrderDetails = ({ isEditable }: Props) => {
  const { cart, orders } = useCart();
  const navigate = useNavigate();
  const cartObj = isEditable ? cart : orders.cart;
  const { restaurantId } = useParams();
  const theme = useTheme();
  const [instruction, setInstruction] = useState('');
  const [orderType, setOrderType] = useState(OrderType.InRestaurant);
  const [user, loading] = useAuthState(auth);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [widget, setWidget] = useState<Widget>(Widget.Login)

  useEffect(() => {
    if (!loading) {
      setLoggedIn(!!user);
    }
  }, [loading, user]);

  useEffect(() => {
    console.log('Rendering UserOrderDetails, widget:', widget, 'isLoggedIn:', isLoggedIn);
  }, [widget, isLoggedIn]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (loadingIndicator) {
      timeoutId = setTimeout(() => {
        setLoadingIndicator(false);
      }, 4000);
    }

    return () => clearTimeout(timeoutId);
  }, [loadingIndicator]);

  const orderHeader = {
    position: 'fixed',
    pl: 2,
    pt: 1.5,
    top: 0,
    width: "100%",
    color: theme.palette.grey[0],
    zIndex: 99999,
  };

  const orderHeader2 = {
    position: 'fixed',
    py: 1.5,
    top: 0,
    width: "100%",
    zIndex: 999,
    textAlign: 'center',
    color: theme.palette.grey[0],
    backgroundColor: "#fe8154;",
  };

  useEffect(() => {
    setInstruction(isEditable ? '' : orders.notes);
  }, [orders, isEditable]);

  const goToLink = () => {
    if (restaurantId) {
      navigate('/explore/' + restaurantId + '/menu');
    } else {
      navigate(-1);
    }
  };

  return (
    <Stack sx={{ backgroundColor: theme.palette.grey[0] }}>
      <Box sx={orderHeader} maxWidth='sm'>
        <ArrowBackIosNewIcon onClick={goToLink} />
      </Box>
      <Box sx={orderHeader2} maxWidth='sm'>
        <Typography variant='body1' sx={{ fontSize: 18 }}>
          Order
        </Typography>
      </Box>
      {cartObj && cartObj.orders.length ? (
        <>
          {cartObj.orders.map((order) => (
            <UserOrderItem 
              instruction={instruction} 
              setInstruction={setInstruction} 
              key={`row-${order.userId}`} 
              orderType={orderType} 
              order={order} 
              isEditable={isEditable} 
            />
          ))}
        </>
      ) : (
        <Stack alignItems='center' spacing={2} mt={5}>
          <NoMealsIcon />
          <Typography variant='body1'>Your Cart is Empty.</Typography>
        </Stack>
      )}

      {loadingIndicator && (
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <img src="/loggedIn.gif" alt="Loading..." />
        </Box>
      )}

      {!isLoggedIn && !loadingIndicator && widget == Widget.Login && (
        <LoginWidget 
          setLoadingIndicator={setLoadingIndicator} 
          setLoggedIn={setLoggedIn} 
          isLoggedIn={isLoggedIn} 
          setWidget={setWidget}
        />
      )}
      
      {!isLoggedIn && !loadingIndicator && widget == Widget.SignUp && (
        <RegisterWidget 
          setLoadingIndicator={setLoadingIndicator} 
          setLoggedIn={setLoggedIn} 
          isLoggedIn={isLoggedIn} 
          setWidget={setWidget}
        />
      )}

      {!isLoggedIn && !loadingIndicator && widget == Widget.ForgotPassword && (
        <ForgotPasswordWidget
          setLoadingIndicator={setLoadingIndicator}
          setWidget={setWidget}
        />
      )}

    </Stack>
  );
};
