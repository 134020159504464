import { styled } from '@mui/material/styles';

export const MenuTabWrapper = styled('div')({
  backgroundColor: '#e5e5e5',
  padding:'32px 94px 0 94px',
  '@media (max-width: 768px)': {
    padding: '20px',
  },
});

export const TabHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  '@media (max-width: 768px)': {
    flexDirection: 'column',
    gap: 10,
    marginBottom:20
  },
});
