import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

type DeleteResourceDialogPropsType = {
    isOpen: boolean;
    handleClose: () => void;
    title: string;
    body?: string;
    handleDelete: () => void;
    disabled?: boolean;
}

const DeleteResourceDialog = ({
  isOpen,
  handleClose,
  title,
  body,
  handleDelete,
  disabled,
}: DeleteResourceDialogPropsType) => {
    return (
    <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="delete-resource-dialog-title"
        aria-describedby="delete-resource-dialog-description"
    >
        <DialogTitle id="delete-resource-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-resource-dialog-description">
            {body || 'This is an irreversible action. Are you sure you wish to delete this item?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button 
            onClick={handleDelete} 
            autoFocus 
            color='error' 
            disableElevation 
            variant='contained'
            disabled={disabled}
          >
            Delete
          </Button>
        </DialogActions>
    </Dialog>
    )
}

export default DeleteResourceDialog