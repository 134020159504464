import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from "react-query";
import StyledContainer from 'components/StyledContainer';
import LoadingPage from 'components/LoadingPage';
import SimpleNav from 'components/SimpleNav';
import { useApi } from 'providers/ApiProvider';
import MerchantForm from 'components/MerchantForm';
import { useMutationQuery } from 'providers/MutationQueryProvider';
import { Merchant } from 'interfaces/MerchantInterface';
import { generateMerchantIdQuery } from 'Utils/helpers/queryGenerators';
import { useEffect, useState } from 'react';

const EditMerchant = () => {
  // API
  const api = useApi();
  const { merchantId } = useParams();
  const navigate = useNavigate();
  const { editMerchantMutation } = useMutationQuery();
  const [loader, setLoader] = useState(false);
  const { status: _merchantStatus, error: _merchantError, data: merchant, isLoading: isLoadingMerchant, refetch } = useQuery(generateMerchantIdQuery(merchantId), async () => {
    return api.getMerchantById(merchantId);
  });

   useEffect(() => {
    setLoader(true);
    refetch();
    setLoader(false);
  }, [merchantId])
  
  const handleSubmit = (params: Merchant): Promise<any> => {
    return editMerchantMutation.mutateAsync(params)
  }

  return ( isLoadingMerchant ? <LoadingPage/> :
    <StyledContainer>
      <SimpleNav text={`Edit ${merchant.businessName}`} onClick={() => navigate(-1)} />
      
      {!loader && <MerchantForm handleSubmit={handleSubmit} merchant={merchant} />}
    </StyledContainer>
  );
};

export default EditMerchant;
