import { styled } from '@mui/system';

export const MenuCardContainer = styled('div')({
  position: 'relative',
  width: '300px',
  backgroundColor: '#ffffff',
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  boxShadow: '0px 14px 34px rgba(0, 0, 0, 0.08)',
  borderRadius: '10px',
  cursor: 'pointer',
  '@media (max-width: 768px)': {
    width: '100%',
  },
});

export const CardHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '16px',
});

export const OptionsMenu = styled('div')({
  position: 'absolute',
  right: '25px',
  top: '48px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  padding: '16px 12px',
  height: '56px',
  width: '113px',
  background: '#ffffff',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
  borderRadius: '8px',
});
