import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useTheme } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props: any) {

  const handleClose = (val: boolean) => {
    props.setOpenDialog(false, val);
  };
  const theme = useTheme();
  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Proceed?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {props.text ?? "By proceeding with this order, you confirm that you have read and agreed to Goldi’s terms of service"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={{
          color: theme.palette.grey[0],
          background: theme.palette.primary.dark,
          border: '2px solid',

          borderColor: theme.palette.primary.dark,
          '&:hover': {
            background: theme.palette.primary.darker,
            borderColor: theme.palette.primary.darker,
          },
          width: '20%',
          maxWidth: 360,
          borderRadius: 12,
          pr: 2,
          pl: 2,
        }} onClick={() => handleClose(true)}>OK</Button>
        <Button sx={{
          color: theme.palette.grey[0],
          background: theme.palette.primary.dark,
          border: '2px solid',

          borderColor: theme.palette.primary.dark,
          '&:hover': {
            background: theme.palette.primary.darker,
            borderColor: theme.palette.primary.darker,
          },
          width: '20%',
          maxWidth: 360,
          borderRadius: 12,
          pr: 2,
          pl: 2,
        }} onClick={() => handleClose(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
