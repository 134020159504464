import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Axios from 'axios';
import ThemeProvider from './theme';

import './index.css';
import { store } from './store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApiProvider } from './providers/ApiProvider';
import { MutationQueryProvider } from './providers/MutationQueryProvider';
import { DietaryContextProvider } from './providers/DietaryPreferenceProvider';
import 'react-toastify/dist/ReactToastify.css';

Axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={null}>
      <QueryClientProvider client={queryClient}>
          <ThemeProvider>
          <ApiProvider>
            <MutationQueryProvider>
              <BrowserRouter>
                <DietaryContextProvider>
                  <App />
                </DietaryContextProvider>
              </BrowserRouter>
            </MutationQueryProvider>
          </ApiProvider>
          </ThemeProvider>
      </QueryClientProvider>
    </Suspense>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
