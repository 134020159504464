import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import logo from '../../../../images/Goldi-Animation.svg';
import GetStarted from './GetStarted';

interface Props {
  setVideoPlayed(val: boolean): any;
}

const Splash = ({ setVideoPlayed }: Props) => {
  const [checked, setChecked] = useState(false);
  const theme = useTheme();

  const splashScreenStyles = {
    backgroundColor: theme.palette.primary.lighter,
    height: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column', // Stacks logo and text vertically
    textAlign: 'center', // Centers the text
    padding: theme.spacing(0, 12), 
  };

  useEffect(() => {
    setTimeout(() => {
      setChecked(true);
    }, 1000);
  }, []);

  return (
    <Box sx={splashScreenStyles}>
      <img src={logo} alt="Goldi Logo" style={{ marginBottom: theme.spacing(2) }} />
      <Typography variant='h5' fontWeight={'bold'}>
        The easiest way to personalize your food order, so that it’s Just Right.
      </Typography>
      {checked && <GetStarted setVideoPlayed={setVideoPlayed} />}
    </Box>
  );
};

export default Splash;
