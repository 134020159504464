import { Box } from '@mui/material';
import { SortableElement } from 'react-sortable-hoc';

interface ISortableItem {
  children: React.ReactNode;
  display: 'grid' | 'vertical';
}

const SortableItem = SortableElement(({ children, display }: ISortableItem) => {
  return (
    <Box
      sx={
        display === 'grid'
          ? { float: 'left', padding: '8px' }
          : { marginBottom: '8px' }
      }
    >
      {children}
    </Box>
  );
});

export default SortableItem;
