import { Box } from '@mui/system';
import CustomIconButton from 'components/CustomIconButton';
import completeIcon from 'images/table/check_circle.svg';
import deleteIcon from 'images/table/delete.svg';
import editIcon from 'images/table/edit.svg';
import copyIcon from 'images/table/file_copy.svg';
import claimIcon from 'images/table/pan_tool.svg';
import reportIcon from 'images/table/report.svg';
interface IIngredientActions {
  handleEdit?: () => void;
  handleDelete?: () => void;
  handleCancel?: () => void;
  handleComplete?: () => void;
  handleClaim?: () => void;
  handleDuplicate?: () => void;
  handleEscalated?: () => void;
  children?: React.ReactNode;
}

const IngredientActions = ({ handleEdit, handleDelete, handleDuplicate,handleComplete,handleCancel,handleClaim,handleEscalated,children }: IIngredientActions) => {
  return (
    <Box sx={{ whiteSpace: 'nowrap' }}>
       {handleClaim && <CustomIconButton
        icon={claimIcon}
        altText='claim'
        label=''
        onClick={handleClaim}
        styles={{ marginRight: '5px', width: '20px' }}
      />}
       {handleComplete && <CustomIconButton
        icon={completeIcon}
        altText='complete'
        label=''
        onClick={handleComplete}
        styles={{ marginRight: '5px', width: '20px' }}
      />}
      { handleEdit && <CustomIconButton
        icon={editIcon}
        altText='edit'
        label=''
        onClick={handleEdit}
        styles={{ marginRight: '5px', width: '20px' }}
      /> }
      
      { handleDuplicate && <CustomIconButton
        icon={copyIcon}
        altText='duplicate'
        label=''
        onClick={handleDuplicate}
        styles={{ marginRight: '5px', width: '20px' }}
      /> }
      {handleDelete && <CustomIconButton
        icon={deleteIcon}
        altText='delete'
        label=''
        onClick={handleDelete}
        styles={{ marginRight: '5px', width: '20px' }}
      />}
      {handleCancel && <CustomIconButton
        icon={deleteIcon}
        altText='cancel'
        label=''
        onClick={handleCancel}
        styles={{ marginRight: '5px', width: '20px' }}
      />}
      {handleEscalated && <CustomIconButton
        icon={reportIcon}
        altText='escalate'
        label=''
        onClick={handleEscalated}
        styles={{ marginRight: '5px', width: '20px' }}
      />}
     
      {children}
    </Box>
  );
};
export default IngredientActions;
