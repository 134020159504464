import { Box, styled } from '@mui/material';

export const Container = styled(Box)({
  width: '100%',
});

export const StyledContainer = styled(Box)({
  '&:after': {
    content: "''",
    clear: 'both',
    display: 'table',
  },
});
