// import { BaseSyntheticEvent, useEffect, useState } from 'react';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import { FastField, FieldArray, FieldProps, FormikProps } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import { processCustomization } from 'Utils/helpers/processMenuItem';
import IngredientsSearchField from 'components/IngredientsSearchField';
import { GoldiTextField } from 'components/InputField';
import { Ingredient } from 'interfaces/IngredientInterface';
import {
  MenuItem,
  MenuItemCustomization,
  MenuItemCustomizationOption,
} from 'interfaces/MenuInterface';
import AllergiesDisplay from './AllergiesDisplay';
import DietsDisplay from './DietsDisplay';
import GoldiPriceField from './PriceInputField';
import IngredientsPillDisplay from './IngredientsPillDisplay';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:first-of-type)': {
    borderTop: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const generateNewCustomizationOption = (): MenuItemCustomizationOption => ({
  id: uuidv4(),
  price: 0,
  title: '',
  description: '',
  defaultModifier: false,
  ingredients: [],
  externalId: '',
  externalSource: '',
});

type PropsType = {
  customization: MenuItemCustomization;
  index: number;
  removeCustomization: (index: number) => void;
  formik: FormikProps<MenuItem>;
  canRemove?: boolean;
};

const MenuItemCustomizationForm = ({
  customization,
  index,
  removeCustomization,
  formik,
  canRemove = true,
}: PropsType) => {
  const { values, handleBlur, handleChange, setFieldValue } = formik;
  return customization ? (
    <Accordion key={`menuItemCustomization-${index}`}>
      <AccordionSummary>
        <Box display={'flex'} alignItems={'center'} width={'100%'}>
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            {customization.title || `Customization ${index + 1}`}
          </Typography>
          {canRemove && (
            <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
              <Button>
                <DeleteIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    removeCustomization(index);
                  }}
                />
              </Button>
            </Box>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          {/* customization Name */}
          <Grid item xs={9}>
            <Typography>Name</Typography>
            <FastField
              name={`menuItemCustomizations[${index}].title`}
              type='text'
            >
              {({ field, form: { errors } }: FieldProps) => (
                <GoldiTextField
                  {...field}
                  id={field.name}
                  value={customization.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors[field.name]}
                />
              )}
            </FastField>
          </Grid>
          <Grid item xs={12}>
            <Typography>Description</Typography>
            <FastField
              name={`menuItemCustomizations[${index}].description`}
              type='text'
            >
              {({ field, form: { errors } }: FieldProps) => (
                <GoldiTextField
                  {...field}
                  id={field.name}
                  value={customization.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors[field.name]}
                />
              )}
            </FastField>
          </Grid>
          <Grid item xs={6}>
            <Typography>Minimum Options Permitted</Typography>
            <FastField
              name={`menuItemCustomizations[${index}].minPermitted`}
              type='text'
            >
              {({ field, form: { errors } }: FieldProps) => (
                <GoldiTextField
                  {...field}
                  id={field.name}
                  value={customization.minPermitted}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors[field.name]}
                />
              )}
            </FastField>
          </Grid>
          <Grid item xs={6}>
            <Typography>Maximum Options Permitted</Typography>
            <FastField
              name={`menuItemCustomizations[${index}].maxPermitted`}
              type='text'
            >
              {({ field, form: { errors } }: FieldProps) => (
                <GoldiTextField
                  {...field}
                  id={field.name}
                  value={customization.maxPermitted}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors[field.name]}
                />
              )}
            </FastField>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Shared Ingredients (Ingredients shared by all options)
            </Typography>
            <FastField
              id={`menuItemCustomizations[${index}].ingredients`}
              name={`menuItemCustomizations[${index}].ingredients`}
              key={`menuItemCustomizations[${index}].ingredients`}
              type='text'
            >
              {({ field, form }: FieldProps) => (
                <IngredientsSearchField
                  ingredients={customization.ingredients || []}
                  restaurantId={values.restaurantId}
                  handleSetIngredients={(ingredients: Ingredient[]) => {
                    setFieldValue(
                      `menuItemCustomizations[${index}].ingredients`,
                      ingredients
                    );
                    const processedMenuItemCustomizations =
                      processCustomization(
                        values.menuItemCustomizations[index]
                      );
                  }}
                />
              )}
            </FastField>
          </Grid>
          {(values.menuItemCustomizations[index]?.allergies || []).length >
            0 && (
            <Grid item xs={12}>
              <AllergiesDisplay
                allergies={
                  values.menuItemCustomizations[index]?.allergies || []
                }
              />
            </Grid>
          )}
          {(values.menuItemCustomizations[index]?.diets || []).length > 0 && (
            <Grid item xs={12}>
              <DietsDisplay
                diets={values.menuItemCustomizations[index]?.diets || []}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography>Options</Typography>
            <FieldArray
              name={`menuItemCustomizations[${index}].menuItemCustomizationOptions`}
            >
              {({
                remove: removeCustomizationOption,
                push: addCustomizationOption,
              }) => (
                <Box>
                  {customization.menuItemCustomizationOptions.map(
                    (customizationOption, optionIndex: number) =>
                      customizationOption ? (
                        <Accordion
                          key={`customization-${index}-options-${optionIndex}`}
                        >
                          <AccordionSummary>
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              width={'100%'}
                            >
                              <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                {customizationOption.title ||
                                  `Customization Option ${optionIndex + 1}`}
                              </Typography>
                              <Box
                                width={'100%'}
                                display={'flex'}
                                justifyContent={'flex-end'}
                              >
                                <FormControlLabel
                                  value='right'
                                  onClick={(e) =>{
                                    e.stopPropagation();
                                  }}
                                  control={
                                    <Switch
                                      id={`menuItemCustomizations[${index}].menuItemCustomizationOptions[${optionIndex}].defaultModifier`}
                                      checked={
                                        customizationOption.defaultModifier
                                      }
                                      onChange={handleChange}
                                      name={`menuItemCustomizations[${index}].menuItemCustomizationOptions[${optionIndex}].defaultModifier`}
                                    />
                                  }
                                  label='Default Modifier'
                                  labelPlacement='start'
                                />
                                <Button>
                                  <DeleteIcon
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      removeCustomizationOption(optionIndex);
                                    }}
                                  />
                                </Button>
                              </Box>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={1}>
                              <Grid item xs={8}>
                                <Typography>Name</Typography>
                                <FastField
                                  name={`menuItemCustomizations[${index}].menuItemCustomizationOptions[${optionIndex}].title`}
                                  type='text'
                                >
                                  {({
                                    field,
                                    form: { errors },
                                  }: FieldProps) => (
                                    <GoldiTextField
                                      {...field}
                                      id={field.name}
                                      value={customizationOption.title}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={!!errors[field.name]}
                                    />
                                  )}
                                </FastField>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography>Price</Typography>
                                <FastField
                                  name={`menuItemCustomizations[${index}].menuItemCustomizationOptions[${optionIndex}].price`}
                                  type='text'
                                >
                                  {({
                                    field,
                                    form: { errors },
                                  }: FieldProps) => (
                                    // <GoldiTextField
                                    //     {...field}
                                    //     id={field.name}
                                    //     value={customizationOption.price}
                                    //     onChange={handleChange}
                                    //     onBlur={handleBlur}
                                    //     error={!!errors[field.name]}
                                    // />
                                    <GoldiPriceField
                                      {...field}
                                      id={field.name}
                                      value={customizationOption.price}
                                      onChange={(price: number) => {
                                        setFieldValue(field.name, price);
                                      }}
                                      onBlur={handleBlur}
                                      error={!!errors[field.name]}
                                    />
                                  )}
                                </FastField>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography>Pick Up Price</Typography>
                                <FastField
                                  name={`menuItemCustomizations[${index}].menuItemCustomizationOptions[${optionIndex}].pickup_price`}
                                  type='text'
                                >
                                  {({
                                    field,
                                    form: { errors },
                                  }: FieldProps) => (
                                    <GoldiTextField
                                      {...field}
                                      id={field.name}
                                      value={
                                        customizationOption.pickupPrice || ''
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={!!errors[field.name]}
                                    />
                                  )}
                                </FastField>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography>Suspend Until</Typography>
                                <FastField
                                  name={`menuItemCustomizations[${index}].menuItemCustomizationOptions[${optionIndex}].suspendUntil`}
                                  type='text'
                                >
                                  {({
                                    field,
                                    form: { errors },
                                  }: FieldProps) => (
                                    <GoldiTextField
                                      {...field}
                                      id={field.name}
                                      value={
                                        customizationOption.suspendUntil || ''
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={!!errors[field.name]}
                                    />
                                  )}
                                </FastField>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography>Description</Typography>
                                <FastField
                                  name={`menuItemCustomizations[${index}].menuItemCustomizationOptions[${optionIndex}].description`}
                                  type='text'
                                >
                                  {({
                                    field,
                                    form: { errors },
                                  }: FieldProps) => (
                                    <GoldiTextField
                                      {...field}
                                      id={field.name}
                                      value={
                                        customizationOption.description || ''
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={!!errors[field.name]}
                                    />
                                  )}
                                </FastField>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography>Ingredients</Typography>
                                <FastField
                                  id={`menuItemCustomizations[${index}].menuItemCustomizationOptions[${optionIndex}].ingredients`}
                                  name={`menuItemCustomizations[${index}].menuItemCustomizationOptions[${optionIndex}].ingredients`}
                                  key={`menuItemCustomizations[${index}].menuItemCustomizationOptions[${optionIndex}].ingredients`}
                                  type='text'
                                >
                                  {({ field, form }: FieldProps) => (
                                    <IngredientsSearchField
                                      ingredients={ customizationOption.ingredients ||[]}
                                      
                                      restaurantId={values.restaurantId}
                                      handleSetIngredients={(
                                        ingredients: Ingredient[]
                                      ) => {
                                        setFieldValue(
                                          `menuItemCustomizations[${index}].menuItemCustomizationOptions[${optionIndex}].ingredients`,
                                          ingredients
                                        );
                                        const processedMenuItemCustomizations =
                                          processCustomization(
                                            values.menuItemCustomizations[index]
                                          );
                                      }}
                                    />
                                  )}
                                </FastField>
                              </Grid>
                              {(
                                  customizationOption.fuzzySearchIngredientsResults?.customIngredients
                                  || []
                              ).length > 0 && (
                                <Grid item xs={12}>
                                  <IngredientsPillDisplay
                                    ingredients={
                                      customizationOption.fuzzySearchIngredientsResults?.customIngredients || []
                                    }
                                    title='Custom Ingredient Search Results'
                                  />
                                </Grid>
                              )}
                              {(
                                  customizationOption.fuzzySearchIngredientsResults?.ingredients
                                  || []
                              ).length > 0 && (
                                <Grid item xs={12}>
                                  <IngredientsPillDisplay
                                    ingredients={
                                      customizationOption.fuzzySearchIngredientsResults?.ingredients || []
                                    }
                                    title='Raw Ingredient Search Results'
                                  />
                                </Grid>
                              )}
                              {(
                                  customizationOption.allergies || []
                              ).length > 0 && (
                                <Grid item xs={12}>
                                  <AllergiesDisplay
                                    allergies={
                                      customizationOption.allergies || []
                                    }
                                  />
                                </Grid>
                              )}
                              {(
                               customizationOption.diets || []
                              ).length > 0 && (
                                <Grid item xs={12}>
                                  <DietsDisplay
                                    diets={
                                      customizationOption.diets || []
                                    }
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      ) : null
                  )}
                  <Button
                    onClick={() => {
                      addCustomizationOption({
                        ...generateNewCustomizationOption(),
                        title: `Customization Option ${
                          (customization.menuItemCustomizationOptions || [])
                            .length + 1
                        }`,
                      });
                    }}
                  >
                    Add Customization Option
                  </Button>
                </Box>
              )}
            </FieldArray>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  ) : null;
};

export default MenuItemCustomizationForm;
