import { ReactComponent as GoldiLogo } from 'images/goldi-logo.svg';
import { StyledHeader } from './index.styles';

const GoldiHeader = () => {
  return (
    <StyledHeader>
      <GoldiLogo />
    </StyledHeader>
  );
};
export default GoldiHeader;
