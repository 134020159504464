import { Box, Container, Grid, Stack, Typography } from '@mui/material';
// import { UserOrder, OrderType } from '../model/cart';
// import { UserOrderMenuItem } from './UserOrderMenuItem';
// import { useCart, CartContextProvider } from '../context/CartContext';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import NoMealsIcon from '@mui/icons-material/NoMeals';
import { useTheme } from '@mui/material/styles';
import { formatCentsToDollars } from 'modules/explorer/ExploreRestaurant/util/util';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import { OrderItem } from './OrderItem';
import { generateOrdersIdDetailsQuery } from 'Utils/helpers/queryGenerators';
import { OverviewContainer } from 'components/RestaurantOverview/index.styles';
import RestaurantSummary from 'components/RestaurantSummary';
import { Item } from 'firebase/analytics';
import { Restaurant } from 'interfaces/ResturantInterface';
import { Cart, Order, OrderType } from 'modules/explorer/ExploreRestaurant/model/cart';
import { useApi } from 'providers/ApiProvider';
import { useQuery } from 'react-query';
import { User } from '../../../../src/interfaces/UserInterface';
import { OrderItem } from './OrderItem';

interface Props {
  isEditable: boolean;
}

const initialState: Cart = {
  orders: [],
  subtotal: 0,
  deliveryFee: 0,
  tipFee: 0,
  taxFee: 0,
  grandTotal: 0,
  serviceFee: 0,
  coupon: '',
  discount: 0 
};

const initialStateOrders: Order = {
  cart: initialState,
  restaurant: {},
  restaurantName: "",
  notes: '',
  OrderType: OrderType.InRestaurant,
  orderType: OrderType.InRestaurant
}

export const OrderDetails = ({ isEditable }: Props) => {
  const api = useApi();

  const { orderId } = useParams();
  const [orderDetails, setOrderDetails] = useState<Order>(initialStateOrders);
  const [cartItems, setCartItems] = useState([]);
  const [restaurant, setRestaurant] = useState<Restaurant>();
  const [customer, setCustomer] = useState<User>();
  const [address, setAddress] = useState();
  const { status: _status, error: _error, data: orders, isLoading: _isLoading, refetch } = useQuery(generateOrdersIdDetailsQuery(orderId), async () => {
    return api.getOrderDetails(orderId);
  });

  const navigate = useNavigate();
  const theme = useTheme();
  const [instruction, setInstruction] = useState('');
  // const [orderType, setOrderType] = useState(OrderType.InRestaurant);

  useEffect(() => {
    if (_status === 'success') {
      if (orders && orders.order) {
        setOrderDetails(orders.order);
        if (orders.order.cart) setCartItems(orders.order.cart.orders);
        if (orders.restaurant) setRestaurant(orders.restaurant);
        if (orders.customer) setCustomer(orders.customer);
        if (orders.order) setAddress(orders.order.addressString);
      }
    }
    // console.log('address');
    // console.log(orders.order.addressString);
  }, [_status, orderId, orders])

  const orderHeader = {
    position: 'fixed',
    pl: 2,
    pt: 1.5,
    top: 0,
    width: "100%",
    color: theme.palette.grey[0],
    zIndex: 99999,
  }

  const orderHeader2 = {
    // position: 'fixed',
    py: 1.5,
    top: 0,
    width: "100%",
    zIndex: 999,
    textAlign: 'center',
    color: theme.palette.grey[0],
    backgroundColor: theme.palette.primary.main
  }

  let itemsCount = 0;

  return (
    <Stack>
      <Box sx={orderHeader} maxWidth='sm'>
        <ArrowBackIosNewIcon onClick={() => { navigate(-1); }} />
      </Box>
      <Box sx={orderHeader2}>
        <Typography variant='body1' sx={{ fontSize: 18 }}>
          Order Type:  {orderDetails.orderType}
        </Typography>
      </Box>

      <br />
      <br />
      <Container>
        {restaurant &&
          <OverviewContainer>
            <Box sx={orderHeader2}>
              <Typography variant='body1' sx={{ fontSize: 18 }}>
                Restaurant Details
              </Typography>
            </Box>
            <RestaurantSummary
              restaurant={restaurant}
              canEdit={false}
            />
          </OverviewContainer>}
        <br />
        <br />
        {customer &&
          <OverviewContainer>


            <Grid container >
              <Grid item >
                <Box sx={orderHeader2}>
                  <Typography variant='body1' sx={{ fontSize: 18 }}>
                    Customer Details
                  </Typography>
                </Box>


                <Typography>Name: {customer.name} </Typography>
                <Typography>Phone: {customer.phoneNumber} </Typography>
                <Typography>Email: {customer.email} </Typography>
                <Typography>Address: {address} </Typography>
              

                <br />

              </Grid>
            </Grid>
          </OverviewContainer>}
      </Container>
      <br />
      <br />
      <Container>
        <OverviewContainer>
          <Box sx={orderHeader2}>
            <Typography variant='body1' sx={{ fontSize: 18 }}>
              Order Details
            </Typography>
           
          </Box>
          
          {cartItems?.length ? (
            cartItems.map((order: { items: Item[], userId: any; }) => {
              itemsCount += order.items.length;
              return <OrderItem instruction={instruction} setInstruction={(val: string) => setInstruction(val)} key={`row-${order.userId}`} order={order} isEditable={isEditable} orderType={''} />
            })
          ) : (
            <Stack alignItems='center' spacing={2} mt={5}>
              <NoMealsIcon />
              <Typography variant='body1'>No Order Details.</Typography>
            </Stack>
          )}
        </OverviewContainer>
      </Container>
      {orderDetails && orderDetails.cart && orderDetails.cart.subtotal != 0 &&
        <Container>
          <OverviewContainer>

            <Grid container sx={{ mb: 5 }}>
              <Grid item lg={6}>

                <Typography variant='body1' sx={{ fontSize: 18 }}>
                  Total Items: {itemsCount}
                </Typography>
                <Typography>Order Notes: {orderDetails.notes} </Typography>
              </Grid>
              <Grid item lg={6}>
                {orderDetails.cart && <Box sx={{ textAlign: 'right' }}>
                  <Typography variant='body1' sx={{ fontSize: 18 }}>
                    Orders Subtotal : {formatCentsToDollars(orderDetails.cart.subtotal)}
                  </Typography>
                  {orderDetails.cart.deliveryFee && <Typography variant='body1' sx={{ fontSize: 18 }}>
                    Delivery : {formatCentsToDollars(orderDetails.cart.deliveryFee)}
                  </Typography>}
                  {orderDetails.cart.tipFee && <Typography variant='body1' sx={{ fontSize: 18 }}>
                    Tip : {formatCentsToDollars(orderDetails.cart.tipFee)}
                  </Typography>}
                  {orderDetails.cart.taxFee && <Typography variant='body1' sx={{ fontSize: 18 }}>
                    Tax : {formatCentsToDollars(orderDetails.cart.taxFee)}
                  </Typography>}
                  {orderDetails.cart.grandTotal && <Typography variant='body1' sx={{ fontSize: 18 }}>
                    Total : {formatCentsToDollars(orderDetails.cart.grandTotal)}
                  </Typography>}
                 
                </Box>}
               
              
              </Grid>
            </Grid>

          </OverviewContainer>
        </Container>
      }
    </Stack>
  );
};