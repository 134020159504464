// import './index.css';
// import { ReactComponent as AddressIcon } from '../../images/icons/address-icon.svg'; //images/icons/address-icon.svg
import { Box, Grid } from '@mui/material';
import { GoldiTextField } from 'components/InputField';
import { useState } from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

type PropsTypes = {
    value: string;
    handleSelect: (address: string, placeId?: string, suggestions?: Record<string, unknown>,latLng?: google.maps.LatLngLiteral) => void;
    placeholder?: string;
    error?: boolean;
}

const GoldiAddressInputField = ({
    value = '',
    handleSelect,
    placeholder,
    error,
}: PropsTypes) => {
    const [address, setAddress] = useState<string>(value)

    return (
        <PlacesAutocomplete
            value={address}
            onChange={(address) => setAddress(address)}
            onSelect={async (address: string, placeId?: string, suggestion?: Record<string, unknown>) => {
                setAddress(address);
                try {
                    const geoCode = await geocodeByAddress(address);
                    const latLng: google.maps.LatLngLiteral = await getLatLng(geoCode[0]);
                    handleSelect(address, placeId, suggestion, latLng);
                } catch (error) {
                    console.log('Error', error)
                }
            }}
            >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <Box className="autocomplete-input-root">
                    {/* {!address && <AddressIcon className='prefix'/>} */}
                    <GoldiTextField
                        {...getInputProps({
                            placeholder,
                            className: 'input-field',
                            error
                        })}
                    />
                    <Box className="autocomplete-dropdown-container">
                        {loading && <Box component={'span'}>Loading...</Box >}
                        {suggestions.map((suggestion, idx) => {
                            const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                                <Grid
                                    {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                    })}
                                    key={`suggestion_${idx}`}
                                    borderBottom={1}
                                    px={1}
                                    pb={1}
                                    sx={{
                                        mt: 2,
                                    }}
                                >
                                    <span>{suggestion.description}</span>
                                </Grid>
                            );
                        })}
                    </Box>
                </Box>
            )}
        </PlacesAutocomplete>
    )
}

export default GoldiAddressInputField