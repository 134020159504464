// import './index.css';
import { generateIngredientsQuery } from 'Utils/helpers/queryGenerators';
import { useApi } from 'providers/ApiProvider';
import { useState } from "react";
import { useQuery } from "react-query";

import { Box, Chip, Tooltip } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import ButtonAddNew from 'components/ButtonAddNew';
import LoadingPage from 'components/LoadingPage';
import TableActions from 'components/TableActions';

import { Ingredient } from 'interfaces/IngredientInterface';

import { useMutationQuery } from 'providers/MutationQueryProvider';

import IngredientDialog from "./IngiedientDialog";

import ActionDialog from "components/ActionDialog";
import DeleteResourceDialog from "components/DeleteResourceDialog";
import CustomTypography from "components/Typography";
import './index.css';

const Ingredients = () => {
  const newIngredient: Ingredient = {
    id: '',
    title: '',
    description: '',
    foodGroup: '',
    active: true,
    allergies: [],
    diets: [],
    ingredients: [],
    internalId: 0,
  };
  const { addIngredientMutation, deleteIngredientMutation, editIngredientMutation, duplicateIngredientMutation } = useMutationQuery();
  const [ingredientDialogIsOpen, setIngredientDialogIsOpen] = useState(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const [duplicateIngredientDialogIsOpen, setDuplicateIngredientDialogIsOpen] = useState(false);


  const [ingredient, setIngredient] = useState<Ingredient>(newIngredient);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const api = useApi();
  const { status: _ingredientsStatus, error: _ingredientsError, data: ingredients, isLoading: _isLoadingiIngredients } = useQuery(generateIngredientsQuery(), async () => {
    return api.getIngredients();
  });

  const isLoading = !ingredients;



  return isLoading ? (
    <LoadingPage />
  ) : (
    <>
      <Box sx={{ px: 2, py: 4 }}>
        {/* <h1>Ingredients</h1> */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <CustomTypography
            fontWeight='bold'
            fontSize='24px'
            lineHeight='26px'
            marginBottom='37px'
          >
            Raw Ingredients
          </CustomTypography>
          <ButtonAddNew
            label='Add New'
            styles={{ marginTop: '-8px', marginRight: '3px' }}
            onClick={() => {
              setIngredient(newIngredient);
              setIsEdit(false);
              setIngredientDialogIsOpen(true);
            }}
          />
        </Box>
        <Box sx={{ backgroundColor: 'white', height: 850 }}>
          <DataGrid
            // editMode='row'

            rows={ingredients}
            showCellRightBorder={true}
            columns={[
              {
                field: 'id', headerName: 'NDB', flex: 1, width: 100, maxWidth: 130,
                renderCell: (params) => {
                  const tooltip = params.row.id;
                  return (
                    <Tooltip title={`Id: ${tooltip}`} placement="top">
                      <div>
                        {tooltip}
                      </div>
                    </Tooltip>
                  )
                }
              },
              {
                field: 'title', headerName: 'Name', minWidth: 100, flex: 1, maxWidth: 300,
                renderCell: (params) => {
                  const tooltip = params.row.title;
                  return (
                    <Tooltip title={`Name: ${tooltip}`} placement="top">
                      <div>
                        {tooltip}
                      </div>
                    </Tooltip>
                  )
                }

              },
              // {
              //   field: 'description',
              //   headerName: 'Description',
              //   minWidth: 200,
              //   flex: 1,
              // },
              {
                field: 'active',
                headerName: 'Active',
                minWidth: 80,
                maxWidth: 80,
                flex: 1,
                renderCell: (params) => {
                  return (
                    <Chip
                      label={params.row.active ? 'Yes' : 'No'}
                      color={params.row.active ? 'success' : 'error'}
                    />
                  );
                },
              },
              {
                field: 'allergies',
                headerName: 'Dislikes',
                cellClassName: 'goalsList',
                width: 300,
                maxWidth: 300,
                flex: 1,
                renderCell: (params) => {
                  const allergiesTooltip = params.row.allergies.join(', ');
                  return (
                    <Tooltip title={`Allergies: ${allergiesTooltip}`} placement="top">

                      <div>
                        {params.row.allergies.map((allergy: string, idx: number) => (
                          <Chip
                            key={`ingredient-${params.row.id}-allergy-${idx}`}
                            label={allergy}
                          />
                        ))}
                      </div>
                    </Tooltip>
                  )
                }

              },
              {
                field: 'diets',
                headerName: 'Goals',
                cellClassName: 'goalsList',
                maxWidth: 300,
                flex: 1,
                renderCell: (params) => {
                  const diets = params.row.diets.join(', '); // Join diets with commas
                  return (
                    <Tooltip title={`Diets: ${diets}`} placement="top">
                      <div>
                        {(params.row.diets as string[]).map((diet: string, idx: number) => (
                          <Chip
                            key={`ingredient-${params.row.id}-diet-${idx}`}
                            label={diet}
                          />
                        ))}
                      </div>
                    </Tooltip>
                  );
                },
              },
              {
                field: 'ingredients',
                headerName: 'Ingredients',
                width: 300,
                renderCell: (params) => {
                  if (params.row.ingredients) {
                    const ingredientsTooltip = params.row.ingredients.map((ingredient1: Ingredient) => ingredient1.title).join(', ');
                    return (
                      <Tooltip title={`Ingredients: ${ingredientsTooltip}`} placement="top">

                        <div>
                          {params.row.ingredients.map((ingredient1: Ingredient, idx: number) => (
                            <Chip
                              key={`ingredient-${params.row.id}-ingredient-${idx}`}
                              label={ingredient1.title}
                            />
                          ))}
                        </div>
                      </Tooltip>
                    )
                  }
                }
              },
              // { field: 'foodGroup', maxWidth: 100, headerName: 'Food Groups' ,
              // renderCell: (params) => {
              //   const tooltip = params.row.foodGroup;
              //   return (
              //     <Tooltip title={`Food Groups: ${tooltip}`} placement="top">
              //       <div>
              //         {tooltip}
              //       </div>
              //     </Tooltip>
              //   )
              // }},
              {
                field: 'actions',
                headerName: 'Actions',
                minWidth: 120,
                maxWidth: 120,

                flex: 1,
                renderCell: (params) => {
                  return (
                    <TableActions
                      handleEdit={() => {
                        setIngredient(params.row);
                        setIsEdit(true);
                        setIngredientDialogIsOpen(true);
                      }}
                      handleDelete={() => {
                        setIngredient(params.row);
                        setDeleteDialogIsOpen(true);
                      }}
                      handleDuplicate={() => {
                        setIngredient(params.row);
                        setDuplicateIngredientDialogIsOpen(true);
                      }}
                    />
                  );
                },
              },
            ]}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
        </Box>
      </Box>
      <IngredientDialog
        isOpen={ingredientDialogIsOpen}
        saveDisabled={
          addIngredientMutation.isLoading || editIngredientMutation.isLoading
        }
        ingredient={ingredient}
        isEdit={isEdit}
        handleCloseDialog={() => {
          setIngredientDialogIsOpen(false);
        }}
        handleSave={async (ingredient: Ingredient) => {
          const result = isEdit
            ? await editIngredientMutation.mutateAsync(ingredient)
            : await addIngredientMutation.mutateAsync(ingredient);

          if (!isEdit) {
            setIngredient(result);
            setIsEdit(true);
          }
        }}
      />
      <DeleteResourceDialog
        isOpen={deleteDialogIsOpen}
        handleClose={() => setDeleteDialogIsOpen(false)}
        title={`Delete ${ingredient?.title || 'Ingredient'}?`}
        body={
          'This is an irreversible action. Are you sure you wish to delete this ingredient?'
        }
        handleDelete={async () => {
          await deleteIngredientMutation.mutateAsync(ingredient);
          setIngredient(newIngredient);
          setDeleteDialogIsOpen(false);
        }}
        disabled={deleteIngredientMutation.isLoading}
      />
      <ActionDialog
        isOpen={duplicateIngredientDialogIsOpen}
        handleClose={() => setDuplicateIngredientDialogIsOpen(false)}
        title={`Copy ${ingredient?.title || 'Ingredient'}`}
        body={`Are you sure you wish to duplicate ${ingredient?.title
          ? `the Ingredient ${ingredient?.title}`
          : 'this menu item'
          }?`}
        handleSubmit={async () => {
          const result =await duplicateIngredientMutation.mutateAsync(ingredient);
          setIngredient(result);
          setDuplicateIngredientDialogIsOpen(false);
        }}
        disabled={duplicateIngredientMutation.isLoading}
      />
    </>
  );
};

export default Ingredients;
