import MoreVertIcon from '@mui/icons-material/MoreVert'; //TODO: figure out if we just need to import certain icons
import { Box, Card, CardActionArea, CardContent, CardMedia, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router';

import { Restaurant, RestaurantStatus } from 'interfaces/ResturantInterface';
import { RESTAURANTS } from 'Utils/constants/routes';
import trimText from 'Utils/helpers/trimString';
import DEFAULT_IMAGE from '../../images/theapplepan.png'; // TODO: clean up this naming

const MAX_SUBTEXT_LENGTH = 25;

type PropsType = {
    restaurant: Restaurant;
    handleDeleteRestaurant: (restaurant: Restaurant) => void;
    handleCopyRestaurant:(restaurant: Restaurant) => void;
}

const RestaurantCard = ({restaurant, handleDeleteRestaurant,handleCopyRestaurant}: PropsType) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  
  const handleOpenMenu = (event: any /*React.MouseEvent<HTMLElement>*/) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const navigateToRestaurantPage = () => {
    navigate(`${RESTAURANTS}/${restaurant.id}`);
  }

  const navigateToRestaurantExplorePage = () => {
    window.open(`/explore/${restaurant.urlSlug || restaurant.id}`, '_blank');
  }

  const navigateToRestaurantEditPage = () => {
    navigate(`${RESTAURANTS}/${restaurant.id}/edit`);
  }

  const handleDeleteRestaurantClick = () => {
    setAnchorEl(null);
    handleDeleteRestaurant(restaurant);
  }
  const handleCopyRestaurantClick = () => {
    setAnchorEl(null);
    handleCopyRestaurant(restaurant);
  }

  return (
    <Box mr={4} mb={4}>
      <Card 
        sx={{
          width: 345
        }}
      >
        <CardActionArea 
          onClick={restaurant.status === RestaurantStatus.INCOMPLETE ? navigateToRestaurantEditPage : navigateToRestaurantPage}
        >
          <CardMedia 
            component="img"
            height="194"
            image={restaurant.imageUrl || (restaurant.imageUrls && restaurant.imageUrls[0]) || DEFAULT_IMAGE}
            alt={restaurant.name}
            sx={{
              objectFit: 'contain',
              filter: restaurant.status === RestaurantStatus.INCOMPLETE ? 'grayscale(100%)' : undefined,
            }}
          />
        </CardActionArea>
        <CardContent>
          <Grid container>
            <Grid item xs={11}>
              <Typography fontWeight={'bold'}>{trimText(restaurant.name, MAX_SUBTEXT_LENGTH)}</Typography>
              {restaurant.status === RestaurantStatus.INCOMPLETE && <Typography>(INCOMPLETE)</Typography>}
              {restaurant.address && <Typography variant="body2" color="text.secondary">
                {trimText(restaurant.address, MAX_SUBTEXT_LENGTH)}</Typography>}
            </Grid>
            <Grid item xs={1}>
              <IconButton aria-label="settings" onClick={handleOpenMenu}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                id={`restuarant-${restaurant.id}-menu`}
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem onClick={handleCopyRestaurantClick}>Copy</MenuItem>
                <MenuItem onClick={handleDeleteRestaurantClick}>Delete</MenuItem>
                <MenuItem onClick={navigateToRestaurantEditPage}>Edit</MenuItem>
                {restaurant.status !== RestaurantStatus.INCOMPLETE && <MenuItem onClick={navigateToRestaurantExplorePage}>Explore</MenuItem>}
                {restaurant.status !== RestaurantStatus.INCOMPLETE && <MenuItem onClick={navigateToRestaurantPage}>Profile</MenuItem>}
              </Menu>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  )
}

export default RestaurantCard;