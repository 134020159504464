import { Box } from '@mui/material';
import { ReactElement } from 'react';

interface ITabPanel {
  id: string;
  children: ReactElement;
  value: number;
  index: number;
}

export default function TabPanel({ id, children, value, index }: ITabPanel) {
  return (
    <Box hidden={value !== index} id={id}>
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}
