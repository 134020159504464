import { BaseSyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router';
import { useQuery } from "react-query";
import { Box, Button, Grid, Modal, styled, TextField, Typography } from '@mui/material';
import WestIcon from '@mui/icons-material/West';
import ErrorAlert from 'components/ErrorAlert';
import { useApi } from 'providers/ApiProvider';
import { useMutationQuery } from 'providers/MutationQueryProvider';
import MenuCategoriesSidebar from 'components/MenuCategoriesSidebar';
import CustomButton from 'components/CustomButton';
import CustomTypography from 'components/Typography';
import MenuSubsectionItems from 'components/MenuSubsectionItems';

import LoadingPage from 'components/LoadingPage';
// import MenuSubsectionDialog from './MenuSubsectionDialog';
import { MenuSubsection, MenuItem } from 'interfaces/MenuInterface';
import DeleteResourceDialog from 'components/DeleteResourceDialog';
import { genreateMenuIdQuery, generateMenuItemsQuery } from 'Utils/helpers/queryGenerators';
import MenuItemCard from 'components/MenuItemCard';
import MenuItemDialog from 'components/MenuSubsectionItems/MenuItemDialog';

const MenuDetailsPage = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const MenuDetailsHeader = styled(Box)({
  height: '50px',
  background: '#FFFFFF',
  boxShadow: 'inset 0px -1px 0px #E9E9E9',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '12px 24px',
});

const MenuDetailsContent = styled(Box)({
  position: 'absolute',
  minHeight: 'calc(100% - 74px);',
  marginTop: '74px',
  width: '100%',
  display: 'flex',
});


const MenuAutoAssignIngredients = () => {
    const {
        editMenuMutation,
        addMenuItemMutation,
        editMenuItemMutation,
        // deleteMenuItemMutation,
        // duplicateMenuItemMutation,
        generateMenuItemIngredientsMutation,
      } = useMutationQuery();
    const [menuItem, setMenuItem] = useState<MenuItem>();
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [menuItemDialogIsOpen, setMenuItemDialogIsOpen] = useState<boolean>(false);
  const [selectedSubsectionIdx, setSelectedSubsectionIdx] = useState<any>();
  const [selectedSubsection, setSelectedSubsection] = useState<MenuSubsection>();
  const navigate = useNavigate();
  const [restaurantId, setRestaurantId] = useState("");
  const [error, setError] = useState('');
  const [disableSave, setDisableSave] = useState(false);
  const [isInProgress, setIsInProgress] = useState(false);
  const [scheduledMenuItems, setScheduledMenuItems] = useState<MenuItem[]>([]);
  const [inProgressMenuItems, setInProgressMenuItems] = useState<MenuItem[]>([]);
  const [processedMenuItems, setProcessedMenuItems] = useState<MenuItem[]>([]);
  const [errorProcessedMenuItems, setErrorProcessedMenuItems] = useState<MenuItem[]>([]);
  const [menuItemsLoaded, setMenuItemsLoaded] = useState(false);
  const api = useApi();
  const urlParams = useParams();
  const { status: _menuStatus, error: _menuError, data: menu, isLoading: _isLoadingMenu, refetch } = useQuery(genreateMenuIdQuery(urlParams.menuId), async () => {
    return api.getMenuById(urlParams.menuId);
  });
  const { status: _menuItemsStatus, error: _menuItemsError, data: menuItems, isLoading: _isLoadingMenuItems } = useQuery(generateMenuItemsQuery(urlParams.menuId), async () => {
    return api.getMenuItemsByMenuId(urlParams.menuId);
  });

  const isLoading = !(menu && menuItems)

  useEffect(() => {
    if (menu) {
      setRestaurantId(menu.restaurantId);
      if(window.location.hash) {
        setSelectedSubsectionIdx(window.location.hash.replace("#", ""));
      } else {
        setSelectedSubsectionIdx(0);
      }
    }
  }, [menu]);

  useEffect(() => {
    if (menuItems && menuItemsLoaded === false) {
      setScheduledMenuItems([...menuItems]);
        // setScheduledMenuItems(menuItems.slice(0, 2));
        setMenuItemsLoaded(true);
    }
  }, [menuItems]);

  useEffect(() => {
    if(window.location.hash) {
      setSelectedSubsectionIdx(window.location.hash.replace("#", ""));
    }
  }, [window.location.hash])

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setTimeout(()=> {
      setDisableSave(false);
    }, 2000);
  }, [disableSave]);

  const autotGenerateIngredients = async (menuItems: MenuItem[]) => {
    setError('');
    try {
        const successResults: MenuItem[] = [];
        const errorResults: MenuItem[] = [];
        
        const results = await Promise.allSettled(menuItems.map(generateMenuItemIngredientsMutation.mutateAsync));
        
        results.forEach((result, idx) => {
            if (result.status === 'fulfilled') {
                successResults.push(result.value as MenuItem);
            } else {
                errorResults.push(menuItems[idx]);
            }
        });

        // const savedSuccessResults: MenuItem[] = await Promise.all(successResults.map(editMenuItemMutation.mutateAsync));
        
        setInProgressMenuItems([]);
        setProcessedMenuItems([...processedMenuItems, ...successResults]);
        setErrorProcessedMenuItems([...errorProcessedMenuItems, ...errorResults]);
    } catch (e:any) {
        setError(e.error)
    }
  }
  useEffect(() => {
    if (inProgressMenuItems.length) {
        autotGenerateIngredients(inProgressMenuItems);
    }
  }, [inProgressMenuItems]);

  useEffect(() => {
    if (scheduledMenuItems.length) {

        handleAutoGenerateIngredients();
    } else {
        setIsInProgress(false);
    }
  }, [processedMenuItems]);

  const handleSaveMenu = async () => {
    setError('');
    setDisableSave(true);
    try {
        await Promise.all(processedMenuItems.map(editMenuItemMutation.mutateAsync));
        setDisableSave(false);
    } catch (e:any) {
      setError(e.error)
    }
  };

  const handleAutoGenerateIngredients = async () => {
    setIsInProgress(true);
    // Keeping splice for now incase we want to parallelize the process later
    const items = scheduledMenuItems.splice(0, 1);

    if (items.length) {
        setScheduledMenuItems([...scheduledMenuItems]);
        setInProgressMenuItems(items);
    } else {
        setIsInProgress(false);
    }
  };

 const sidebar = {
   display: {
     lg: 'block',
     xs: 'none',
   },
   };

   const sidebarMobile = {
   display: {
     sm: 'block',
   },
   position:'absolute',
   top:185,
   left:0,
   zIndex:10
  }

  return isLoading ? (
    <LoadingPage />
  ) : (
    <>
      <MenuDetailsPage>
        <MenuDetailsHeader>
          <Button onClick={() => restaurantId ? navigate("/restaurants/".concat(restaurantId)) : navigate(-1)}>
            <WestIcon />
          </Button>
          <CustomTypography
            fontWeight='600'
            fontSize='22px'
            lineHeight='24px'
            color='#000000'
          >
            {menu.name}
          </CustomTypography>
          {error && <ErrorAlert error={error} />}
          <CustomButton
            onClick={() => navigate(-1)}
            backgroundColor='#ffffff'
            border='1px solid #d8d8d8'
            margin='0 16px 0 auto'
            width='102px'
          >
            Cancel
          </CustomButton>
          <CustomButton
            onClick={handleAutoGenerateIngredients}
            disabled={isInProgress}
          >
            Start
          </CustomButton>
          <CustomButton
            onClick={handleSaveMenu}
            disabled={disableSave || isInProgress}
          >
            {disableSave ? "Saved!" : "Save Menu"}
          </CustomButton>
        </MenuDetailsHeader>
        {/* <MenuDetailsContent> */}
          <Box sx={sidebar}>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    Menu Items

                    {scheduledMenuItems.map((menuItem: MenuItem) => {
                        return (
                            <MenuItemCard 

                            key={menuItem.id}
                            item={menuItem}
                        
                            handleRemoveMenuItem={() => {
                                console.log('Delete menu item with ID: ', menuItem.id);
                            }}
                            handleEditMenuItem={() => {
                                setIsEdit(true);
                                setMenuItem(menuItem);
                                setMenuItemDialogIsOpen(true);
                            }}
                            handleDuplicateMenuItem={() => {
                                console.log('Edit menu item with ID: ', menuItem.id);
                            }}
                            handleDeleteMenuItem={() => {
                                console.log('delete menu item with id', menuItem.id);
                            }}
                            handleGenerateRawIngredients={async() => {
                                console.log('Edit menu item with ID: ', menuItem.id);
                            }}
                            />
                        )
                    })}
                </Grid>
                <Grid item xs={3}>
                    In Progress

                    {inProgressMenuItems.map((menuItem: MenuItem) => {
                        return (
                            <MenuItemCard 

                            key={menuItem.id}
                            item={menuItem}
                        
                            handleRemoveMenuItem={() => {
                                console.log('Delete menu item with ID: ', menuItem.id);
                            }}
                            handleEditMenuItem={() => {
                                console.log('Edit menu item with ID: ', menuItem.id);
                            }}
                            handleDuplicateMenuItem={() => {
                                console.log('Edit menu item with ID: ', menuItem.id);
                            }}
                            handleDeleteMenuItem={() => {
                                console.log('delete menu item with id', menuItem.id);
                            }}
                            handleGenerateRawIngredients={async() => {
                                console.log('Edit menu item with ID: ', menuItem.id);
                            }}
                            />
                        )
                    })}
                </Grid>
                <Grid item xs={3}>
                    Completed

                    {processedMenuItems.map((menuItem: MenuItem) => {
                        return (
                            <MenuItemCard 

                            key={menuItem.id}
                            item={menuItem}
                        
                            handleRemoveMenuItem={() => {
                                console.log('Delete menu item with ID: ', menuItem.id);
                            }}
                            handleEditMenuItem={() => {
                                setIsEdit(true);
                                setMenuItem(menuItem);
                                setMenuItemDialogIsOpen(true);
                            }}
                            handleDuplicateMenuItem={() => {
                                console.log('Edit menu item with ID: ', menuItem.id);
                            }}
                            handleDeleteMenuItem={() => {
                                console.log('delete menu item with id', menuItem.id);
                            }}
                            handleGenerateRawIngredients={async() => {
                              // console.log('Edit menu item with ID: ', menuItem.id);
                              const result = await generateMenuItemIngredientsMutation.mutateAsync(menuItem);
                              // const savedResult = await editMenuItemMutation.mutateAsync(result);  
                          }}
                            />
                        )
                    })}
                </Grid>
                <Grid item xs={3}>
                    Error

                    {errorProcessedMenuItems.map((menuItem: MenuItem) => {
                        return (
                            <MenuItemCard 

                            key={menuItem.id}
                            item={menuItem}
                        
                            handleRemoveMenuItem={() => {
                                console.log('Delete menu item with ID: ', menuItem.id);
                            }}
                            handleEditMenuItem={() => {
                                console.log('Edit menu item with ID: ', menuItem.id);
                            }}
                            handleDuplicateMenuItem={() => {
                                console.log('Edit menu item with ID: ', menuItem.id);
                            }}
                            handleDeleteMenuItem={() => {
                                console.log('delete menu item with id', menuItem.id);
                            }}
                            handleGenerateRawIngredients={async() => {
                                console.log('Edit menu item with ID: ', menuItem.id);
                            }}
                            />
                        )
                    })}
                </Grid>
            </Grid>
            {menuItem && (
                <MenuItemDialog
                isOpen={menuItemDialogIsOpen}
                saveDisabled={
                    editMenuItemMutation.isLoading
                }
                canAutoSave={false}
                menuItem={menuItem}
                subsection={selectedSubsection?.name}
                isEdit={isEdit}
                handleCloseDialog={() => {
                    setMenuItemDialogIsOpen(false);
                }}
                handleSave={async (menuItem: MenuItem) => {
                    const _menuItem = {
                        ...menuItem,
                        price: parseFloat(menuItem.price.toString()),
                    };
                    
                    const result = await editMenuItemMutation.mutateAsync(_menuItem);

                    if (!isEdit) {
                        setMenuItem(result);
                        setIsEdit(true);
                    }

                }}
                />
            )}
          </Box>
        {/* </MenuDetailsContent> */}
        
        {/* </MenuDetailsContent> */}
      </MenuDetailsPage>
    </>
  );
};

export default MenuAutoAssignIngredients;
