import { Box, IconButton, styled } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import CustomTypography from 'components/Typography';
import OptionsMenu from 'components/OptionsMenu';
import { MenuSubsection } from 'interfaces/MenuInterface';

export const StyledTab = styled(Box)({
  background: '#ffffff',
  mixBlendMode: 'normal',
  padding: '19px 21px',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const SubsectionTab = ({
  subsection,
  selected,
  onClick,
  handleEditCategory,
  handleDeleteCategory,
}: {
  subsection: MenuSubsection;
  selected: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  handleEditCategory: (category: MenuSubsection) => void;
  handleDeleteCategory: (category: MenuSubsection) => void;
}) => (
  <StyledTab
    sx={
      selected
        ? {
            background: 'rgb(16, 134, 128, 0.04)',
            borderRadius: '8px',
          }
        : {}
    }
    onClick={onClick}
  >
    <CustomTypography
      fontWeight={selected ? '600' : 'normal'}
      fontSize='16px'
      lineHeight='20px'
      color={selected ? '#108680' : '#626262'}
    >
      {subsection.name}
    </CustomTypography>
    <OptionsMenu
      id='menu-subsection-options'
      buttonIcon={<MoreVertIcon />}
      options={[
        {
          label: 'Rename Subsection',
          onClick: () => handleEditCategory(subsection),
        },
        {
          label: 'Delete Subsection',
          onClick: () => handleDeleteCategory(subsection),
        },
      ]}
    />
  </StyledTab>
);

export default SubsectionTab;
