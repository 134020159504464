import { Button } from '@mui/material';
import Typography from 'components/Typography';
import plusIcon from 'images/restaurants/details/menu-tab/plus-icon.svg';

interface IButtonAddNew {
  label: string;
  onClick: () => void;
  styles?: Record<string, string>;
}
const ButtonAddNew = ({ label, onClick, styles }: IButtonAddNew) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        padding: '15px 24px',
        height: '40px',
        minWidth:'100px',
        backgroundColor: '#ffbf30',
        borderRadius: '8px',
        border: 'none',
        textTransform: 'none',
        color:'black',
        '&:hover': {
          backgroundColor: '#ffbf30',
        },
        ...styles,
      }}
      startIcon={<img src={plusIcon} alt='plus icon' />}
    >
       <Typography variant="subtitle2">
        {label}
      </Typography>
    </Button>
  );
};

export default ButtonAddNew;
