import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import { JSXElementConstructor, ReactElement } from 'react';

interface ICustomTab {
  label: string;
  selectedFontWeight: string;
  icon?:
    | string
    | ReactElement<any, string | JSXElementConstructor<any>>
    | undefined;
  iconPosition?: 'bottom' | 'top' | 'end' | 'start' | undefined;
  onClick?: () => void;
  tabStyles?: Record<string, unknown>;
}

const CustomTab = styled(
  ({ selectedFontWeight, tabStyles, ...props }: ICustomTab) => (
    <Tab
      disableRipple
      {...props}
      sx={{
        pl: '1rem' ,
        '&.MuiTab-root': {
          ...tabStyles,
        },
        '&.Mui-selected': {
          fontWeight: selectedFontWeight,
        },
      }}
    />
  )
)(() => ({
  minHeight: '48px',
  textTransform: 'none',
  minWidth: 0,
  fontWeight: '400',
  color: '#969696',
  fontFamily: 'Graphik',
  fontStyle: 'normal',
  letterSpacing: '-0.015em',
  alignItems: 'center',
  '&.Mui-selected': {
    color: '#131313',
  },
}));

export default CustomTab;
