//COMMON ROUTES//
export const DASHBOARD = '/dashboard';
export const ACCOUNT_SETTINGS = '/account-settings';
export const SIGN_IN = '/sign-in';
export const SIGN_UP = '/sign-up';
export const RESET_LINK_SENT = '/reset-link-sent';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password';
export const FINISH_SIGN_UP = '/finish-sign-up';

//PUBLIC FACING RESTAURANTS/MENU ROUTES
export const EXPLORE_RESTAURANT_ID = '/explore/:restaurantId';
export const EXPLORE_RESTAURANT_ONBOARDING = '/explore/:restaurantId/onboarding';
export const EXPLORE_TNC = '/tnc';
export const EXPLORE_RESTAURANT_MENU = '/explore/:restaurantId/menu';
export const EXPLORE_RESTAURANT_CART = '/explore/:restaurantId/cart/:id';
export const EXPLORE_RESTAURANT_ORDER = '/explore/:restaurantId/order/:id';

//MERCHANT PORTAL ROUTES//
export const RESTAURANTS = '/restaurants';
export const RESTAURANT_DETAILS = '/restaurants/:restaurantId';
export const RESTAURANT_NEW = '/restaurants/new';
export const RESTAURANT_EDIT = '/restaurants/:restaurantId/edit';
export const REPORTINGS = '/reportings';
export const ORDERS = '/orders';

export const SUPPORT = '/support';
export const SET_NEW_PASSWORD = '/set-new-password';
// TODO [integration]: the route will depend on the selected restaurant and menu
export const MENU_DETAILS = '/menus/:menuId';
export const MENU_AUTO_ASSIGN_INGREDIENTS = '/menus/:menuId/auto-assign-ingredients';
export const OAUTH_AUTHORIZE = '/oauth/authorize/:provider'

//ADMIN PORTAL ROUTES//
export const MERCHANTS = '/merchants';
export const MERCHANTS_REPORTING = '/merchants/:merchantId/reportings/';
export const MERCHANT_RESTAURANTS = '/merchants/:merchantId/restaurants';
export const MERCHANT_RESTAURANT_NEW = '/merchants/:merchantId/restaurants/new';
export const MERCHANTS_ORDERS = '/merchants/:merchantId/orders/';
export const MERCHANTS_INTEGRATIONS = '/merchants/:merchantId/integrations/';
export const MERCHANTS_ACCOUNT_SETTINGS = '/merchants/:merchantId/account_setting/';
export const CREATE_CLIENT = '/merchants/new';
export const EDIT_CLIENT = '/merchants/:merchantId/edit';
export const DIETS = '/diets';
export const INGREDIENTS = '/ingredients';
export const ADMIN_RESTAURANTS = '/admin/restaurants';
export const ADMIN_ORDERS = '/admin/orders';
export const ADMIN_ORDERS_DETAIL = '/admin/orders/:orderId';
export const ALLERGIES = '/allergies';
export const GOALS = '/goals';
export const ADMIN_GLOBAL_SETTINGS = '/admin/globalsettings';