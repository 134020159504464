import { Outlet } from 'react-router';
import Sidebar from '../../../components/Sidebar';
import { useParams } from 'react-router-dom';
import './index.css';

const Dashboard = () => {
  const { res_name } = useParams();

  return (
    <div className='dashboard-root'>
      <Sidebar sidebarType={ res_name === undefined ? 'Main' : 'Client'} />
      <div className='dashboard-content'>
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
