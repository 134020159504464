import { Box, Chip, Stack, Typography } from "@mui/material";
import { FastField } from "formik";
import { useDietaryPreferences } from 'providers/DietaryPreferenceProvider';
import { removeDuplicates } from '../Utils/helpers/removeDuplicates';
import { Ingredient } from "interfaces/IngredientInterface";

type PropsType = {
    ingredients: Ingredient[];
    title?: string;
};

const IngredientsPillDisplay = ({ ingredients, title }: PropsType) => {
    return <Box width={'100%'}>
        <Typography>{title || 'Ingredients'}</Typography>
        <FastField
            name={title ? 'ingredients' : title?.toLowerCase().split(' ').join('_')}
            type='text'
        >
            {() => (
                <Stack direction="row" spacing={1}>
                    {ingredients.map((ingredient) => (
                        <Chip
                            key={ingredient.id}
                            label={ingredient.title}
                        />
                    ))}
                </Stack>
            )}
        </FastField>
    </Box>
};

export default IngredientsPillDisplay;