import {
    Typography,
    Dialog,
    Container,
    Avatar,
    TextField,
    Divider,
    Box
} from '@mui/material';
import { useState } from 'react';
import {PrimaryActionButton} from '../components/PrimaryActionButton';
import closeIcon from 'images/close.svg';
import { InstructionForm } from './index.styles';
import CustomTextField from 'components/CustomTextField';

interface Props {
	instruction: string,
	saveInstruction: (val:string) => void,
  isEditable: boolean
}

const closeIconProperties = {
    height: '30px', 
    width: '30px', 
    position: "absolute",
    top: '10px', 
    right: '10px', 
    cursor: 'pointer'
  };

const AddInstructionsPopup = (props:any) => {
	const [instruction, setInstruction] = useState(props.instruction);
	return(
	<Box sx={{width:'100%'}}>
      <Avatar src={closeIcon} sx={closeIconProperties} onClick={() => props.onClose()} />
      <InstructionForm>
      <Typography>Add Instruction</Typography>
      <TextField
          label='Instruction'
          size='small'
          sx={{width: '100%', mt:1}}
          value={instruction}
          onChange={(e) => setInstruction(e.target.value)}
        />
        <Box mt={3} textAlign='center'>
        <PrimaryActionButton title={'Save'} onClick={() => props.saveInstruction(instruction)} />
        </Box>
      </InstructionForm>
    </Box>
	)
}

const AddSpecialInstructions = ({instruction, saveInstruction, isEditable}: Props) => {
	const [dialog, setDialog] = useState(false);
    return (
      <>
      <Typography onClick={() => setDialog(isEditable ? true: false)} variant='body2'>{instruction ? "Instruction: ".concat(instruction) : (isEditable ? 'Add Special Instructions' : 'No Instructions')}</Typography>
      <Dialog fullWidth open={dialog} onClose={() => setDialog(false)}>
        <AddInstructionsPopup onClose={() => setDialog(false)} instruction={instruction} saveInstruction={(val:string) => {saveInstruction(val); setDialog(false)}} />
      </Dialog>
    </>
    );
  };
  
export default AddSpecialInstructions;