import { styled } from '@mui/material';

export const StyledMenuFormSidebar = styled('div')({
  height: '100vh',
  width: '534px',
  overflow: 'auto',
  position: 'fixed',
  right: '0',
  top: '0',
  background: '#ffffff',
  boxShadow: '-5px 0px 24px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '44px',
  '@media (max-width: 768px)': {
    width: '100%',
  },
});
