import * as yup from "yup";

export const RestaurantInformationValidation = yup.object({
    name: yup
        .string()
        .required('Restaurant Name is required'),
    address: yup
        .string()
        .required('Restaurant Address is required'),
    urlSlug: yup
        .string()
        .required('Restaurant Url Slug is required'),
    phoneNumber: yup
        .string()
        .required('Phone Number is required')
        .max(12, 'Max lenth upto 12 digits'),
    contactPhoneNumber: yup
        .string()
        .required('Phone Number is required')
        .max(12, 'Max lenth upto 12 digits'),
    email: yup
        .string()
        .email('Pleaes enter a valid email address')
        .required('Contact Email is required'),
    contactName: yup
        .string()
        .required('Contact Name is required'),
    externalId: yup
        .string()
        .required('External ID from the integration platform is required')
});

//TODO: figure out how to validate days and hours
export const RestaurantOperatingHoursValidation = yup.object({
    operatingHours: yup
        .object({ 
            openAt: yup.string(), //TODO: write regex to match {00 - 23}:{00 - 59} 
            closeAt: yup.string(), 
            isOpen: yup.boolean(), 
        })
});

