import { useState } from 'react';

import { Box, Chip, Tooltip } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import ButtonAddNew from 'components/ButtonAddNew';
import DeleteResourceDialog from 'components/DeleteResourceDialog';
import TableActions from 'components/TableActions';
import CustomTypography from 'components/Typography';
import { useMutationQuery } from 'providers/MutationQueryProvider';
import { TabHeader } from '../MenuTab/index.styles';
import CustomIngredientDialog from './CustomIngredientDialog';

import ActionDialog from 'components/ActionDialog';
import { CustomIngredient } from 'interfaces/CustomIngredientInterface';
import { Ingredient } from 'interfaces/IngredientInterface';
import { useNavigate } from 'react-router-dom';

type CustomIngredientTabPropsType = {
  restaurantId: string;
  merchantId :string;
  customIngredients: CustomIngredient[];
}

const CustomIngredientsTab = ({ restaurantId, merchantId, customIngredients }: CustomIngredientTabPropsType) => {
  const newCustomIngredient: CustomIngredient = {
    restaurantId: restaurantId,
    merchantId: merchantId,
    title: '',
    description: '',
    active: true,
    ingredients: [],
    allergies: [],
    diets: [],
    isRestaurantOnly: false,
  };
  const { addCustomIngredientMutation, deleteCustomIngredientMutation, editCustomIngredientMutation, duplicateCustomIngredientMutation } = useMutationQuery();
  const [customIngredientDialogIsOpen, setCustomIngredientDialogIsOpen] = useState(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const [duplicateIngredientDialogIsOpen, setDuplicateIngredientDialogIsOpen] = useState(false);
  const [customIngredient, setCustomIngredient] = useState<CustomIngredient>(newCustomIngredient);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          padding: {
            lg: '32px 94px 0 94px',
            xs: '20px',
          },
        }}
      >
        <TabHeader>
          <CustomTypography
            fontWeight='bold'
            fontSize='24px'
            lineHeight='26px'
            marginBottom='37px'
          >
            Custom Ingredients
          </CustomTypography>
          <ButtonAddNew
            label='Add New'
            styles={{ marginTop: '-8px', marginRight: '3px' }}
            onClick={() => {
             
              setCustomIngredient(newCustomIngredient);
              setIsEdit(false);
              setCustomIngredientDialogIsOpen(true);
            }}
          />
        </TabHeader>
        <Box sx={{ backgroundColor: 'white', height: 650 }}>
          <DataGrid
            showCellRightBorder={true}
            rows={customIngredients}
            columns={[
              { field: 'title', headerName: 'Name', width:150 , maxWidth:200,
              renderCell: (params) => {
                const tooltip = params.row.title;
                return (
                  <Tooltip title={`Name: ${tooltip}`} placement="top">
                    <div>
                      {tooltip}
                    </div>
                  </Tooltip>
                )
              } },
              // { field: 'description', headerName: 'Description', width: 250 },
              {
                field: 'active',
                headerName: 'Active',
                minWidth: 80,
                maxWidth: 80,
                flex: 1,
                renderCell: (params) => {
                  return (
                    <Chip
                      label={params.row.active ? 'Yes' : 'No'}
                      color={params.row.active ? 'success' : 'error'}
                    />
                  );
                },
              },
              {
                field: 'allergies',
                headerName: 'Dislikes',
                cellClassName: 'goalsList',
                maxWidth: 300, width:300,
                flex: 1,
                renderCell: (params) =>{
                  const allergiesTooltip = params.row.allergies.join(', ');
                  return (
                    <Tooltip title={`Dislikes: ${allergiesTooltip}`} placement="top">
                     
                      <div>
                      {params.row.allergies.map((allergy:string, idx:number) => (
                        <Chip
                          key={`ingredient-${params.row.id}-allergy-${idx}`}
                          label={allergy}
                        />
                      ))}
                    </div>
                  </Tooltip>
                  )
                }
                
              },
              {
                field: 'diets',
                headerName: 'Preferences',
                cellClassName: 'goalsList',
                maxWidth: 300, width:300,
                flex: 1,
                renderCell: (params) => {
                  const diets = params.row.diets.join(', '); // Join diets with commas
                  return (
                    <Tooltip title={`Preferences: ${diets}`} placement="top">
                      <div>
                        {(params.row.diets as string[]).map((diet:string , idx:number) => (
                          <Chip
                            key={`ingredient-${params.row.id}-diet-${idx}`}
                            label={diet}
                          />
                        ))}
                      </div>
                    </Tooltip>
                  );
                },
              },
              {
                field: 'ingredients',
                headerName: 'Ingredients',
                maxWidth: 300, width:300,
                renderCell: (params) =>{
                  const ingredientsTooltip = params.row.ingredients.map(ingredient => ingredient.title).join(', ');
                  return (
                    <Tooltip title={`Ingredients: ${ingredientsTooltip}`} placement="top">
                     
                      <div>
                      {params.row.ingredients.map((ingredient: Ingredient, idx:number) => (
                        <Chip
                          key={`ingredient-${params.row.id}-ingredient-${idx}`}
                          label={ingredient.title}
                        />
                      ))}
                    </div>
                  </Tooltip>
                  )
                }
               
              },
              {
                field: 'actions',
                headerName: 'Actions',
                maxWidth: 100,
                renderCell: (params) => {
                  return (
                    <TableActions
                      handleEdit={() => {
                        setCustomIngredient(params.row);
                        setIsEdit(true);
                        setCustomIngredientDialogIsOpen(true);
                      }}
                      handleDelete={() => {
                        setCustomIngredient(params.row);
                        setDeleteDialogIsOpen(true);
                      }}
                      handleDuplicate={() => {
                        setCustomIngredient(params.row);
                        setDuplicateIngredientDialogIsOpen(true);
                      }}
                      
                    />
                  );
                },
              },
            ]}
            pageSize={10}
            rowsPerPageOptions={[10]}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
        </Box>
      </Box>
      <CustomIngredientDialog
        isOpen={customIngredientDialogIsOpen}
        saveDisabled={
          addCustomIngredientMutation.isLoading ||
          editCustomIngredientMutation.isLoading
        }
        customIngredient={customIngredient}
        isEdit={isEdit}
        handleCloseDialog={() => {
          setCustomIngredientDialogIsOpen(false);
        }}
        handleSave={async (customIngredient: CustomIngredient) => {
          const result = isEdit
            ? await editCustomIngredientMutation.mutateAsync(customIngredient)
            : await addCustomIngredientMutation.mutateAsync(customIngredient);

          if (!isEdit) {
            setCustomIngredient(result);
            setIsEdit(true);
          }
        }}
      />
      <DeleteResourceDialog
        isOpen={deleteDialogIsOpen}
        handleClose={() => setDeleteDialogIsOpen(false)}
        title={`Delete ${customIngredient?.title || 'Custom Ingredient'}?`}
        body={
          'This is an irreversible action. Are you sure you wish to delete this custom ingredient?'
        }
        handleDelete={async () => {
          await deleteCustomIngredientMutation.mutateAsync(customIngredient);
          setCustomIngredient(newCustomIngredient);
          setDeleteDialogIsOpen(false);
        }}
        disabled={deleteCustomIngredientMutation.isLoading}
      />
      <ActionDialog
        isOpen={duplicateIngredientDialogIsOpen}
        handleClose={() => setDuplicateIngredientDialogIsOpen(false)}
        title={`Copy ${customIngredient?.title || 'Menu Item'}`}
        body={`Are you sure you wish to duplicate ${customIngredient?.title
          ? `the Custom Ingredient ${customIngredient?.title}`
          : 'this menu item'
          }?`}
        handleSubmit={async () => {
          const result = await duplicateCustomIngredientMutation.mutateAsync(customIngredient);
          setCustomIngredient(result);
          setDuplicateIngredientDialogIsOpen(false);
        }}
        disabled={duplicateCustomIngredientMutation.isLoading}
      />
    </>
  );
};

export default CustomIngredientsTab;
