import { useEffect, useState } from "react";
import { Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { useQuery } from "react-query";

import { useApi } from 'providers/ApiProvider';
import { Menu } from "interfaces/MenuInterface";
import { Restaurant } from "interfaces/ResturantInterface";
import { generateMerchantsQuery } from "Utils/helpers/queryGenerators";

import LoadingPage from 'components/LoadingPage';
import GoldiDropDownMenu from 'components/DropDown';
import { Merchant } from "interfaces/MerchantInterface";

type ImportMenuDialogPropsType = {
  isOpen: boolean;
  merchantId: string;
  handleClose: () => void;
  handleImport: (menu: Menu) => void;
}

const ImportMenuDialog = ({ isOpen, merchantId, handleClose, handleImport }: ImportMenuDialogPropsType) => {
 
    const [menus, setMenus] = useState<Menu[]>([]);
    const [restaurants, setRestaurants] = useState<Restaurant[]>([]);
    const [selectedMenu, setSelectedMenu] = useState<Menu>();
    const [selectedRestaurant, setSelectedRestaurant] = useState<Restaurant>();
    const [selectedMerchant, setSelectedMerchant] = useState<Merchant>();

    const api = useApi();
    const { status: _status, error: _error, data: merchants, isLoading: _isLoading } = useQuery(generateMerchantsQuery(), async () => {
        return api.getMerchants();
    });

    const isLoading = !merchants

    const handleSelectMerchant = async (merchant: Merchant) => {
      const restaurants = await api.getMerchantRestaurants(merchant.id);
      setRestaurants(restaurants);
    };

    const handleSelectRestaurant = async (restaurant: Restaurant) => {
        const menus = await api.getMenusByRestaurantId(restaurant.id);
        setMenus(menus);
    };

    useEffect(() => {
      if (selectedMerchant) {
          handleSelectMerchant(selectedMerchant)
      } else {
          setMenus([]);
          setSelectedMenu(undefined);
      }
  }, [selectedMerchant]);
  
    useEffect(() => {
        if (selectedRestaurant) {
            handleSelectRestaurant(selectedRestaurant)
        } else {
            setMenus([]);
            setSelectedMenu(undefined);
        }
    }, [selectedRestaurant]);

  return ( isLoading ? <LoadingPage /> : 
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="import-menu-dialog-title"
        aria-describedby="import-menu-dialog-description"
        maxWidth='sm'
        fullWidth={true}
      >
        <DialogTitle>
          Import Menu
        </DialogTitle>
        <DialogContent>
            <Typography>Select Merchant</Typography>
            <GoldiDropDownMenu 
                id={'import-menu-dialog-merchants-dropdown'}
                // value={formik.values.status}
                onChange={(e: any) => {
                    setSelectedMerchant(merchants.find((merchant: Merchant) => merchant.id === e.target.value))
                }}
                options={merchants.map((merchant: Merchant) => ({
                    key: merchant.id,
                    value: merchant.id,
                    label: merchant.businessName,
                }))}
            />
            <Typography>Select Restaurant</Typography>
            <GoldiDropDownMenu 
                id={'import-menu-dialog-restaurants-dropdown'}
                // value={formik.values.status}
                onChange={(e: any) => {
                    setSelectedRestaurant(restaurants.find((restaurant: Restaurant) => restaurant.id === e.target.value))
                }}
                options={restaurants.map((restaurant: Restaurant) => ({
                    key: restaurant.id!,
                    value: restaurant.id!,
                    label: restaurant.name,
                }))}
            />
            <Typography>Select Menu</Typography>
            <GoldiDropDownMenu 
                id={'import-menu-dialog-menus-dropdown'}
                // value={formik.values.status}
                onChange={(e: any) => {
                    setSelectedMenu(menus.find((menu: Menu) => menu.id === e.target.value))
                }}
                options={menus.map((menu: Menu) => ({
                    key: menu.id!,
                    value: menu.id!,
                    label: menu.title,
                }))}
            />
        </DialogContent>
        <DialogActions>
          <Button sx={{mr: 2}} onClick={handleClose}>Cancel</Button>
          <Button 
            variant='contained' 
            disableElevation
            disabled={!selectedMenu}
            onClick={() => {
                handleImport(selectedMenu!)
            }}
          >
            Import
          </Button>
        </DialogActions>
      </Dialog>
  )
};

export default ImportMenuDialog