import { StyledLink } from './index.styles';

interface ICustomLink {
  children: string;
  onClick?: () => void;
  sx?: Record<string, unknown>;
}
const CustomLink = ({ children, onClick, sx }: ICustomLink) => {
  return (
    <StyledLink sx={sx} onClick={onClick} underline='none'>
      {children}
    </StyledLink>
  );
};

export default CustomLink;
