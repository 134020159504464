import { Box, IconButton, styled } from '@mui/material';

export const CategoryHeader = styled(Box)({
  height: '62px',
  background: '#FFFFFF',
  boxShadow: 'inset 0px -1px 0px #E9E9E9',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '9px 64px 9px 54px',
  '@media (max-width: 767px)': {
    flexDirection: 'column',
    padding: '20px',
    height: 'auto',
  },
});

export const CategoryOptions = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export const CategoryItemsContainer = styled(Box)({
  padding: '23px 48px 0 54px',
  '@media (max-width: 767px)': {
    padding: '20px 20px 0 20px',
  },
});

export const AddMenuItemButton = styled(IconButton)({
  padding: 0,
  marginLeft: '16px',
});
