import { Checkbox, FormControlLabel, styled } from '@mui/material';

export const StyledCheckbox = styled(Checkbox)({
  padding: '0',
  borderRadius: '3px',
  color: '#5D7386',
  width: '16px',
  height: '16px',
  '&.Mui-checked': {
    color: '#0C847E',
  },
  '& .MuiSvgIcon-root': { fontSize: 22 },
});

export const StyledFormControlLabel = styled(FormControlLabel)({
  '& .MuiFormControlLabel-label': {
    fontFamily: 'Graphik',
    fontSize: '15px',
    lineHeight: '20px',
    color: '#131313',
    marginLeft: '8px',
  },
  marginLeft: '8px',
});
