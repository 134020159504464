import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const NavigationSidebar = styled(Box)({
  width: '20%',
  height: '100%',
  position: 'fixed',
  overflowY: 'auto',
  background: '#FFFFFF',
  boxShadow: '1px 0px 0px #E9E9E9',
  ['@media (max-width:960px)']: {
    display: 'none',
  },
});

export const Header = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '16px',
  paddingTop: '28px',
});

export const GoldiLogo = styled(Box)({
  width: '83px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  marginBottom: '30px',
});

export const Divider = styled(Box)({
  height: '0px',
  border: '1px solid #E9E9E9',
  boxSizing: 'border-box',
  marginTop: '12px',
  marginBottom: '38px',
});
