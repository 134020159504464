import * as yup from "yup";

export const MerchantInformationValidation = yup.object({
    businessName: yup
        .string()
        .required('Merchant Name is required'),
    businessAddress: yup
        .string()
        .required('Merchant Address is required'),
    phoneNumber: yup
        .string()
        .required('Phone Number is required')
        .max(12, 'Max lenth upto 12 digits'),
    contactPhoneNumber: yup
        .string()
        .required('Contact Phone Number is required')
        .max(12, 'Max lenth upto 12 digits'),
    email: yup
        .string()
        .email('Pleaes enter a valid email address')
        .required('Email is required'),
    contactName: yup
        .string()
        .required('Contact Name is required')
});