import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useQuery } from "react-query";
import { useNavigate } from 'react-router';
import { useParams } from "react-router-dom";

import GoldiButton from 'components/Button';
import LoadingPage from 'components/LoadingPage';
import RestaurantCard from 'components/RestaurantCard';
import StyledContainer from 'components/StyledContainer';
import { ReactComponent as SpoonsIcon } from '../../../images/icons/spoonsVec.svg';

import { generateMerchantRestaurantsQuery } from 'Utils/helpers/queryGenerators';
import ImportRestaurantDialog from 'components/ImportRestaurantDialog';
import { Restaurant } from 'interfaces/ResturantInterface';
import { useApi } from 'providers/ApiProvider';
import { useMutationQuery } from 'providers/MutationQueryProvider';
import AuthService from 'services/auth.service';

const Restaurants = () => {
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [importRestaurantDialogIsOpen, setImportRestaurantDialogIsOpen] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [copyDialogIsOpen, setCopyDialogIsOpen] = useState<boolean>(false);
  const [restaurantToDelete, setRestaurantToDelete] = useState<Restaurant>();
  const [restaurantToCopy, setRestaurantToCopy] = useState<Restaurant>();
  const [loader, setLoader] = useState(false);
  const { deleteRestaurantMutation, copyRestaurantMutation ,importRestaurantMutation} = useMutationQuery();
  const navigate = useNavigate();
  const api = useApi();
  const { merchantId } = useParams();
  const theme = useTheme();

  const { status: _status, error: _error, data: restaurants, isLoading: _isLoading, refetch } = useQuery(generateMerchantRestaurantsQuery(merchantId), async () => {
    return api.getMerchantRestaurants(merchantId);
  });

  const handleCloseDialog = () => {
    setDialogIsOpen(false);
    setRestaurantToDelete(undefined);

  }
  const handleCopyCloseDialog = () => {
    setCopyDialogIsOpen(false);
    setRestaurantToCopy(undefined);
  }

  const handleCopyRestaurantClick = (restaurant: Restaurant) => {
    setCopyDialogIsOpen(true);
    setRestaurantToCopy(restaurant);
  }
  const handleCopyRestaurant = async () => {
    await copyRestaurantMutation.mutateAsync(restaurantToCopy);
    handleCopyCloseDialog();
  }
  const handleDeleteRestaurantClick = (restaurant: Restaurant) => {
    setDialogIsOpen(true);
    setRestaurantToDelete(restaurant);
  }

  const handleDeleteRestaurant = async () => {
    await deleteRestaurantMutation.mutateAsync(restaurantToDelete);
    handleCloseDialog();
  }
  const handlImportRestaurant = async (restaurant: Restaurant,selectedMerchantId:string) => {
      console.log('restaurant',restaurant) ;
      console.log('merchantId',selectedMerchantId) ;
     
      await api.importRestaurant(
        restaurant,
        selectedMerchantId,
      );
      setImportRestaurantDialogIsOpen(false);
      setLoader(true);
    await refetch();
    setLoader(false);
  }

  const refetchHandler = async () => {
    setLoader(true);
    await refetch();
    setLoader(false);
  }

  const orderHeader = {
    position: 'fixed',
    pl: 2,
    pt: 1.5,
    top: 0,
    width: "100%",
    color: theme.palette.grey[500],
    zIndex: 99999,
  }


  useEffect(() => {
    refetchHandler();
  }, [])

  useEffect(() => {
    (async () => {
      const _isAdmin = await AuthService.isAdmin();
      setIsAdmin(_isAdmin);
    })();
  }, [isAdmin]);
  return !restaurants || loader ? (
    <LoadingPage />
  ) : (
    <>
      <StyledContainer>
        <Box sx={orderHeader} maxWidth='sm'>
          <ArrowBackIosNewIcon onClick={() => { navigate(-1); }} />
        </Box>
        <Grid container mb={5}>
          <Grid item xs={7} lg={10}>
            <Typography variant='h4' fontWeight={'bold'} textAlign={'center'}>
              Restaurants ({restaurants.length}){' '}
            </Typography>
          </Grid>
          <Grid item xs={5} lg={2} sx={{ textAlign: 'right' }}>
            <GoldiButton
              startIcon={<AddCircleOutlineIcon />}
              text='Add New'
              onClick={() => {
                const route = `/merchants/${merchantId}/restaurants/new`;
                //console.log(route);
                navigate(route);
              }}        

            />
              <GoldiButton
                 text='Import'
              onClick={() => {
                setImportRestaurantDialogIsOpen(true);
              }}
            />
               
  
          </Grid>
        </Grid>
        {restaurants.length ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'stretch',
              flexWrap: 'wrap',
              alignContent: 'stretch',
              mt: 2,
            }}
          >
            {restaurants.map((restaurant: Restaurant) => (
              <RestaurantCard
                key={restaurant.id}
                restaurant={restaurant}
                handleDeleteRestaurant={handleDeleteRestaurantClick}
                handleCopyRestaurant={handleCopyRestaurantClick}
              />
            ))}
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 2,
              p: 2,
              bgcolor: 'background.paper',
            }}
          >
            <Box>
              <SpoonsIcon className='logo' />
              <Typography variant='h6'>
                You don&apos;t have any restaurant
              </Typography>
            </Box>
          </Box>
        )}
      </StyledContainer>
      <Dialog
        open={dialogIsOpen}
        onClose={handleCloseDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {`Delete ${restaurantToDelete?.name || 'Restaurant'}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            This is an irreversible action. Are you sure you wish to delete this
            restaurant?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            onClick={handleDeleteRestaurant}
            autoFocus
            color='error'
            disableElevation
            variant='contained'
            disabled={deleteRestaurantMutation.isLoading}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={copyDialogIsOpen}
        onClose={handleCopyCloseDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {`Copy ${restaurantToCopy?.name || 'Restaurant'}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            This is an irreversible action. Are you sure you wish to copy this
            restaurant?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCopyCloseDialog}>Cancel</Button>
          <Button
            onClick={handleCopyRestaurant}
            autoFocus
            color='error'
            disableElevation
            variant='contained'
            disabled={copyRestaurantMutation.isLoading}
          >
            Copy
          </Button>
        </DialogActions>
      </Dialog>

      <ImportRestaurantDialog
        isOpen={importRestaurantDialogIsOpen}
        handleClose={() => setImportRestaurantDialogIsOpen(false)}
        handleImport={handlImportRestaurant} merchantId={merchantId??''} />

    </>
  );

};

export default Restaurants;
