// import { BaseSyntheticEvent, useEffect, useState } from 'react';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Autocomplete,
    Box,
    Button,
    FormControlLabel,
    Grid,
    Switch,
    TextField,
    Typography
} from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import { FastField, FieldProps, FormikProps, } from 'formik';

import IngredientsSearchField from 'components/IngredientsSearchField';
import { GoldiTextField } from 'components/InputField';
import { Ingredient } from 'interfaces/IngredientInterface';
import { MenuItem, MenuItemComponent, MenuItemCustomization } from 'interfaces/MenuInterface';
import AllergiesDisplay from './AllergiesDisplay';
import DietsDisplay from './DietsDisplay';
import MenuItemCustomizationForm from './MenuItemCustomizationForm';
import RawIngredientsDisplay from './RawIngredientsDisplay';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:first-of-type)': {
        borderTop: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
<MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
/>
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

type PropsType = {
    component: MenuItemComponent;
    customizations?: MenuItemCustomization[]
    index: number;
    removeComponent: (index: number) => void;
    formik: FormikProps<MenuItem>;
}

const MenuItemComponentForm = ({
    component,
    customizations,
    index,
    removeComponent,
    formik
}: PropsType) => {
    const { handleBlur, handleChange, setFieldValue, values } = formik;
    return (component ?
        <Accordion key={`component-${index}`}>
            <AccordionSummary>
                <Box display={'flex'} alignItems={'center'} width={'100%'} >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        {component.title || `Component ${index + 1}`}
                    </Typography>
                    <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
                        <Button>
                            <DeleteIcon onClick={(e) => {
                                e.stopPropagation();
                                removeComponent(index);
                            }}/>
                        </Button>
                    </Box>
                </Box>
                
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={1}>
                    {/* Component Name */}
                    <Grid item xs={9}>
                        <Typography>Name</Typography>
                        <FastField
                            name={`components[${index}].title`}
                            type='text'
                        >
                            {({ field, form: { errors } }: FieldProps) => (
                                <GoldiTextField
                                    {...field}
                                    id={field.name}
                                    value={component.title}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={!!errors[field.name]}
                                />
                            )}
                        </FastField>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel
                            value="top"
                            control={
                                <Switch 
                                    id={`components[${index}].required`}
                                    checked={component.required} 
                                    onChange={handleChange} 
                                    name={`components[${index}].required`}
                                />
                            }
                            label="Required"
                            labelPlacement="top"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>Description</Typography>
                        <FastField
                            name={`components[${index}].description`}
                            type='text'
                        >
                            {({ field, form: { errors } }: FieldProps) => (
                                <GoldiTextField
                                    {...field}
                                    id={field.name}
                                    value={component.description}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={!!errors[field.name]}
                                />
                            )}
                        </FastField>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>Ingredients</Typography>
                        <FastField
                            name={`components[${index}].ingredients`}
                            key={`components[${index}].ingredients`}
                            type='text'
                        >
                            {() => (
                                <IngredientsSearchField
                                    ingredients={component.ingredients || []}
                                    restaurantId={values.restaurantId}
                                    handleSetIngredients={(ingredients: Ingredient[]) => {
                                        setFieldValue(`components[${index}].ingredients`, ingredients);
                                    }}
                                />
                            )}
                        </FastField>
                    </Grid>
                    {(values.components?.[index].ingredients || []).length > 0 && 
                        <Grid item xs={12}>
                            <RawIngredientsDisplay 
                                ingredients={values.components?.[index].ingredients || []}
                            />
                        </Grid>}
                    {(values.components?.[index].allergies || []).length > 0 && 
                        <Grid item xs={12}>
                            <AllergiesDisplay 
                                allergies={values.components?.[index].allergies || []}
                            />
                        </Grid>}
                    {(values.components?.[index].diets || []).length > 0 && 
                        <Grid item xs={12}>
                            <DietsDisplay
                                diets={values.components?.[index].diets || []}
                            />
                        </Grid>}
                    {customizations && customizations.length > 0 && (
                        <Grid item xs={12}>
                        <Typography>Customizations</Typography>
                        <FastField
                            name={`components[${index}].customizations`}
                            key={`components[${index}].customizations`}
                            type='text'
                        >
                            {() => (
                                <Autocomplete
                                    multiple
                                    limitTags={2}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    onChange={(e, value) => {
                                         console.log({customization: value});
                                        setFieldValue(
                                            `components[${index}].customizations`, 
                                            value.map(v => ({
                                                title: v.title, 
                                                customizationId: v.id,
                                            }))
                                        );
                                    }}
                                    options={customizations.map(c => ({
                                        title: c.title,
                                        id: c.id,
                                    }))}
                                    getOptionLabel={(option) => option.title}
                                    value={ (values.components?.[index]?.customizations || []).map(c => ({
                                        title: c.title,
                                        id: c.customizationId,
                                    })) }
                                    renderInput={(params) => (
                                        <TextField 
                                            {...params} 
                                            placeholder="Customizations" 
                                        />
                                    )}
                                />
                            )}
                        </FastField>
                    </Grid>
                    )}
                    
                    {
                        values.components?.[index]?.customizations && 
                        <Box mt={1} pl={1}>
                            {(values.components?.[index]?.customizations || [])
                            .map((customization: any) => customizations?.find(c => c.id === customization.customizationId))
                            .map((customization: any, _index: number) => (
                                <MenuItemCustomizationForm 
                                    key={`values.components.[${index}].customizations-${_index}`}
                                    customization={customization}
                                    removeCustomization={(...args)  => {console.log({args})}}
                                    index={_index}
                                    formik={formik}
                                    canRemove={false}
                                />
                            ))}
                        </Box>
                        
                    }
                </Grid>
            </AccordionDetails>
        </Accordion>
        : null
    )
}

export default MenuItemComponentForm;