import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Field, FieldProps, Form, Formik, FormikProps, FieldArray } from 'formik';
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";

import CustomTypography from 'components/Typography';
import { StyledMenuFormSidebar } from './index.styles';
import { Menu } from 'interfaces/MenuInterface';
import { WEEKDAYS } from "Utils/constants/schedule";
import { GoldiTextField } from 'components/InputField';
import GoldiButton from 'components/Button';
import { Restaurant } from 'interfaces/ResturantInterface';

const StyledTableCell = styled(TableCell)({
  borderBottom: 'none',
});

type MenuFormSidebarPropsType = {
  closeMenu: () => void;
  handleSave: (menu: Menu) => void;
  menu: Menu;
  isEdit?: boolean;
}

const MenuFormSidebar = ({
  closeMenu,
  handleSave,
  menu,
  isEdit = false,
}: MenuFormSidebarPropsType) => {

  //TODO: clean up these components, preferably only use Material

  const btnContainer = {
    position: 'sticky',
    bottom: -46,
    background: 'white',
    justifyContent: 'space-between',
    paddingTop: 2,
    paddingBottom:2,
    display:'flex'
  };
  
  return (
    <StyledMenuFormSidebar>
      <Box
        p={4}
        boxShadow='inset 0px -1px 0px rgba(97, 117, 134, 0.2)'
        borderRadius='6px 6px 0px 0px'
        position='relative'
      >
        <CustomTypography fontWeight='700' fontSize='20px' lineHeight='24px'>
          {isEdit ? 'Edit Menu' : 'Add New Menu'}
        </CustomTypography>
      </Box>

      <Grid container p={2}>
        <Formik
          initialValues={menu}
          onSubmit={(menu: Menu) => handleSave(menu)}
          // validationSchema={} //TODO: figure out validation
        >
          {({
            values,
            handleBlur,
            handleChange,
            isValid,
            handleSubmit,
          }: FormikProps<Menu>) => (
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              {/* Menu Name */}
              <Grid item>
                <Typography>Menu Name</Typography>
                <Field
                  name='title'
                  type='text'
                  render={({ field, form: { errors } }: FieldProps) => (
                    <GoldiTextField
                      {...field}
                      id={field.name}
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!errors[field.name]}
                    />
                  )}
                />
              </Grid>
              {/* Menu Schedule */}

              <Grid item mt={3}>
                {/* TODO: refactor this MenuSchedulePicker */}
                <FieldArray name='serviceAvailability'>
                  {({ insert: _insert, remove: _remove, push: _push }) => (
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Days Available</TableCell>
                            <TableCell>Menu Start</TableCell>
                            <TableCell>Menu End</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Object.values(WEEKDAYS).map((key, idx) => {
                            const day = values.serviceAvailability.find(
                              (_day) => _day.day_of_week === key
                            );
                            const dayIdx = values.serviceAvailability.findIndex(
                              (_day) => _day.day_of_week === key
                            );
                            return (
                              <TableRow key={key} sx={{ borderBottom: 'none' }}>
                                <StyledTableCell>
                                  <Grid container spacing={1}>
                                    <Grid item xs={3} mt={'2px'}>
                                      <Field
                                        type='checkbox'
                                        name={`serviceAvailability.${dayIdx}.enabled`}
                                      />
                                    </Grid>
                                    <Grid item xs={9}>
                                      <Typography textTransform={'capitalize'}>
                                        {key}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Field
                                    name={`serviceAvailability.${dayIdx}.time_periods.${0}.start_time`}
                                    value={day!.time_periods[0].start_time}
                                  >
                                    {({
                                      field, // { name, value, onChange, onBlur }
                                      form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                      meta,
                                    }: any) => (
                                      <GoldiTextField
                                        {...field}
                                        id={`serviceAvailability.${dayIdx}.time_periods.${0}.start_time`}
                                        type='time'
                                        value={day!.time_periods[0].start_time}
                                        onChange={handleChange}
                                        // onChange={(e: React.ChangeEvent<any>) => {
                                        //   // This is how you would save changes as you update the field
                                        //   // setValues((values) => {
                                        //   //   values.schedule[key as WEEKDAYS].hours[0].startAt = e.target.value
                                        //   //   setFormValues({ ...currentValues, ...values });
                                        //   //   return values
                                        //   // })
                                        //   // setFieldValue(`schedule.${key}.hours.${0}.startAt`, e.target.value)
                                        // }}
                                        onBlur={handleBlur}
                                        inputProps={{
                                          step: 300, // 5 min
                                        }}
                                        disabled={!day!.enabled}
                                      />
                                    )}
                                  </Field>
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Field
                                    name={`serviceAvailability.${dayIdx}.time_periods.${0}.end_time`}
                                    value={day!.time_periods[0].end_time}
                                  >
                                    {({
                                      field, // { name, value, onChange, onBlur }
                                    }: any) => (
                                      <GoldiTextField
                                        {...field}
                                        id={`serviceAvailability.${dayIdx}.time_periods.${0}.end_time`}
                                        type='time'
                                        value={day!.time_periods[0].end_time}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled={!day!.enabled}
                                        inputProps={{
                                          step: 300, // 5 min
                                        }}
                                      />
                                    )}
                                  </Field>
                                </StyledTableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </FieldArray>
              </Grid>

              {/* Menu External ID */}
              <Grid item>
                <Typography>Menu External ID</Typography>
                <Field
                  name='externalId'
                  type='text'
                  render={({ field, form: { errors } }: FieldProps) => (
                    <GoldiTextField
                      {...field}
                      id={field.name}
                      value={values.externalId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!errors[field.name]}
                    />
                  )}
                />
              </Grid>

              {/* Menu Name */}
              <Grid item>
                <Typography>Menu External Source</Typography>
                <Field
                  name='externalSource'
                  type='text'
                  render={({ field, form: { errors } }: FieldProps) => (
                    <GoldiTextField
                      {...field}
                      id={field.name}
                      value={values.externalSource}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!errors[field.name]}
                    />
                  )}
                />
              </Grid>
              <Box mt={5} sx={btnContainer}>
                <GoldiButton
                  text='Cancel'
                  outlined={true}
                  onClick={closeMenu}
                />
                <GoldiButton
                  text='Save'
                  disable={!isValid}
                  onClick={handleSubmit}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </Grid>
    </StyledMenuFormSidebar>
  );
};

export default MenuFormSidebar;
