import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthService from 'services/auth.service';

const RedirectWithLink = () => {
    const navigate = useNavigate();
    

    useEffect(() => {
        if (AuthService.getCurrentUser()) {
            navigate('/');
        } else {
            console.log(window.location.href);
            navigate(`/?redirect_uri=${encodeURIComponent(window.location.href)}`);
        }
    }, []);

    return <>{window.location.href}</>;
};

export default RedirectWithLink;
