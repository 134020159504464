import { useEffect, useState } from 'react';
import { FastField, FieldProps, Form, Formik, FormikProps } from 'formik';
import * as yup from "yup";
import { 
    Box, 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle, 
    Typography, 
    Grid, 
    Divider, 
    FormControlLabel, 
    Switch, 
    IconButton,
    Autocomplete,
    TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useQueryClient } from "react-query";

import { GoldiTextField } from 'components/InputField';

import { Goal } from 'interfaces/GoalInterface';

import FormikAutoSave from 'components/FormikAutoSave';
import { useDietaryPreferences } from 'providers/DietaryPreferenceProvider';
import debounce from 'lodash/debounce';
import { generateDietsQuery, generateIngredientsQuery } from 'Utils/helpers/queryGenerators';
import IngredientsSearchField from 'components/IngredientsSearchField';
import { processIngredients } from 'Utils/helpers/processMenuItem';

type GoalDialogPropsType = {
    isOpen: boolean;
    saveDisabled: any;
    goal: Goal;
    isEdit: boolean;
    handleCloseDialog: () => void;
    handleSave: (goal: Goal) => void;
  };

const DEBOUNCE_DELAY_MS = 500;

const GoalsDialog = ({
    isOpen,
    saveDisabled = false,
    goal,
    handleCloseDialog,
    handleSave,
    isEdit = false,
}: GoalDialogPropsType) => {
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(true);
    const queryClient = useQueryClient();
    const existingGoals: Goal[] = queryClient.getQueryData(generateDietsQuery()) || [];

    useEffect(() => {
        setModalIsOpen(isOpen);
    }, [isOpen]);

    console.log({ goal })
    // Slightly hacky, we need to use this state to stop the auto-save from firing when we close the dialog
    useEffect(() => {
        if (!modalIsOpen) {
            handleCloseDialog();
        }
    }, [modalIsOpen]); //Do not add handleCloseDialog to the dependency array, it will stop modal from re-opening

    // debounce validation code from https://stackoverflow.com/questions/58576825/yup-formik-async-validation-with-debounce=
    const validationFunction = async (value: string|undefined, resolve: any) => {
        // Don't need to perform this validation if we're editing existing ingredient
        if (isEdit) resolve(true);

        try {
            const existingGoal = existingGoals.find((i: Goal) => i.id === value)
            if (existingGoal) {
                resolve(false);
            } else {
                resolve(true);
            }
        } catch (error) {
            resolve(false);
        }
    };

    const validationDebounced = debounce(validationFunction, DEBOUNCE_DELAY_MS);

    const dietsValidationSchema = yup.object({
        id: yup
            .string()
            .required()
            .test('is-valid-id', 'ID Already Exists', (value) => new Promise((resolve) => validationDebounced(value, resolve))),
        title: yup
            .string()
            .required(),
    });

    return (
        <Dialog
            // fullScreen
            open={isOpen}
            onClose={(_e, reason) => {
                // disable closing dialog when clicking outside of dialog
                if (reason && reason == "backdropClick") return;
                setModalIsOpen(false);
            }}
            aria-labelledby="new-menu-item-dialog-title"
            aria-describedby="new-menu-item-dialog-description"
            maxWidth='sm'
            fullWidth={true}
            disableEscapeKeyDown
        >
            <Formik
                enableReinitialize={true}
                initialValues={goal}
                onSubmit={(goal: Goal) => { 
                    handleSave(goal);
                }}
                validationSchema={dietsValidationSchema}
                validateOnMount={true}
                initialErrors={{ id: 'Required', title: 'Required' }}
            >
                {(formik: FormikProps<Goal>) => {
                    const { values, handleBlur, handleChange, isValid, handleSubmit } = formik;
                    return (
                    <Form>
                        <DialogTitle>
                            <Box display={'flex'} alignItems={'center'} width={'100%'}>
                                <Typography width={'100%'} fontSize={'1.25rem'}>
                                { isEdit ? `Edit ${goal.name.length ? goal.name : 'Goal'}` : 'Add Goal' }
                                </Typography>
                                <Box display={'flex'} justifyContent={'flex-end'} >
                                    <IconButton
                                        onClick={handleCloseDialog}
                                        disableRipple
                                        sx={{cursor:'pointer', float:'right', width: '20px'}}
                                    >
                                        <CloseIcon />
                                    </IconButton>    
                                </Box>       
                            </Box>
                        </DialogTitle>
                        <Divider />
                        <DialogContent>
                            <Box>
                                <Grid container spacing={1}>
                                    {/* Item ID */}
                                    <Grid item xs={9}>
                                        <Typography>ID / NDB Number</Typography>
                                        <FastField
                                            name='id'
                                            type='text'
                                            render={({ field, form: { errors } }: FieldProps) => (
                                                <GoldiTextField 
                                                    {...field}
                                                    id={field.name}
                                                    value={values.id}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={!!errors[field.name]}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControlLabel
                                            value="top"
                                            control={
                                                <Switch
                                                    checked={values.active} 
                                                    onChange={handleChange} 
                                                    name={'active'}
                                                />
                                            }
                                            label="Active"
                                            labelPlacement="top"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>Name</Typography>
                                        <FastField
                                            name='name'
                                            type='text'
                                            render={({ field, form: { errors } }: FieldProps) => (
                                                <GoldiTextField 
                                                    {...field}
                                                    id={field.name}
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={!!errors[field.name]}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>Title</Typography>
                                        <FastField
                                            name='title'
                                            type='text'
                                            render={({ field, form: { errors } }: FieldProps) => (
                                                <GoldiTextField 
                                                    {...field}
                                                    id={field.name}
                                                    value={values.title}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={!!errors[field.value]}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* Item Description */}
                                        <Box width={'100%'}>
                                            <Typography>Description</Typography>
                                            <FastField
                                                name='description'
                                                type='text'
                                                render={({ field, form: { errors } }: FieldProps) => (
                                                    <GoldiTextField 
                                                        {...field}
                                                        id={field.name}
                                                        value={values.description}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={!!errors[field.name]}
                                                    />
                                                )}
                                            />
                                        </Box>  
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* <Typography>Ingredients</Typography>
                                        <FastField
                                            name={'ingredients'}
                                            key={'ingredients'}
                                            type='text'
                                            render={({ field, form: { errors } }: FieldProps) => (
                                                <IngredientsSearchField
                                                    ingredients={values.allergies || []}
                                                    handleSetIngredients={(ingredients: Goal[]) => {
                                                        const { allergies, diets } = processIngredients(allergies);
                                                        setFieldValue('ingredients', ingredients);
                                                        setFieldValue('allergies', allergies);
                                                        setFieldValue('diets', diets);
                                                    }}
                                                />
                                            )}
                                        /> */}
                                    </Grid>
                                     {/* drop down allergies and dierts */}
                                </Grid>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <FormikAutoSave 
                                canAutoSave={isValid && modalIsOpen}
                                sx={{ 
                                    mr: 2,
                                    fontSize: '0.75rem',
                                    color: 'text.secondary',
                                }} 
                            />
                            <Button sx={{mr: 2}} onClick={() => {
                                setModalIsOpen(false);
                            }}>Cancel</Button>
                            <Button 
                                variant='contained' 
                                disableElevation 
                                onClick={() => {
                                    handleSubmit();
                                    setModalIsOpen(false);
                                }}
                                disabled={!isValid || saveDisabled}
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </Form>
                )}} 
            </Formik>
        </Dialog>
    )
}

export default GoalsDialog;
