// import { IResturant } from './ResturantInterface';

// Need to add the rest of the properties
// export interface IMerchant {
//   name: string;
//   resturants: IResturant[];
// }

export enum MerchantStatus {
    ACTIVE = 'active',
    PENDING = 'pending',
    DISABLED = 'disabled',
    PAUSED = 'paused',
}

export type MerchantInformation = {
    businessName: string,
    businessAddress: string,
    phoneNumber: string,
    contactName: string,
    contactPhoneNumber?: string,
    email: string,
    status: MerchantStatus,
    homePage?: string,
    restaurantCount: number,
};

export type MerchantImage = {
    image?: File;
    imageUrl?: string;
};

export type Merchant = {
    id?: string;
    createdAt?: string;
    updatedAt?: string;
    createdBy?: string;
    updatedBy?: string;
    deletedAt?: string;
    deletedBy?: string;
  } & MerchantInformation & MerchantImage;