import { TextField, TextFieldProps } from '@mui/material';
import { useState } from 'react';
import toPriceDisplay from 'Utils/helpers/toPriceDisplay';

type PriceInputProps = Omit<TextFieldProps, 'onChange'> & {
    onChange: (price: number) => void;
};

export const GoldiPriceField = (props: PriceInputProps) => {
    const [price, setPrice] = useState<number>(props.value as number || 0);
    
    return <TextField 
        {...props} 
        value={toPriceDisplay(price)}
        onChange={(e) => {
            const price = Number(e.target.value.replace(/[^0-9]/g, ''));
            setPrice(price);
            props.onChange!(price);
        }}
        style={{
            width: "100%"
        }}
        InputProps={{
            style: {
                height: 44
            }
        }}
    />
}

export default GoldiPriceField;
