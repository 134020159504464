import * as React from 'react';
import {Grid, Box, Typography, Button} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useTheme } from '@mui/material/styles';
import {ReactComponent as Icon1} from '../../../../images/icons/header/icon1.svg';
import {ReactComponent as Icon2} from '../../../../images/icons/header/icon2.svg';
import {ReactComponent as Icon3} from '../../../../images/icons/header/icon3.svg';
import {ReactComponent as Icon4} from '../../../../images/icons/header/icon4.svg';
import line from '../../../../images/icons/header/line.svg';
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';

interface Props {
  changeStep (val: number, index: boolean): any;
  stepValue: number,
  selectedPreferencesCount: number
}

export default function Header({changeStep, stepValue, selectedPreferencesCount} : Props) {
  const theme = useTheme();
  const hash = window.location.hash;
  const headerProps = {
    padding: 3,
    zIndex: 9999999,
    width: '100%',
    position: 'fixed',
    backgroundColor: theme.palette.grey[0]
  }

  const gridProps = {
    display: 'inline-grid',
    justifyContent: 'center',
    justifyItems: 'center',
    alignItems: 'center',
    gridGap: '3px'

  }

  const iconProps = {
    color: theme.palette.primary.darker,
    fontSize: 14
  }

  return (
    <Box sx={headerProps} maxWidth='sm'>
      <Grid container sx={{justifyContent: 'center'}} >
        <Grid item xs={2} sx={gridProps}>
          <CircleIcon sx={iconProps}  onClick={() => changeStep(0, false)} />
        </Grid>
        <Grid item xs={2}  sx={gridProps}>
          <img src={line} />
        </Grid>
        <Grid item xs={3}  sx={gridProps}>
          {stepValue >= 1 && <CircleIcon sx={iconProps} onClick={() => changeStep(1, false)} />}
          {stepValue < 1 && <CircleOutlinedIcon sx={iconProps} onClick={() => changeStep(1, false)} />}
        </Grid>
        <Grid item xs={2}  sx={gridProps}>
          <img src={line} />
        </Grid>
        <Grid item xs={2}  sx={gridProps}>
          {stepValue >= 2 && <CircleIcon sx={iconProps} onClick={() => changeStep(2, false)} />}
          {stepValue < 2 && <CircleOutlinedIcon sx={iconProps} onClick={() => changeStep(2, false)} />}
        </Grid>
      </Grid>
    </Box>
  );
}
