import { Box, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import { FastField, Field, FieldProps, Form, Formik, FormikProps } from 'formik';

import { RestaurantInformationValidation } from 'Utils/Schemas/NewRestaurant.schema';
import GoldiDropDownMenu from 'components/DropDown';
import TagsInputField from 'components/TagsInputField';
import { RestaurantChowly, RestaurantDeliveryStatus, RestaurantInformation, RestaurantStatus } from 'interfaces/ResturantInterface';
import { RestaurantFormStepPropsType } from '..';
import GoldiAddressInputField from '../../AddressInputField';
import GoldiButton from '../../Button';
import { GoldiPhoneNumberField, GoldiTextField } from '../../InputField';

const initialValues: RestaurantInformation = {
  name: '',
  address: '',
  latitude: undefined,
  longitude: undefined,
  googlePlaceId: undefined,
  phoneNumber: '',
  contactName: '',
  contactPhoneNumber: '',
  email: '',
  status: RestaurantStatus.PENDING,
  isDeliverySupported: RestaurantDeliveryStatus.ENABLED,
  homePage: '',
  orderPage: '',
  externalId: '',
  urlSlug: '',
  tags: [],
  isChowly : RestaurantChowly.DISABLED,
  isPickUpSupported: RestaurantDeliveryStatus.ENABLED,
  isDineInSupported: RestaurantDeliveryStatus.ENABLED,
  priceMarkUpPercentage:0 ,
};


const saveContinueButton = {
  position: 'sticky',
  bottom: 0,
  background: 'white',
  justifyContent: 'flex-end',
  paddingBottom: 2,
  paddingTop: 1,
};

export const RestaurantInformationForm = ({ setCurrentStep, setFormValues, currentValues, currentStep, isAdmin }: RestaurantFormStepPropsType) => {

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography fontWeight={'bold'} fontSize={'1.4rem'}>
          Restaurant Information
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Formik
          initialValues={{ ...initialValues, ...currentValues }}
          enableReinitialize={true}
          onSubmit={(values: RestaurantInformation) => {
            setFormValues({ ...currentValues, ...values });
            setCurrentStep(++currentStep);
          }}
          validationSchema={RestaurantInformationValidation}
        >
          {(formik: FormikProps<RestaurantInformation>) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                <Typography variant='subtitle1' fontWeight={'bold'}>
                  Input Restaurant information below{' '}
                </Typography>
              </Grid>
              {isAdmin && <Grid item xs={6}>
                <FormControlLabel
                  value='right'
                  onClick={(e) =>{
                    e.stopPropagation();
                  }}
                  control={
                    <Switch
                      checked={
                        formik.values.isDeliverySupported
                      }
                      onChange={formik.handleChange}
                      name={'isDeliverySupported'}
                    />
                  }
                  label='Delivery'
                  labelPlacement='start'
                />
                <FormControlLabel
                  value='right'
                  onClick={(e) =>{
                    e.stopPropagation();
                  }}
                  control={
                    <Switch
                      checked={
                        formik.values.isDineInSupported
                      }
                      onChange={formik.handleChange}
                      name={'isDineInSupported'}
                    />
                  }
                  label='Dine In'
                  labelPlacement='start'
                />

                 <FormControlLabel
                  value='right'
                  onClick={(e) =>{
                    e.stopPropagation();
                  }}
                  control={
                    <Switch
                      checked={
                        formik.values.isPickUpSupported
                      }
                      onChange={formik.handleChange}
                      name={'isPickUpSupported'}
                    />
                  }
                  label='Pick Up'
                  labelPlacement='start'
                />
                {isAdmin &&
                <FormControlLabel
                  value='right'
                  onClick={(e) =>{
                    e.stopPropagation();
                  }}
                  control={
                    <Switch
                      checked={
                        formik.values.isChowly
                      }
                      onChange={formik.handleChange}
                      name={'isChowly'}
                    />
                  }
                  label='Is Chowly'
                  labelPlacement='start'
                />
              }
              </Grid>}
                {/* Restaurant Name */}
                <Grid item xs={6}>
                  <Typography>Name*</Typography>
                  <Field name='name' type='text'>
                    {({ field, form: { errors } }: FieldProps) => (
                      <GoldiTextField
                        {...field}
                        id={field.name}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={!!errors[field.name]}
                      />
                    )}
                  </Field>
                  </Grid>
                  <Grid item xs={6}>
                  <Typography>Mark up</Typography>
                  <Field name='priceMarkUpPercentage' type='text'>
                    {({ field, form: { errors } }: FieldProps) => (
                      <GoldiTextField
                        {...field}
                        id={field.name}
                        value={formik.values.priceMarkUpPercentage}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={!!errors[field.name]}
                      />
                    )}
                  </Field>
                </Grid>
                
                {/* Restaurant Address */}
                <Grid item xs={12}>
                  <Typography>Address*</Typography>
                  <Field name='address' type='text'>
                    {({ field, form: { errors } }: FieldProps) => (
                      <GoldiAddressInputField
                        value={formik.values.address}
                        error={!!errors[field.name]}
                        handleSelect={(
                          address,
                          placeId,
                          _suggestion,
                          latLng
                        ) => {
                          formik.setFieldValue('address', address, true);
                          formik.setFieldValue('googlePlaceId', placeId);
                          formik.setFieldValue('latitude', latLng?.lat);
                          formik.setFieldValue('longitude', latLng?.lng);
                        }}
                      />
                    )}
                  </Field>
                </Grid>

                {/* Contact Info */}
                <Grid item xs={6}>
                  {/* Restaurant Phone Number */}
                  <Typography>Phone Number*</Typography>
                  <Field name='phoneNumber' type='text'>
                    {({ field, form: { errors } }: FieldProps) => (
                      <GoldiPhoneNumberField
                        {...field}
                        id={field.name}
                        value={formik.values.phoneNumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={!!errors[field.name]}
                      />
                    )}
                  </Field>
                </Grid>

                {/* Status */}
                {isAdmin && (
                  <Grid item xs={6}>
                    {/* Restaurant Status */}
                    <Typography>Status*</Typography>
                    <Field name='status' type='text'>
                      {({ field, form: { errors } }: FieldProps) => (
                        <GoldiDropDownMenu
                          {...field}
                          id={field.name}
                          value={formik.values.status}
                          onChange={formik.handleChange}
                          options={Object.values(RestaurantStatus).map(
                            (value) => ({
                              key: value,
                              value: value,
                              label: value.toUpperCase(),
                            })
                          )}
                          onBlur={formik.handleBlur}
                          error={!!errors[field.name]}
                        />
                      )}
                    </Field>
                  </Grid>
                )}

                {/* Restaurant Contact Full Name */}
                <Grid item xs={6}>
                  <Typography>Contact Full Name*</Typography>
                  <Field name='contactName' type='text'>
                    {({ field, form: { errors } }: FieldProps) => (
                      <GoldiTextField
                        {...field}
                        id={field.name}
                        value={formik.values.contactName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={!!errors[field.name]}
                      />
                    )}
                  </Field>
                </Grid>
                {/* Email */}
                <Grid item xs={6}>
                  <Typography>Email*</Typography>
                  <Field name='email' type='text'>
                    {({ field, form: { errors } }: FieldProps) => (
                      <GoldiTextField
                        {...field}
                        id={field.name}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={!!errors[field.name]}
                      />
                    )}
                  </Field>
                </Grid>

                {/* Phone */}
                <Grid item xs={6}>
                  <Typography>Contact Phone Number*</Typography>
                  <Field name='contactPhoneNumber' type='text'>
                    {({ field, form: { errors } }: FieldProps) => (
                      <GoldiPhoneNumberField

                        {...field}
                        id={field.name}
                        value={formik.values.contactPhoneNumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={!!errors[field.name]}
                      />
                    )}
                  </Field>
                </Grid>

                  {/* Tags  */}
                  <Grid item xs={6}>
                      {/* Item Description */}
                      <Box width={'100%'}>
                          <Typography>Tags</Typography>
                          <FastField
                              name='tags'
                              type='text'
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              >
                                  {({ field, form: { errors } }: FieldProps) => (
                              <TagsInputField
                              initialTags={formik.values.tags || []}
                              handleGetTags={(tags: string[]) => {
                                  //console.log('triggered', tags);
                                  formik.setFieldValue(`tags`, tags);
                              }}


                              />
                              )}
                          </FastField>
                      </Box>
                  </Grid>


                {/* Restaurant Home Page */}
                <Grid item xs={12}>
                  <Typography>Home Page</Typography>
                  <Field name='homePage' type='text'>
                    {({ field, form: { errors } }: FieldProps) => (
                      <GoldiTextField
                        {...field}
                        id={field.name}
                        value={formik.values.homePage}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={!!errors[field.name]}
                      />
                    )}
                  </Field>
                </Grid>

                {/* Restaurant Order Page */}
                <Grid item xs={12}>
                  <Typography>Order Page</Typography>
                  <Field name='orderPage' type='text'>
                    {({ field, form: { errors } }: FieldProps) => (
                      <GoldiTextField
                        {...field}
                        id={field.name}
                        value={formik.values.orderPage}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={!!errors[field.name]}
                      />
                    )}
                  </Field>
                </Grid>

                {/* Restaurant External ID */}
                <Grid item xs={12}>
                  <Typography>External ID*</Typography>
                  <Field name='externalId' type='text'>
                    {({ field, form: { errors } }: FieldProps) => (
                      <GoldiTextField
                        {...field}
                        id={field.name}
                        value={formik.values.externalId}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={!!errors[field.name]}
                      />
                    )}
                  </Field>
                </Grid>

                {/* Restaurant URL Slug */}
                <Grid item xs={12}>
                  <Typography>Restaurant Url Slug*</Typography>
                  <Field name='urlSlug' type='text'>
                    {({ field, form: { errors } }: FieldProps) => (
                      <GoldiTextField
                        {...field}
                        id={field.name}
                        value={formik.values.urlSlug}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={!!errors[field.name]}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <Grid
                container

                sx={saveContinueButton}
              >
                <Grid item mt={2} mb={1}>
                  <GoldiButton
                    text='Save and Continue'
                    disable={!formik.isValid}
                    onClick={formik.handleSubmit}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default RestaurantInformationForm;
