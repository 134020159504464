import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  userType,
  resetPasswordSentLinkType,
  resendRestPasswordLinkType,
  resetPasswordType,
} from '../interfaces/UserInerface';
import showToaster from '../showToaster';

const initialState: userType = {
  isAuthenticated: false,
  token: null,
  user: null,
  loading: false,
  error: null,
  role: null,
};

// Login user executor-
export const loginUser = createAsyncThunk(
  'user/login',
  async (userCredentials: any, { rejectWithValue }) => {
    try {
      const data = axios({
        url: 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyD5trncfAvVuYB-sZMHkMFejdydN2-qDoU',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          ...userCredentials,
          returnSecureToken: true,
        }),
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

// Send reset password link-
export const sentResetPasswordLink = createAsyncThunk(
  'user/sentResetPasswordLink',
  async (userCredential: resetPasswordSentLinkType, { rejectWithValue }) => {
    try {
      const data = await axios({
        url: '/v1/user/sendLink',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(userCredential),
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

// Resend resetPassword link
export const resendResetPasswordLink = createAsyncThunk(
  'user/resendResetPasswordLink',
  async (userCredential: resendRestPasswordLinkType, { rejectWithValue }) => {
    try {
      const data = await axios({
        url: '/v1/user/resendLink',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(userCredential),
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

// Reset password
export const resetPassword = createAsyncThunk(
  'user/resetPassword',
  async (userCredentials: resetPasswordType, { rejectWithValue }) => {
    try {
      const data = await axios({
        url: '/v1/user/resetPassword',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(userCredentials),
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  // Syncchronized reducer
  reducers: {
    // Logout user
    logOut: (state) => {
      state.isAuthenticated = false;
      state.token = null;
      state.user = null;
      state.role = null;
      localStorage.removeItem('goldiToken');
    },
    // Add user type
    setUserType: (state, action) => {
      state.role = action.payload;
    },
    //Add user email verification status
    setEmailVerificationStatus: (state, action) => {
      state.isAuthenticated = action.payload;
    },
  },
  // Asynchronized reducer
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      state.token = action.payload?.data.idToken;
      state.loading = false;
      showToaster('success', 'Logged in successfully!!!');
      localStorage.setItem('goldiToken', action.payload?.data.idToken);
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      let error;
      state.loading = false;
      switch (action.error.message) {
        case 'Request failed with status code 400':
          error = 'Invalid email or password';
          break;
        case 'Network Error':
          error = 'Network Error';
          break;
        default:
          break;
      }
      showToaster('error', error as string);
    });
    builder.addCase(sentResetPasswordLink.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sentResetPasswordLink.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(sentResetPasswordLink.rejected, (state, action: any) => {
      state.loading = false;
      let error;
      if (action.payload.message === 'Network Error') {
        error = 'Network Error';
      } else {
        error = action?.payload?.response.data.error;
      }
      state.error = error;
      showToaster('error', error as string);
    });
    builder.addCase(resendResetPasswordLink.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(resendResetPasswordLink.fulfilled, (state) => {
      state.loading = false;
      showToaster('success', 'Link sent successfully!!!');
    });
    builder.addCase(resendResetPasswordLink.rejected, (state, action: any) => {
      state.loading = false;
      let error;
      if (action.payload.message === 'Network Error') {
        error = 'Network Error';
      } else {
        error = action?.payload?.response.data.error;
      }
      state.error = error;
      showToaster('error', error as string);
    });
    builder.addCase(resetPassword.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(resetPassword.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      showToaster('success', 'Password changed successfully!!!');
    });
    builder.addCase(resetPassword.rejected, (state, action: any) => {
      state.loading = false;
      if (action.payload.message === 'Network Error') {
        showToaster('error', 'Network Error');
      } else {
        if (typeof action?.payload?.response.data.error === 'object') {
          state.error = action?.payload?.response.data.error;
        } else {
          state.error = action?.payload?.response.data.error;
          showToaster('error', action?.payload?.response.data.error);
        }
      }
    });
  },
});

export const { logOut, setUserType, setEmailVerificationStatus } = userSlice.actions;

export default userSlice.reducer;
