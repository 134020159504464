import { Box, ButtonBase, Typography, Stack, Grid } from '@mui/material';
import Alert from '@mui/material/Alert';

const style = {
  position: 'fixed',
  bottom: 0,
  zIndex: 99999999,
  textAlign: 'center',
  display: 'block',
  width: '100%'
}

interface Props {
  error: string
}

function ErrorAlert({error} : Props) {
  return (
    <Box sx={style} maxWidth='sm'>
      <Alert severity="error">{error}</Alert>
    </Box>
  );
}

export default ErrorAlert;
