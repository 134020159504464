import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { Fragment, useCallback, useEffect, useState } from 'react';

import { Ingredient } from 'interfaces/IngredientInterface';
import debounce from "lodash/debounce";

import { Checkbox } from '@mui/material';
import { useApi } from 'providers/ApiProvider';

const MIN_SEARCH_LENGTH = 3;

type PropsType = {
    ingredients: Ingredient[];
    restaurantId?: string;
    merchantId?: string;
    handleSetIngredients: (ingredients: Ingredient[]) => void;
    delayInMs?: number;
}

const DEBOUNCE_DELAY_MS = 200; 

export default function IngredientsSearchField({
    ingredients: _ingredients = [],
    restaurantId,
    merchantId,
    handleSetIngredients,
    delayInMs
}: PropsType) {
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<readonly Ingredient[]>([]);
  const api = useApi();
  const _delayInMs = delayInMs || DEBOUNCE_DELAY_MS;

  const getOptionsDelayed = useCallback((text, callback) =>
    debounce(async () => {
      setOptions([]);
      setLoading(true);
      const ingredientOptions = await api.searchIngredients(text, restaurantId ,merchantId);
      callback(ingredientOptions);
    }, _delayInMs),
    [_delayInMs, api]
  );

  useEffect(() => {
    if (inputValue && inputValue.length > MIN_SEARCH_LENGTH) {
        getOptionsDelayed(inputValue, (filteredOptions: Ingredient[]) => {
            setOptions(filteredOptions);
            setLoading(false);
        })();
    }
  }, [inputValue, getOptionsDelayed]);

  return (
    <Autocomplete
        id="search-ingredients-autocomplete"
        isOptionEqualToValue={(option, value) => option.title === value.title}
        getOptionLabel={(option) => option.title}
        options={options}
        loading={loading}
        multiple
        disableCloseOnSelect
        limitTags={2}
        filterOptions={(x) => x} // disable filtering on client side
        onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
        onChange={(e, value) => handleSetIngredients(value)}
        value={_ingredients}
        renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={ <CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.title}
            </li>
          )}
        renderInput={(params) => (
            <TextField
            {...params}
            InputProps={{
                ...params.InputProps,
                endAdornment: (
                <Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                </Fragment>
                ),
            }}
            />
        )}
    />
  );
}
