import { SortEndHandler } from 'react-sortable-hoc';
import { Container } from './index.styles';
import SortableList from './SortableList';

interface ISortableGrid<DataItem> {
  children: React.ReactNode[];
  gridItems: DataItem[];
  display: 'grid' | 'vertical';
  onSortEnd: SortEndHandler;
}

export default function SortableGrid<DataItem>({
  gridItems,
  display,
  onSortEnd,
  children,
}: ISortableGrid<DataItem>) {
  return (
    <Container sx={{ minHeight:'100vh'}}>
      <SortableList
        axis='xy'
        distance={1}
        items={gridItems}
        onSortEnd={onSortEnd}
        display={display}
      >
        {children}
      </SortableList>
    </Container>
  );
}
