import { Container } from '@mui/material';
import { Outlet } from 'react-router';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';

const ExplorerLayout = () => {
  const { res_name } = useParams();
  const theme = useTheme();
  document.title = "Explore Restaurant - Goldi";
  return (<Container disableGutters maxWidth="sm" sx={{backgroundColor: theme.palette.grey[0], height: '100vh'}}>
      <Outlet />
    </Container>
  );
};

export default ExplorerLayout;
