import { TextField, TextFieldProps } from '@mui/material';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import './index.css';

interface propType {
  id?: string;
  width?: string;
  height?: string;
  Prefix?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  onClick?: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  value: string;
  isTouched?: boolean | undefined;
  isError?: string | undefined;
  isSelect?: boolean | undefined;
  autoCompleteValues?: string[];
  type?: 'text' | 'password';
  placeHolder?: string;
  readonly?:boolean|false;
}

const GoldiInput = ({
  id,
  // width,
  // height,
  Prefix,
  isSelect,
  isError,
  onClick,
  onChange,
  onBlur,
  value,
  type,
  placeHolder,
  readonly,
}: propType) => {
  const [isShow, setIShow] = useState<boolean>(false);

  return (
    <div
      onClick={() => (onClick ? onClick() : '')}
      className={`input-root 
         ${isSelect ? 'selected' : ''} 
         ${isError ? 'error' : ''} 
         `}
      // style={{ width: width, height: height }}
    >
      {Prefix && <Prefix className='prefix' />}
      <input
        readOnly={readonly}
        id={id}
        value={value}
        placeholder={placeHolder}
        className={`input-field ${Prefix ? 'isPrefix' : ''}`}
        type={isShow ? 'text' : type}
        onChange={(e) => onChange(e)}
        onBlur={(e) => onBlur(e)}
      />
      <div className='postfix' onClick={() => setIShow(!isShow)}>
        {type === 'password' ? (isShow ? 'Hide' : 'Show') : null}
      </div>
    </div>
  );
};

export const GoldiTextField = (props: TextFieldProps, delayInMs?: number , readOnly?:boolean|false) => {
  const DEBOUNCE_DELAY_MS = 200; 
  const [innerValue, setInnerValue] = useState('');
  const _delayInMs = delayInMs || DEBOUNCE_DELAY_MS; // default 200ms delay

  useEffect(() => {
    if (props.value) {
      setInnerValue(props.value as string);
    } else {
      setInnerValue('');
    }
  }, [props.value]);

  const handleOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const newValue = event.currentTarget.value;
    setInnerValue(newValue);

    debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      if (props.onChange) {
        props.onChange(event);
      }
    }, _delayInMs)(event);
  }, []);

  return <TextField 
    {...props} 
    disabled={readOnly}
    style={{
      width: "100%"
    }}
    InputProps={{
      style: {
        height: 44
      }
    }}
    value={innerValue}
    onChange={handleOnChange}
  />
}

export const GoldiPhoneNumberField = (props: TextFieldProps, delayInMs?: number) => {
  const DEBOUNCE_DELAY_MS = 200; 
 
  const _delayInMs = delayInMs || DEBOUNCE_DELAY_MS; // default 200ms delay
  const [formattedValue, setFormattedValue] = useState('');

  useEffect(() => {
    if (props.value) {
      setFormattedValue(props.value as string);
    } else {
      setFormattedValue('');
    }
  }, [props.value]);

  const handlePhoneNumberChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const rawPhoneNumber = event.currentTarget.value.replace(/\D/g, ''); // Remove non-digit characters
      const formattedPhoneNumber = formatPhoneNumber(rawPhoneNumber); // Implement your formatting logic
      setFormattedValue(formattedPhoneNumber);
      debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) {
          props.onChange(event);
        }
      }, _delayInMs)(event);
    }, []);

  

  return <TextField 
    {...props} 
    style={{
      width: "100%"
    }}
    InputProps={{
      style: {
        height: 44
      }
    }}
    value={formattedValue}
    onChange={handlePhoneNumberChange}
  />
  
}
// Implement your own phone number formatting logic
const formatPhoneNumber = (phoneNumber: string) => {
  // You can format the phone number based on your requirements
  // Example: (123) 456-7890
  // Example: 123-456-7890
  return phoneNumber;
};

export default GoldiInput;
