import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    TextField,
    Typography
} from '@mui/material';
import { FastField, FieldArray, FieldProps, Form, Formik, FormikProps } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import * as yup from "yup";

import CloseIcon from '@mui/icons-material/Close';
import { processMenuItem } from 'Utils/helpers/processMenuItem';
import { generateChatgptIngredientNameQuery } from 'Utils/helpers/queryGenerators';
import AllergiesDisplay from 'components/AllergiesDisplay';
import DietsDisplay from 'components/DietsDisplay';
import FormikAutoSave from 'components/FormikAutoSave';
import { GoldiTextField } from 'components/InputField';
import GoldiInputImage from 'components/InputImage';
import MenuItemComponentForm from 'components/MenuItemComponentForm';
import MenuItemCustomizationForm from 'components/MenuItemCustomizationForm';
import { GoldiPriceField } from 'components/PriceInputField';
import TagsInputField from 'components/TagsInputField';
import { MenuItem, MenuItemComponent, MenuItemCustomization } from 'interfaces/MenuInterface';
import { useApi } from 'providers/ApiProvider';
import { useDietaryPreferences } from 'providers/DietaryPreferenceProvider';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import ChatGptIngredientsResultDisplay from './ChatGptIngredientsResult';
import { useMutationQuery } from 'providers/MutationQueryProvider';

type MenuItemDialogPropsType = {
    isOpen: boolean;
    handleCloseDialog: () => void;
    handleSave: (menuItem: MenuItem) => void;
    menuItem: MenuItem;
    saveDisabled: boolean;
    isEdit?: boolean;
    subsection?: string;
    canAutoSave?: boolean;
}

const newMenuItemComponent: MenuItemComponent = {
    title: '',
    description: '',
    ingredients: [],
    required: true,
    customizations: [],
};

const generateNewMenuItemCustomization = (): MenuItemCustomization => ({
    id: uuidv4(),
    title: '',
    externalId: '',
    externalSource: '',
    maxPermitted: 1,
    minPermitted: 0,
    menuItemCustomizationOptions: [
        {
            id: uuidv4(),
            price: 0,
            title: 'Customization Option 1',
            description: '',
            defaultModifier: false,
            ingredients: [],
            externalId: '',
            externalSource: '',
        }
    ],
    description: '',
});

const MenuItemValidationSchema = yup.object({
    title: yup
        .string()
        .required(),
});

const MenuItemDialog = ({
    isOpen,
    saveDisabled = false,
    menuItem: initMenuItem,
    handleCloseDialog,
    handleSave,
    isEdit = false,
    subsection = '',
    canAutoSave = true,
}: MenuItemDialogPropsType) => {
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(true);
    const [menuItem, setMenuItem] = useState<MenuItem>(initMenuItem);
    const { dietKeyToName: Diets, allergyKeyToName: Allergies } = useDietaryPreferences();
    const api = useApi();
    const {
        generateMenuItemIngredientsMutation,
      } = useMutationQuery();

    // const { status: _status, error: _error, data: chatgptIngredients, isLoading: _isLoading, refetch } = useQuery(generateChatgptIngredientNameQuery(menuItem.title), async () => {
    //     return await api.getChatgptIngredients(menuItem.title, menuItem.description, subsection);

    // });

    useEffect(() => {
        setModalIsOpen(isOpen);
    }, [isOpen]);

    // Slightly hacky, we need to use this state to stop the auto-save from firing when we close the dialog
    useEffect(() => {
        if (!modalIsOpen) {
            handleCloseDialog();
        }
    }, [modalIsOpen]); //Do not add handleCloseDialog to the dependency array, it will stop modal from re-opening

    useEffect(() => {
        setMenuItem(initMenuItem);
    }, [initMenuItem]); 

    const handleGenerateIngredients = async () => {
        const result = await generateMenuItemIngredientsMutation.mutateAsync(menuItem);
        //TODO: Hack to force re-render, fix this
        setMenuItem({...result, subtext: result.subtext + ' '});
    }

    return (
        <Dialog
            // fullScreen
            open={isOpen}
            onClose={(_e, reason) => {
                // disable closing dialog when clicking outside of dialog
                if (reason && reason == "backdropClick") return;
                setModalIsOpen(false);
            }}
            aria-labelledby="new-menu-item-dialog-title"
            aria-describedby="new-menu-item-dialog-description"
            maxWidth='md'
            fullWidth={true}
            disableEscapeKeyDown
        >
            <Formik
                enableReinitialize={true}
                initialValues={menuItem}
                onSubmit={(menuItem: MenuItem) => {
                    handleSave(menuItem);
                }}
                validationSchema={MenuItemValidationSchema}
                validate={(values) => {
                    // Slightly hacky, we're using the validation to calculate the alleriges/diets of this menu-item
                    const processedMenuItem = processMenuItem(values);
                }}
                validateOnMount={true}
                initialErrors={{ title: 'Required' }}
            >
                {(formik: FormikProps<MenuItem>) => {
                    const { values, handleBlur, handleChange, isValid, handleSubmit, setFieldValue } = formik;

                    return (
                        <Form>
                            <DialogTitle>
                                <Box display={'flex'} alignItems={'center'} width={'100%'}>
                                    <Typography width={'100%'} fontSize={'1.25rem'}>
                                        {isEdit ? `Edit ${menuItem.title.length ? menuItem.title : 'Menu Item'}` : 'New Menu Item'}
                                    </Typography>
                                    <Box display={'flex'} justifyContent={'flex-end'} >
                                        <IconButton
                                            onClick={() => {
                                                setModalIsOpen(false);
                                            }}
                                            disableRipple
                                            sx={{ cursor: 'pointer', float: 'right', width: '20px' }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </DialogTitle>
                            <Divider />
                            <DialogContent>
                                <Box
                                    display={"flex"}
                                >
                                    {/* Item Image  */}
                                    <Box width={'145px'} >
                                        <GoldiInputImage
                                            id='image'
                                            formik={formik}
                                            sx={{ minHeight: 145 }}
                                            imageUrl={menuItem.imageUrl || (menuItem.imageUrls && menuItem.imageUrls[0]) || undefined}
                                        />
                                    </Box>
                                    <Box width={'100%'} ml={2}>
                                        <Grid container spacing={1}>
                                            {/* Item Name */}
                                            <Grid item xs={8}>
                                                <Typography>Name</Typography>
                                                <FastField
                                                    name='title'
                                                    type='text'
                                                >
                                                    {({ field, form: { errors } }: FieldProps) => (
                                                        <GoldiTextField
                                                            {...field}
                                                            id={field.name}
                                                            value={values.title || ''}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={!!errors[field.name]}
                                                        />
                                                    )}
                                                </FastField>
                                            </Grid>
                                            {/* Item Price */}
                                            <Grid item xs={4}>
                                                <Typography>Price ($)</Typography>
                                                <FastField
                                                    name='price'
                                                    type='number'
                                                >
                                                    {({ field, form: { errors } }: FieldProps) => (
                                                        <GoldiPriceField
                                                            {...field}
                                                            id={field.name}
                                                            value={values.price || 0}
                                                            onChange={(price: number) => {
                                                                setFieldValue(field.name, price);
                                                            }}
                                                            onBlur={handleBlur}
                                                            error={!!errors[field.name]}
                                                        />
                                                    )}
                                                </FastField>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {/* Item Description */}
                                                <Box width={'100%'}>
                                                    <Typography>Description</Typography>
                                                    <FastField
                                                        name='description'
                                                        type='text'
                                                    >
                                                        {({ field, form: { errors } }: FieldProps) => (
                                                            <GoldiTextField
                                                                {...field}
                                                                id={field.name}
                                                                value={values.description || ''}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={!!errors[field.name]}
                                                            />
                                                        )}
                                                    </FastField>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12}>
                                                {/* Item Extra Text */}
                                                <Box width={'100%'}>
                                                    <Typography>Additional Information</Typography>
                                                    <FastField
                                                        name='subtext'
                                                        type='text'
                                                    >
                                                        {({ field, form: { errors } }: FieldProps) => (
                                                            <GoldiTextField
                                                                {...field}
                                                                id={field.name}
                                                                value={values.subtext || ''}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={!!errors[field.name]}
                                                            />
                                                        )}
                                                    </FastField>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12}>
                                                {/* Item Description */}
                                                <Box width={'100%'}>
                                                    <Typography>Tags</Typography>
                                                    <FastField
                                                        name='tags'
                                                        type='text'
                                                    >
                                                        {({ field, form: { errors } }: FieldProps) => (
                                                            <TagsInputField
                                                                initialTags={values?.tags || []}
                                                                handleGetTags={(tags: string[]) => {
                                                                    setFieldValue(`tags`, tags);
                                                                }}
                                                            />
                                                        )}
                                                    </FastField>
                                                </Box>
                                            </Grid>

                                            {/* <ChatGptIngredientsResultDisplay chatgptIngredients={chatgptIngredients} /> */}


                                        </Grid>
                                    </Box>
                                </Box>
                                <Divider sx={{ mt: 4, mb: 2 }} />
                                <Box>
                                    <Grid item xs={12}>
                                        {/* Item Components */}
                                        <Typography>Components</Typography>
                                        <FieldArray name="components">
                                            {({ remove: removeComponent, push: addComponent }) => (
                                                <Box>
                                                    {(values.components || []).map((component: MenuItemComponent, index: number) => (
                                                        <MenuItemComponentForm
                                                            key={`component-${index}`}
                                                            component={component}
                                                            customizations={values.menuItemCustomizations}
                                                            index={index}
                                                            removeComponent={removeComponent}
                                                            formik={formik}
                                                        />
                                                    ))}
                                                    <Button onClick={() => {
                                                        addComponent({ ...newMenuItemComponent, title: `Component ${(values.components || []).length + 1}` });
                                                    }}>Add Component</Button>
                                                </Box>
                                            )}
                                        </FieldArray>
                                    </Grid>
                                </Box>
                                <Divider sx={{ mt: 4, mb: 2 }} />
                                <Box>
                                    <Grid item xs={12}>
                                        {/* Item Customizations */}
                                        <Typography>Customizations</Typography>
                                        <FieldArray name="menuItemCustomizations">
                                            {({ remove: removeCustomization, push: addCustomization }) => (
                                                <Box>
                                                    {(values.menuItemCustomizations || []).map((customization, index: number) => (
                                                        <MenuItemCustomizationForm
                                                            key={`menuItemCustomizations-${index}`}
                                                            customization={customization}
                                                            removeCustomization={removeCustomization}
                                                            index={index}
                                                            formik={formik}
                                                        />
                                                    ))}
                                                    <Button onClick={() => {
                                                        addCustomization({ ...generateNewMenuItemCustomization(), title: `Customization ${(values.menuItemCustomizations || []).length + 1}` });
                                                    }}>Add Customization</Button>
                                                </Box>
                                            )}
                                        </FieldArray>
                                    </Grid>
                                </Box>
                                <Divider sx={{ mt: 4, mb: 2 }} />
                                <Box>
                                    {values.allergies && values.allergies.length > 0 && <Grid item xs={12}>
                                        {/* Item Allergies */}
                                        <AllergiesDisplay allergies={values.allergies} />
                                    </Grid>}
                                    <Grid item xs={12}>
                                        {/* Item Additional Allergies */}
                                        <Box width={'100%'}>
                                            <Typography>Additional Allergies</Typography>
                                            <FastField
                                                name='additionalAllergies'
                                                type='text'
                                            >
                                                {() => (
                                                    <Autocomplete
                                                        multiple
                                                        limitTags={2}
                                                        onChange={(e, value) => {
                                                            setFieldValue(`additionalAllergies`, value);
                                                        }}
                                                        options={Object.keys(Allergies)}
                                                        getOptionLabel={(option: string) => Allergies[option]}
                                                        value={values.additionalAllergies || []}
                                                        renderInput={(params) => (
                                                            <TextField {...params} placeholder="Dislikes" />
                                                        )}
                                                    />
                                                )}
                                            </FastField>
                                        </Box>
                                    </Grid>
                                    {values.diets && values.diets.length > 0 && <Grid item xs={12}>
                                        <DietsDisplay diets={values.diets} />
                                    </Grid>}
                                    <Grid item xs={12} mt={1}>
                                        {/* Item Additional Diets */}
                                        <Box width={'100%'}>
                                            <Typography>Additional Supported Diets</Typography>
                                            <FastField
                                                name='additionalDiets'
                                                type='text'
                                            >
                                                {() => (
                                                    <Autocomplete
                                                        multiple
                                                        limitTags={2}
                                                        onChange={(e, value) => {
                                                            setFieldValue(`additionalDiets`, value);
                                                        }}
                                                        options={Object.keys(Diets)}
                                                        getOptionLabel={(option: string) => Diets[option]}
                                                        value={values.additionalDiets || []}
                                                        renderInput={(params) => (
                                                            <TextField {...params} placeholder="Preferences" />
                                                        )}
                                                    />
                                                )}
                                            </FastField>
                                        </Box>
                                    </Grid>
                                </Box>
                            </DialogContent>
                            <DialogActions sx={{
                                position: 'sticky',
                                bottom: 0,
                                background: '#fff',
                                borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                            }}>
                                <FormikAutoSave
                                    canAutoSave={canAutoSave && isValid && modalIsOpen}
                                    sx={{
                                        mr: 2,
                                        fontSize: '0.75rem',
                                        color: 'text.secondary',
                                    }}
                                />
                                <Button sx={{ mr: 2 }} onClick={handleGenerateIngredients}>Generate Ingredient</Button>
                                <Button sx={{ mr: 2 }} onClick={() => {
                                    setModalIsOpen(false);
                                }}>Cancel</Button>
                                <Button
                                    variant='contained'
                                    disableElevation
                                    onClick={() => {
                                        handleSubmit();
                                        setModalIsOpen(false);
                                    }}
                                    disabled={!isValid || saveDisabled}
                                >
                                    Save
                                </Button>
                            </DialogActions>
                        </Form>
                    )
                }}
            </Formik>
        </Dialog>
    )
}

export default MenuItemDialog;