import { useNavigate } from 'react-router';
import { Divider, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { MERCHANTS } from 'Utils/constants/routes';
import GoldiButton from '../../Button';
// import CustomEdit from 'components/CustomEditBox';
import { MerchantFormStepPropsType } from '..';
// import AuthService, { ROLES } from 'services/auth.service';
import MerchantSummaryDisplay from 'components/MerchantSummaryDisplay';
import { Merchant } from 'interfaces/MerchantInterface';
import { useTheme } from '@mui/material/styles';
import ErrorAlert from 'components/ErrorAlert';

type MerchantSummaryPropsType = {
  handleSubmit: (params: any) => Promise<any>
} & MerchantFormStepPropsType

const MerchantSummary = ({setCurrentStep, currentValues, handleSubmit}: MerchantSummaryPropsType) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [error, setError] = useState('');
  const submitForm = async () => {
    await handleSubmit(currentValues).then((res) => {
      if(res.success == false) {
        setError(res.error);
      } else {
        navigate(MERCHANTS);
      }
    }).catch((e) => {
      console.log(e);
    });
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography fontWeight={'bold'} fontSize={'1.4rem'}>
          Review
        </Typography>
        {error && <ErrorAlert error={error} />}
      </Grid>

      <Grid item xs={12}>
        <Typography variant='subtitle1' fontWeight={'bold'}>
          Look over the info below to make sure everything looks right
        </Typography>
      </Grid>

      <Divider variant='middle' />

      <MerchantSummaryDisplay
        merchant={currentValues as Merchant}
        canEdit={false}
      />

      <Grid
        container
        mt={5}
        sx={{
          position: 'sticky',
          bottom: 0,
          background: 'white',
          justifyContent: 'space-between',
          padding: 1,
        }}
      >
        <Grid item >
          <GoldiButton
            text='Edit'
            outlined={true}
            onClick={() => setCurrentStep(1)}
          />
        </Grid>

        <Grid item justifyContent='flex-end'>
          <GoldiButton onClick={submitForm} text='Save and Finish' />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MerchantSummary;
