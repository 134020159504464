import { ReactElement } from 'react';
import { GridList } from './index.styles';

interface ICustomGridList {
  sx?: Record<string, unknown>;
  children: Array<ReactElement>;
}
const CustomGridList = ({ sx, children }: ICustomGridList) => {
  return <GridList sx={sx}>{children}</GridList>;
};

export default CustomGridList;
