import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Select, Box, TextField } from '@mui/material';
import { GoldiTextField } from './InputField';

type ActionDialogPropsType = {
    isOpen: boolean;
    handleClose: () => void;
    title: string;
    body: string;
    handleSubmit: () => void;
    disabled?: boolean;
    fieldValue?: any,
    fieldText?: string,
    fieldOptions?: any[],
    setFieldValue?: (val: any) => void
}

const ActionDialog = ({
  isOpen,
  handleClose,
  title,
  body,
  handleSubmit,
  disabled,
  fieldValue,
  fieldText,
  fieldOptions,
  setFieldValue
}: ActionDialogPropsType) => {
    const onChange = (val: any) => {
      if(setFieldValue) {
        setFieldValue(val.target.value);
      }
    }
    return (
    <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="action-dialog-title"
        aria-describedby="action-dialog-description"
    >
        <DialogTitle id="action-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="action-dialog-description">
            {body}
          </DialogContentText>
        </DialogContent>
        {setFieldValue && <DialogContent>
          {fieldText}
          <Box>
          {fieldOptions && <Select
            MenuProps={{ PaperProps: { sx: { maxHeight: '200px' } } }}
            sx={{
              borderRadius: '4px',
              width: '100%',
              height: '44px',
            }}
            value={fieldValue}
            disabled={disabled}
            onChange={onChange}
          >
            {fieldOptions.map((option, index) => (
              <MenuItem value={option} key={index}>
                {option}
              </MenuItem>
            ))}
          </Select>}
          {!fieldOptions && <GoldiTextField placeholder={fieldText} value={fieldValue} onChange={onChange}></GoldiTextField>}
          </Box>
          </DialogContent>}
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button 
            onClick={handleSubmit} 
            autoFocus 
            color='primary' 
            disableElevation 
            variant='contained'
            disabled={disabled}
          >
            Confirm
          </Button>
        </DialogActions>
    </Dialog>
    )
}

export default ActionDialog