import { Box, Button, Grid, Typography } from '@mui/material';
import { generateGlobalSettingsQuery } from 'Utils/helpers/queryGenerators';
import { GoldiTextField } from 'components/InputField';
import LoadingPage from 'components/LoadingPage';
import { FastField, FieldProps, Form, Formik, FormikProps } from 'formik';
import { GlobalSettings } from 'interfaces/GlobalSettingsInterface';
import { useApi } from 'providers/ApiProvider';
import { useQuery } from 'react-query';

import { OverviewContainer } from 'components/RestaurantOverview/index.styles';
import StyledContainer from 'components/StyledContainer';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css';

const AdminGlobalSettings = () => {

  const api = useApi();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const { status: _status, error: _error, data: globalsettings, isLoading: _isLoading,refetch } = useQuery(generateGlobalSettingsQuery(), async () => {
    return api.getGlobalSettings();
  });
  const refetchHandler = async () => {
    setLoader(true);
    await refetch();
    setLoader(false);
  }

  useEffect(() => {
    refetchHandler();
  }, [])
  async function handleSave(settings: GlobalSettings) {
    await api.editGlobalSettings(settings);
   // navigate(0);
  }
  if (_isLoading) <LoadingPage />;
  return (
    !globalsettings || loader ? (
      <LoadingPage />
    ) : (
    <StyledContainer>
      <div className='main-global-settings-root'>

        <div className='main-global-settings-outlet'>
          <h1>Global Settings</h1>
          <OverviewContainer>
            <Formik
              enableReinitialize={true}
              initialValues={globalsettings || { id: '', active: false, goldiStripeId: '', markupPercentage: 0, deliveryFee: 0 }}
              onSubmit={(globalsettings: GlobalSettings) => {
                handleSave(globalsettings);
              }}
              // validationSchema={allergyValidationSchema}
              validateOnMount={true}
              initialErrors={{ id: 'Required', title: 'Required' }}
            >
              {(formik: FormikProps<GlobalSettings>) => {
                const { handleBlur, handleChange, isValid, handleSubmit } = formik;
                return (
                  <Form>

                    <Box>
                      <Grid container spacing={1}>
                        {/* Item ID */}
                        {/* <Grid item xs={9}>
                          <Typography>ID </Typography>
                          <FastField
                            readOnly={true}
                            name='id'
                            type='text'
                            render={({ field, form: { errors } }: FieldProps) => (
                              <Label 
                            
                              />
                            )}
                          />
                        </Grid> */}
                        {/* <Grid item xs={3}>
                      <FormControlLabel
                        value="top"
                        control={
                          <Switch
                            checked={formik.values.active}
                            onChange={handleChange}
                            name={'active'}
                          />
                        }
                        label="Active"
                        labelPlacement="top"
                      />
                    </Grid> */}
                        <Grid item xs={12}>
                          <Typography>Goldi Stripe Id</Typography>
                          <FastField
                            name='goldiStripeId'
                            type='text'
                            render={({ field, form: { errors } }: FieldProps) => (
                              <GoldiTextField
                                {...field}
                                id={field.name}
                                value={formik.values.goldiStripeId}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={!!errors[field.name]}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {/* Item Description */}
                          <Box width={'100%'}>
                            <Typography>Mark up Percentage</Typography>
                            <FastField
                              name='markupPercentage'
                              type='numeric'
                              render={({ field, form: { errors } }: FieldProps) => (
                                <GoldiTextField
                                  {...field}
                                  id={field.name}
                                  value={formik.values.markupPercentage}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={!!errors[field.name]}
                                />
                              )}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          {/* Item Description */}
                          <Box width={'100%'}>
                            <Typography>Delivery Fee</Typography>
                            <FastField
                              name='deliveryFee'
                              type='numeric'
                              render={({ field, form: { errors } }: FieldProps) => (
                                <GoldiTextField
                                  {...field}
                                  id={field.name}
                                  value={formik.values.deliveryFee}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={!!errors[field.name]}
                                />
                              )}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12}>

                        </Grid>
                        {/* drop down allergies and dierts */}
                      </Grid>
                    </Box>


                    <Button sx={{ mr: 2 }} onClick={() => {
                      
                      refetchHandler();
                    }}>Cancel</Button>
                    <Button
                      variant='contained'
                      disableElevation
                      onClick={() => {
                        handleSubmit();

                      }}
                      disabled={!isValid}
                    >
                      Save
                    </Button>

                  </Form>
                )
              }}
            </Formik>
          </OverviewContainer>
        </div>

      </div>
    </StyledContainer>
    )
  );
};

export default AdminGlobalSettings;
