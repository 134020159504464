import { Box, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useQuery } from "react-query";
import { Link, useNavigate } from 'react-router-dom';
import './index.css';

import LoadingPage from 'components/LoadingPage';
import StyledContainer from 'components/StyledContainer';
import CustomTypography from 'components/Typography';
import { Order, OrderStatus, PaymentStatus } from 'interfaces/OrderInterface';
import { useApi } from 'providers/ApiProvider';

import { generateOrdersQuery } from 'Utils/helpers/queryGenerators';
import ActionDialog from 'components/ActionDialog';
import TableActions from 'components/TableActions';
import moment from 'moment';
import { useMutationQuery } from 'providers/MutationQueryProvider';
import OrdersDialog from './OrdersDialog';
const Orders = () => {
  const newOrder = {
    id: '1',

  };
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [dialogSettings, setDialogSettings] = useState<Record<string, any>>({});
  const [actionDisabled, setActionDisabled] = useState(false);
  const [editOrderIsOpen, setEditOrderIsOpen] = useState(false);
  const [externalOrderNumber, setExternalOrderNumber] = useState("");
  const [order, setOrder] = useState<Order>(newOrder);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const navigate = useNavigate();
  const api = useApi();
  const [roleType, setRoleType] = useState();



  const { status: _status, error: _error, data: orders, isLoading: _isLoading, refetch } = useQuery(generateOrdersQuery(), async () => {
    //const isAdmin = await AuthService.isAdmin();

    // if (!isAdmin) {
    //   navigate('/');
    // }

    return api.getAdminOrders();
  });
  const { editAdminOrderMutation } = useMutationQuery();
  useEffect(() => {
    refetch();
    // console.log(orders);
  }, [refetch])

  // useEffect(() => {
  //   console.log(roleType)
  //   AuthService.getCurrentUserRole().then((data) => {
  //     setRoleType(data.roleType);
  //   });
  // }, [roleType]);
  const TabHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
  });

  const isLoading = !(orders);

  const getChipColor = (status: OrderStatus) => {
    switch (status) {
      case OrderStatus.COMPLETE:
      case OrderStatus.CANCELLED:
        return 'success';
      case OrderStatus.PENDING:
        return 'error';
      case OrderStatus.PAUSED:
        return 'warning';
      case OrderStatus.INPROCESS:
        return 'warning';
      case OrderStatus.DISABLED:
        return 'error';
      case OrderStatus.CLAIMED:
        return 'info';
      case OrderStatus.ESCALATED:
        return 'error';
      default:
        return 'default';
    }
  }

  const getPaymentStatusChipColor = (status: PaymentStatus) => {
    switch (status) {
      case PaymentStatus.SUCCEEDED:
      case PaymentStatus.PAYMENT_NA:
        return 'success';
      case PaymentStatus.PAYMENT_FAILED:

        return 'error';
      case PaymentStatus.AMOUNT_CAPTURABLE_UPDATED:
      case PaymentStatus.PROCESSING:
      case PaymentStatus.PENDING:
        return 'warning';
      default:
        return 'default';
    }
  }


  // const handleEvent: GridEventListener<'rowClick'> = (
  //   params
  // ) => {
  //   const orderId = params.row.id;
  //   // navigate(`/admin/orders/${orderId}`);
  // };




  const completeOrder = (params: any) => {
    setActionDisabled(!externalOrderNumber ? true : false)
    setDialogSettings(() => ({
      title: 'Mark Complete',
      fieldText: 'External Order Number',
      setFieldValue: (val:string) => {
        setExternalOrderNumber(val)
        setActionDisabled(!val ? true : false)
      },
      fieldValue: externalOrderNumber,
      body: `Mark "${params.row.title}" order with id "${params.row.id}"  as complete `,
      handleSubmit: async (externalOrderNumber:string) => {
        setActionDisabled(true);
        const result = await api.markOrderAsComplete(
          params.row.id,
          externalOrderNumber
        );
        setOrder(result);
        setActionDisabled(false);
        setDialogIsOpen(false);
        setExternalOrderNumber("");
        refetch();
      },
    }));
    setDialogIsOpen(true);
  }
  const escalateOrder = (params: any) => {
    setDialogSettings(() => ({
      title: 'Mark Escalated',
      body: `Mark "${params.row.title}" order with id "${params.row.id}"  as escalated `,
      handleSubmit: async () => {
        setActionDisabled(true);
        const result = await api.markOrderAsEscalated(
          params.row.id
        );
        setOrder(result);
        setActionDisabled(false);
        setDialogIsOpen(false);
        refetch();
      },
    }));
    setDialogIsOpen(true);
  }

  const cancelOrder = (params: any) => {
    setDialogSettings(() => ({
      title: 'Cancel Order',
      body: `Mark "${params.row.title}" order with id "${params.row.id}"  as cancelled `,
      handleSubmit: async () => {
        setActionDisabled(true);
        const result = await api.markOrderAsCancelled(
          params.row.id
        );
        setOrder(result);
        setActionDisabled(false);
        setDialogIsOpen(false);
        refetch();
      },
    }));
    setDialogIsOpen(true);
  }

  const claimOrder = (params: any) => {
    setDialogSettings(() => ({
      title: 'Mark Claimed',
      body: `Mark "${params.row.title}" order with id "${params.row.id}"  as Claimed `,
      handleSubmit: async () => {
        setActionDisabled(true);
        const result = await api.markOrderAsClaimed(
          params.row.id
        );

        setOrder(result);
        setActionDisabled(false);
        setDialogIsOpen(false);
        refetch();
      },
    }));
    setDialogIsOpen(true);
  }


  return isLoading ? (
    <LoadingPage />
  ) : (
    <>
      <StyledContainer>
        <TabHeader>
          <CustomTypography
            fontWeight='bold'
            fontSize='24px'
            lineHeight='26px'
            marginBottom='37px'
          >
            Orders
          </CustomTypography>

        </TabHeader>
        <Box sx={{ backgroundColor: 'white', height: 750 }}>
          <DataGrid
            showCellRightBorder={true}
            // onRowClick={handleEvent}
            rows={orders || []}
            columns={[


              {
                field: 'id',
                headerName: 'Goldi id',
                minWidth: 120,
                flex: 1,
                maxWidth: 120,
                renderCell: (params) => {
                  return (
                    <Link to={`/admin/orders/${params.row.id}`} >
                      {params.row.id}
                    </Link>

                  );
                }

              },
              {
                field: 'orderType',
                headerName: 'Type',
                minWidth: 80,
                flex: 1,
                maxWidth:80,

              },
              //   {
              //   field: 'viewOrder',
              //   headerName: '',
              //   minWidth: 30,
              //   maxWidth:50,
              //   flex: 1,

              //   renderCell: (params) => {
              //     return (
              //       <TableActions>
              //         <Tooltip title='View Order Details'>
              //           <IconButton
              //             sx={{ color: '#0C847E' }}
              //             onClick={() => {navigate(`/admin/orders/${params.row.id}`)}}
              //           >
              //               {<Stop />}
              //           </IconButton>
              //         </Tooltip>
              //       </TableActions>
              //     );
              //   }
              // },
              {
                field: 'createdAt',
                headerName: 'Created At',
                minWidth: 140,
                maxWidth: 140,
                flex: 1,
                renderCell: (params) => {
                  return (
                    <Chip
                      label={moment(params.value).format('DD MMM YY HH:mm')}
                    />
                  );
                }

              },

              {
                field: 'restaurantName',
                headerName: 'Restaurant',
                minWidth: 180,
                maxWidth: 180,
                flex: 1,
                renderCell: (params) => {
                  return (
                    <a href={`${params.row.restaurantHomePage}`} target='_new' title='go to order page' >
                      {params.value}
                    </a>
                  );
                }
              },
              {

                field: 'userName',
                headerName: 'Customer',
                minWidth: 160,
                maxWidth: 160,
                flex: 1,

              },
            
              {
                field: 'orderStatus',
                headerName: 'Status',
                minWidth: 100,
                maxWidth: 100,
                flex: 1,
                renderCell: (params) => {
                  return (
                    <Chip
                      label={params.value}
                      color={getChipColor(params.value)}
                    />
                  );
                }
              },
              {

                field: 'claimedBy',
                headerName: 'Claimed By',
                minWidth: 120,
                maxWidth: 120,
                flex: 1,

              },
              {

                field: 'externalOrderId',
                headerName: 'External Id ',
                minWidth: 100,
                maxWidth: 120,
                flex: 1,

              },
             
              {
                field: 'actions',
                headerName: 'Actions',
                maxWidth: 200,
                flex: 1,
                renderCell: (params) => {
                  return (
                    <>
                      {/* <TableActions>
                        <Tooltip title='Mark Claimed'>
                          <IconButton
                            sx={{ color: '#0C847E' }}
                            onClick={() => claimOrder(params)}
                          >
                            {<Handyman />}
                          </IconButton>
                        </Tooltip>
                      </TableActions>
                      <TableActions>
                        <Tooltip title='Mark Complete'>
                          <IconButton
                            sx={{ color: '#0C847E' }}
                            onClick={() => completeOrder(params)}
                          >
                            {<Done />}
                          </IconButton>
                        </Tooltip>
                      </TableActions>
                      <TableActions>
                        <Tooltip title='Cancel Order'>
                          <IconButton
                            sx={{ color: '#0C847E' }}
                            onClick={() => cancelOrder(params)}
                          >
                            {<Cancel />}
                          </IconButton>
                        </Tooltip>
                      </TableActions> */}
                      {
                        params.row.active ? <TableActions
                          handleEdit={() => {
                            // alert('Working on it');
                            setOrder(params.row);
                            setIsEdit(true);
                            setEditOrderIsOpen(true);
                          }}
                          handleClaim={() => {
                            claimOrder(params)
                          }}
                          handleCancel={() => {
                            cancelOrder(params)
                          }}
                          handleComplete={() => {
                            completeOrder(params)
                          }}
                        />
                          :
                          <TableActions
                            handleEdit={() => {
                              // alert('Working on it');
                              setOrder(params.row);
                              setIsEdit(true);
                              setEditOrderIsOpen(true);
                            }}
                            handleClaim={() => {
                              claimOrder(params)
                            }}
                            handleCancel={() => {
                              cancelOrder(params)
                            }}
                            handleComplete={() => {
                              completeOrder(params)
                            }}
                            handleEscalated={() => {
                              escalateOrder(params)
                            }}
                          />
                      }
                    </>
                  );
                },
              },

            ]}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
        </Box>
      </StyledContainer>
      <ActionDialog
        isOpen={dialogIsOpen}
        handleClose={() => {
          setExternalOrderNumber("");
          setDialogIsOpen(false);
          setActionDisabled(false);
        }}
        title={dialogSettings.title}
        body={dialogSettings.body}
        fieldText={dialogSettings.fieldText}
        fieldValue={dialogSettings.fieldValue}
        setFieldValue={dialogSettings.setFieldValue}
        handleSubmit={() => {
          if(externalOrderNumber) {
            dialogSettings.handleSubmit(externalOrderNumber)
          } else {
            dialogSettings.handleSubmit()
          }
        }}
        disabled={actionDisabled}
      />
      <OrdersDialog
        isOpen={editOrderIsOpen}
        saveDisabled={
          editAdminOrderMutation.isLoading
        }
        order={order}
        isEdit={isEdit}
        handleCloseDialog={() => {
          setEditOrderIsOpen(false);
        }}
        handleSave={async (order: Order) => {
          const result = await editAdminOrderMutation.mutateAsync(order)

          if (!isEdit) {
            setOrder(result);
            setIsEdit(true);
          }
        }}
      />
    </>
  );
};

export default Orders;
