import React, { useEffect, useState } from 'react';
import GoldiHeader from 'components/GoldiHeader';
import CustomTypography from 'components/Typography';
import { Content } from './index.styles';
import { Container } from '../index.styles';
import { useForm } from 'react-hook-form';
import CustomTextField from 'components/CustomTextField';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomLink from 'components/CustomLink';
import CustomButton from 'components/CustomButton';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { resetPasswordSchema } from 'Utils/Schemas/ResetPassword.schema';
import AuthService from 'services/auth.service';
import LoadingPage from 'components/LoadingPage';

type ResetPasswordFormFields = {
  password: string;
  confirmPassword: string;
};

function ResetPassword(): React.ReactElement {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const oobCode = searchParams.get('oobCode') as string;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] =
    useState<boolean>(false);

  useEffect(() => {
    async function verifyCode() {
      const isCodeValid = await AuthService.verifyResetPasswordCode(oobCode);

      if (!isCodeValid) navigate('/');
      setIsLoading(false);
    }

    verifyCode();
  }, [navigate, oobCode]);

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<ResetPasswordFormFields>({
    mode: 'onChange',
    resolver: yupResolver(resetPasswordSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  if (isLoading) return <LoadingPage />;

  const onChangePassword = async (data: ResetPasswordFormFields) => {
    if (oobCode) {
      await AuthService.resetPassword(oobCode, data.password);
      navigate('/');
    }
  };

  return (
    <Container>
      <GoldiHeader />

      <Content>
        <CustomTypography
          fontWeight='bold'
          fontSize='24px'
          lineHeight='26px'
          letterSpacing='-0.015em'
          color='#000000'
          marginBottom='7px'
        >
          Welcome to Goldi
        </CustomTypography>

        <CustomTypography
          fontWeight='500'
          fontSize={'15px'}
          lineHeight='20px'
          letterSpacing='-0.015em'
          color='#000000'
          marginBottom='31px'
        >
          Create a new password to secure your account.
        </CustomTypography>

        <CustomTextField
          id='password'
          label='New Password'
          fieldName='password'
          register={register}
          errors={errors}
          type={showNewPassword ? 'text' : 'password'}
          endAdornmentText={showNewPassword ? 'HIDE' : 'SHOW'}
          handleClickEndAdornment={() => setShowNewPassword(!showNewPassword)}
        />

        <CustomTextField
          id='confirm-password'
          label='Confirm New Password'
          fieldName='confirmPassword'
          register={register}
          errors={errors}
          type={showConfirmNewPassword ? 'text' : 'password'}
          endAdornmentText={showConfirmNewPassword ? 'HIDE' : 'SHOW'}
          handleClickEndAdornment={() =>
            setShowConfirmNewPassword(!showConfirmNewPassword)
          }
        />

        <CustomTypography
          fontSize='12px'
          lineHeight='16px'
          color='rgba(0, 0, 0, 0.7)'
          marginBottom='16px'
        >
          By clicking Change Password, you agree to our
          <CustomLink
            sx={{
              fontWeight: 'normal',
              fontSize: '12px',
              lineHeight: '16px',
              display: 'inline',
            }}
            onClick={() => console.log('Terms and Conditions')}
          >
            Terms and Conditions
          </CustomLink>
          and{' '}
          <CustomLink
            sx={{
              fontWeight: 'normal',
              fontSize: '12px',
              lineHeight: '16px',
              display: 'inline',
            }}
            onClick={() => console.log('Privacy Statement')}
          >
            Privacy Statement
          </CustomLink>
          .
        </CustomTypography>

        <CustomButton
          onClick={handleSubmit(onChangePassword)}
          disabled={!isValid}
          width='100%'
        >
          Change Password
        </CustomButton>
      </Content>
    </Container>
  );
}

export default React.memo(ResetPassword);
