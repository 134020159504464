import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from "react-query";
import StyledContainer from 'components/StyledContainer';

import SimpleNav from 'components/SimpleNav';
import { useApi } from 'providers/ApiProvider';
import LoadingPage from 'components/LoadingPage';
import RestaurantForm from 'components/RestaurantForm';
import { useMutationQuery } from 'providers/MutationQueryProvider';
import { Restaurant } from 'interfaces/ResturantInterface';

import AuthService from 'services/auth.service';
import { generateRestaurantIdQuery } from 'Utils/helpers/queryGenerators';

const EditRestaurant = () => {
  // API
  const api = useApi();
  const { restaurantId } = useParams();
  const navigate = useNavigate();
  const { editRestaurantMutation } = useMutationQuery();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    (async () => {
      const _isAdmin = await AuthService.isAdmin();
      setIsAdmin(_isAdmin);
    })()
  }, [isAdmin]);

  const { status: _restaurantStatus, error: _restaurantError, data: restaurant, isLoading: isLoadingRestaurant, refetch } = useQuery(generateRestaurantIdQuery(restaurantId), async () => {
    return api.getRestaurantById(restaurantId);
  });

  useEffect(() => {
    refetch();
  }, []);
  
  const handleSubmit = (params: Restaurant): Promise<any> => {
    return editRestaurantMutation.mutateAsync(params)
  }

  return ( isLoadingRestaurant ? <LoadingPage/> :
    <StyledContainer>
      <SimpleNav text='Edit Restaurant' onClick={() => navigate(restaurant && restaurant.merchantId ? '/merchants/'.concat(restaurant.merchantId, '/restaurants') : '/')} />
      
    <RestaurantForm 
        restaurant={restaurant}
        handleSubmit={handleSubmit}
        isAdmin={isAdmin}
    />
    </StyledContainer>
  );
};

export default EditRestaurant;
