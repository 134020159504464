import { Box, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DataGrid, GridEventListener, GridToolbar } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useQuery } from "react-query";
import { useNavigate } from 'react-router-dom';
import './index.css';

import LoadingPage from 'components/LoadingPage';
import StyledContainer from 'components/StyledContainer';
import CustomTypography from 'components/Typography';
import { useApi } from 'providers/ApiProvider';
import AuthService from 'services/auth.service';

import { generateMerchantsQuery } from 'Utils/helpers/queryGenerators';
import ActionDialog from 'components/ActionDialog';
import { RestaurantStatus } from 'interfaces/ResturantInterface';
const Restaurants = () => {
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [dialogSettings, setDialogSettings] = useState<Record<string, any>>({});
  const [actionDisabled, setActionDisabled] = useState(false);
  const navigate = useNavigate();
  const api = useApi();

  const { status: _status, error: _error, data: restaurants, isLoading: _isLoading, refetch } = useQuery(generateMerchantsQuery(), async () => {
    const isAdmin = await AuthService.isAdmin();

    if (!isAdmin) {
      navigate('/');
    }

    return api.getAdminRestaurants();
  });

  useEffect(() => {
    refetch();
    // console.log(restaurants);
  }, [refetch])

  const TabHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
  });

  const isLoading = false; !(restaurants);

  const getChipColor = (status: RestaurantStatus) => {
    switch (status) {
      case RestaurantStatus.ACTIVE:

        return 'success';
      case RestaurantStatus.DISABLED:
        return 'error';
      case RestaurantStatus.PENDING:
      case RestaurantStatus.PAUSED:
      case RestaurantStatus.INCOMPLETE:
        return 'warning';

      default:
        return 'default';
    }
  }

  const handleEvent: GridEventListener<'rowClick'> = (
    params
  ) => {
    navigate(`/merchants/${params.row.merchantid}/restaurants`);
  };



  return isLoading ? (
    <LoadingPage />
  ) : (
    <>
      <StyledContainer>
        <TabHeader>
          <CustomTypography
            fontWeight='bold'
            fontSize='24px'
            lineHeight='26px'
            marginBottom='37px'
          >
            Restaurants
          </CustomTypography>

        </TabHeader>
        <Box sx={{ backgroundColor: 'white', height: 750 }}>
          <DataGrid
            showCellRightBorder={true}
            rows={restaurants || []}
            onRowClick={handleEvent}

            columns={[

              {
                field: 'name',
                headerName: 'Name',
                minWidth: 200,
                flex: 1,

              },

              {
                field: 'email',
                headerName: 'Email',
                minWidth: 200,
                flex: 1,
              },

              {

                field: 'contactname',
                headerName: 'Contact',
                minWidth: 200,
                flex: 1,

              },
              {

                field: 'contactphonenumber',
                headerName: 'Phone #',
                minWidth: 100,
                flex: 1,

              },
              {

                field: 'businessname',
                headerName: 'Merchant',
                minWidth: 200,
                flex: 1,

              },

              {
                field: 'status',
                headerName: 'status',
                minWidth: 80,
                flex: 1,
                renderCell: (params) => {
                  return (
                    <Chip
                      label={params.value}
                      color={getChipColor(params.value)}
                    />
                  );
                }
              },

              {
                field: 'isdeliverysupported',
                headerName: 'Deliver',
                minWidth: 10,
                flex: 1,
                renderCell: (params) => {
                  return (
                    <Chip
                      label={
                        params.value ? 'Yes' : 'No'
                      }
                      color={
                        params.value
                          ? 'success'
                          : 'error'
                      }
                    />
                  );
                },

              },
              {
                field: 'isdineinsupported',
                headerName: 'Dine In',
                minWidth: 10,
                flex: 1,
                renderCell: (params) => {
                  return (
                    <Chip
                      label={
                        params.value ? 'Yes' : 'No'
                      }
                      color={
                        params.value
                          ? 'success'
                          : 'error'
                      }
                    />
                  );
                },

              },
              {
                field: 'ispickupsupported',
                headerName: 'Pick Up',
                minWidth: 10,
                flex: 1,
                renderCell: (params) => {
                  return (
                    <Chip
                      label={
                        params.value ? 'Yes' : 'No'
                      }
                      color={
                        params.value
                          ? 'success'
                          : 'error'
                      }
                    />
                  );
                },

              },
              {
                field: 'ischowly',
                headerName: 'chowly',
                minWidth: 10,
                flex: 1,
                renderCell: (params) => {
                  return (
                    <Chip
                      label={
                        params.value ? 'Yes' : 'No'
                      }
                      color={
                        params.value
                          ? 'success'
                          : 'error'
                      }
                    />
                  );
                },

              },
              {
                field: 'active',
                headerName: 'Active',
                minWidth: 10,
                flex: 1,
                renderCell: (params) => {
                  return (
                    <Chip
                      label={
                        params.value ? 'Yes' : 'No'
                      }
                      color={
                        params.value
                          ? 'success'
                          : 'error'
                      }
                    />
                  );
                },
              },
              // {
              //   field: 'deletedAt',
              //   headerName: 'Deleted At',
              //   minWidth: 180,
              //   flex: 1,
              //   renderCell: (params) => {
              //     return (
              //       <Chip
              //         label={moment(params.value).format('YY-MM-DD HH:mm')}
              //                         />
              //     );
              //   }

              // },


            ]}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
        </Box>
      </StyledContainer>
      <ActionDialog
        isOpen={dialogIsOpen}
        handleClose={() => {
          setDialogIsOpen(false);
          setActionDisabled(false);
        }}
        title={dialogSettings.title}
        body={dialogSettings.body}
        handleSubmit={dialogSettings.handleSubmit}
        disabled={actionDisabled}
      />
    </>
  );
};

export default Restaurants;
